import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from './index';

/**
 * @deprecated use zzm-base
 * @param color
 * @param size
 * @constructor
 */
export const Plus: FC<IconProps> = ({ color = '#FFFFFF', size = 24 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        d="M12 5.25L12 18.75M18.75 12L5.25 12"
    />
  </svg>;

};
