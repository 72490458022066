import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Body14, Caption } from '../../typography';
import { css } from '@emotion/react';

const Container = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.inverted ? 'flex-end' : !props.isNear && 'space-between'};
  ${props => props.inverted && 'flex-direction: row-reverse; gap: 8px;'}
  ${props => props.isNear && 'gap: 12px;'}
`;

const LabelOff = styled(Caption)(({ theme }) => css`
  color: ${theme.colors.Gray400};
`);

const LabelOn = styled(Caption)(({ theme }) => css`
  color: ${theme.colors.Green};
`);

const LabelFixed = styled(Body14)(({ theme }) => css`
  color: ${theme.colors.Gray600};
`);

const Switch = styled.label<any>`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  opacity: ${props => props.disabled ? 0.5 : 1};
`;

const Input = styled.input(({ theme }) => css`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${theme.colors.Primary};
  }

  &:focus + span {
    box-shadow: 0 0 1px ${theme.colors.Primary};
  }

  &:checked + span:before {
    transform: translateX(12px);
  }
`);

const Slider = styled.span<any>`
  position: absolute;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
`;

export interface ToggleProps {
  name: string;
  enabledLabel?: string;
  disabledLabel?: string;
  fixedLabel?: string;
  isChecked?: boolean;
  onToggle?: (checked: boolean, moveToggler: () => void) => void;
  onClick?: () => void;
  /** @deprecated avoid nline styles props */
  containerStyle?: any;
  disabled?: boolean;
  invertedLabelPosition?: boolean;
  isNear?: boolean;
}

/**
 * @deprecated use src/components/inputs/toggle/ToggleBase.tsx
 */
export const Toggle = React.forwardRef<any, ToggleProps>((props, ref) => {
  const {
    name,
    enabledLabel,
    disabledLabel,
    isChecked = false,
    onToggle,
    fixedLabel,
    disabled,
    invertedLabelPosition,
    isNear,
    ...restProps
  } = props;

  const [checked, setChecked] = useState<boolean>(isChecked);

  const handleToggle = () => {
    if (disabled) {
      return;
    }

    !onToggle && setChecked(!checked);
    onToggle && onToggle(!checked, () => setChecked(!checked));
  };

  return (
    <Container inverted={invertedLabelPosition} isNear={isNear}>
      {!fixedLabel ?
        checked ? (<LabelOn>{enabledLabel}</LabelOn>) : <LabelOff>{disabledLabel}</LabelOff>
        : <LabelFixed>{fixedLabel}</LabelFixed>
      }
      <Switch disabled={disabled}>
        <Input ref={ref} aria-label={name} name={name} type="checkbox" checked={checked} onChange={handleToggle}/>
        <Slider disabled={disabled} {...restProps}/>
      </Switch>
    </Container>
  );
});
