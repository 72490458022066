import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FCC, Flex } from '@zazume/zzm-base';
import { RadioGroupProvider } from './RadioGroupContext';
import { Error } from '../atoms/Error';
import { RadioCardInput, RadioCardInputTemplate } from './atoms/RadioCardInput';
import { RadioInput, RadioInputTemplate } from './atoms/RadioInput';
import { useFormContext } from 'react-hook-form';
import { getFieldError } from '../logic/errors';

const CardsContainer = styled.div<any>(({ theme, direction }) => css`
  max-width: 100%;
  display: flex;
  flex-direction: ${direction};
  gap: ${theme.spacing.s8};
  flex-wrap: wrap;

  @media (min-width: ${theme.breakpoints.sm}) {
    gap: ${theme.spacing.s10};
  }
`);

const DefaultContainer = styled.div<any>(({ theme, direction }) => css`
  display: flex;
  flex-direction: ${direction};
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: nowrap;
  gap: ${theme.spacing.md};
`);

const variantContainers = {
  default: DefaultContainer,
  card: CardsContainer
};

export const RadioGroupVariants = ['default', 'card'];
export type RadioGroupVariant = typeof RadioGroupVariants[number];

interface RadioGroupProps {
  name: string;
  direction?: 'row' | 'column';
  variant?: RadioGroupVariant;
}

/**
 * @deprecated use zzm-base RadioGroup
 */
interface RadioGroupItems {
  RadioCard: RadioCardInputTemplate;
  Radio: RadioInputTemplate;
}

type RadioGroupTemplate = FCC<RadioGroupProps> & RadioGroupItems;

/**
 * @deprecated use src/components/forms/inputGroup/InputGroup.tsx type="radio"
 */
export const RadioGroup: RadioGroupTemplate = ({ name, direction = 'row', variant = 'default', children }) => {
  const { formState: { errors } } = useFormContext();

  const Container = variantContainers[variant];
  const error = getFieldError(name, errors);

  return (
    <Flex direction="column" gap="xs">
      <RadioGroupProvider name={name}>
        <Container direction={direction}>
          {children}
        </Container>
      </RadioGroupProvider>
      <Error error={error?.message}/>
    </Flex>
  );
};

RadioGroup.RadioCard = RadioCardInput;
RadioGroup.Radio = RadioInput;
