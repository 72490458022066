import { FC } from 'react';
import { getFieldError, OptionType, BaseSelect, BaseSelectProps } from '@zazume/zzm-base';
import { useFormContext } from 'react-hook-form';
import { useI18n } from '../../../hooks/useI18n';

export interface SelectV2Props extends Omit<BaseSelectProps, 'currentValue' | 'onSelected' | 'error'> {
  name: string;
  isOptional?: boolean;
  onSelect?: (option: OptionType) => void;
  isNumberInput?: boolean;
}

export const Select: FC<SelectV2Props> = ({ name, isOptional, onSelect, isNumberInput, ...baseProps }) => {
  const { t } = useI18n();
  const { watch, register, setValue, formState: { errors } } = useFormContext();

  const registerInputNumber = { setValueAs: (value: string) => Number(value) };

  const currentValue = watch(name);

  const onSelected = (option: OptionType) => {
    setValue(name, option.value, { shouldValidate: true });
    onSelect?.(option);
  };

  const error = getFieldError(name, errors);
  const optionalText = isOptional ? t('general.optional') : undefined;

  return <>
    <BaseSelect
      {...baseProps}
      name={name}
      error={error}
      currentValue={currentValue}
      onSelected={onSelected}
      optionalText={optionalText}
    />
    <input ref={register(isNumberInput ? registerInputNumber : {})} name={name} hidden readOnly/>
  </>;
};
