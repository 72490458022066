import { IdentifierDTO } from '@zazume/zzm-base';

export const ProvidersKeys = {
  base: 'providers',
  all: (filter = 'all'): string[] =>
    [ProvidersKeys.base, filter],
  byId: (providerId: IdentifierDTO) =>
    [ProvidersKeys.base, providerId],
  getDocuments: (providerId: IdentifierDTO) =>
    [ProvidersKeys.base, 'documents', providerId]
};
