import React, { FC } from 'react';
import { Check, Flex, Regular, ZazumeModal } from '@zazume/zzm-base';
import styled from '@emotion/styled';
import { useI18n } from '../../../../hooks/useI18n';
import { ZazumeServiceDetails } from './ZazumeServiceDetails';
import { PricePrimitive, PriceVO, ZazumeService } from '@zazume/zzm-utils';
import { I18NValues } from '../../../../lib/i18n/generatedKeys';
import { css } from '@emotion/react';
import { useAuth } from '../../../../contexts/AuthProvider';
import { ZazumeServicesHelper } from '#/models/helpers/zazumeServices/ZazumeServicesHelper';

const Container = styled(Flex)(({ theme }) => css`
  padding: 0 ${theme.spacing.sm} ${theme.spacing.s18};
`);

const IconContainer = styled.div`
  display: flex;
`;

interface ZazumeServiceModalProps {
  service: ZazumeService;
  monthlyRent: PricePrimitive;
  onClose: () => void;
}

export const ZazumeServiceModal: FC<ZazumeServiceModalProps> = ({ service, monthlyRent, onClose }) => {
  const { t } = useI18n();
  const { organization } = useAuth();

  const zazumeServicesHelper = new ZazumeServicesHelper(organization!, service);

  const quota = zazumeServicesHelper.getManagementServiceValue();
  const netAmount = zazumeServicesHelper.getMonthlyRentNetAmount(PriceVO.fromPrimitive(monthlyRent)).toUnit();
  const { icon } = zazumeServicesHelper.getZazumeManagementServicesAttributes();

  const benefits: string[] = t(`zazumeServices.${service}.benefits` as I18NValues, { returnObjects: true }) as unknown as string[];

  return (
    <ZazumeModal onClose={onClose}>
      <Container direction="column" gap="s12">
        <ZazumeServiceDetails
          serviceName={service}
          amount={netAmount}
          quota={quota}
          icon={icon}
          monthlyRent={monthlyRent}
          withFootnote
        />
        <Flex direction="column" gap="s9">
          {benefits.map((benefit, index) =>
            <Flex key={index} align="center" gap="s2">
              <IconContainer>
                <Check color="Green" size={20}/>
              </IconContainer>
              <Regular variant="XS">{benefit}</Regular>
            </Flex>
          )}
        </Flex>
      </Container>
    </ZazumeModal>
  );
};
