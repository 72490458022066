import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from '.';

export const ProfileCheck: FC<IconProps> = ({ color = '#FFFFFF', size = 24 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.6351 13.1295C13.1304 13.1295 15.1532 11.1067 15.1532 8.61138C15.1532 6.11609 13.1304 4.09326 10.6351 4.09326C8.1398 4.09326 6.11697 6.11609 6.11697 8.61138C6.11697 11.1067 8.1398 13.1295 10.6351 13.1295ZM10.6351 13.1295C6.89216 13.1295 3.85791 16.1638 3.85791 19.9067M20.1421 11.2148L14.5229 19.9067L10.6351 15.8966"
    />
  </svg>;
};
