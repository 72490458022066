import React from 'react';
import styled from '@emotion/styled';
import { Subtitle, Caption } from '../typography';
import { css } from '@emotion/react';
import { FCC, Theme } from '@zazume/zzm-base';

const Container = styled<any>(Subtitle)(({ theme, background, color }) => css`
  padding: ${theme.spacing.s0} ${theme.spacing.s2};
  background: ${theme.colors[background] || background};
  color: ${theme.colors[color] || color};
  border-radius: ${theme.radius.base};
  width: fit-content;
`);

const SmallContainer = styled<any>(Caption)(({ theme, background, color, borderColor, extraSmall }) => css`
  background: ${theme.colors[background] || background};
  color: ${theme.colors[color] || color};
  border: ${borderColor && `${theme.borderWeights.light} solid ${theme.colors[borderColor] || borderColor}`};
  border-radius: ${theme.radius.base};
  width: fit-content;
  padding: ${theme.spacing.s1} ${theme.spacing.s2};
  font-weight: ${theme.fontWeights.semibold};
  ${extraSmall && 'font-size: 0.625rem;'}
`);

/**
 * @deprecated use zzm-base Tag
 */
export interface TagProps {
  /** Theme[colors] or color value */
  backgroundColor: keyof Theme['colors'] | string;
  /** Theme[colors] or color value */
  textColor: keyof Theme['colors'] | string;
  /** Theme[colors] or color value */
  borderColor?: keyof Theme['colors'] | string;
  small?: boolean;
  extraSmall?: boolean;
  soft?: boolean;
}

/**
 * @deprecated use zzm-base
 * @param backgroundColor
 * @param textColor
 * @param borderColor
 * @param children
 * @param small
 * @param extraSmall
 * @param rest
 * @constructor
 */
export const Tag: FCC<TagProps> = ({ backgroundColor, textColor, borderColor, children, small, extraSmall, ...rest }) => {
  if (small || extraSmall) {
    return <SmallContainer background={backgroundColor} color={textColor} borderColor={borderColor} extraSmall={extraSmall} {...rest}>
      {children}
    </SmallContainer>;
  }
  return <Container background={backgroundColor} color={textColor} borderColor={borderColor} {...rest}>
    {children}
  </Container>;
};
