import { IdentifierDTO } from '@zazume/zzm-base';
import { useQueryClient } from 'react-query';
import { NotificationKeys } from './NotificationKeys';
import { API } from '../../../lib/network/API';
import { useUpdate } from '#/lib/reactQuery/hooks/useUpdate';

interface UseMarkAsReadNotificationResult {
  markAsRead: (notificationId: IdentifierDTO) => Promise<void>;
  markAsUnRead: (notificationId: IdentifierDTO) => Promise<void>;
  markAllAsRead: () => Promise<void>;
}

export const useMarkAsReadNotification = (): UseMarkAsReadNotificationResult => {
  const queryClient = useQueryClient();

  const { mutate: markAsReadMutation } = useUpdate(
    NotificationKeys.all(),
    API.notifications.markAsRead(), { isOptimistic: false });

  const { mutate: markAsUnReadMutation } = useUpdate(
    NotificationKeys.all(),
    API.notifications.markAsUnRead(), { isOptimistic: false });

  const { mutate: markAllAsReadMutation } = useUpdate(
    NotificationKeys.all(),
    API.notifications.markAllAsRead, { isOptimistic: false });

  const markAsRead = async (notificationId: IdentifierDTO) => {
    await markAsReadMutation(notificationId);
    await queryClient.invalidateQueries(NotificationKeys.base);
  };

  const markAsUnRead = async (notificationId: IdentifierDTO) => {
    await markAsUnReadMutation(notificationId);
    await queryClient.invalidateQueries(NotificationKeys.base);
  };

  const markAllAsRead = async () => {
    await markAllAsReadMutation({});
    await queryClient.invalidateQueries(NotificationKeys.base);
  };

  return {
    markAsRead,
    markAsUnRead,
    markAllAsRead
  };
};
