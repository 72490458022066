import React, { FC } from 'react';
import { RouteProps } from 'react-router';
import { Navigate, Route } from 'react-router-dom';
import { RouteContainer } from '../../containers/router/components/RouteContainer';
import { useAuth } from '../../contexts/AuthProvider';
import { isZazumeUser } from '../../models/User';

interface AdminRouteProps extends RouteProps {
}

export const AdminRoute: FC<AdminRouteProps> = ({ element, ...props }) => {
  const { user, logout } = useAuth();

  if (!user) {
    logout();
  }

  if (!isZazumeUser(user)) {
    return <Navigate to="/" replace={true}/>;
  }

  return <Route {...props} element={<RouteContainer body={element}/>}/>;
};
