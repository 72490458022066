import { ZazumeFooterVariants } from '@zazume/zzm-base/dist/components/modal/atoms/ZazumeModalFooter';
import React from 'react';
import styled from '@emotion/styled';
import { useI18n } from '../../hooks/useI18n';
import { FCC, IconType, Regular, ZazumeModal } from '@zazume/zzm-base';
import { css } from '@emotion/react';

const Body = styled.div(({ theme }) => css`
  padding: ${theme.spacing.md} 0;
`);


export interface GenericConfirmationModalProps {
  onConfirm: () => Promise<void> | void;
  onCancel?: () => void;
  title: string;
  description?: string;
  actionButtonText?: string;
  cancelButtonText?: string;
  variant?: ZazumeFooterVariants;
  hideCancelButton?: boolean;
  hideActionButton?: boolean;
  withCloseButton?: boolean;
  isSubmitting?: boolean;
  leftIcon?: IconType;
}

export const GenericConfirmationModal: FCC<GenericConfirmationModalProps> = ({
  onConfirm,
  onCancel,
  title,
  description,
  actionButtonText,
  cancelButtonText,
  variant = 'confirm',
  hideCancelButton,
  hideActionButton,
  withCloseButton = false,
  isSubmitting,
  children,
  leftIcon
}) => {

  const { t } = useI18n();

  const cancelText = hideCancelButton ? undefined : cancelButtonText || t('buttons.cancel');
  const confirmText = hideActionButton ? undefined : actionButtonText || t('buttons.accept');


  return <ZazumeModal title={title} withCloseButton={withCloseButton} circleIcon leftIcon={leftIcon}>
    <Body>
      {description && <Regular variant="S" color="Gray500">{description}</Regular>}
      {children}
    </Body>
    <ZazumeModal.Footer
      onConfirm={onConfirm}
      onCancel={onCancel}
      acceptButtonText={confirmText}
      cancelButtonText={cancelText}
      isSubmitting={isSubmitting}
      variant={variant}
    />
  </ZazumeModal>;
};
