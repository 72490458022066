import { PriceVO, ZazumeService } from '@zazume/zzm-utils';
import { Organization } from '#/models/Organization';
import { OrganizationHelper, ZazumeServicesConstantType, getZazumeManagementServicesAttributes } from '../organization/OrganizationHelper';

const VAT = 21; // 21%

export class ZazumeServicesHelper {
  private readonly organizationHelper: OrganizationHelper;
  private readonly service: ZazumeService;

  constructor(organization: Organization, service: ZazumeService) {
    this.service = service;
    this.organizationHelper = OrganizationHelper.build(organization);
  }

  getManagementPrice(monthlyRent: PriceVO, withVat = true): PriceVO {
    const quota = this.organizationHelper.getManagementServiceValue(this.service) || 0;
    const managementAmount = monthlyRent.percentage(quota);
    if (!withVat) {
      return managementAmount;
    }

    const vatAmount = managementAmount.percentage(VAT);
    return managementAmount.add(vatAmount);
  }

  getMonthlyRentNetAmount(monthlyRent: PriceVO, withVat = true): PriceVO {
    const managementPrice = this.getManagementPrice(monthlyRent, withVat);
    return monthlyRent.subtract(managementPrice);
  }

  getManagementServiceValue(): number | undefined {
    return this.organizationHelper.getManagementServiceValue(this.service);
  }

  getZazumeManagementServicesAttributes(): ZazumeServicesConstantType {
    return getZazumeManagementServicesAttributes(this.organizationHelper)[this.service];
  }

}
