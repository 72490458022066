import React, { FC } from 'react';
import { OrganizationsMenuList } from './OrganizationsMenuList';
import { useGetAllOrganizationsAPI } from '../../../../../hooks';
import { IdentifierDTO } from '../../../../../models';
import { DropdownProps, Flex, Hidden, Position, Visible, ZazumeLogotype } from '@zazume/zzm-base';

interface ZazumeOrganizationOptionsProps {
  header: DropdownProps['header'];
  onSelect: (organizationId: IdentifierDTO) => void;
  position?: Position;
  topValue?: number;
}

export const ZazumeOrganizationOptions: FC<ZazumeOrganizationOptionsProps> = ({ header, onSelect, position, topValue }) => {
  const { organizations } = useGetAllOrganizationsAPI();

  if (!organizations) {
    return null;
  }

  if (organizations.length === 1) {
    return <>
      <Hidden xs sm md>
        <OrganizationsMenuList
        header={header}
        organizations={organizations}
        onSelect={onSelect}/>
      </Hidden>
      <Visible xs sm md>
        <Flex align="center">
          <ZazumeLogotype size={124} color="Primary"/>
        </Flex>
      </Visible>
    </>;
  }

  return <OrganizationsMenuList
    header={header}
    organizations={organizations}
    onSelect={onSelect}
    position={position}
    topValue={topValue}
  />;
};
