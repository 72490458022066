import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Regular } from '@zazume/zzm-base';
import { useI18n } from '../../../../hooks/useI18n';

export const BaseLabel = styled.label(({ theme }) => css`
  display: Flex;
  gap: ${theme.spacing.sm};
  align-items: center;
  width: 100%;
  margin: 0;
  font-family: ${theme.fonts.base};
  font-weight: ${theme.fontWeights.normal};
  font-size: ${theme.fontsSizes.xs};
  line-height: ${theme.lineHeights.xl};
  font-style: normal;
  color: ${theme.colors.Gray600};
  -webkit-font-smoothing: antialiased;
`);

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  isOptional?: boolean;
};

export const Label: FC<LabelProps> = ({ htmlFor, children, isOptional }) => {
  const { t } = useI18n();

  return <BaseLabel htmlFor={htmlFor}>
    {children}
    {isOptional && <Regular color="Gray400">{t('general.optional')}</Regular>}
  </BaseLabel>;
};
