import { CommercialisationFeeType } from '#/pages/workspaces/units/components/unitDetail/leaseTab/serviceContract/modals/addCommercialization/components/Form.schema';
import { PriceVO } from '@zazume/zzm-utils';
import { Split } from './types';
import { Discount } from './Discount';

interface ConstructorParams {
  value?: number;
  absolutePrice?: PriceVO;
  type: CommercialisationFeeType;
  vat?: number;
  retentions?: number;
  isTaxesAlreadyIncluded?: boolean;
  split?: Split;
  discount?: Discount;
}

export class CommercialisationFee {
  value?: number;
  absolutePrice?: PriceVO;
  type: CommercialisationFeeType;
  vat?: number;
  retentions?: number;
  isTaxesAlreadyIncluded?: boolean;
  split?: Split;
  discount?: Discount;

  constructor(params: ConstructorParams){
    this.value = params.value;
    this.absolutePrice = params.absolutePrice;
    this.type = params.type;
    this.vat = params.vat;
    this.retentions = params.retentions;
    this.isTaxesAlreadyIncluded = params.isTaxesAlreadyIncluded;
    this.split = params.split;
    this.discount = params.discount;
  }

  static fromRaw(commercialisationFee: any): CommercialisationFee {
    return new CommercialisationFee({
      value: commercialisationFee.value,
      absolutePrice: commercialisationFee.absolutePrice && PriceVO.fromPrimitive(commercialisationFee.absolutePrice),
      type: commercialisationFee.type,
      vat: commercialisationFee.vat,
      retentions: commercialisationFee.retentions,
      isTaxesAlreadyIncluded: commercialisationFee.isTaxesAlreadyIncluded,
      split: commercialisationFee.split,
      discount: commercialisationFee.discount ? new Discount({
        discountType: commercialisationFee.discount.discountType,
        absoluteDiscount: commercialisationFee.discount.discountType === 'absolute' ? PriceVO.fromPrimitive(commercialisationFee.discount.absoluteDiscount) : undefined,
        percentageDiscount: commercialisationFee.discount.discountType === 'percentage' ? commercialisationFee.discount.percentageDiscount : undefined,
        concept: commercialisationFee.discount.concept
      }) : undefined
    });
  }
}
