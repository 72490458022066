import { User, UserAdvanceRolesTypes } from '#/models';
import { arrayIsNotEmpty } from '#/utils/arraysHelper';
import { IdentifierDTO, UserAvatar } from '@zazume/zzm-base';

export class UserHelper {
  private readonly user: User;

  constructor(user: User) {
    this.user = user;
  }

  static build(user: User) {
    return new UserHelper(user);
  }

  /**
   * @deprecated move to InvoiceHelper
   */
  canCreateInvoices(): boolean {
    return this.hasSomeRole(['buildingManager', 'admin']);
  }

  /**
   * @deprecated move to InvoiceHelper
   */
  canUpdateInvoices(): boolean {
    return this.hasSomeRole(['buildingManager', 'admin']);
  }

  /**
   * @deprecated move to InvoiceHelper
   */
  canDeleteIncomesFromRecurrences(): boolean {
    return this.hasSomeRole(['buildingManager', 'admin']);
  }

  hasRole(role: UserAdvanceRolesTypes): boolean {
    return this.user.roles.includes(role);
  }

  hasSomeRole(roles: UserAdvanceRolesTypes[]): boolean {
    return roles.some(role => this.hasRole(role));
  }

  /**
   * @deprecated move to ExpenseHelper
   */
  canDeleteExpensesFromRecurrences(): boolean {
    return this.hasSomeRole(['buildingManager', 'admin']);
  }

  composeFullName(): string {
    return this.user.surnames ? `${this.user.name} ${this.user.surnames}` : this.user.name;
  }

  isInOwnerAccount(id: IdentifierDTO): boolean {
    return this.user.owner?.entityOwnerAccountIds.some((entityOwnerAccountId) => entityOwnerAccountId === id) ||
      this.user.owner?.ownerAccountId === id;
  }

  isInAnyOwnerAccount(ownerAccountIds: IdentifierDTO[]): boolean {
    if (ownerAccountIds.length === 0) {
      return false;
    }

    if (!this.user.owner) {
      return false;
    }

    if (this.user.owner.ownerAccountId) {
      return ownerAccountIds.some(ownerAccountId =>
        ownerAccountId === this.user.owner!.ownerAccountId);
    }

    if (this.user.owner.entityOwnerAccountIds) {
      return ownerAccountIds.some(ownerAccountId =>
        this.user.owner!.entityOwnerAccountIds.some(entityOwnerAccountId =>
          entityOwnerAccountId === ownerAccountId));
    }

    return false;

  }

  toAvatarWithNameUser = (extraContent?: string): UserAvatar => {
    return {
      fullName: this.composeFullName(),
      avatar: this.user.avatar,
      extraContent: extraContent ?? ''
    };
  };

  hasAdvanceRole(role: UserAdvanceRolesTypes): boolean {
    const userRoles = this.user.roles;

    if (!userRoles) {
      return false;
    }

    return userRoles.some(userRole => {
      const destructuredRole = userRole.split(':');
      return arrayIsNotEmpty(destructuredRole) && destructuredRole[0] === role;
    });
  }

  isOwner() {
    return this.hasAdvanceRole('ownerBasic') || this.hasAdvanceRole('ownerManager') || this.hasAdvanceRole('ownerMiddle');
  }

  isZazumeUser(): boolean {
    return this.hasAdvanceRole('zazume');
  }

  isAgent(): boolean {
    return this.hasRole('agent');
  }

  getFullName(): string {
    if (!this.user.surnames) {
      return this.user.name;
    }
    return [this.user.name, this.user.surnames].join(' ').trim();
  }
}
