import { UserRolesTypes } from '#/models';
import { IconType, Options, TertiaryButton, Dropdown } from '@zazume/zzm-base';
import { FC } from 'react';
import styled from '@emotion/styled';
import { usePermissions } from '#/hooks';
import { ItemVariant } from '@zazume/zzm-base/dist/components/dropdown/atoms/Item';

interface Action {
  title: string;
  onClick: () => void;
  icon: IconType;
  roles?: UserRolesTypes[];
  dataId?: string;
  variant?: ItemVariant;
}

interface Props {
  actions: Action[];
}

const CustomButton = styled(TertiaryButton)`
  position: relative;
  min-width: 2.375rem;
  width: 2.375rem;
`;

const header = <CustomButton>
  <Options color="Gray500" size={24}/>
</CustomButton>;

export const Actions: FC<Props> = ({ actions }) => {
  const { canRender } = usePermissions();

  return <Dropdown
    header={header}
    position="right"
    topValue={45}
  >
    {actions.map(action => {
      if (action.roles && !canRender(action.roles)) {
        return null;
      }
      return <Dropdown.Item
        key={action.title}
        dataId={action.dataId}
        icon={action.icon}
        onClick={action.onClick}
        variant={action.variant}
      >
        {action.title}
      </Dropdown.Item>;
    })}
  </Dropdown>;
};
