import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Regular } from '@zazume/zzm-base';

const ErrorBase = styled.div(({ theme }) => css`
  margin: ${theme.spacing.s2} ${theme.spacing.s0};
`);

interface ErrorProps {
  error?: string;
}

export const Error: FC<ErrorProps> = ({ error }) => {
  if (!error) {
    return null;
  }

  return <ErrorBase>
    <Regular variant="XS" color="Red">{error}</Regular>
  </ErrorBase>;
};
