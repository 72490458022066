import React, { FC } from 'react';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { useI18n } from '#hooks/useI18n';
import { ZazumeCan } from '#/components/roles/ZazumeCan';
import { SectionProps } from '#/containers/asideNavigation/Sections';

interface Props extends SectionProps {
}

export const OrganizationServices: FC<Props> = ({ onClickItem, isMobile, depth }) => {
  const { t } = useI18n();
  return <>
    <ZazumeCan>
      <NavigationLink to={'/organization-services'}
                      icon="marketplace"
                      depth={depth}
                      isMobile={isMobile}
                      title={t('asideNavigation.services')} onClick={onClickItem}/>
    </ZazumeCan>
  </>;
};
