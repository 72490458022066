import { doGet, doPost, doPut, EndpointBuilder, toPaginatedURL } from '../../helpers';
import { PaginationOptions } from '../../../../hooks';
import { Brand } from '../../../../models/Brand';
import { IdentifierDTO } from '@zazume/zzm-base';
import { BrandRequestData } from './models/BrandCreateRequest';

export const brands = {
  create: (body: BrandRequestData) =>
    doPost<Brand>('/dashboard/brands/create', body),
  edit: (brandId: IdentifierDTO) => (body: BrandRequestData) =>
    doPut<Brand>(`/dashboard/brands/${brandId}`, body),
  getById: (brandId: IdentifierDTO) =>
    doGet<Brand>(`/dashboard/brands/${brandId}`),
  getMyBrand: () =>
    doGet<Brand>('/public/brand/my'),
  getPaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Brand[]>(toPaginatedURL(`/dashboard/brands${params.url}${params.queryParams}`, pagination)),
  search: (name?: string) => () =>
    doGet<Brand[]>(`/dashboard/brands/search?name=${name || ''}`)
};
