import { FC } from 'react';
import { DocumentCategories, RelatedModelType } from '../../models';
import { Flex, Regular, Spacer, ZazumeModal } from '@zazume/zzm-base';
import { useI18n } from '../../hooks/useI18n';
import { FormProvider, useForm } from 'react-hook-form';
import { DocumentUpload } from '../../components/forms';
import { useNetworkExceptions } from '../../lib/network/helpers';
import { useNotifications, useYupValidationResolver } from '../../hooks';
import { getToday } from '@zazume/zzm-utils';
import { CreateDocumentFormInputs, getCreateDocumentSchema } from './CreateDocument.schema';
import { SelectField } from '../../components/forms/select/SelectField';
import { ResponsiveModalForm } from '../../templates/ResponsiveModalForm';
import { SignersBulk } from '../../components/forms/signer/SignersBulk';
import { getAllFileTypes, getAttachmentsTypeOnlyPDF } from '../../utils/fileHelper';
import { DatePicker } from '../calendar/DatePicker';
import { getDocumentCategoriesOptions, getDocumentTypesOptions } from './utils/utils';
import { CreateDocumentRequest } from '../../lib/network/api/documents/requests/CreateDocumentRequest';
import { Toggle } from '../inputs/toggle/ToggleBase';
import { Can } from '../roles/Can';

interface CreateDocumentModalProps {
  close: () => void;
  defaultCategory?: DocumentCategories;
  hiddenCategories?: DocumentCategories[];
  relatedModel: RelatedModelType;
  isSelectCategoryLocked?: boolean;
  withSignature?: boolean;
  onSubmit: (formData: CreateDocumentRequest) => Promise<void>;
  withHiddenFromOwner?: boolean;
}

export const CreateDocumentModal: FC<CreateDocumentModalProps> = ({ close, defaultCategory, hiddenCategories, relatedModel, isSelectCategoryLocked = false, withSignature = true, onSubmit, withHiddenFromOwner = true }) => {
  const { t } = useI18n();
  const { handleNetworkExceptions } = useNetworkExceptions();
  const { showNotification } = useNotifications();
  const { Body: FormBody, Row: FormRow, Column: FormColumn } = ResponsiveModalForm;

  const formSchema = getCreateDocumentSchema(t);
  const { resolver } = useYupValidationResolver(formSchema);
  const formMethods = useForm<CreateDocumentFormInputs>({
    resolver,
    defaultValues: {
      signatureConfiguration: 'pageEnd'
    }
  });
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    setError,
    formState: { isSubmitting, errors }
  } = formMethods;

  const selectedCategory: DocumentCategories = watch('category') || defaultCategory;
  const isExpirable = watch('isExpirable');
  const isSignable = watch('isSignable');
  const maxNumberOfFiles = (isExpirable || isSignable) ? 1 : undefined;
  const attachmentsTypes = isSignable ? getAttachmentsTypeOnlyPDF() : getAllFileTypes();

  const documentCategoriesOptions = getDocumentCategoriesOptions(relatedModel, t, hiddenCategories);
  const documentTypesOptions = getDocumentTypesOptions(selectedCategory, relatedModel, t);

  const documentTypesHelp = isSignable
    ? t('general.forms.fileTypePDF', { maxFileSizeMB: 10 })
    : t('documentsModals.documentSpecs', { maxFileSizeMB: 10 });

  const selectCategoryHandler = () =>
    setValue('type', undefined, { shouldValidate: true });

  const isHiddenFromOwner = watch('isHiddenFromOwner');

  const onCreateDocument = async (formData: CreateDocumentFormInputs) => {
    const { type, expireAt, signers, isExpirable, isSignable, signatureConfiguration, isHiddenFromOwner } = formData;
    const files: any[] = formData.document.fileToAdd;

    if ((isExpirable || isSignable) && files.length > 1) {
      setError('document', { type: 'required', message: t('documents.oneDocumentPerUploadError') });
      return;
    }

    if (isSignable && files[0].type !== 'application/pdf') {
      setError('document', { type: 'required', message: t('documents.onlyPdfSigningFilesError') });
      return;
    }

    const newDocumentRequest = new CreateDocumentRequest(type);
    for (const file of files) {
      newDocumentRequest.addFile(file);
    }

    if (isSignable) {
      newDocumentRequest.addSigners(signers);
      newDocumentRequest.withSignatureConfiguration(signatureConfiguration);
    }

    if (isExpirable) {
      newDocumentRequest.addExpiredAt(expireAt);
    }

    if (withHiddenFromOwner) {
      newDocumentRequest.addHiddenFromOwner(isHiddenFromOwner);
    }

    try {
      await onSubmit(newDocumentRequest);
      showNotification(t('documents.created'));
    } catch (error) {
      handleNetworkExceptions(error);
    }
    close();
  };

  return <ZazumeModal title={t('documentsModals.create.title')}>
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onCreateDocument)}>
        <FormBody>
          <FormRow>
            <FormColumn>
              <Flex direction="column" gap="md">
                <SelectField
                  name="category"
                  register={register}
                  setValue={setValue}
                  options={documentCategoriesOptions}
                  placeholder={t('documentsModals.selectCategory')}
                  label={t('documentsModals.category')}
                  defaultValue={defaultCategory}
                  onSelected={selectCategoryHandler}
                  isLocked={isSelectCategoryLocked}
                  errors={errors.category}
                />
                <SelectField
                  name="type"
                  register={register}
                  setValue={setValue}
                  options={documentTypesOptions}
                  placeholder={t('documentsModals.selectDocumentType')}
                  label={t('documentsModals.documentType')}
                  errors={errors.type}
                />
                <Flex direction="column" gap="s2">
                  <Toggle name="isExpirable" label={t('documentsModals.isExpirable')}/>
                  {isExpirable &&
                    <DatePicker
                      label={t('documentsModals.when')}
                      name={'expireAt'}
                      register={register}
                      setValue={setValue}
                      placeholder={t('documentsModals.selectDate')}
                      errors={errors.expireAt}
                      minDate={getToday()}
                      help={t('documents.expirableDocumentsLength')}
                    />}
                </Flex>
              </Flex>
            </FormColumn>
            <FormColumn>
              <DocumentUpload
                name="document"
                setValue={setValue}
                register={register}
                fileTypes={attachmentsTypes}
                maxFileSizeMB={10}
                help={documentTypesHelp}
                label={t('documentsModals.uploadDocument')}
                maxNumberOfFiles={maxNumberOfFiles}
                errors={errors.document}
              />
            </FormColumn>
          </FormRow>
          {withSignature && <Flex direction="column" gap="md">
            <Toggle name="isSignable" label={t('documentsModals.isSignable')}/>
            {isSignable && <SignersBulk/>}
          </Flex>}
          {withHiddenFromOwner && <Can hasRoles={['admin', 'buildingManager']}>
            <Spacer vertical="s12"/>
            <Flex gap="s6">
              <Toggle name="isHiddenFromOwner"/>
              <Flex direction="column" gap="s2">
                <Regular variant="S">{t('documents.isHiddenFromOwnerToggle.label')}</Regular>
                <Regular variant="S" color="Gray400">
                  {t(`documents.isHiddenFromOwnerToggle.${isHiddenFromOwner ? 'active' : 'disabled'}`)}
                </Regular>
              </Flex>
            </Flex>
          </Can>}
        </FormBody>
        <ZazumeModal.Footer
          acceptButtonText={t('general.upload')}
          cancelButtonText={t('general.cancel')}
          isSubmitting={isSubmitting}
          acceptButtonType="submit"
        />
      </form>
    </FormProvider>
  </ZazumeModal>;
};
