import { FC, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Flex, IdentifierDTO, Regular, Spacer } from '@zazume/zzm-base';
import { Message, ReviewType } from '../../CopilotChat';
import { DateVO } from '@zazume/zzm-utils';
import { useI18n } from '#/hooks/useI18n';
import { ThumbsUp } from '#/components/icons/ThumbsUp';
import { ThumbsDown } from '#/components/icons/ThumbsDown';
import { API } from '#/lib/network/API';
import { useUpdate } from '#/lib/reactQuery/hooks/useUpdate';
import { useAsyncMethod } from '#/hooks/useAsyncMethod';
import { CopilotChatKeys } from '#/hooks/api/copilot/Copilot.keys';
import { BodyRenderer } from '#/pages/copilot/components/body/BodyRenderer';
import { useQueryClient } from 'react-query';


const Button = styled.button<{up: boolean}>(({ theme, up }) => css`
  all: unset;
  width: 2rem;
  height: 2rem;
  border-radius: ${theme.radius.xxl};
  background: ${theme.colors.Primary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;

  :hover {
    background: ${theme.colors.PrimaryDark1};
  }

  &:disabled {
    background: ${up ? theme.colors.GreenDark1 : theme.colors.RedDark1};
    cursor: not-allowed;
  }
`);

const ButtonsContainer = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.s5};
  justify-content: start;
  position: absolute;
  bottom: 0;
  right: ${theme.spacing.md};
  max-width: 6rem;
  background: linear-gradient(to top, ${theme.colors.Gray200} 65%, rgba(255, 255, 255, 0) 100%);
  padding: ${theme.spacing.sm};
  padding-top: ${theme.spacing.md};
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
`);


const Container = styled.div<{ fromUser: boolean }>(({ theme, fromUser }) => css`
  position: relative;
  width: fit-content;
  max-width: 35rem;
  background: ${fromUser ? theme.colors.Primary : theme.colors.Gray200};
  padding: ${theme.spacing.s6} ${theme.spacing.s10};
  border-radius: 1.2rem;
  ${fromUser && 'margin-left: auto;'}

  :hover {
    div[data-content="button-container"] {
      opacity: 1;
    }
  }
`);

interface Props {
  conversationId: IdentifierDTO;
  message: Message;
}

const urlPattern = /https?:\/\/[^\s/$.?#].[^\s)]*/g;

export const Bubble: FC<Props> = ({ message, conversationId }) => {
  const { languageVO, t } = useI18n();
  const fromUser = message.type === 'user';
  const infoColor = fromUser ? 'White' : 'Gray500';
  const { call } = useAsyncMethod();
  const { mutate } = useUpdate<Message, { review: ReviewType }>(CopilotChatKeys.sendReview(conversationId, message._id), API.copilot.sendReview(conversationId, message._id));
  const [review, setReview] = useState<ReviewType | undefined>(message.review);
  const queryClient = useQueryClient();

  const handlePositiveReview = () => call(async () => {
    if (review === 'positive') {
      return;
    }
    setReview('positive');
    await mutate({ review: 'positive' });
    queryClient.invalidateQueries(CopilotChatKeys.getMessages(conversationId));
  });

  const handleNegativeReview = () => call(async () => {
    if (review === 'negative') {
      return;
    }
    setReview('negative');
    await mutate({ review: 'negative' });
    queryClient.invalidateQueries(CopilotChatKeys.getMessages(conversationId));
  });

  const isThumbsUpButtonDisabled = review === 'positive';

  const isThumbsDownButtonDisabled = review === 'negative';

  return <Container fromUser={fromUser}>
    <BodyRenderer
      fromUser={fromUser}
      text={message.message || ''}
    />
    <Spacer vertical="s5"/>
    <Flex direction="row" justify="between" align="end">
      <Regular variant="XS" color={infoColor}>
        {fromUser ? t('general.you') : t('copilot.systemUser')} - {DateVO.fromISOString(message.createdAt).format('datetimeMinutes', languageVO.toPlatform())}
      </Regular>
      {message.type === 'assistant' && <ButtonsContainer data-content="button-container">
        <Button disabled={isThumbsUpButtonDisabled} onClick={handlePositiveReview} up={true}>
          <ThumbsUp/>
        </Button>
        <Button disabled={isThumbsDownButtonDisabled} onClick={handleNegativeReview} up={false}>
          <ThumbsDown/>
        </Button>
      </ButtonsContainer>}
    </Flex>
  </Container>;
};
