import { useI18n } from '#hooks/useI18n';
import { useNotifications } from '#hooks/useNotifications';

type FunctionToCall = () => Promise<any>;

export interface UseAsyncMethod {
  handleNetworkExceptions: (error: any, values?: any) => void;
  call: (functionToCall: FunctionToCall) => Promise<any> | undefined;
}

export const useAsyncMethod = (): UseAsyncMethod => {
  const { t } = useI18n();
  const { showNotification } = useNotifications();

  const handleNetworkExceptions = (error, values = {}) => {
    const { errorCode } = error;

    if (error instanceof TypeError) {
      return showNotification(t('errorMessages.clientError'), { type: 'error' });
    }

    if (!errorCode && !navigator.onLine) {
      return showNotification(t('errorMessages.network'), { type: 'error' });
    }

    // TODO check if error code is not in the list of error codes
    return showNotification(t(`errorCodes.${errorCode}` as any, values), { type: 'error' });
  };

  const call = async (functionToCall: FunctionToCall) => {
    try {
      await functionToCall();
    } catch (e) {
      handleNetworkExceptions(e);
    }
  };

  return {
    handleNetworkExceptions,
    call
  };
};
