import React, { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';
import { useI18n } from '#hooks/useI18n';
import { SectionProps } from '#/containers/asideNavigation/Sections';

interface HomeProps extends SectionProps {
}

export const Home: FC<HomeProps> = ({ onClickItem, isMobile, depth }) => {
  const { t } = useI18n();
  return <Can hasRoles={['buildingManager', 'viewer', 'admin', 'staff', 'commercial', 'ownerManager', 'ownerMiddle', 'agent']}>
    <NavigationLink to={Private.DASHBOARD.route}
                    icon="home"
                    isMobile={isMobile}
                    depth={depth}
                    title={t('asideNavigation.dashboard')}
                    onClick={onClickItem}/>
  </Can>;
};
