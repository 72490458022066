import { DateVO } from '@zazume/zzm-utils';
import { BasicUserVO } from '../BasicUser';
import { IdentifierDTO, Variants } from '@zazume/zzm-base';
import { TFunction } from '#/hooks/useI18n';

interface DocumentUploadLinkProps {
  _id: IdentifierDTO;
  createdBy: BasicUserVO;
  expiresAt: DateVO;
  description?: string;
  slug: string;
  type: DocumentType;
  url: string;
}

export class DocumentUploadLink {
  _id: IdentifierDTO;
  createdBy: BasicUserVO;
  expiresAt: DateVO;
  description?: string;
  slug: string;
  type: DocumentType;
  url: string;

  constructor(params: DocumentUploadLinkProps) {
    this._id = params._id;
    this.createdBy = params.createdBy;
    this.expiresAt = params.expiresAt;
    this.description = params.description;
    this.slug = params.slug;
    this.type = params.type;
    this.url = params.url;
  }

  isExpired(): boolean {
    return this.expiresAt.isPast();
  }

  getStatusTag(): Variants {
    return this.isExpired() ? 'error' : 'success';
  }

  getStatusName(t: TFunction): string {
    return this.isExpired()
      ? t('documentUploadLink.statuses.expired')
      : t('documentUploadLink.statuses.available');
  }
}
