import { PaginationOptions } from '#/hooks';
import { DocumentTemplate } from '#/models/DocumentTemplate';
import { TemplatePreviewResult } from '#/models/template/TemplatePreviewResult';
import { AddTemplateForm } from '#/pages/templates/components/AddTemplateForm.schema';
import { EditTemplateForm } from '#/pages/templates/components/EditTemplateForm.schema';
import { IdentifierDTO } from '@zazume/zzm-base';
import { Language, MergeTag } from '@zazume/zzm-utils';
import { doDelete, doGet, doPost, doPut, EndpointBuilder, toPaginatedURL } from '../../helpers';

export const documentTemplate = {
  calculatePreviewSettings: (id: IdentifierDTO, requiredRelatedModels: Record<string, IdentifierDTO>) =>
    () =>
      doPut<TemplatePreviewResult>(`/dashboard/document-template/${id}/preview`, { requiredRelatedModels }),
  create: (body: AddTemplateForm) =>
    doPost<DocumentTemplate>('/dashboard/document-template/create', body),
  delete: (id: IdentifierDTO) =>
    doDelete(`/dashboard/document-template/${id}`),
  edit: (id: IdentifierDTO) => (body: EditTemplateForm) =>
    doPut<void>(`/dashboard/document-template/${id}/update`, body),
  findPaginated: (params: EndpointBuilder) => (pagination: PaginationOptions) => () =>
    doGet<DocumentTemplate[]>(toPaginatedURL(`/dashboard/document-template${params.url}${params.queryParams}`, pagination)),
  getMergeTags: (language: Language) =>
    () =>
      doGet<MergeTag>(`/dashboard/document-template/merge-tags/${language}`),
  getOne: (id: IdentifierDTO) =>
    () =>
      doGet<DocumentTemplate>(`/dashboard/document-template/${id}`)
};
