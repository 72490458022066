import React from 'react';
import styled from '@emotion/styled';
import { FCC, RightArrow } from '@zazume/zzm-base';
import { css } from '@emotion/react';

interface ContainerProps {
  withMargin?: boolean;
  disabled?: boolean;
}
const Container = styled.div<ContainerProps>(({ withMargin = true, disabled = false, theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${disabled ? theme.colors.Gray200 : theme.colors.White};
  color: ${theme.colors.Gray600};
  padding: ${theme.spacing.xs};
  border: ${theme.borderWeights.light} solid ${theme.colors.Gray300};
  margin-right: ${withMargin ? '16px' : '0px'};
  cursor: pointer;
  border-radius: ${theme.radius.lg};
  box-shadow: ${theme.shadows.s1};
  ${disabled && 'pointer-events: none;'}

  &:hover {
    background: ${theme.colors.Gray200};
  }
`);

interface ForwardButtonBaseProps {
  onClick: () => void;
  disabled?: boolean;
}
type ForwardButtonProps = ForwardButtonBaseProps & ContainerProps
/**
 * @deprecated use zzm-base
 * @param onClick
 * @param withMargin
 * @param disabled
 * @param children
 * @constructor
 */
export const ForwardButton: FCC<ForwardButtonProps> = ({ onClick, withMargin, disabled, children }) => {
  return (
    <Container role="button" onClick={onClick} withMargin={withMargin} disabled={disabled}>
      {children ? children : <RightArrow color="Gray600" size={20}/>}
    </Container>
  );
};
