import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Colors, Delete, Download, Dropdown, Eye, getIcon, IdentifierDTO, Options, Pencil, useModal } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { useAuth } from '../../contexts/AuthProvider';
import { useSigningDocument } from '../../hooks/application/useSigningDocument';
import { useDownloadDocument } from '../../hooks/documents/useDownloadDocument';
import { useI18n } from '../../hooks/useI18n';
import { Document } from '../../models';
import { UserRolesTypes } from '../../models/User';
import { isImage } from '../../utils/fileHelper';
import { SigningDocumentStateTag } from '../forms/signingDocumentPreview/SigningDocumentStateTag';
import { GenericDeleteConfirmationModal } from '../modal/GenericDeleteConfirmationModal';
import { Can } from '../roles/Can';
import { Body14 } from '../typography';
import { DocumentCardFootnote } from './DocumentCardFootnote';
import { DocumentModal } from './DocumentModal';

const Container = styled.div<any>(({ theme, thin }) => css`
  display: flex;
  position: relative;
  align-items: center;
  border: ${theme.borderWeights.light} solid ${theme.colors.Gray200};
  ${thin
    ? `padding: ${theme.spacing.s4} ${theme.spacing.s6} ${theme.spacing.s4} ${theme.spacing.s3};`
    : `padding: ${theme.spacing.s8} ${theme.spacing.s6} ${theme.spacing.s8} ${theme.spacing.s4};`}
  border-radius: ${theme.radius.lg};
  box-shadow: ${theme.shadows.s1};
  transition: .15s;
  width: 100%;

  svg {
    flex-shrink: 0;
  }

  :hover {
    cursor: pointer;
    background-color: ${theme.colors.Gray100};
  }
`);

const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: 4px;
  width: 100%;
`;


const FileName = styled(Body14)`
  color: ${Colors.FONT_3};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;

interface DocumentCardProps {
  documentId: IdentifierDTO;
  relatedUser?: string;
  onRemove?: (document: Document) => void;
  onUpdate?: (document: Document) => void;
  deletePermissionRoles?: UserRolesTypes[];
  thin?: boolean;
}

export const DocumentCard: FC<DocumentCardProps> = ({ documentId, relatedUser, onRemove, onUpdate, deletePermissionRoles, thin }) => {
  const { t } = useI18n();
  const { isOwner, user } = useAuth();

  const { RenderModal, open, close } = useModal({ modalSize: 'l' });
  const { RenderModal: RenderDeleteModal, open: openDeleteModal, close: closeDeleteModal } = useModal({ modalSize: 's', withMobileFullscreen: false });

  const { document, isLoading } = useSigningDocument(documentId);
  const { download } = useDownloadDocument(documentId);

  if (isLoading || !document) {
    return null;
  }

  const { filename, signatureExternalId, state, rejected, hasSignatureError } = document;

  const isSigningDocument = Boolean(signatureExternalId);
  const canRemoveSigningDocument = isSigningDocument ? Boolean(rejected) : true;
  const canUser = !isOwner || document.author === user?._id;

  const openDeleteModalHandler = (e) => {
    e.stopPropagation();
    openDeleteModal();
  };

  const isRemovable = Boolean(onRemove) && canRemoveSigningDocument && canUser;
  const removeHandler = async () => {
    if (onRemove) {
      await onRemove(document);
      closeDeleteModal();
    }
  };

  const isEditable = Boolean(onUpdate) && canUser;
  const editHandler = () => onUpdate && onUpdate(document);

  const Icon = isImage(filename) ? getIcon('imageIcon') : getIcon('document');

  return (
    <>
      <Container thin={thin} onClick={open}>
        <Icon color="Gray600" size={40}/>
        <Details>
          <FileName>{filename}</FileName>
          <DocumentCardFootnote document={document} relatedUser={relatedUser}/>
        </Details>
        {signatureExternalId && state && <SigningDocumentStateTag state={state} hasSignatureError={hasSignatureError}/>}
        <Dropdown header={<Options color="Gray600" size={20} cursor="pointer"/>} position="right" topValue={30}>
          <Dropdown.Item onClick={open} icon="eye">
            {t('general.viewDocument')}
          </Dropdown.Item>
          <Dropdown.Item onClick={download} icon="download">
            {t('general.download')}
          </Dropdown.Item>
          {isEditable &&
            <Dropdown.Item onClick={editHandler} icon="pencil">
              {t('general.edit')}
            </Dropdown.Item>}
          {isRemovable &&
            <Can hasRoles={deletePermissionRoles || ['admin', 'buildingManager', 'ownerManager']}>
              <Dropdown.Item onClick={openDeleteModalHandler} icon="delete" variant="danger">
                {t('general.remove')}
              </Dropdown.Item>
            </Can>}
        </Dropdown>
      </Container>

      <RenderModal>
        <DocumentModal
          close={close}
          document={document}
          download={download}/>
      </RenderModal>
      <RenderDeleteModal>
        <GenericDeleteConfirmationModal
          onConfirm={removeHandler}
          onCancel={closeDeleteModal}
          title={t('payments.detail.attachments.remove')}
          description={t('payments.detail.attachments.removeContent')}
        />
      </RenderDeleteModal>
    </>
  );
};
