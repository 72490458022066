import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { Collapse, Expand, ZazumeIsotype, ZazumeLogotype } from '@zazume/zzm-base';
import { useLocation, useNavigate } from 'react-router';
import { useAuth } from '#/contexts/AuthProvider';
import { Private } from '../router/routes/Private';
import { Navigation } from './Navigation';
import { css } from '@emotion/react';
import { useImgix } from '#/hooks';
import { useNavigation } from './context/NavigationContext';

const LogoContainer = styled.div<{ open: boolean }>(({ theme, open }) => css`
  display: flex;
  transition-duration: 0.5s;
  transition-property: padding;
  padding: ${open ? '31px 30px' : '31px 10px'};
  box-shadow: inset -1px 0px 0px ${theme.colors.Gray200};
  align-items: center;

  & > img {
    width: 102px;
    height: 26px;
  }

  & > * {
    margin-right: ${open ? '9px' : '4px'};
  }
`);

const Container = styled.div<any>(({ theme, open }) => css`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.White};
  justify-content: flex-start;
  transition-duration: 0.5s;
  transition-property: width;
  width: ${open ? '300px' : '75px'};
  height: 100vh;
`);

const FixContainer = styled.div<any>(({ theme, open }) => css`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.White};
  min-width: ${open ? '290px' : '75px'};
  box-shadow: inset -1px 0px 0px ${theme.colors.Gray200};
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 6px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: ${theme.colors.Primary};
    }
  }

  &::-webkit-scrollbar-track {
    background: none;
  }
`);

const Pressable = styled.div`
  display: flex;
  cursor: pointer;
`;

const IconContainer = styled.div<any>(({ theme }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  color: ${theme.colors.Gray600};
  cursor: pointer;

  &:hover {
    background: ${theme.colors.PrimaryLight4};

    svg {
      fill: ${theme.colors.Primary};
    }
  }
`);

const Image = styled.img<any>`
  padding: 0;
  margin: 0;
  width: ${props => props.size}px;
`;

const ImageSquare = styled(Image)<any>`
  height: ${props => props.size}px;
`;

const getLandscapeLogo = (src) => {
  return src
    ? <Image size={102} src={src}/>
    : <ZazumeLogotype size={102} color="Primary"/>;
};

const getSquareLogo = (src) => {
  return src
    ? <ImageSquare size={24} src={src}/>
    : <ZazumeIsotype size={16} color="Primary"/>;
};

export const AsideNavigation: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { organization } = useAuth();
  const { applyImgix } = useImgix();
  const { isCollapsed, toggleCollapseNavigation, setCollapseNavigation } = useNavigation();

  const [activeTransition, setActiveTransition] = useState<boolean>(false);
  const [activePointerOver, setActivePointerOver] = useState<boolean>(false);

  const handleLogoClick = () => navigate(Private.HOME.route);

  if (!organization?.onBoardingSettings && pathname === '/onboarding') {
    navigate(Private.DASHBOARD.route);
  }

  const onTranstionActive = () => {
    setActiveTransition(true);
    setTimeout(() => {
      setActiveTransition(false);
    }, 1100);
  };

  const changeMenuState = () => {
    toggleCollapseNavigation();
    onTranstionActive();
  };

  const onPointerOver = () => {
    if (!open && !activeTransition) {
      setCollapseNavigation(false);
      setActivePointerOver(true);
    }
  };

  const onPointerOut = () => {
    if (activePointerOver && open) {
      setCollapseNavigation(true);
    }
    setActivePointerOver(false);
  };

  const open = !isCollapsed;
  return <Container open={open}>
    <LogoContainer open={open}>
      <IconContainer open={open} onClick={changeMenuState}>
        {open ? <Collapse color="Gray600" size={24}/> : <Expand color="Gray600" size={24}/>}
      </IconContainer>
      <Pressable onClick={handleLogoClick}>
        {open
          ? getLandscapeLogo(organization?.logoLandscape && applyImgix({ src: organization.logoLandscape, width: 150 }))
          : getSquareLogo(organization?.logo && applyImgix({ src: organization.logo, height: 24, width: 24 }))}
      </Pressable>
    </LogoContainer>
    <FixContainer open={open} onMouseEnter={onPointerOver} onMouseLeave={onPointerOut}>
      <Navigation isOpen={open}/>
    </FixContainer>
  </Container>;
};
