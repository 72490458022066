import { doDelete, doGet, doPost, doPut, EndpointBuilder, toPaginatedURL } from '#lib/network/helpers';
import { InvestmentAlertCreateBody } from '#lib/network/api/investment/models/createAlert';
import { IdentifierDTO } from '@zazume/zzm-base';
import { InvestmentAlert } from '#/models/investment/alert/InvestmentAlert';
import { InvestmentOpportunity } from '#/models/investmentOpportunity/InvestmentOpportunity';
import { PaginationOptions } from '#/hooks';

export const investment = {
  alerts: {
    create: (formDate: InvestmentAlertCreateBody) =>
      doPost<void>('/dashboard/investment-alert/', formDate),
    delete: (alertId: IdentifierDTO) =>
      doDelete<void>(`/dashboard/investment-alert/${alertId}`),
    getById: (alertId: IdentifierDTO) =>
      doGet<InvestmentAlert>(`/dashboard/investment-alert/${alertId}`),
    update: (alertId: IdentifierDTO, formDate: Partial<InvestmentAlertCreateBody>) =>
      doPut<void>(`/dashboard/investment-alert/${alertId}`, formDate),
    getByPaginated: () =>
      (pagination: PaginationOptions) => () =>
        doGet<InvestmentAlert[]>(toPaginatedURL('/dashboard/investment-alert/', pagination))
  },
  opportunities: {
    getById: (investmentOpportunityId: IdentifierDTO) => () =>
      doGet<InvestmentOpportunity>(`/dashboard/investment-opportunities/${investmentOpportunityId}`),
    getPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) => () =>
        doGet<InvestmentOpportunity[]>(toPaginatedURL(`/dashboard/investment-opportunities${params.url}${params.queryParams}`, pagination)),
    getMySavedOpportunitiesPaginated: () =>
      (pagination: PaginationOptions) => () =>
        doGet<InvestmentOpportunity[]>(toPaginatedURL('/dashboard/investment-opportunities/saved-by-me', pagination))
  },
  myOpportunities: {
    getById: (marketUnitId: IdentifierDTO) => () =>
      doGet<InvestmentOpportunity>(`/dashboard/my-investment-opportunities/${marketUnitId}`, undefined, undefined, { expectNotFound: true }),
    update: (marketUnitId: IdentifierDTO) => (formDate: any) =>
      doPut<void>(`/dashboard/my-investment-opportunities/${marketUnitId}`, formDate),
    create: (marketUnitId: IdentifierDTO) =>
      doPost(`/dashboard/my-investment-opportunities/${marketUnitId}`),
    remove: (marketUnitId: IdentifierDTO) =>
      doDelete(`/dashboard/my-investment-opportunities/${marketUnitId}`)
  }
};
