import React, { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';
import { useI18n } from '#hooks/useI18n';
import { SectionProps } from '#/containers/asideNavigation/Sections';

interface BookingsProps extends SectionProps {
}

export const Bookings: FC<BookingsProps> = ({ onClickItem, depth, isMobile }) => {
  const { t } = useI18n();
  return <Can hasRoles={['buildingManager', 'viewer', 'admin']}>
    <NavigationLink to={Private.BOOKINGS.route} onClick={onClickItem} icon="repeatArrows" title={t('asideNavigation.bookings')} depth={depth} isMobile={isMobile}/>
  </Can>;
};
