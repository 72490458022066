import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PaperPlane } from '@zazume/zzm-base';
import React from 'react';

const InputContainer = styled.div(({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.s5};
  padding: 8px 8px 8px 24px;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 3.125rem;
  display: flex;
  align-items: center;
  color: ${theme.colors.Gray400};
  border: 1.5px solid ${theme.colors.Gray200};
  transition: border 0.1s;

  :hover {
    border: 1.5px solid ${theme.colors.Gray300};
  }
`);

const Button = styled.button(({ theme }) => css`
  all: unset;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 3.125rem;
  background: ${theme.colors.Primary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;

  :hover {
    background: ${theme.colors.PrimaryDark1};
  }

  &:disabled {
    background: ${theme.colors.PrimaryLight1};
    cursor: not-allowed;
  }
`);

const InputField = styled.input(({ theme }) => css`
  all: unset;
  width: 100%;
  display: block;
  min-height: 2rem;
  color: ${theme.colors.Gray600};

  &:disabled {
    cursor: not-allowed;
    color: ${theme.colors.Gray400};
  }
`);

interface Props {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSendingMessage: boolean;
}

export const Input = React.forwardRef<any, Props>(({ value, onChange, isSendingMessage }, ref) => {
  return <InputContainer>
    <InputField
      ref={ref}
      type="text"
      placeholder="Pregúntale algo a Zazume copilot..."
      value={value}
      onChange={onChange}
      maxLength={200}
      disabled={isSendingMessage}
      autoFocus
    />
    <Button type="submit" disabled={isSendingMessage}>
      <PaperPlane/>
    </Button>
  </InputContainer>;
});
