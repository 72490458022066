import { PaginationOptions } from '#/hooks';
import { doDelete, doGet, doPost, doPut, EndpointBuilder, toPaginatedURL } from '#lib/network/helpers';
import { DraftDocument } from '#/models/drafts/DraftDocument';
import { DraftDocumentCreateRequest } from '#/models/drafts/DraftDocumentCreateRequest';
import { IdentifierDTO } from '@zazume/zzm-base';
import { DraftDocumentUpdateRequest } from '#/models/drafts/DraftDocumentUpdateRequest';
import { DraftDocumentPreviewRequest } from '#/models/drafts/DraftDocumentPreviewRequest';
import { BasicUnit, BasicUser } from '#/models';
import { TemplatePreviewResult } from '#/models/template/TemplatePreviewResult';

export const drafts = {
  documents: {
    calculatePreviewSettings: (id: IdentifierDTO, requiredRelatedModels: Record<string, IdentifierDTO>) =>
      () =>
        doPut<TemplatePreviewResult>(`/dashboard/draft/documents/${id}/preview-variables`, { requiredRelatedModels }),
    create: (request: DraftDocumentCreateRequest) =>
      doPost('/dashboard/draft/documents', request),
    delete: (documentId: IdentifierDTO) =>
      doDelete(`/dashboard/draft/documents/${documentId}`),
    duplicate: (documentId: IdentifierDTO) =>
      doPost(`/dashboard/draft/documents/duplicate/${documentId}`),
    get: (id: IdentifierDTO) => () =>
      doGet<DraftDocument>(`/dashboard/draft/documents/${id}`),
    getAuthors: () =>
      doGet<BasicUser[]>('/dashboard/draft/documents/authors'),
    getImagePresignedPostURL: (templateId: IdentifierDTO) =>
      (contentType: string, filename: string) =>
        doPost<{ url: string, fields: Record<string, string>, publicURL: string }>(`/dashboard/draft/documents/to-upload-url/${templateId}`, { contentType, filename }),
    getPaginated: (params: EndpointBuilder) =>
      (pagination: PaginationOptions) =>
        () =>
          doGet<DraftDocument[]>(
            toPaginatedURL(`/dashboard/draft/documents${params.queryParams}`, pagination)),
    preview: (id: IdentifierDTO, request: DraftDocumentPreviewRequest) =>
      doPut<{ url: string }>(`/dashboard/draft/documents/${id}/preview`, request),
    update: (documentId: IdentifierDTO) =>
      (request: DraftDocumentUpdateRequest) =>
        doPut(`/dashboard/draft/documents/${documentId}`, request),
    getDraftDocumentsUnits: () => () =>
      doGet<BasicUnit[]>('/dashboard/draft/documents/units')
  }
};
