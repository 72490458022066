import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Navigation } from './Navigation';
import { Colors, Cross, Flex, ZazumeLogotype } from '@zazume/zzm-base';
import { HEADER_HEIGHT } from '../../utils/containers/containersConstants';
import { usePreventScroll } from '@react-aria/overlays';
import { FocusScope } from '@react-aria/focus';
import { useImgix } from '../../hooks';
import { useAuth } from '../../contexts/AuthProvider';

const Container = styled.div<any>(({ theme }) => css`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${theme.colors.White};
  z-index: ${theme.zIndices.i5};
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overscroll-behavior-y: none;
`);

const Content = styled.div<any>(() => css`
  display: flex;
  flex-direction: column;
`);

const Head = styled.div<any>(({ theme }) => css`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.s8} ${theme.spacing.s7};
  height: ${HEADER_HEIGHT}px;
  box-shadow: inset 0px -1px 0px ${Colors.BACKGROUND_6};
  background: ${theme.colors.White};

  ${theme.mq.tablet} {
    padding: ${theme.spacing.s8} ${theme.spacing.s15};
  }
`);

const CloseButton = styled.div<any>(() => css`
  display: flex;
  justify-content: center;
  align-items: center;
`);

const Image = styled.img<any>`
  padding: 0;
  margin: 0;
  width: ${props => props.size}px;
`;

const getLandscapeLogo = (src) => {
  return src
    ? <Image size={120} src={src}/>
    : <ZazumeLogotype size={120} color="Primary"/>;
};

export const MobileNavigation = ({ isOpen, handleIsOpen }) => {
  const { organization } = useAuth();
  const { applyImgix } = useImgix();
  usePreventScroll({ isDisabled: !isOpen });

  if (!isOpen) {
    return null;
  }

  return (
    <FocusScope contain restoreFocus>
      <Container isOpen={isOpen}>
        <Head>
          <Flex align="center">
            {getLandscapeLogo(organization?.logoLandscape && applyImgix({ src: organization.logoLandscape, width: 150 }))}
          </Flex>
          <CloseButton onClick={handleIsOpen} role="button">
            <Cross color="Gray600"/>
          </CloseButton>
        </Head>
        <Content>
          <Navigation isMobile={true} onClickItem={handleIsOpen}/>
        </Content>
      </Container>
    </FocusScope>
  );
};
