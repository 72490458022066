import { getFieldError, InputGroupBase, InputGroupBaseItems, InputGroupBaseProps, CardInputBase, CheckboxBase, RadioBase, FCC } from '@zazume/zzm-base';
import { useFormContext } from 'react-hook-form';

interface InputGroupProps extends Omit<InputGroupBaseProps, 'error'> {
}

export const InputGroup: FCC<InputGroupProps> & InputGroupBaseItems = (props) => {
  const { register, formState: { errors } } = useFormContext();

  const error = getFieldError(props.name, errors);

  return (
    <InputGroupBase
      {...props}
      ref={register}
      error={error}
    />
  );
};

InputGroup.RadioInput = RadioBase;
InputGroup.CheckboxInput = CheckboxBase;
InputGroup.CardInput = CardInputBase;
