import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from './Body';

const Base = styled.div(({ theme }) => css`
  ${theme.mq.tablet} {
    position: relative;
  }

  ${theme.mq.desktop} {
    position: relative;
  }
`);

const Container = styled.div<{ isOpened: boolean }>(({ theme, isOpened }) => css`
  display: ${isOpened ? 'block' : 'none'};
  position: absolute;
  width: 100%;
  left: 0;
  top: 4.375rem;

  ${theme.mq.tablet} {
    width: 19.3125rem;
    max-width: 19.3125rem;
    left: -19.3125rem;
    top: ${theme.spacing.s9};
  }
`);

interface NotificationsOverlayProps {
  isOpened: boolean;
  onCloseOverlay: () => void;
  children?: React.ReactNode;
}

export const NotificationsOverlay = forwardRef<any, NotificationsOverlayProps>(({ children, isOpened, onCloseOverlay }, ref) => {
  return <Base>
    <Container ref={ref} isOpened={isOpened}>
      <Body onCloseOverlay={onCloseOverlay}/>
    </Container>
  </Base>;
});
