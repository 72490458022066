import React, { FC, useEffect } from 'react';
import { IdentifierDTO } from '@zazume/zzm-base';
import { useGetUnit } from '#/hooks';
import { Unit } from '#/models';
import { SelectHeader } from '#/components/forms/select/SelectHeader';

interface ContentProps {
  onChangeUnit: any;
  unitId: IdentifierDTO;
  onSelect: (unit: Unit) => void;
  disabled?: boolean;
}

export const SelectedUnitCard: FC<ContentProps> = ({ onChangeUnit, unitId, disabled, onSelect }) => {
  const { unit } = useGetUnit(unitId);

  useEffect(() => {
    if (unit) {
      onSelect(unit);
    }
  }, [unit, onSelect]);

  if (!unit) {
    return null;
  }

  return <SelectHeader
    onClick={onChangeUnit}
    selectedOptionName={unit.name}
    isLocked={disabled}
  />;
};
