import { IdentifierDTO } from '@zazume/zzm-base';
import { DateVO, Language } from '@zazume/zzm-utils';
import { DeepLinkingRoute } from '../deepLinking/deepLinking';

export const NotificationCategories = <const>[
  'Applications',
  'Management',
  'Accounting',
  'Portfolio'
];
export type NotificationCategory = typeof NotificationCategories[number];

export const NotificationTypes = <const>[
  'Autoreconciliation',
  'BackgroundCheckCompleted',
  'BankAccountAboutToExpire',
  'BankAccountExpired',
  'CommercialisationFeeRejected',
  'CommercialisationFeeSigned',
  'DocumentAboutToExpire',
  'DocumentExpired',
  'DocumentRejected',
  'DocumentSigned',
  'HoldingDepositRejected',
  'HoldingDepositSigned',
  'InvoiceIssued',
  'IncidentComments',
  'LeaseRejected',
  'LeaseSigned',
  'LeaseUpdated',
  'NewIncident',
  'PaymentExpired',
  'PayoutPaid',
  'PayoutWithNegativePriceCreated',
  'ViewingCanceled',
  'ViewingRescheduled',
  'ViewingScheduled',
  'ManagerAssignedToUnit',
  'ManagerUnassignedFromUnit',
  'BulkActionCompleted'
];
export type NotificationType = typeof NotificationTypes[number];

interface NotificationProps {
  _id: IdentifierDTO;
  applicationId: IdentifierDTO;
  category: NotificationCategory;
  createdAt: DateVO;
  /**
   * Used to link to any other model (based on type and category)
   */
  customId?: IdentifierDTO;
  isAlert: boolean;
  message: string;
  readAt: DateVO;
  route: DeepLinkingRoute;
  title: string;
  type: NotificationType;
}

export class Notification {
  _id: IdentifierDTO;
  type: NotificationType;
  category: NotificationCategory;
  readAt?: DateVO;
  isAlert: boolean;
  title: string;
  message: string;
  private createdAt: DateVO;
  route: DeepLinkingRoute;
  // Extra parameters
  applicationId?: IdentifierDTO;
  customId?: IdentifierDTO;

  constructor({ _id, type, category, readAt, isAlert, title, message, createdAt, applicationId, route, customId }: NotificationProps) {
    this._id = _id;
    this.type = type;
    this.category = category;
    this.readAt = readAt;
    this.isAlert = isAlert;
    this.title = title;
    this.message = message;
    this.createdAt = createdAt;
    this.applicationId = applicationId;
    this.route = route;
    this.customId = customId;
  }

  isRead(): boolean {
    return Boolean(this.readAt);
  }

  getCreateAt(language: Language): string {
    return this.createdAt.format('monthDatetimeMinutes', language);
  }
}
