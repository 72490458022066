import { FCC, ZZMColor } from '@zazume/zzm-base';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Container = styled.div<{ color: ZZMColor }>(({ theme, color }) => css`
  font-family: ${theme.fonts.base};
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0;
  color: ${theme.colors[color]};
`);

const StrongContainer = styled(Container)`
  font-weight: 600;
`;

type Variant = 'default' | 'strong' | 'thin';

interface CaptionProps {
  color?: ZZMColor;
  variant?: Variant;
}

const getContainerToRender = (variant: Variant) => {
  if (variant === 'strong') {
    return StrongContainer;
  }
  return Container;
};

/**
 * @deprecated use a zzm-base component instead
 * @param children
 * @param variant
 * @param color
 * @param rest
 * @constructor
 */
export const Caption: FCC<CaptionProps> = ({ children, variant = 'default', color = 'Gray500', ...rest }) => {
  const ContainerToRender = getContainerToRender(variant);

  return <ContainerToRender color={color} {...rest}>
    {children}
  </ContainerToRender>;
};
