import { IdentifierDTO } from '@zazume/zzm-base';
import { PaginationOptions } from '../../../../hooks';
import { Provider } from '../../../../models';
import { doDelete, doGet, doPost, doPostFileUpload, doPut, doPutFileUpload, EndpointBuilder, toPaginatedURL } from '../../helpers';
import { ProviderFormInputs } from '../../../../pages/people/provider/components/modals/Provider.schema';
import { CreateDocumentRequest } from '../documents/requests/CreateDocumentRequest';
import { UpdateDocumentRequest } from '../documents/requests/UpdateDocumentRequest';

export const providers = {
  getById: (id: IdentifierDTO) => () =>
    doGet<Provider>(`/dashboard/providers/${id}`),
  search: (name?: string) => () =>
    doGet<Provider[]>(`/dashboard/providers/search?name=${name || ''}`),
  //TODO Update request type with yup schema
  create: (request: ProviderFormInputs): Promise<Provider> =>
    doPost('/dashboard/providers/create', request),
  update: (providerId: IdentifierDTO) => (request: any): Promise<Provider> =>
    doPut(`/dashboard/providers/${providerId}`, request),
  findAll: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Provider[]>(toPaginatedURL(`/dashboard/providers${params.queryParams}`, pagination)),
  findById: (providerId: IdentifierDTO) => (): Promise<Provider> =>
    doGet<Provider>(`/dashboard/providers/${providerId}`),
  delete: (providerId: IdentifierDTO): Promise<Provider> =>
    doDelete(`/dashboard/providers/${providerId}`),
  createDocument: (providerId: IdentifierDTO) =>
    (request: CreateDocumentRequest) =>
      doPostFileUpload<void>(`/dashboard/providers/documents/${providerId}/`, request.build()),
  updateDocument: (providerId: IdentifierDTO) =>
    (request: UpdateDocumentRequest) =>
      doPutFileUpload<void>(`/dashboard/providers/documents/${providerId}/${request.documentId}`, request.build())
};
