import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FieldErrors } from 'react-hook-form';
import { Regular } from '@zazume/zzm-base';

const ErrorBase = styled.div(({ theme }) => css`
  margin: ${theme.spacing.s2} ${theme.spacing.s0};
`);

interface ErrorProps {
  inputError?: FieldErrors;
}

export const Error: FC<ErrorProps> = ({ inputError }) => {
  const message = inputError?.message;
  if (!message) {
    return null;
  }

  return <ErrorBase>
    <Regular variant="XS" color="Red">{message}</Regular>
  </ErrorBase>;
};
