import React, { FC, useState } from 'react';
import { Flex, ZazumeModal, ZZMForm } from '@zazume/zzm-base';
import { useI18n } from '#hooks/useI18n';
import { getInvestmentAlertSchema, InvestmentAlertFormInputs } from '#/pages/investment/alerts/components/modal/InvestmentAlert.schema';
import { City } from '#/models/location/City';
import { useAsyncMethod } from '#hooks/useAsyncMethod';
import { API } from '#lib/network/API';
import { useNotifications } from '#/hooks';
import { CurrencyVO, PriceVO } from '@zazume/zzm-utils';
import { InvestmentAlert } from '#/models/investment/alert/InvestmentAlert';
import { EditAlertModalBody } from '#/pages/investment/alerts/components/modal/EditAlertModalBody';
import { EditAlertModalFooter } from '#/pages/investment/alerts/components/modal/EditAlertModalFooter';

type EditAlertModalProps = {
  alert: InvestmentAlert;
  onClose?: () => void;
}

const isCity = (value: any): value is City => {
  return value && value.id && value.name;
};

export const EditAlertModal: FC<EditAlertModalProps> = ({ alert, onClose }) => {
  const { t } = useI18n();
  const { call } = useAsyncMethod();
  const formSchema = getInvestmentAlertSchema(t);
  const [city, setCity] = useState<City>(alert.location.city);
  const { showNotification } = useNotifications();
  const defaultValues = {
    ...alert,
    location: alert.location.city.name,
    maxBudget: PriceVO.fromPrimitive(alert.maxBudget).toNumber() / 100,
    minRentPrice: PriceVO.fromPrimitive(alert.minRentPrice).toNumber() / 100
  };

  const onSubmit = (formData: InvestmentAlertFormInputs) => call(async () => {
    if (!isCity(city)) {
      return;
    }

    await API.investment.alerts.update(alert._id, {
      ...formData, location: {
        city
      },
      maxBudget: PriceVO.fromAmount(formData.maxBudget * 100, CurrencyVO.fromPrimitive('eur')).toPrimitive(),
      minRentPrice: PriceVO.fromAmount(formData.minRentPrice * 100, CurrencyVO.fromPrimitive('eur')).toPrimitive()
    });

    showNotification(t('investment.alertModal.updateSuccess'), 'success');
    onClose?.();
  });

  const onDelete = () => call(async () => {
    await API.investment.alerts.delete(alert._id);

    showNotification(t('investment.alertModal.deleteSuccess'), 'success');
    onClose?.();
  });

  return (
    <ZazumeModal title={t('investment.alertModal.title')} withCloseButton={false}>
      <ZZMForm.Form validation={formSchema} onSubmit={onSubmit} defaultValues={defaultValues}>
        <Flex gap="xl" direction="column">
          <EditAlertModalBody setCity={setCity}/>
          <EditAlertModalFooter onDelete={onDelete}/>
        </Flex>
      </ZZMForm.Form>
    </ZazumeModal>
  );
};
