import React, { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';
import { useI18n } from '#hooks/useI18n';
import { SectionProps } from '#/containers/asideNavigation/Sections';

interface ValuationsProps extends SectionProps {
}

export const Valuations: FC<ValuationsProps> = ({ isMobile, onClickItem, depth }) => {
  const { t } = useI18n();
  return <Can hasRoles={['admin', 'buildingManager', 'commercial', 'ownerManager', 'agent']}>
    <NavigationLink to={Private.VALUATIONS.route} icon="addHouse" title={t('asideNavigation.valuations')} isMobile={isMobile} onClick={onClickItem} depth={depth}/>
  </Can>;
};
