import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FCC } from '@zazume/zzm-base';
import { FiltersContainer } from '../atoms/FiltersContainer';
import { Actions } from './Actions';

const Container = styled.div<any>(({ withTabs, theme }) => css`
  width: 100%;
  padding-bottom: ${theme.spacing.s10};
  padding-left: ${withTabs ? 7 : 0}px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing.md};
  flex-wrap: wrap;
`);

const InteractionsContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 5.875rem;
`;

const ActionsContainer = styled.div<any>(({ fullWidth, theme }) => css`
  display: flex;
  gap: 16px;
  align-items: center;
  width: fit-content;
  justify-content: flex-end;
  flex-shrink: 0;
  ${fullWidth && 'width: 100%;'}
  gap: ${theme.spacing.s4};
`);

const Title = styled.div(({ theme }) => css`
  font-size: ${theme.fontsSizes.md};
  font-style: normal;
  font-weight: 700;
  line-height: ${theme.lineHeights.xxxl};
  letter-spacing: 0;

  ${theme.mq.tablet} {
    font-size: ${theme.fontsSizes.mmd};
  }

  ${theme.mq.desktop} {
    font-size: ${theme.fontsSizes.lg};
  }
`);

interface PageHeaderAtoms {
  InteractionsContainer: any;
  FiltersContainer: any;
  ActionsContainer: any;
  Actions: any;
  Title: any;
}

interface PageHeaderProps {
  withTabs?: boolean;
}

type PageHeaderTemplate = FCC<PageHeaderProps> & PageHeaderAtoms;

export const PageHeader: PageHeaderTemplate = ({ children, ...props }) => {
  return <Container {...props}>
    {children}
  </Container>;
};

PageHeader.InteractionsContainer = InteractionsContainer;
PageHeader.FiltersContainer = FiltersContainer;
PageHeader.ActionsContainer = ActionsContainer;
PageHeader.Actions = Actions;
PageHeader.Title = Title;
