import { User } from '#/models';
import { getEnvironment, isSentryEnabled } from '#lib/Config';
import { CaptureConsole as CaptureConsoleIntegration, HttpClient } from '@sentry/integrations';
import * as SentrySDK from '@sentry/react';

/**
 * Only report errors that are not BadRequest or NotFound
 * @param event
 * @returns {boolean} true if the event should be filtered and not reported to Sentry
 */
const filterEvent = (event: any): boolean => {
  // In case there's a chain, we take the last entries
  const mainAndMaybeCauseErrors = event.exception?.values?.slice(-2) ?? [];

  for (const error of mainAndMaybeCauseErrors) {
    const { type, value } = error;

    // Unexpected type of event (reported always on Sentry)
    if (typeof value !== 'string') {
      return false;
    }

    if (value.startsWith('[BadRequest]')) {
      return true;
    }

    const is404 = type === 'NotFoundError' && !!value.match('(GET|POST|PUT|DELETE) .* 404');
    if (is404) {
      return true;
    }
  }

  return false;
};

export default class Sentry {
  static startSentry() {
    if (isSentryEnabled()) {
      SentrySDK.init({
        dsn: 'https://802abc860eea494e925228746594324e@o551562.ingest.sentry.io/5675129',
        release: `zzm-front@${import.meta.env.REACT_APP_VERSION}`,
        integrations: [
          new SentrySDK.Integrations.TryCatch(),
          new SentrySDK.Integrations.GlobalHandlers({
            onerror: true,
            onunhandledrejection: true
          }),
          new SentrySDK.Integrations.HttpContext(),
          new CaptureConsoleIntegration({
            levels: ['error']
          }),
          new HttpClient()
        ],
        environment: getEnvironment(),
        tracesSampleRate: 0.3,
        ignoreErrors: [
          'ResizeObserver'
        ],
        beforeSend(event) {
          if (filterEvent(event)) {
            return null;
          }
          return event;
        }
      });
    }
  }

  static setUser(user: User) {
    if (isSentryEnabled()) {
      SentrySDK.setUser(user);
    }
  }

  static clearUser() {
    if (isSentryEnabled()) {
      SentrySDK.configureScope(scope => scope.setUser(null));
    }
  }

  static reportError(error: Error, errorInfo) {
    if (isSentryEnabled()) {
      SentrySDK.captureException(error, { extra: errorInfo });
    }
  }
}
