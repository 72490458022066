import { useGet } from '#/hooks';
import { CopilotChatKeys } from '#/hooks/api/copilot/Copilot.keys';
import { API } from '#/lib/network/API';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Bold, Chat, Cross, IdentifierDTO, Plus, Sparkles, Tag } from '@zazume/zzm-base';
import { FC } from 'react';
import { Message } from '../../CopilotChat';

export const HEADER_HEIGHT = 3.5;

const Container = styled.div<{ isWidget: boolean }>(({ theme, isWidget }) => css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.s4};
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: ${theme.spacing.s7};
  height: ${HEADER_HEIGHT}rem;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: ${theme.zIndices.i2};

  ${theme.mq.md} {
    padding: ${isWidget ? theme.spacing.s7 : `${theme.spacing.s7} ${theme.spacing.s12}`};
  }
`);

const LeftContent = styled.div(({ theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s4};
`);

const ActionsContainer = styled.div(({ theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s4};
`);

const ActionButton = styled.button<{disabled?: boolean}>(({ theme, disabled }) => css`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${disabled ? 'auto' : 'pointer'};
  height: 2rem;
  width: 2rem;
  border-radius: 1.5rem;
  transition: 0.15s;
  opacity: ${disabled ? 0.5 : 1};

  :hover {
    background: ${disabled ? theme.colors.Gray100 : theme.colors.Gray200};
  }
`);

interface Props {
  onCreateNewChat: () => void;
  openChatList: () => void;
  isWidget: boolean;
  onClose?: () => void;
  conversationId: IdentifierDTO;
}

export const Header: FC<Props> = ({ onCreateNewChat, openChatList, isWidget, onClose, conversationId }) => {
  //TODO: Refactor copilot chat avoiding to get messages again to check if the chat is empty
  const { data: messages, isLoading } = useGet<Message[]>(CopilotChatKeys.getMessages(conversationId), API.copilot.getConversationMessages(conversationId));

  const disabledCreateNewChat = isLoading || !messages || messages.length === 0;

  const handleCreateNewChat = async () => {
    if (!disabledCreateNewChat) {
      onCreateNewChat();
    }
  };

  return (
    <Container isWidget={isWidget}>
      <LeftContent>
        <Sparkles color="Primary" size={24}/>
        <Bold variant="L">Zazume Copilot</Bold>
        <Tag>Beta</Tag>
      </LeftContent>
      <ActionsContainer>
        <ActionButton onClick={handleCreateNewChat} disabled={disabledCreateNewChat}>
          <Plus color="Gray500" size={24}/>
        </ActionButton>
        <ActionButton onClick={openChatList}>
          <Chat color="Gray500" size={20}/>
        </ActionButton>
        {isWidget && <ActionButton onClick={onClose}>
          <Cross color="Gray500" size={20}/>
        </ActionButton>}
      </ActionsContainer>
    </Container>
  );
};
