import React, { useState } from 'react';
import { Bold, FCC, Flex, getIcon, IconType, Semibold } from '@zazume/zzm-base';
import { DetailFieldsProvider } from './atoms/FieldsSectionContext';
import { ShowMore } from './atoms/ShowMore';
import styled from '@emotion/styled';

const CTAContainer = styled.div`
  width: fit-content;
  white-space: nowrap;
`;

export interface DetailFieldsProps {
  title?: string;
  icon?: IconType;
  CTAContent?: React.ReactNode;
  titleVariant?: 'XL' | 'L';
}

const checkChildHasDefaultHidden = (child: React.ReactElement) => {
  return child.props?.isDefaultHidden === true;
};

export const DetailFields: FCC<DetailFieldsProps> = ({ title, titleVariant = 'XL', icon, children, CTAContent }) => {
  const Icon = icon && getIcon(icon);
  const sectionChildren = React.Children.toArray(children) as React.ReactElement[];

  const hasDefaultHidden = sectionChildren.some(child => {
    if (checkChildHasDefaultHidden(child)) {
      return true;
    }
    const fields = React.Children.toArray(child.props?.children) as React.ReactElement[];
    return fields.some(checkChildHasDefaultHidden);
  });

  const [areSectionsHidden, setAreSectionsHidden] = useState(true);
  const handleShowInfo = () => setAreSectionsHidden(!areSectionsHidden);

  return (
    <Flex direction="column" gap="s9">
      <Flex justify="between">
        {title &&
          <Flex gap="xs" align="center">
            {Icon && <Icon size={titleVariant === 'XL' ? 32 : 20} color="Primary"/>}
            {titleVariant === 'XL' ? <Semibold variant="XL">{title}</Semibold> : <Bold variant="M">{title}</Bold>}
          </Flex>
        }
        {CTAContent && <CTAContainer>{CTAContent}</CTAContainer>}
      </Flex>
      <Flex direction="column" gap="s12">
        <DetailFieldsProvider areSectionsHidden={areSectionsHidden}>
          {children}
        </DetailFieldsProvider>
      </Flex>
      {hasDefaultHidden && <ShowMore handleShowInfo={handleShowInfo} showMoreInfo={!areSectionsHidden}/>}
    </Flex>
  );
};
