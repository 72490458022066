import { useI18n } from '#hooks/useI18n';
import React from 'react';
import styled from '@emotion/styled';
import { parseErrors, WithErrors } from '../utils';
import { css } from '@emotion/react';

const Container = styled.div(({ theme }) => css`
  flex: 1;
  width: 100%;
  font-family: ${theme.fonts.base};

  & > fieldset {
    width: 100%;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
  }
`);

const TextareaField = styled.textarea<any>(({ theme, height, hasErrors, highlight, type, font }) => css`
    width: 100%;
    min-height: 90px;
    max-height: ${height}px;
    height: ${height}px;
    padding: 8px 12px;
    border: 1px solid ${theme.colors.Gray300};
    border-color: ${ hasErrors ? theme.colors.Red : type === 'highlight' || highlight ? theme.colors.MostardDark : theme.colors.Gray300 } ;
    border-radius: 8px;
    box-sizing: border-box;
    outline: none;
    font-family: ${theme.fonts.base};
    font-size: ${font.size}px;
    font-style: normal;
    font-weight: ${font.weight}px;
    line-height: ${font.lineHeight}px;
    letter-spacing: 0;
    color: ${theme.colors.Gray600};
    resize: none;

    &::placeholder{
      color: ${theme.colors.Gray300}
    }

    &:hover,
    &:focus {
      border-color: ${theme.colors.Primary};
    }

    &:read-only {
      border-color: ${theme.colors.Gray200};
    }

    &.highlight {
      border: 1px solid ${theme.colors.MostardDark};
    }
`);

const Label = styled.label(({ theme }) => css`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: ${theme.colors.Gray600};
  margin: 0;
  width: 100%;
  height: 28px;
`);

const Helper = styled.div(({ theme }) => css`
  color: ${theme.colors.Gray400};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0;
`);

const Error = styled.div(({ theme }) => css`
  color: ${theme.colors.Red};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0;
`);

const Highlight = styled.div(({ theme }) => css`
  color: ${theme.colors.MostardDark};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0;
`);

const OptionalTag = styled.label(({ theme }) => css`
  color: ${theme.colors.Gray400};
  padding-left: 8px;
`);

type TextareaScale = 'small' | 'large' | 'medium';

export interface TextareaProps extends WithErrors {
  name?: string;
  placeholder?: string;
  scale?: TextareaScale;
  label?: string;
  help?: string;
  defaultValue?: string;
  value?: string;
  isOptional?: boolean;
  onBlur?: (newValue: string) => void;
  onChange?: (newValue: string) => void;
  maxLength?: number;
  height?: number;
  highlight?: boolean;
  highlightMessage?: string;
  readOnly?: boolean
}

/**
 * @deprecated use src/components/forms/input/TextareaBase.tsx
 */
export const Textarea = React.forwardRef<any, TextareaProps>((props: any, ref) => {
  const { t } = useI18n();

  const { name, placeholder, children, label, onChange, onBlur, help, errors, clearErrors, scale, value, defaultValue, isOptional, maxLength, height = 90, highlight, highlightMessage, ...rest } = props;

  const handleOnBlur = (event) => onBlur?.(event.currentTarget.value);
  const handleOnChange = ({ currentTarget: element }) => {
    name && hasErrors && clearErrors?.(name);
    element.style.height = `${height}px`;
    element.style.height = (element.scrollHeight + 2) + 'px';
    onChange?.(element.value);
  };
  const getFont = () => {
    switch (scale) {
      case 'small':
        return { size: 14, weight: 400, lineHeight: 20 };
      case 'medium':
        return { size: 16, weight: 400, lineHeight: 24 };
      case 'large':
        return { size: 20, weight: 700, lineHeight: 32 };
      default:
        return { size: 16, weight: 400, lineHeight: 24 };
    }
  };

  const { hasErrors, errorMessage } = parseErrors(props);

  return <Container>
    <fieldset>
      {label && <Label htmlFor={name}>
        {label}
        {isOptional && <OptionalTag>{t('general.optional')}</OptionalTag>}
      </Label>}
      <TextareaField
          ref={ref}
          name={name}
          placeholder={placeholder}
          font={getFont()}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          hasErrors={hasErrors}
          defaultValue={defaultValue}
          value={value}
          maxLength={maxLength}
          height={height}
          highlight={highlight}
          {...rest}
      />
      {help && <Helper>{help}</Helper>}
      {errorMessage && <Error>{errorMessage}</Error>}
      {highlight && highlightMessage && <Highlight>{highlightMessage}</Highlight>}
    </fieldset>
  </Container>;
});
