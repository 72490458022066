import React, { FC } from 'react';
import { Building, Folder, Wallet, Warning, Welcome } from '@zazume/zzm-base';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useTheme } from '@emotion/react';
import { NotificationCategory } from '../../models/notifications/Notifications';

interface IconDefinition {
  color: string;
  Icon: any,
  backgroundColor: any;
}

const Ellipse = styled.div<{ color: string, size: number }>(({ color, size }) => css`
  background-color: ${color};
  min-height: ${size}px;
  min-width: ${size}px;
  width: ${size}px;
  height: ${size}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`);

const Icons: Record<NotificationCategory, IconDefinition> = {
  'Accounting': { color: 'Green', Icon: Wallet, backgroundColor: 'GreenLight2' },
  'Applications': { color: 'Orange', Icon: Welcome, backgroundColor: 'OrangeLight' },
  'Management': { color: 'Pink', Icon: Folder, backgroundColor: 'PinkLight' },
  'Portfolio': { color: 'CianDark', Icon: Building, backgroundColor: 'CianLight' }
};

const DefaultIcon: IconDefinition = {
  color: 'Red',
  Icon: Warning,
  backgroundColor: 'RedLight'
};


interface NotificationIconProps {
  variant?: 'small' | 'big';
  category: NotificationCategory;
  isAlert: boolean;
}


export const NotificationIcon: FC<NotificationIconProps> = ({ variant = 'small', category, isAlert = false }) => {
  const theme = useTheme();
  const { color, Icon, backgroundColor } = Icons[category] || DefaultIcon;
  const sizeEllipsis = variant === 'small' ? 24 : 40;
  const sizeIcon = variant === 'small' ? 16 : 32;

  if (isAlert) {
    return <Ellipse size={sizeEllipsis} color={theme.colors['Redlight1']}>
      <Warning size={sizeIcon} color="Red"/>
    </Ellipse>;
  }

  return <Ellipse size={sizeEllipsis} color={theme.colors[backgroundColor]}>
    <Icon size={sizeIcon} color={color}/>
  </Ellipse>;
};
