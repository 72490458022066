import { IdentifierDTO } from '@zazume/zzm-base';
import { doGet, doPost, doPut } from '../../helpers';
import { KeyDelivery, RelatedModelType } from '../../../../models/keyDelivery/KeyDelivery';
import { UpdateFromRequest } from './models/UpdateFromRequest';
import { CreateRequest } from './models/CreateRequest';
import { UpdateToRequest } from './models/UpdateToRequest';

export const keyDelivery = {
  getById: (id: IdentifierDTO) => () =>
    doGet<KeyDelivery>(`/dashboard/key-delivery/${id}`),
  create: (relatedModelId: IdentifierDTO, relatedModelType: RelatedModelType) =>
    (request: CreateRequest) =>
      doPost<KeyDelivery>(`/dashboard/key-delivery/${relatedModelType}/${relatedModelId}`, request),
  updateFrom: (keyDeliveryId: IdentifierDTO) =>
    (request: UpdateFromRequest) =>
      doPut<KeyDelivery>(`/dashboard/key-delivery/from/${keyDeliveryId}`, request),
  updateTo: (keyDeliveryId: IdentifierDTO) =>
    (request: UpdateToRequest) =>
      doPut<KeyDelivery>(`/dashboard/key-delivery/to/${keyDeliveryId}`, request),
  cancel: (id: IdentifierDTO) => () =>
    doPut<KeyDelivery>(`/dashboard/key-delivery/cancel/${id}`)
};
