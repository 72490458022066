import { FC, useState } from 'react';
import { IdentifierDTO, Spinner, useModal } from '@zazume/zzm-base';
import { useI18n } from '../../../../../hooks/useI18n';
import { DocumentUploader } from '../../../../../components/documentUploader/DocumentUploader';
import { CreateDocumentModal } from '../../../../../components/modal/CreateDocumentModal';
import { useUpdate } from '../../../../../lib/reactQuery/hooks/useUpdate';
import { API } from '../../../../../lib/network/API';
import { ProvidersKeys } from '../../../../../hooks/api/providers/Providers.keys';
import { CreateDocumentRequest } from '../../../../../lib/network/api/documents/requests/CreateDocumentRequest';
import { useDelete, useGet, useNotifications } from '../../../../../hooks';
import { Document } from '../../../../../models';
import { useNetworkExceptions } from '../../../../../lib/network/helpers';
import { UpdateDocumentWrapper } from '../modals/UpdateDocumentWrapper';

interface DocumentsTabProps {
  providerId: IdentifierDTO;
}

export const DocumentsTab: FC<DocumentsTabProps> = ({ providerId }) => {
  const { t } = useI18n();
  const { showNotification } = useNotifications();
  const { handleNetworkExceptions } = useNetworkExceptions();

  const { open: show, RenderModal, close: hide } = useModal({ modalSize: 'l' });
  const { open: openUpdateModal, RenderModal: RenderUpdateModal, close: hideUpdateModal } = useModal({ modalSize: 'l' });

  const [focusDocument, setFocusDocument] = useState<Document | undefined>();

  const { mutate: addDocument } = useUpdate<void, CreateDocumentRequest>(
    ProvidersKeys.getDocuments(providerId),
    API.providers.createDocument(providerId));

  const { data: documents, isLoading } = useGet<Document[]>(
    ProvidersKeys.getDocuments(providerId),
    API.documents.getDocuments('Provider', providerId));

  const { mutate: deleteDocument } = useDelete(
    ProvidersKeys.getDocuments(providerId),
    API.documents.delete);

  const handleOnDelete = (document: Document) => {
    try {
      deleteDocument(document._id);
      showNotification(t('documents.removed'));
    } catch (e) {
      handleNetworkExceptions(e);
    }
  };

  const handleOpenUpdate = (document: Document) => {
    setFocusDocument(document);
    openUpdateModal();
  };

  return <>
    {isLoading
      ? <Spinner color="Primary" size="extra-large"/>
      : <DocumentUploader
        icon="document"
        title={t('general.documents')}
        onRemove={handleOnDelete}
        onUpdate={handleOpenUpdate}
        onUpload={show}
        documents={documents || []}
        buttonSize="Large"
        relatedModel="Provider"
        relatedModelId={providerId}
        category="provider"
      />
    }
    <RenderModal>
      <CreateDocumentModal
        isSelectCategoryLocked
        relatedModel="Provider"
        defaultCategory="provider"
        close={hide}
        onSubmit={addDocument}
        withHiddenFromOwner={false}
      />
    </RenderModal>
    {focusDocument &&
      <RenderUpdateModal>
        <UpdateDocumentWrapper
          document={focusDocument}
          providerId={providerId}
          onClose={hideUpdateModal}
        />
      </RenderUpdateModal>
    }
  </>;
};
