import { useI18n } from '#hooks/useI18n';
import { ZazumeModal, ZZMForm } from '@zazume/zzm-base';
import { FC } from 'react';

export const CreateDocumentUploadLinkModalFooter: FC = () => {
  const { t } = useI18n();
  const { formState: { isSubmitting } } = ZZMForm.useFormContext();

  return <ZazumeModal.Footer
    cancelButtonText={t('general.cancel')}
    acceptButtonType="submit"
    isSubmitting={isSubmitting}
    acceptButtonText={t('documentUploadLink.create.submitAction')}/>;
};
