import React, { useMemo } from 'react';
import { OptionType, Select, SelectProps } from './Select';
import { DropdownVariant } from '../../dropdown/Dropdown';

export type SelectValue = string | number | boolean;

type DeprecatedProps = 'options' | 'name' | 'defaultValue';

export interface SelectFieldProps extends Omit<SelectProps, DeprecatedProps> {
  options: object[];
  name: string;
  labelFieldName?: string;
  valueFieldName?: string;
  defaultValue?: SelectValue;
  variant?: DropdownVariant;
  forceUpdate?: OptionType;
  trimSelected?: boolean;
}

const getOldDefaultValue = (defaultValue?: SelectValue, oldOptions: OptionType[] = []): OptionType | undefined => {
  // Avoiding falsy - Do not use !defaultValue here
  if (defaultValue === undefined) {
    return undefined;
  }

  return oldOptions.find(option => option.value === defaultValue);
};
/**
 * @deprecated use src/components/forms/select/SelectBase.tsx
 */
export const SelectField = React.forwardRef<any, SelectFieldProps>((props, ref) => {
  const {
    options,
    labelFieldName = 'name',
    valueFieldName = 'value',
    defaultValue,
    variant = 'normal',
    forceUpdate,
    trimSelected = true,
    ...rest
  } = props;

  const oldOptions = useMemo<OptionType[]>(() => {
    return options.map(option => ({
      value: option[valueFieldName],
      name: option[labelFieldName],
      disabled: option['disabled']
    }));
  }, [options, valueFieldName, labelFieldName]);

  const selectDefaultValue = useMemo(() => getOldDefaultValue(defaultValue, oldOptions),
    [oldOptions, defaultValue]);

  return <Select
    ref={ref}
    options={oldOptions}
    defaultValue={selectDefaultValue}
    forceUpdate={forceUpdate}
    variant={variant}
    trimSelected={trimSelected}
    {...rest}
  />;
});
