import { FCC } from '@zazume/zzm-base';
import { createContext, useContext } from 'react';

export type Variant = 'small' | 'default';

interface PageContextValue {
  variant: Variant;
}

const PageContext = createContext<PageContextValue | null>(null);

interface PageContextProps {
  variant?: Variant;
}

export const PageContextProvider: FCC<PageContextProps> = ({ variant = 'default', children }) => {
  const value: PageContextValue = {
    variant
  };

  return <PageContext.Provider value={value}>
    {children}
  </PageContext.Provider>;
};

export const usePageContext = (): PageContextValue => {
  const context = useContext(PageContext);
  // TODO temporal fix waiting for pages refactor
  // if (!context) {
  //   throw new Error('usePageContext must be used within a PageContextProvider');
  // }
  return context || {
    variant: 'default'
  };
};
