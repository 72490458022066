import React, { FC } from 'react';
import { useNotifications } from '../../hooks/useNotifications';
import { ToastContainer } from './ToastContainer';
import { ModalContainer } from './ModalContainer';

export const NotificationsContainer: FC = () => {
  const { notifications } = useNotifications();

  if (!notifications) {
    return null;
  }

  return <>
    <ToastContainer/>
    <ModalContainer/>
  </>;
};
