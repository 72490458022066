import { FCC, IconType } from '@zazume/zzm-base';
import { createContext, useContext, useState } from 'react';
import { CurrencyVO, PriceVO } from '@zazume/zzm-utils';
import { AdministrativeArea } from '#/models/location/AdministrativeArea';

type Step = 'select-plan' | 'accept-conditions';

type RealEstatePlanType = 'real_estate_opportunities_area' | 'real_estate_opportunities';

interface RealEstatePlan {
  name: RealEstatePlanType,
  icon: IconType,
  price: PriceVO
}

export const OPPORTUNITIES_PLANS: Record<RealEstatePlanType, RealEstatePlan> = {
  'real_estate_opportunities_area': {
    name: 'real_estate_opportunities_area',
    icon: 'locationPin',
    price: PriceVO.fromUnitAmount(20, CurrencyVO.fromPrimitive('eur'))
  },
  'real_estate_opportunities': {
    name: 'real_estate_opportunities',
    icon: 'wallet',
    price: PriceVO.fromUnitAmount(75, CurrencyVO.fromPrimitive('eur'))
  }
};

interface ModalContextValue {
  step: Step;
  setStep: (step: Step) => void;
  cancel: () => void;
  selectedPlan?: string;
  onSelectPlan: (plan: string) => void;
  area?: AdministrativeArea;
  onSelectArea: (area: AdministrativeArea | undefined) => void;
}

const ModalContext = createContext<ModalContextValue | null>(null);

interface ModalContextProviderProps {
  onCloseModal: () => void;
}

export const ModalContextProvider: FCC<ModalContextProviderProps> = ({ onCloseModal, children }) => {

  const [step, setStep] = useState<Step>('select-plan');
  const [selectedPlan, setSelectedPlan] = useState<string>();
  const [selectedArea, setSelectedArea] = useState<AdministrativeArea>();

  const value: ModalContextValue = {
    step,
    setStep,
    cancel: onCloseModal,
    selectedPlan,
    onSelectPlan: setSelectedPlan,
    area: selectedArea,
    onSelectArea: setSelectedArea
  };

  return <ModalContext.Provider value={value}>
    {children}
  </ModalContext.Provider>;
};

export const useModalContext = (): ModalContextValue => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModalContext needs a ModalContext');
  }
  return context;
};
