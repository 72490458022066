import React, { FC, Suspense } from 'react';
import { ErrorBoundary } from '../errors/ErrorBoundary';
import { LoadingPage } from '#/pages/Loading.page';
import { useAuth } from '#/contexts/AuthProvider';
import { AnonymousRoutes } from './AnonymousRoutes';
import { PrivateRoutes } from './PrivateRoutes';
import { LeftNavigationTemplate } from '#/templates/LeftNavigationTemplate';
import { PublicRoutes } from './PublicRoutes';
import { useRouter } from '#/hooks';
import usePageTracking from '../../hooks/router/usePageTracking';

export const Router: FC = () => {
  const { isLogged } = useAuth();
  const { isPublicRoute } = useRouter();

  usePageTracking();

  // TODO Create reducer for User like UserHelper in backend. Tell don't ask.
  // TODO Create Owner Routes separately.
  if (isPublicRoute()) {
    return <ErrorBoundary>
      <Suspense fallback={<LoadingPage/>}>
        <PublicRoutes/>
      </Suspense>
    </ErrorBoundary>;
  }

  if (isLogged()) {
    return <ErrorBoundary>
      <Suspense fallback={<LoadingPage/>}>
        <LeftNavigationTemplate>
          <PrivateRoutes/>
        </LeftNavigationTemplate>
      </Suspense>
    </ErrorBoundary>;
  }

  return <ErrorBoundary>
    <Suspense fallback={<LoadingPage/>}>
      <AnonymousRoutes/>
    </Suspense>
  </ErrorBoundary>;
};
