import { IconType } from '@zazume/zzm-base';
import { PriceVO, ZazumeService } from '@zazume/zzm-utils';
import { AuthOrganization, ManagementService, Organization } from '../../Organization';

export class OrganizationHelper {
  private readonly organization: Organization | AuthOrganization;

  constructor(organization: Organization | AuthOrganization) {
    this.organization = organization;
  }

  static build(organization: Organization | AuthOrganization) {
    return new OrganizationHelper(organization);
  }

  isManagementServiceAvailable(service: ZazumeService): boolean {
    return this.organization.managementServices.some(managementService => managementService.type === service);
  }

  isSomeServiceAvailable(): boolean {
    return this.organization.managementServices.length > 0;
  }

  getManagementServiceValue(service: ZazumeService): number | undefined {
    return this.organization.managementServices.find(managementService => managementService.type === service)?.managementFee.value;
  }

  hasPaymentsFees(): boolean {
    return Boolean(this.organization.paymentData);
  }

  hasManagementServices(): boolean {
    return Boolean(this.organization.managementServices?.length);
  }

  hasBillingData(): boolean {
    return Boolean(this.organization.billingData);
  }

  getManagementServices(): ManagementService[] {
    //TODO: Remove filter when tenant_search is removed from platform
    return this.organization.managementServices.filter((service) => service.type !== 'tenant_search');
  }
}

export interface ZazumeServicesConstantType {
  icon: IconType;
  value: ZazumeService;
  quota: number;
  price?: PriceVO;
}
//Just for default organization
export const getZazumeManagementServicesAttributes = (zazumeOrganizationHelper: OrganizationHelper): { [key: string]: ZazumeServicesConstantType } => {
  return {
    tenant_search: {
      icon: 'profileCheck',
      value: 'tenant_search',
      quota: zazumeOrganizationHelper.getManagementServiceValue('tenant_search')!
    },
    rental_management: {
      icon: 'owner',
      value: 'rental_management',
      quota: zazumeOrganizationHelper.getManagementServiceValue('rental_management')!
    },
    guaranteed_rental_self_management: {
      icon: 'owner',
      value: 'guaranteed_rental_self_management',
      quota: zazumeOrganizationHelper.getManagementServiceValue('guaranteed_rental_self_management')!
    },
    guaranteed_rental: {
      icon: 'welcome',
      value: 'guaranteed_rental',
      quota: zazumeOrganizationHelper.getManagementServiceValue('guaranteed_rental')!
    },
    full_coverage: {
      icon: 'lock',
      value: 'full_coverage',
      quota: zazumeOrganizationHelper.getManagementServiceValue('full_coverage')!
    },
    peer_to_peer: {
      icon: 'magnifier',
      value: 'peer_to_peer' as ZazumeService,
      quota: 100,
      price: PriceVO.fromString('5900', 'eur')
    }
  };
};
