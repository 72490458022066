import React, { FC } from 'react';
import { Calendar, Flex, Regular, Semibold } from '@zazume/zzm-base';
import { getCurrentUILanguage } from '#lib/i18n/utils';
import { useI18n } from '#hooks/useI18n';
import { DateVO } from '@zazume/zzm-utils';

interface ExpirationProps {
  expireAt?: Date;
}

export const Expiration: FC<ExpirationProps> = ({ expireAt }) => {
  const { t } = useI18n();
  const locale = getCurrentUILanguage();

  if (!expireAt) {
    return null;
  }

  const expiration = expireAt && DateVO.fromPrimitive(expireAt);
  const isExpired = expiration && expiration.isPast();
  const isExpiring = expiration && !isExpired && expiration.subtract(1, 'month').isPast();

  return <Flex direction="column" gap="s2">
    <Regular variant="XS" color="Gray500">{t('documents.expirationDate')}</Regular>
    <Flex gap="s2" align="center">
      <Calendar color="Gray600" size={20}/>
      <Semibold variant="S">{expiration.format('date', locale)}</Semibold>
    </Flex>
    {isExpiring && <Regular variant="XXS" color="Red">{t('documents.expiresIn', { count: expiration.withTime(23, 59, 59, 59).fromNow('day') })}</Regular>}
    {isExpired && <Regular variant="XXS" color="Red">{t('documents.expiredDetail')}</Regular>}
  </Flex>;
};
