import { FCC } from '@zazume/zzm-base';
import React from 'react';
import styled from '@emotion/styled';

export const FOOTER_HEIGHT = 72;

const Container = styled.div`
  padding: 16px 0 16px 0;
  height: ${FOOTER_HEIGHT}px;
  display: flex;
  justify-content: flex-end;
`;

export const Footer: FCC<any> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};
