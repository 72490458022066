import { PaginationOptions } from '#/hooks';
import { BasicUnit, Document } from '#/models';
import { PaymentDistribution, Payout, PayoutRecipients } from '#/models/Payout';
import { InitiateBulkPaymentReport } from '#/models/payouts/InitiateBulkPaymentReport';
import { InitiatePaymentResponse } from '#/models/payouts/InitiatePaymentResponse';
import { AddPayoutRequest } from './requests/AddPayoutRequest';
import { doDelete, doGet, doPatchFileUpload, doPost, doPut, doPutFileUpload, EndpointBuilder, toPaginatedURL } from '#lib/network/helpers';
import { IdentifierDTO } from '@zazume/zzm-base';

export const payouts = {
  addAttachments: (payoutId: IdentifierDTO) => (document: any) =>
    doPatchFileUpload(`/dashboard/payouts/attachments/${payoutId}`, document),
  addMultiplePayments: () => (newReconciliations) =>
    doPut('/dashboard/payouts/multiple-payments', newReconciliations),
  addMultiplePaymentsFromBankTransactions: (payoutId: IdentifierDTO) => (formData: any) =>
    doPut(`/dashboard/payouts/payment/${payoutId}/bank-transactions`, formData),
  addPayment: (payoutId: IdentifierDTO) => (formData: any) =>
    doPutFileUpload(`/dashboard/payouts/payment/${payoutId}`, formData),
  addPayout: (organizationId: IdentifierDTO) => (payout: AddPayoutRequest) =>
    doPost(`/dashboard/payouts/${organizationId}`, payout),
  delete: (payoutId: IdentifierDTO) => () =>
    doDelete(`/dashboard/payouts/${payoutId}`),
  downloadSEPABulkPayment: (organizationId: IdentifierDTO, payouts: IdentifierDTO[], bankAccountLinkedId?: IdentifierDTO) =>
    doPost<{ url: string }>(`/dashboard/banking/payments/bulk/sepa/${organizationId}`, { payouts, bankAccountLinkedId }),
  editPayoutPayments: (payoutId: IdentifierDTO) => (payout: any) =>
    doPut(`/dashboard/payouts/${payoutId}`, payout),
  findNoPaidPayoutsByUnit: (unitId: IdentifierDTO) =>
    () =>
      doGet<Payout[]>(`/dashboard/payouts/no-paid/units/${unitId}`),
  getAttachments: (payoutId: IdentifierDTO) => () =>
    doGet<Document[]>(`/dashboard/payouts/attachments/${payoutId}`),
  getOne: (payoutId: IdentifierDTO) => () =>
    doGet<Payout>(`/dashboard/payouts/${payoutId}`),
  getPaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Payout[]>(toPaginatedURL(`/dashboard/payouts${params.url}${params.queryParams}`, pagination)),
  getPayoutRecipientsByOrganization: (organizationId: IdentifierDTO) => () =>
    doGet<PayoutRecipients[]>(`/dashboard/payouts/recipients/organization/${organizationId}`),
  getPayoutUnitsByOrganization: (organizationId: IdentifierDTO) => () =>
    doGet<BasicUnit[]>(`/dashboard/payouts/units/organization/${organizationId}`),
  getReconcilablePaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Payout[]>(toPaginatedURL(`/dashboard/payouts/reconcilable${params.url}${params.queryParams}`, pagination)),
  getReconcilablePayoutUnitsBy: (organizationId: IdentifierDTO) => () =>
    doGet<BasicUnit[]>(`/dashboard/payouts/reconcilable/units/organization/${organizationId}`),
  initiateBulkPayment: (organizationId: IdentifierDTO, payouts: IdentifierDTO[]) =>
    doPost<{ transactionId: IdentifierDTO }>(`/dashboard/banking/payments/bulk/initiate/${organizationId}`, { payouts }),
  initiateBulkPaymentReport: (organizationId: IdentifierDTO, payouts: IdentifierDTO[]) =>
    doPost<InitiateBulkPaymentReport>(`/dashboard/banking/payments/bulk/initiate/report/${organizationId}`, { payouts }, raw => new InitiateBulkPaymentReport({ ...raw })),
  initiatePayment: (organizationId: IdentifierDTO, payoutId: IdentifierDTO) =>
    doPost<InitiatePaymentResponse>(`/dashboard/banking/payments/initiate/${organizationId}/${payoutId}`),
  markAsToValidate: (payoutId: IdentifierDTO) => () =>
    doPut(`/dashboard/payouts/${payoutId}/toValidate`),
  removePayment: (payoutId: IdentifierDTO) => (formData: any) =>
    doDelete(`/dashboard/payouts/${payoutId}/payment/${formData.reconciliationId}`, { forced: formData.forced }),
  getPlannedPayoutPaymentsDistribution: (payoutId: IdentifierDTO) => () =>
    doGet<PaymentDistribution[]>(`/dashboard/payouts/planned-payments-distribution/${payoutId}`)
};
