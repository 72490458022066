import { BaseRoute } from './types';

const AnonymousRoutesIdentifiers = <const>[
  'ERROR',
  'FORGOT_PASSWORD',
  'HOME',
  'LOGIN',
  'SINGLE_SIGN_ON_LOGIN',
  'NEW_PASSWORD'
];

type AnonymousRoutesType = typeof AnonymousRoutesIdentifiers[number];

export const Anonymous = {
  HOME: {
    route: '/'
  },
  LOGIN: {
    route: '/login'
  },
  SINGLE_SIGN_ON_LOGIN: {
    route: '/single-sign-on'
  },
  FORGOT_PASSWORD: {
    route: '/forgot'
  },
  NEW_PASSWORD: {
    route: '/new-password'
  },
  ERROR: {
    route: '/error'
  }
} satisfies Record<AnonymousRoutesType, BaseRoute>;
