import { Phone, Whatsapp } from '@zazume/zzm-base';
import { LanguageVO } from '@zazume/zzm-utils';
import { FC } from 'react';
import { useI18n } from '#/hooks/useI18n';
import { formatPhone } from '../../utils/phoneHelper';
import { linkWhatsapp } from '../../utils/whatsappHelper';
import { Stack } from '../layout/Stack';
import { Link } from '../typography';
import { RespondioButton } from './RespondioButton';
import { Can } from '../roles/Can';

export interface ButtonsContactProps {
  email?: string;
  phone: string
  countryCode: number
  name: string
  // TODO change to LanguageVO ASAP
  lang: string
}

export const ButtonsContact: FC<ButtonsContactProps> = ({ email, phone, countryCode, name, lang }) => {
  const { t } = useI18n();

  return (
    <Stack spacing={8} align="center">
      <Link href={linkWhatsapp(phone, countryCode, t('whatsapp.message', { name, lng: LanguageVO.from(lang).toPlatform() }))} target="_blank">
        <Whatsapp size={20} color="Gray400"/>
      </Link>
      <Link href={`tel:${formatPhone(phone, countryCode.toString())}`} target="_blank">
        <Phone size={20} color="Gray400"/>
      </Link>
      {email && <Can hasRoles={['admin', 'buildingManager']}>
        <RespondioButton email={email}/>
      </Can>}
    </Stack>
  );
};
