import { ZazumeManagementService } from '#/models';
import { IdentifierDTO } from '@zazume/zzm-base';
import { IBAN } from '@zazume/zzm-utils';
import { ManagementFee } from './ManagementFee';
import { Service } from './Service';

interface ConstructorParams {
  managementFee: ManagementFee;
  platformManagementFee?: ManagementFee;
  isInheritedFromOrganization: boolean;
  managementService?: ZazumeManagementService;
  isGuaranteedRentalService?: boolean;
  leaseId?: IdentifierDTO;
  iban: IBAN;
}

export class ManagementService extends Service {
  managementFee: ManagementFee;
  platformManagementFee?: ManagementFee;
  isInheritedFromOrganization: boolean;
  managementService?: ZazumeManagementService;
  isGuaranteedRentalService?: boolean;
  leaseId?: IdentifierDTO;
  iban: IBAN;

  constructor(params: ConstructorParams) {
    super('Management');
    this.managementFee = params.managementFee;
    this.platformManagementFee = params.platformManagementFee;
    this.isInheritedFromOrganization = params.isInheritedFromOrganization;
    this.managementService = params.managementService;
    this.isGuaranteedRentalService = params.isGuaranteedRentalService;
    this.leaseId = params.leaseId;
    this.iban = params.iban;
  }

  static fromRaw(service: any): ManagementService {
    return new ManagementService({
      iban: IBAN.from(service.iban),
      isGuaranteedRentalService: service.isGuaranteedRentalService,
      isInheritedFromOrganization: service.isInheritedFromOrganization,
      leaseId: service.leaseId,
      managementFee: ManagementFee.fromRaw(service.managementFee),
      managementService: service.managementService,
      platformManagementFee: service.platformManagementFee && ManagementFee.fromRaw(service.platformManagementFee)
    });
  }

  isLinkedToLease(): boolean {
    return Boolean(this.leaseId);
  }

  canBeDeleted(): boolean {
    return !this.isLinkedToLease();
  }
}
