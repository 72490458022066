import React, { FC } from 'react';
import { SectionProps } from '#/containers/asideNavigation/Sections';
import { CanInDefaultOrganization } from '#/components/roles/CanInDefaultOrganization';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';
import { useI18n } from '#hooks/useI18n';

interface MarketplaceProps extends SectionProps {

}

export const Marketplace: FC<MarketplaceProps> = ({ onClickItem, isMobile, depth }) => {
  const { t } = useI18n();
  return <>
    <CanInDefaultOrganization>
      <Can hasRoles={['ownerBasic', 'ownerManager', 'ownerMiddle']} hideFromZazumeUser={true}>
        <NavigationLink to={Private.OWNER_SERVICES.route} parentPath="/owner-services" title={t('asideNavigation.marketPlace')}
                        icon="marketplace"
                        depth={depth}
                        isMobile={isMobile}
                        onClick={onClickItem}/>
      </Can>
    </CanInDefaultOrganization>
  </>;
};
