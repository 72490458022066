import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ResultProps } from './ResultList';
import { css } from '@emotion/react';
import { AvatarWithName } from '@zazume/zzm-base';

const Container = styled.div(({ theme }) => css`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0;
  user-select: none;
  padding: ${theme.spacing.sm} 0;
`);

export const AgentResult: FC<ResultProps> = ({ result, onSelect }) => {
  const handleSelect = () => onSelect(result);

  return (
    <Container onClick={handleSelect}>
      <AvatarWithName user={result}/>
    </Container>
  );
};
