import { StateTag, Variants } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { useI18n } from '../../../../hooks/useI18n';
import { I18NValues } from '../../../../lib/i18n/generatedKeys';
import { ContractState } from '../../../../models/Contract';

const signerStatusVariants: { [key in ContractState]: Variants } = {
  not_signed: 'warning',
  partially_signed: 'success',
  signed: 'success',
  rejected: 'error',
  broken: 'warning',
  expired: 'error'
};

interface SignerStateTagProps {
  signerStatus: ContractState;
  hasEmailError?: boolean;
}

export const SignerStateTag: FC<SignerStateTagProps> = ({ signerStatus, hasEmailError }) => {
  const { t } = useI18n();

  if (hasEmailError) {
    return <StateTag variant="error" text={t('general.error')}/>;
  }

  const text = t(`onboarding.contractSigning.states.${signerStatus}` as I18NValues);

  return <StateTag variant={signerStatusVariants[signerStatus]} text={text}/>;
};
