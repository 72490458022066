import Sentry from './sentryHelper';
import Hotjar from './hotjarHelper';
import { User } from '../../models';
import GoogleAnalytics from './gaHelper';
import Segment from './segmentHelper';
import { EventType } from './events/events';

export default class MetricsHelper {

  static startMetrics() {
    Sentry.startSentry();
    Hotjar.startHotjar();
    //Google Analytics and Segment are not initialized here because it's been configured only for owners
  }

  static identifyUser(user: User) {
    Sentry.setUser(user);
    Hotjar.setUser(user);
    GoogleAnalytics.setUser(user);
    Segment.updateProfile(user);
  }

  static clearUser() {
    Sentry.clearUser();
    Segment.logout();
    GoogleAnalytics.stopGoogleAnalytics();
  }

  static track(eventType: EventType, event?: object) {
    Segment.trackEvent(eventType, event);
  }

  static reportError(error: Error, errorInfo) {
    Sentry.reportError(error, errorInfo);
  }
}
