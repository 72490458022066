import { IdentifierDTO } from '@zazume/zzm-base';
import { Location } from '#/models/location/Location';
import { PricePrimitive } from '@zazume/zzm-utils';

export const AlertChannelTypeValues = ['email', 'both', 'whatsapp'] as const;
export type AlertChannelType = typeof AlertChannelTypeValues[number];
export const AlertPropertyStateTypeValues = ['good', 'renew', 'newdevelopment'] as const;
export type AlertPropertyStateType = typeof AlertPropertyStateTypeValues[number];
export const AlertAdvertiserTypeValues = ['professional', 'private'] as const;
export type AlertAdvertiserType = typeof AlertAdvertiserTypeValues[number];
export const AlertElevatorTypeValues = ['N/A', 'elevator', 'no_elevator'] as const;
export type AlertElevatorType = typeof AlertElevatorTypeValues[number];

export interface InvestmentAlert {
  _id: IdentifierDTO;
  location: Location;
  maxBudget: PricePrimitive;
  status: AlertPropertyStateType;
  profitability: number;
  minRentPrice: PricePrimitive;
  rooms: number;
  advertiser: AlertAdvertiserType;
  elevator: AlertElevatorType;
  channel: AlertChannelType;
  isActive?: boolean;
}
