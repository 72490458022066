import React, { FC } from 'react';
import styled from '@emotion/styled';
import { AvatarWithName } from '@zazume/zzm-base';
import { ResultProps } from './ResultList';
import { css } from '@emotion/react';

const Container = styled.div(({ theme }) => css`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0;
  user-select: none;
  padding: ${theme.spacing.sm} 0;
`);

export const EntityResult: FC<ResultProps> = ({ result, onSelect }) => {
  const handleSelect = () => onSelect(result);
  const user = {
    fullName: result.entityName!,
    avatar: undefined,
    extraContent: ''
  };

  return (
    <Container onClick={handleSelect}>
      <AvatarWithName user={user}/>
    </Container>
  );
};
