import { IdentifierDTO } from '@zazume/zzm-base';
import { doPut, doGet, doDelete, doPost } from '../../helpers';
import { Conversation, Message, ReviewType } from '#/pages/copilot/CopilotChat';

export const copilot = {
  createConversationFromZendesk: (zendeskTicketId: IdentifierDTO) =>
    doPost<IdentifierDTO>(`/dashboard/assistant/create-conversation-from-zendesk-ticket/${zendeskTicketId}`),
  createConversation: () =>
    doPost<IdentifierDTO>('/dashboard/assistant/create-conversation'),
  sendMessage: (conversationId: IdentifierDTO) => (body: { message: string }) =>
    doPut<Message>(`/dashboard/assistant/${conversationId}/add-message`, body),
  sendReview: (conversationId: IdentifierDTO, messageId: IdentifierDTO) => (body: { review: ReviewType }) =>
    doPut<Message>(`/dashboard/assistant/${conversationId}/messages/${messageId}/add-review`, body),
  getConversation: (conversationId: IdentifierDTO) =>
    doGet<Conversation>(`/dashboard/assistant/conversations/${conversationId}`),
  getConversationMessages: (conversationId: IdentifierDTO) => () =>
    doGet<Message[]>(`/dashboard/assistant/conversations/${conversationId}/messages`),
  getAllConversations: () =>
    doGet<Conversation[]>('/dashboard/assistant/conversations'),
  deleteConversation: (conversationId: IdentifierDTO) =>
    doDelete<void>(`/dashboard/assistant/conversations/${conversationId}`)
};

