import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Key } from '#/components';
import { ResultProps } from './ResultList';
import { Regular } from '@zazume/zzm-base';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  user-select: none;
  padding: 12px 0;

  svg {
    flex-shrink: 0;
  }
`;

const LocationName = styled(Regular)`
  margin-left: 8px;
`;

export const LocationResult: FC<ResultProps> = ({ result, onSelect }) => {
  const handleSelect = () => onSelect(result);

  return (
    <Container onClick={handleSelect}>
      <Key size={20}/>
      <LocationName variant="S" color="Gray400">{result.name}</LocationName>
    </Container>
  );
};
