import { IdentifierDTO } from '@zazume/zzm-base';
import { PaginationOptions } from '../../../../hooks';
import { BasicUserVO, Status, Task } from '../../../../models';
import { doDelete, doGet, doPost, doPut, EndpointBuilder, parserDataList, toPaginatedURL } from '../../helpers';

const toBasicUser = (rawBasicUser: any): BasicUserVO =>
  new BasicUserVO({ ...rawBasicUser });

export const tasks = {
  changeStaff: (taskId: IdentifierDTO) => (staffId: IdentifierDTO) =>
    doPut<Task>(`/tasks/${taskId}/staff/${staffId}`),
  changeUnit: (taskId: IdentifierDTO) => (unitId: IdentifierDTO) =>
    doPut<Task>(`/tasks/${taskId}/unit/${unitId}`),
  closeTask: (taskId: IdentifierDTO) => () =>
    doPut<void>(`/tasks/${taskId}/close`),
  createTask: (newTask) =>
    doPost<Task>('/tasks', newTask),
  deleteTask: (taskId: IdentifierDTO) => () =>
    doDelete<void>(`/tasks/${taskId}`),
  findPaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) => () =>
      doGet<Task[]>(
        toPaginatedURL(`/tasks/organization${params.queryParams}`, pagination)),
  findStaffBasicUsers: () =>
    doGet<BasicUserVO[]>('/tasks/staff', parserDataList(toBasicUser)),
  /**
   * @deprecated use pagination
   * @param buildingId
   * @param status
   */
  getByBuildingIdAndStatus: (buildingId: IdentifierDTO, status: Status) => () =>
    doGet<Task[]>(`/tasks/${buildingId}?status=${status}`),
  getById: (taskId: IdentifierDTO) => () =>
    doGet<Task>(`/tasks/task/${taskId}`),
  getByRelationshipId: (bookingId: IdentifierDTO) => () =>
    doGet<Task>(`/tasks/relationship/${bookingId}`),
  getByStatusPaginated: (status: Status) =>
    (pagination: PaginationOptions) =>
      doGet<Task[]>(toPaginatedURL(`/tasks?status=${status}`, pagination)),
  markCheckListItemAs: (taskId: IdentifierDTO) => (update: { checklistItemId: IdentifierDTO, checked: boolean }) =>
    doPut<Task>(`/tasks/${taskId}/check-items/${update.checklistItemId}`, { markAsDone: update.checked }),
  removeStaff: (taskId: IdentifierDTO) => () =>
    doDelete<void>(`/tasks/${taskId}/staff`),
  removeUnit: (taskId: IdentifierDTO) => () =>
    doDelete<void>(`/tasks/${taskId}/unit`),
  reopenTask: (taskId: IdentifierDTO) => () =>
    doPut<void>(`/tasks/${taskId}/reopen`),
  /**
   * @deprecated remove asap
   * @param checked
   */
  updateCheck: (taskId: IdentifierDTO) => (updateValue) =>
    doPut<Task>(`/tasks/${taskId}/check-items/${updateValue.itemId}`, updateValue.requestData),
  updateSnooze: (taskId: IdentifierDTO) => (snoozeAt: Date) =>
    doPut<Task>(`/tasks/${taskId}/snooze`, { snoozeAt }),
  updateStatus: () => (updatedTask: Partial<Task>): any =>
    doPut<Task>(`/tasks/${updatedTask._id}/status`, { status: updatedTask.status }),
  updateTask: (taskId: IdentifierDTO) => (updatedTask: any) =>
    doPut<Task>(`/tasks/${taskId}`, updatedTask),
  updateUnit: () => (updateValue) =>
    doPut<Task>(`/tasks/${updateValue.taskId}/unit`, updateValue.data),
  updateZazu: () => (updateValue) =>
    doPut<Task>(`/tasks/${updateValue.taskId}/zazu`, updateValue.data)
};
