import React from 'react';
import { Caret, FCC, getIcon, Semibold } from '@zazume/zzm-base';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useNavigation } from '../context/NavigationContext';
import { IconType } from '@zazume/zzm-base/dist/components/icons/types';
import { UserRolesTypes } from '#/models';
import { Can } from '#/components/roles/Can';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`);

const Header = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2.5rem;
  padding: ${theme.spacing.s4};

  &:hover {
    background: ${theme.colors.Gray100};
    border-radius: 8px;

    p {
      color: ${theme.colors.Gray600};
    }

    svg {
      & > * {
        stroke: ${theme.colors.Gray600};
      }
    }
  }
`);

const TitleContainer = styled.div(({ theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
  user-select: none;
  padding: 0 ${theme.spacing.s3};

  & > svg {
    flex-shrink: 0;
  }
`);

const BodyContainer = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
`);

interface Props {
  id: string;
  title: string;
  isNavigationOpen: boolean;
  isMobile?: boolean;
  icon: IconType;
  canHasRoles: UserRolesTypes[];
  shouldHideToBlacklistUsers?: boolean;
}

export const SectionGroup: FCC<Props> = ({ id, children, title, isNavigationOpen, isMobile, icon, canHasRoles, shouldHideToBlacklistUsers = false }) => {
  const { toggleSectionGroup, state } = useNavigation();

  if (!isNavigationOpen && !isMobile) {
    return null;
  }

  const handleOnClick = () =>
    toggleSectionGroup(id);

  const Icon = getIcon(icon);
  const isOpen = Boolean(state.sectionGroups[id]?.open);
  return <Can hasRoles={canHasRoles} shouldHideToBlacklistUsers>
    <Container>
      <Header onClick={handleOnClick}>
        <TitleContainer>
          <Icon color="Gray600"/>
          <Semibold variant="S" color="Gray600">{title}</Semibold>
        </TitleContainer>
        <Caret direction={isOpen ? 'down' : 'right'} size={24} color="Gray600"/>
      </Header>
      {isOpen && <BodyContainer>{children}</BodyContainer>}
    </Container>
  </Can>;
};
