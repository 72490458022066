import React, { FC } from 'react';
import { PurchaseInvestmentProduct } from '#/pages/investment/components/productChoose/PurchaseInvestmentProduct';
import { useModalContext } from '#/pages/investment/components/productChoose/ModalContext';
import { SelectPlan } from '#/pages/investment/components/productChoose/SelectPlan';

export const Steps: FC = () => {
  const { step } = useModalContext();
  return <>
    {step === 'select-plan' && <SelectPlan/>}
    {step === 'accept-conditions' && <PurchaseInvestmentProduct/>}
  </>;
};
