import { useI18n } from '#hooks/useI18n';
import styled from '@emotion/styled';
import { Colors, FCC, Shadows, ZIndex } from '@zazume/zzm-base';
import { StringHelper } from '@zazume/zzm-utils';
import React, { useRef, useState } from 'react';
import { useClickOutside } from '#/hooks';
import { DropdownItem, Item } from './atoms/Item';
import { SearchInput } from './atoms/SearchInput';

const Menu = styled.ul<any>`
  line-height: 1;
  display: ${props => props.isOpened ? 'block' : 'none'};
  position: absolute;
  left: ${props => props.position === 'left' && `${props.leftValue}px`};
  right: ${props => props.position === 'right' && `${props.rightValue}px`};
  top: ${props => props.topValue && `${props.topValue}px`};
  background-color: ${Colors.BACKGROUND_0};
  min-width: 160px;
  box-shadow: ${Shadows.SHADOW_7};
  z-index: ${ZIndex.DROPDOWN};
  padding: 0;
  margin: 0;
  max-height: 248px;
  overflow-y: auto;
  border-radius: 8px;
`;

const AllPositionMenu = styled(Menu)`
  width: 100%;
`;

const overrideHeaderOnClick = (header: any, toggleModal: (event) => void) =>
  React.cloneElement(header, { onClick: toggleModal });

interface DropdownAtoms {
  Item: DropdownItem;
}

type DropdownType = FCC<DropdownProps> & DropdownAtoms;

export type Position = 'left' | 'right' | 'all';

export type DropdownVariant = 'normal' | 'search';

export interface DropdownProps {
  header: React.ReactNode;
  position?: Position;
  leftValue?: number;
  rightValue?: number;
  topValue?: number;
  variant?: DropdownVariant;
  searchPlaceHolder?: string;
  readOnly?: boolean;
}

const getMenuStyleFromProps = (position: Position): any => {
  if (position === 'all') {
    return AllPositionMenu;
  }
  return Menu;
};

/**
 * @deprecated use zzm-base
 * @param props
 * @constructor
 */
export const Dropdown: DropdownType = props => {
  const {
    children,
    header,
    position = 'left',
    leftValue = 0,
    rightValue = 0,
    topValue,
    variant = 'normal',
    searchPlaceHolder,
    readOnly,
    ...rest
  } = props;
  const [isOpened, setIsOpened] = useState(false);
  const dropdownRef = useRef();
  const [search, setSearch] = useState<string>('');
  const { t } = useI18n();

  const overrideItemsOnClick = (children: React.ReactNode, toggleModal: (event) => void) => {
    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {

        const name: string | undefined = child.props.name;
        const childProps = {
          onClick: (event) => {
            toggleModal(event);
            if (child.props.onClick) {
              child.props.onClick();
            }
          },
          visible: variant === 'normal' || (variant === 'search' && (!name || StringHelper.contains(name, search)))
        };
        return React.cloneElement(child, childProps);
      }
    });
  };

  useClickOutside(dropdownRef, () => setIsOpened(false));
  const toggleModal = (event) => {
    event.stopPropagation();
    setIsOpened(!isOpened);
    setSearch('');
  };

  if (!React.isValidElement(header)) {
    throw new Error('Header invalid type');
  }

  const baseHeader = overrideHeaderOnClick(header, toggleModal);
  const itemsWithClick = overrideItemsOnClick(children, toggleModal);

  const MenuComponent = getMenuStyleFromProps(position);

  return <>
    {baseHeader}
    <MenuComponent
      ref={dropdownRef}
      isOpened={isOpened}
      position={position}
      topValue={topValue}
      leftValue={leftValue}
      rightValue={rightValue}
      {...rest}>
      {isOpened && variant === 'search' && !readOnly && <SearchInput placeholder={searchPlaceHolder || t('components.dropdown.search.placeholder')} iconLeft={'magnifier'} onChange={setSearch} autoFocus={true}/>}
      {isOpened && itemsWithClick}
    </MenuComponent>
  </>;
};

Dropdown.Item = Item;
