import { Fetcher, UseAPIResult, useGet } from './useGet';

export const useSearch = <T extends any>(uniqueKey: string, fetcher: Fetcher<T>): UseAPIResult<T> => {

  const { data, isLoading, error, refetch, isError, status } = useGet(uniqueKey, fetcher, { refetchOnWindowFocus: false });

  return {
    data,
    isLoading,
    error,
    refetch,
    isError,
    status
  };
};
