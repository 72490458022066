import { API } from '../../lib/network/API';
import { Document } from '../../models';
import { useGet } from '../api';
import { IdentifierDTO } from '@zazume/zzm-base';
import { useUpdate } from '../../lib/reactQuery/hooks/useUpdate';
import { DocumentsKeys } from '../api/documents/Documents.keys';

const nop = async (...arg: any) => {
};

interface UseSigningDocumentResult {
  isLoading: boolean;
  document?: Document | null;
  rejectSigningDocument: (reason: object) => Promise<void>;
  sendSignatureReminder: () => Promise<void>;
}

export const useSigningDocument = (documentId: IdentifierDTO): UseSigningDocumentResult => {
  const { data: document, isLoading } = useGet<Document>(DocumentsKeys.byId(documentId), API.documents.get(documentId));
  const { mutate: rejectSigningDocument } = useUpdate<any, any>(DocumentsKeys.byId(documentId), API.documents.rejectSigningDocument(documentId));
  const { mutate: sendSignatureReminder } = useUpdate<void, void>(DocumentsKeys.byId(documentId), API.documents.sendSignatureReminder(documentId));

  if (isLoading) {
    return {
      isLoading: true,
      document: undefined,
      rejectSigningDocument: nop,
      sendSignatureReminder: nop
    };
  }

  if (!document) {
    return {
      isLoading: false,
      document: null,
      rejectSigningDocument: nop,
      sendSignatureReminder: nop
    };
  }

  return {
    isLoading: false,
    document,
    rejectSigningDocument,
    sendSignatureReminder
  };
};
