import { useTheme } from '@emotion/react';
import React, { FC } from 'react';
import { IconProps } from './index';

/** @deprecated Use ZazumeIsotype from zzm-base  */
export const Logo: FC<IconProps> = ({ color = 'White', size = 16 }) => {
  const { colors: themeColors } = useTheme();
  return <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
        d="M2.84 3.84C3.39228 3.84 3.84 3.39228 3.84 2.84V2.56C3.84 1.85308 4.41308 1.28 5.12 1.28C5.82692 1.28 6.4 1.85308 6.4 2.56V6.4H2.56C1.85308 6.4 1.28 5.82692 1.28 5.12C1.28 4.41307 1.85308 3.84 2.56 3.84H2.84ZM5.12 0C6.53385 0 7.68 1.14615 7.68 2.56V6.68C7.68 7.23228 7.23228 7.68 6.68 7.68H2.56C1.14615 7.68 0 6.53385 0 5.12C0 3.70615 1.14615 2.56 2.56 2.56C2.56 1.14615 3.70615 0 5.12 0ZM0 10.88C0 12.2938 1.14615 13.44 2.56 13.44C2.56 14.8538 3.70615 16 5.12 16C6.53385 16 7.68 14.8538 7.68 13.44V9.32C7.68 8.76772 7.23228 8.32 6.68 8.32H2.56C1.14615 8.32 0 9.46615 0 10.88ZM13.16 3.84C12.6077 3.84 12.16 3.39228 12.16 2.84V2.56C12.16 1.85308 11.5869 1.28 10.88 1.28C10.1731 1.28 9.6 1.85308 9.6 2.56V6.4H13.44C14.1469 6.4 14.72 5.82692 14.72 5.12C14.72 4.41308 14.1469 3.84 13.44 3.84H13.16ZM9.32 7.68C8.76772 7.68 8.32 7.23228 8.32 6.68V2.56C8.32 1.14615 9.46615 0 10.88 0C12.2938 0 13.44 1.14615 13.44 2.56C14.8538 2.56 16 3.70615 16 5.12C16 6.53385 14.8538 7.68 13.44 7.68H9.32ZM13.16 12.16C12.6077 12.16 12.16 12.6077 12.16 13.16V13.44C12.16 14.1469 11.5869 14.72 10.88 14.72C10.1731 14.72 9.6 14.1469 9.6 13.44L9.6 9.6L13.44 9.6C14.1469 9.6 14.72 10.1731 14.72 10.88C14.72 11.5869 14.1469 12.16 13.44 12.16H13.16ZM9.32 8.32C8.76771 8.32 8.32 8.76772 8.32 9.32V13.44C8.32 14.8538 9.46615 16 10.88 16C12.2938 16 13.44 14.8538 13.44 13.44C14.8538 13.44 16 12.2938 16 10.88C16 9.46615 14.8538 8.32 13.44 8.32H9.32Z"
        fill={themeColors[color]}/>
  </svg>;
};
