import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FC } from 'react';
import { useI18n } from '#hooks/useI18n';
import { Document } from '#/models';
import { Flex, Semibold } from '@zazume/zzm-base';
import { SignaturesModalInfo } from './components/SignaturesModalInfo';
import { Expiration } from './components/Expiration';
import { FileSize } from './components/FileSize';
import { FileType } from './components/FileType';
import { OwnerVisibility } from './components/OwnerVisibility';

const Container = styled.div(({ theme }) => css`
  width: 100%;

  ${theme.mq.sm} {
    width: 12.75rem;
  }
`);

interface DocumentModalInfoProps {
  document: Document;
  onSendReminder: () => Promise<void>;
  onAfterUpdate?: () => Promise<void>;
}

export const DocumentModalInfo: FC<DocumentModalInfoProps> = ({ document, onSendReminder, onAfterUpdate }) => {
  const { t } = useI18n();
  const { size, expireAt, type, isHiddenFromOwner } = document;

  return (
    <Container>
      <Flex direction="column" gap="s10">
        <Semibold variant="M">{t('general.information')}</Semibold>
        <Expiration expireAt={expireAt}/>
        <FileSize fileSize={size}/>
        <FileType type={type}/>
        <OwnerVisibility isHiddenFromOwner={isHiddenFromOwner}/>
        <SignaturesModalInfo
          document={document}
          onSendReminder={onSendReminder}
          onAfterUpdate={onAfterUpdate}
        />
      </Flex>
    </Container>
  );
};
