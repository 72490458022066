import { FC } from 'react';
import { IconProps, getStrokeWidth } from './index';
import { Colors } from '@zazume/zzm-base';

/**
 * @deprecated use zzm-base
 * @param color
 * @param size
 * @constructor
 */
export const Workspace: FC<IconProps> = ({ color = Colors.FONT_3, size = 20 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" d="M12.4257 5.60531L11.1933 4.32595C10.6278 3.7389 9.84782 3.40723 9.03271 3.40723H4.91846C3.81389 3.40723 2.91846 4.30266 2.91846 5.40722V7.71443M12.4257 5.60531H19.0819C20.1865 5.60531 21.0819 6.50074 21.0819 7.60531V19.593C21.0819 20.1453 20.6342 20.593 20.0819 20.593H3.91846C3.36617 20.593 2.91846 20.1453 2.91846 19.593V7.71443M12.4257 5.60531L9.85438 7.51695C9.68189 7.64518 9.47268 7.71443 9.25775 7.71443H2.91846M15.4832 12.8872V17.8066C15.4832 18.0884 15.2548 18.3167 14.9731 18.3167H9.02727C8.74553 18.3167 8.51715 18.0884 8.51715 17.8066V12.8872M15.4832 12.8872L12.3789 9.44631C12.1764 9.22176 11.824 9.22176 11.6214 9.44631L8.51715 12.8872M15.4832 12.8872L16.7673 14.3104M8.51715 12.8872L7.23309 14.3104M12.0002 13.7096V16.2602"/>
  </svg>;
};
