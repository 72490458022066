import React, { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';
import { useI18n } from '#hooks/useI18n';
import { SectionProps } from '#/containers/asideNavigation/Sections';

interface Props extends SectionProps {
}

export const Payments: FC<Props> = ({ isMobile, onClickItem, depth }) => {
  const { t } = useI18n();
  return <Can hasRoles={['buildingManager', 'admin', 'ownerBasic', 'ownerManager', 'ownerMiddle']}>
    <NavigationLink to={Private.ACCOUNTING.route} isMobile={isMobile} icon="euro" title={t('general.payments')} onClick={onClickItem} depth={depth}/>
  </Can>;
};
