/** @deprecated use arrarIsEmpty instead */
export const isEmpty = (arr) => {
  return arr === null || arr === undefined || arr.length === 0;
};

export const arrayIsEmpty = arr =>
  Array.isArray(arr) && arr.length === 0;

export const arrayIsNotEmpty = arr =>
  Array.isArray(arr) && arr.length > 0;

export const removeItem = (arr: string[], item: string): string[] => {
  const index = arr.indexOf(item);
  return arr.splice(index, 1);
};

export const addItem = (arr: any[], item: any, limit?: number): any[] => {
  if (!limit || (limit && arr.length < limit)) {
    arr.push(item);
  }
  return arr;
};

export const removeObjectFromArray = (arr: object[], item: object, field: string): any[] =>
  arr.filter(element => !(element[field] === item[field]));

/**
 * Moves an array item from one position in an array to another.
 * Note: This is a pure function so a new array will be returned, instead of altering the array argument.
 * @param array Array in which to move an item
 * @param moveIndex The index of the item to move
 * @param toIndex The index to move item at moveIndex to
 */
export const move = <T>(array: T[], moveIndex: number, toIndex: number): T[] => {
  if (moveIndex === toIndex) {
    // Nothing to change
    return array;
  }

  const diff = moveIndex - toIndex;
  return diff > 0 ?
    moveLeft(array, moveIndex, toIndex) :
    moveRight(array, moveIndex, toIndex);
};

const moveLeft = <T>(array: T[], moveIndex: number, toIndex: number): T[] => [
  ...array.slice(0, toIndex),
  array[moveIndex],
  ...array.slice(toIndex, moveIndex),
  ...array.slice(moveIndex + 1, array.length)
];

const moveRight = <T>(array: T[], moveIndex: number, toIndex: number): T[] => [
  ...array.slice(0, moveIndex),
  ...array.slice(moveIndex + 1, toIndex + 1),
  array[moveIndex],
  ...array.slice(toIndex + 1, array.length)
];

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
