import { ServiceType } from './types';

export abstract class Service {
  type: ServiceType;

  protected constructor(type: ServiceType) {
    this.type = type;
  }

  abstract canBeDeleted(): boolean;
  abstract isLinkedToLease(): boolean;
}
