import { Beauty } from './Beauty';
import { Bell } from './Bell';
import { Bold } from './Bold';
import { Building } from './Building';
import { BulletList } from './BulletList';
import { Care } from './Care';
import { Caret } from './Caret';
import { Chat } from './Chat';
import { Check } from './Check';
import { Cleaning } from './Cleaning';
import { Clock } from './Clock';
import { Copy } from './Copy';
import { CreditCard } from './CreditCard';
import { Cross } from './Cross';
import { DirectOrder } from './DirectOrder';
import { Document } from './Document';
import { Documents } from './Documents';
import { DocumentThin } from './DocumentThin';
import { Download } from './Download';
import { Drag } from './Drag';
import { Email } from './Email';
import { EmailSent } from './EmailSent';
import { Euro } from './Euro';
import { Eye } from './Eye';
import { EyeCrossed } from './EyeCrossed';
import { Help } from './Help';
import { Home } from './Home';
import { Image } from './Image';
import { Info } from './Info';
import { Italic } from './Italic';
import { Key } from './Key';
import { KeyThin } from './KeyThin';
import { Laundry } from './Laundry';
import { Link } from './Link';
import { LocationPin } from './LocationPin';
import { Lock } from './Lock';
import { Logo } from './Logo';
import { Magnifier } from './Magnifier';
import { Maintenance } from './Maintenance';
import { Marketplace } from './Marketplace';
import { Minus } from './Minus';
import { NumberList } from './NumberList';
import { PaperPlane } from './PaperPlane';
import { Pencil } from './Pencil';
import { People } from './People';
import { Percentage } from './Percentage';
import { Plus } from './Plus';
import { Product } from './Product';
import { Profile } from './Profile';
import { ProfileCheck } from './ProfileCheck';
import { ProfileThin } from './ProfileThin';
import { RepeatArrows } from './RepeatArrows';
import { Settings } from './Settings';
import { Sign } from './Sign';
import { Staff } from './Staff';
import { StaffThin } from './StaffThin';
import { Star } from './Star';
import { TinyArrow } from './TinyArrow';
import { Underline } from './Underline';
import { Unlock } from './Unlock';
import { Welcome } from './Welcome';
import { Workspace } from './Workspace';
import { Zazume } from './Zazume';

export * from './Beauty';
export * from './Bell';
export * from './Building';
export * from './Calendar';
export * from './Care';
export * from './Caret';
export * from './Chat';
export * from './Check';
export * from './Clock';
export * from './Cleaning';
export * from './Clip';
export * from './Clock';
export * from './Collapse';
export * from './Copy';
export * from './Cross';
export * from './DirectOrder';
export * from './Drag';
export * from './Document';
export * from './Documents';
export * from './DocumentThin';
export * from './Download';
export * from './Ellipse';
export * from './Email';
export * from './Euro';
export * from './Eye';
export * from './EyeCrossed';
export * from './Expand';
export * from './Facilities';
export * from './Help';
export * from './Home';
export * from './Info';
export * from './Key';
export * from './KeyThin';
export * from './Laundry';
export * from './Link';
export * from './LeftArrow';
export * from './Link';
export * from './Logo';
export * from './Maintenance';
export * from './Marketplace';
export * from './Minus';
export * from './MultiArrow';
export * from './NumberList';
export * from './PaperPlane';
export * from './Pencil';
export * from './People';
export * from './Plus';
export * from './Product';
export * from './Profile';
export * from './ProfileThin';
export * from './RepeatArrows';
export * from './RightArrow';
export * from './Settings';
export * from './Sign';
export * from './Staff';
export * from './StaffThin';
export * from './Star';
export * from './Tenants';
export * from './TinyArrow';
export * from './Welcome';
export * from './Water';
export * from './Workspace';
export * from './Zazume';

/**
 * @deprecated use zzm-base
 */
export interface IconProps {
  color?: string;
  size?: number; //TODO: update type to IconSize when all icons are updated
  onClick?: () => void;
  cursor?: string;
}

/**
 * @deprecated use zzm-base
 */
export const Icons = <const>[
  'alarm',
  'beauty', 'bell', 'bold', 'building', 'bulletList',
  'care', 'caret', 'chat', 'check', 'clock', 'cleaning', 'copy', 'creditCard', 'cross',
  'directOrder', 'document', 'documents', 'documentThin', 'download', 'drag',
  'email', 'emailSent', 'euro', 'eye', 'eyeCrossed',
  'help',
  'image', 'info', 'insurance', 'internet', 'italic',
  'key', 'keyThin',
  'laundry', 'link', 'logo', 'locationPin', 'lock',
  'maintenance', 'magnifier', 'marketplace', 'minus',
  'numberList',
  'paperplane', 'pencil', 'people', 'percentage', 'plus', 'product', 'profile', 'profileCheck', 'profileThin',
  'repeatArrows',
  'star', 'sign', 'settings', 'staff', 'staffThin',
  'tinyArrow',
  'underline', 'unlock',
  'welcome', 'workspace',
  'zazume'
];
/**
 * @deprecated use zzm-base
 */
export type IconType = typeof Icons[number];

/**
 * @deprecated use zzm-base
 */
type IconComponent = {
  [key in IconType]: any;
}

/**
 * @deprecated use zzm-base
 */
const IconsComponent: IconComponent = {
  alarm: Bell,
  beauty: Beauty,
  bell: Bell,
  bold: Bold,
  building: Building,
  bulletList: BulletList,
  care: Care,
  caret: Caret,
  chat: Chat,
  check: Check,
  clock: Clock,
  cleaning: Cleaning,
  copy: Copy,
  creditCard: CreditCard,
  cross: Cross,
  directOrder: DirectOrder,
  document: Document,
  documents: Documents,
  documentThin: DocumentThin,
  download: Download,
  drag: Drag,
  email: Email,
  emailSent: EmailSent,
  euro: Euro,
  eye: Eye,
  eyeCrossed: EyeCrossed,
  help: Help,
  image: Image,
  info: Info,
  insurance: Home,
  internet: Link,
  italic: Italic,
  key: Key,
  keyThin: KeyThin,
  laundry: Laundry,
  link: Link,
  locationPin: LocationPin,
  lock: Lock,
  logo: Logo,
  maintenance: Maintenance,
  magnifier: Magnifier,
  marketplace: Marketplace,
  minus: Minus,
  numberList: NumberList,
  tinyArrow: TinyArrow,
  paperplane: PaperPlane,
  pencil: Pencil,
  people: People,
  percentage: Percentage,
  profile: Profile,
  profileCheck: ProfileCheck,
  profileThin: ProfileThin,
  plus: Plus,
  product: Product,
  underline: Underline,
  unlock: Unlock,
  repeatArrows: RepeatArrows,
  settings: Settings,
  sign: Sign,
  star: Star,
  staff: Staff,
  staffThin: StaffThin,
  welcome: Welcome,
  workspace: Workspace,
  zazume: Zazume
};

/**
 * @deprecated use zzm-base
 * @param name
 */
export const getIcon = (name: IconType) =>
  IconsComponent[name];

/**
 * @deprecated use zzm-base
 */
const IconSizes = <const>[16, 20, 24, 32, 40];
/**
 * @deprecated use zzm-base
 */
export type IconSize = typeof IconSizes[number];

// TODO remove the | number ASAP
/**
 * @deprecated use zzm-base
 * @param size
 */
export const getStrokeWidth = (size: IconSize | number) => {
  switch (size) {
    case 16:
      return 2;
    case 20:
      return 1.68;
    case 24:
      return 1.5;
    case 32:
      return 1.2;
    case 40:
      return 1.02;
  }
};
