import { Flex, Regular, Semibold } from '@zazume/zzm-base';
import { PhoneVO } from '@zazume/zzm-utils';
import React, { FC } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { FieldValueProps } from '.';
import { ButtonsContact, ButtonsContactProps } from '../../../buttonsContact/ButtonsContact';
import { useDetailFieldsContext } from './atoms/FieldsSectionContext';

type PhoneValueProps = FieldValueProps & Omit<ButtonsContactProps, 'phone' | 'countryCode'>;

export const PhoneValue: FC<PhoneValueProps> = ({ title, email, value, emptyValue = '-', isDefaultHidden, name, lang }) => {
  const { areSectionsHidden } = useDetailFieldsContext();

  if (isDefaultHidden && areSectionsHidden) {
    return null;
  }

  const isValid = PhoneVO.isValid(value);
  const phoneHelper = isValid && PhoneVO.from(value);

  const countryCode = phoneHelper ? parseInt(phoneHelper.countryCode) : 34;
  const formattedPhone = phoneHelper ? formatPhoneNumberIntl(phoneHelper.e164 as any) : value;

  return (
    <Flex direction="column" gap="s2">
      <Regular color="Gray400" variant="S">{title}</Regular>
      <Flex align="center" gap="sm">
        <Semibold variant="S">{formattedPhone || emptyValue}</Semibold>
        {isValid && <ButtonsContact phone={value} email={email} countryCode={countryCode} name={name} lang={lang}/>}
      </Flex>
    </Flex>
  );
};
