import { AccountingNote } from '#/domain/accountingNote/AccountingNote';
import { fromRaw } from './parser';
import { doGet, parserDataList } from '#lib/network/helpers';
import { IdentifierDTO } from '@zazume/zzm-base';

export const accountingNotes = {
  getUnnasignedAccountingNotesByUnit: (unitId: IdentifierDTO) => () =>
    doGet<AccountingNote[]>(`/dashboard/accounting-notes/unit/${unitId}/unnasigned`, parserDataList(fromRaw)),
  getAccountingNotesByPayout: (payoutId: IdentifierDTO) => () =>
    doGet<AccountingNote[]>(`/dashboard/accounting-notes/payout/${payoutId}`, parserDataList(fromRaw))
};
