import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { Colors, Fonts, Shadows } from '@zazume/zzm-base';
import { useNotifications } from '../../hooks/useNotifications';
import { css } from '@emotion/react';

const Container = styled.div(({ theme }) => css`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  z-index: ${theme.zIndices.i10};
`);

const Message = styled.div`
  background: ${Colors.BACKGROUND_7};
  cursor: pointer;
  border-radius: 4px;
  box-shadow: ${Shadows.SHADOW_3};
  padding: 4px 12px;
  font-family: ${Fonts.BASE};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${Colors.FONT_1};
`;

interface ToastProps {
  id: string;
  message: string;
  timeout: number;
}

export const Toast: FC<ToastProps> = ({ id, message, timeout }) => {
  const { closeNotification } = useNotifications();

  useEffect(() => {
    const timer = setTimeout(() => closeNotification(id), timeout);

    return () => {
      clearTimeout(timer);
      closeNotification(id);
    };
  }, [id, timeout, closeNotification]);

  return <Container>
    <Message>{message}</Message>
  </Container>;
};
