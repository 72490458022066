import { useI18n } from '#hooks/useI18n';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import empty from '../../assets/images/service/defaultService.png';
import { useImgix } from '../../hooks/useImgix';
import { Caption, Subtitle } from '../typography';
import { ResultProps } from './ResultList';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0;
  user-select: none;
  padding: 12px 0;
  gap: 12px;
`;

const Photo = styled.div<any>`
  border-radius: 4px;
  width: 48px;
  height: 48px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Details = styled.div`
`;


export const ServiceResult: FC<ResultProps> = ({ result, onSelect }) => {
  const { t } = useI18n();
  const { applyImgix } = useImgix();

  const { photo, name, productsCount: count } = result;

  const handleSelect = () => onSelect(result);

  return (
    <Container onClick={handleSelect}>
      <Photo src={applyImgix({ src: photo || empty, width: 48, height: 48 })}/>
      <Details>
        <Subtitle soft>{name}</Subtitle>
        <Caption>{count} {t(count > 1 ? 'search.products' : 'search.product')}</Caption>
      </Details>
    </Container>
  );
};
