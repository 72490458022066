import React, { FC } from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface RouteContainerProps {
  body?: React.ReactElement | null;
}

export const RouteContainer: FC<RouteContainerProps> = ({ body }) => {
  return <Container>
    {body}
  </Container>;
};
