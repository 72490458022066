import { EditEntityFormInputs } from '#/pages/people/entity/components/entityForm/EditEntityForm.schema';
import { EntityParticipantsFormInputs } from '#/pages/people/entity/components/entityParticipantsForm/EntityParticipantsForm.schema';
import { IdentifierDTO } from '@zazume/zzm-base';
import { PaginationOptions } from '../../../../hooks';
import { BasicOwnerAccount, BasicOwnerApplication, EntityOwnerAccount, Organization, OwnerAccount } from '../../../../models';
import { BillingData } from '../../../../models/BillingData';
import { doDelete, doGet, doPost, doPostFileUpload, doPut, doPutFileUpload, EndpointBuilder, toPaginatedURL } from '../../helpers';
import { CreateDocumentRequest } from '../documents/requests/CreateDocumentRequest';
import { UpdateDocumentRequest } from '../documents/requests/UpdateDocumentRequest';

export const ownerAccount = {
  //TODO: Remove organizationId from queries updating the backend also
  create: (ownerAccount: Partial<OwnerAccount>) =>
    doPost('/owner-accounts', ownerAccount),
  createDocument: (ownerAccountId: IdentifierDTO) =>
    (request: CreateDocumentRequest) =>
      doPostFileUpload<void>(`/owner-accounts/documents/${ownerAccountId}/`, request.build()),
  createEntity: (entityOwnerAccount: Partial<EntityOwnerAccount>) =>
    doPost('/owner-accounts/entity', entityOwnerAccount),
  createWithUnitLite: (body: any) =>
    doPost('/owner-accounts/with-lite-unit', body),
  delete: (currentOrganization: string) => (ownerAccountId: IdentifierDTO) =>
    doDelete(`/owner-accounts/${ownerAccountId}/${currentOrganization}`),
  deleteEntity: (currentOrganization: string) => (ownerAccountId: IdentifierDTO) =>
    doDelete(`/owner-accounts/${ownerAccountId}/${currentOrganization}/entity`),
  edit: (ownerAccountId: IdentifierDTO) => (ownerAccount: Partial<OwnerAccount>) =>
    doPut<OwnerAccount>(`/owner-accounts/${ownerAccountId}`, ownerAccount),
  getOwnersForApplication: (unitId: IdentifierDTO) => () =>
    doGet<BasicOwnerApplication[]>(`/owner-accounts/unit/${unitId}/all`),
  getForUnit: (unitId: IdentifierDTO) => () =>
    doGet<OwnerAccount[]>(`/owner-accounts/unit/${unitId}`),
  getBasic: (ownerAccountId: IdentifierDTO) => () =>
    doGet<BasicOwnerAccount>(`/owner-accounts/${ownerAccountId}/get-basic-owner`),
  getById: (ownerAccountId: IdentifierDTO, organizationId: IdentifierDTO) => () =>
    doGet<OwnerAccount>(`/owner-accounts/${ownerAccountId}/${organizationId}/get-owner`),
  getEntitiesBelongingToIndiviualOwnerAccount: (ownerAccountId: IdentifierDTO, organizationId: IdentifierDTO) => () =>
    doGet<EntityOwnerAccount[]>(`/owner-accounts/${ownerAccountId}/entity`),
  getEntityById: (ownerAccountId: IdentifierDTO, organizationId: IdentifierDTO) => () =>
    doGet<EntityOwnerAccount>(`/owner-accounts/${ownerAccountId}/${organizationId}/get-owner`),
  getEntityOwnerAccountsPaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) => () =>
      doGet<EntityOwnerAccount[]>(toPaginatedURL(`/owner-accounts${params.url}/entity`, pagination)),
  getForApplication: (ownerAccountId: IdentifierDTO, organizationId: IdentifierDTO) => () =>
    doGet<BasicOwnerApplication>(`/owner-accounts/${ownerAccountId}/organization/${organizationId}`),
  getIndividualOwnerAccountsPaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<OwnerAccount[]>(toPaginatedURL(`/owner-accounts${params.url}/individual`, pagination)),
  getIndividualOwnersAccountsBelongToEntity: (organizationId: IdentifierDTO, entityId: IdentifierDTO) => () =>
    doGet<OwnerAccount[]>(`/owner-accounts/organization/${organizationId}/entity/${entityId}`),
  getOwnerAccountOrganizations: () => () =>
    doGet<Organization[]>('/owner-accounts/get-organizations'),
  getUploadUnitLink: (ownerAccountId: IdentifierDTO) => () =>
    doGet<{ url: string }>(`/owner-accounts/draft-unit/upload-link/${ownerAccountId}`),
  invite: (currentOrganization: string) => (ownerAccount: Partial<OwnerAccount>) =>
    doPost('/owner-accounts/invite', { currentOrganization, ownerAccount }),
  requestPlatformRating: (ownerAccountId: IdentifierDTO) => () =>
    doPost<void>(`/owner-accounts/${ownerAccountId}/request-platform-rating`),
  requestGoogleReview: (ownerAccountId: IdentifierDTO) => () =>
    doPost<void>(`/owner-accounts/${ownerAccountId}/request-google-review`),
  searchByName: (params?: EndpointBuilder) => (name: string) => () =>
    doGet<OwnerAccount[]>(`/owner-accounts/search${params ? params.queryParams : ''}${params ? '&' : '?'}name=${name}`),
  updateBillingData: (ownerAccountId: IdentifierDTO, organizationId: IdentifierDTO) => (data: BillingData) =>
    doPut<OwnerAccount>(`/owner-accounts/${ownerAccountId}/organization/${organizationId}/billing-data`, data),
  updateDocument: (ownerAccountId: IdentifierDTO) =>
    (request: UpdateDocumentRequest) =>
      doPutFileUpload<void>(`/owner-accounts/documents/${ownerAccountId}/${request.documentId}`, request.build()),
  updateEntity: (ownerAccountId: IdentifierDTO, organizationId: IdentifierDTO) => (data: EditEntityFormInputs) =>
    doPut<OwnerAccount>(`/owner-accounts/${ownerAccountId}/organization/${organizationId}/entity`, data),
  updateEntityParticipants: (ownerAccountId: IdentifierDTO, organizationId: IdentifierDTO) => (data: EntityParticipantsFormInputs) =>
    doPut<OwnerAccount>(`/owner-accounts/${ownerAccountId}/organization/${organizationId}/entity/participants`, data)
};
