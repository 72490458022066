import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Colors, Fonts, Info } from '@zazume/zzm-base';
import React, { ReactNode } from 'react';
import { Tooltip } from '../tooltip/Tooltip';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  flex: 1;
  font-family: ${Fonts.BASE};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: ${Colors.FONT_3};
`;

const Input = styled.input(({ theme }) => css`
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 0.25rem;
  background: ${theme.colors.White};
  border: ${theme.borderWeights.light} solid ${theme.colors.Gray300};
  box-sizing: border-box;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  outline: none;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  position: relative;
  cursor: pointer;

  --active: #275EFE;
  --active-inner: #fff;
  --border: #BBC1E1;
  --border-hover: #275EFE;
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #E1E6F9;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: background .3s, border-color .3s, box-shadow .2s;

  &:checked {
    background: ${theme.colors.Primary};
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    transition: transform .3s cubic-bezier(.2, .85, .32, 1.2), opacity .2s;
    width: 5px;
    height: 10px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 5px;
    top: 2px;
    transform: rotate(var(--r, 35deg));
    -webkit-transform: rotate(35deg);
    -ms-transform: rotate(35deg);

    &:checked {
      --r: 35deg;
      display: block;
    }
  }

  &:disabled {
    background: var(--disabled);
    cursor: default;

    &:checked:after {
      display: block;
      border: 2px solid var(--disabled-inner);
      border-top: 0;
      border-left: 0;
    }

    &:not(:checked):after {
      display: none;
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(39, 94, 254, .3);
  }

  & + label {
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 12px;
  }
`);

const LabelContent = styled.div<{ disabled: boolean }>(({ theme, disabled }) => css`
  display: flex;
  gap: ${theme.spacing.s2};
  color: ${disabled ? theme.colors.Gray300 : theme.colors.Gray600};
  cursor: ${disabled ? 'default' : 'pointer'};
`);

const TooltipText = styled.div(({ theme }) => css`
  color: ${theme.colors.White};
  text-align: center;
  white-space: pre-wrap;
  letter-spacing: 0;
`);

export interface CheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  label?: any;
  containerStyle?: any;
  tooltip?: string | ReactNode;
}

/**
 * @deprecated use CheckboxBase from zzm-base
 */
export const Checkbox = React.forwardRef<any, CheckboxProps>((props, ref) => {
  const { name, label, containerStyle = {}, tooltip, disabled = false, ...rest } = props;

  const id = props.value ? `${name}_${props.value}` : name;

  return <Container style={containerStyle}>
    <Input ref={ref} id={id} name={name} type="checkbox" disabled={disabled} {...rest}/>
    {label && <Label htmlFor={id}>
      <LabelContent disabled={disabled}>{label}
        {tooltip && <Tooltip title={<Info size={16} color="Gray500"/>}><TooltipText>{tooltip}</TooltipText></Tooltip>}
      </LabelContent>
    </Label>}
  </Container>;
});
