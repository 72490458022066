import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps } from './index';

export const Building: FC<IconProps> = ({ size = 20, color = Colors.FONT_3 }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 5C3.25 4.0335 4.0335 3.25 5 3.25H11C11.9665 3.25 12.75 4.0335 12.75 5V6.25H15C15.9665 6.25 16.75 7.0335 16.75 8V16V16.75H16H12H8H7.25H4H3.25V16V5ZM11.25 5V6.25H9C8.0335 6.25 7.25 7.0335 7.25 8V15.25H4.75V5C4.75 4.86193 4.86193 4.75 5 4.75H11C11.1381 4.75 11.25 4.86193 11.25 5ZM8.75 15.25V8C8.75 7.86193 8.86193 7.75 9 7.75H15C15.1381 7.75 15.25 7.86193 15.25 8V15.25H12.75V14H11.25V15.25H8.75ZM11.5 9.75C11.5 10.1642 11.1642 10.5 10.75 10.5C10.3358 10.5 10 10.1642 10 9.75C10 9.33579 10.3358 9 10.75 9C11.1642 9 11.5 9.33579 11.5 9.75ZM10.75 13.5C11.1642 13.5 11.5 13.1642 11.5 12.75C11.5 12.3358 11.1642 12 10.75 12C10.3358 12 10 12.3358 10 12.75C10 13.1642 10.3358 13.5 10.75 13.5ZM14 9.75C14 10.1642 13.6642 10.5 13.25 10.5C12.8358 10.5 12.5 10.1642 12.5 9.75C12.5 9.33579 12.8358 9 13.25 9C13.6642 9 14 9.33579 14 9.75ZM13.25 13.5C13.6642 13.5 14 13.1642 14 12.75C14 12.3358 13.6642 12 13.25 12C12.8358 12 12.5 12.3358 12.5 12.75C12.5 13.1642 12.8358 13.5 13.25 13.5Z"/>
  </svg>;
};
