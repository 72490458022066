import { useGet } from './api';
import { API } from '../lib/network/API';
import { Brand } from '../models/Brand';
import { Config } from '../lib/Config';

type UseBrandResult = {
  isLoading: true;
  brand: undefined;
  publicURL: undefined;
  isZazumeBrand: false;
} | {
  error: string;
  isLoading: false;
  brand: undefined;
  publicURL: undefined;
  isZazumeBrand: false;
} | {
  isLoading: false;
  brand: Brand;
  publicURL: string;
  isZazumeBrand: boolean;
}

export const useBrand = (): UseBrandResult => {
  const { data, isLoading, error } = useGet(['brands'], API.brands.getMyBrand, {
    // We don't need re-fetching the brand all the time
    staleTime: Infinity
  });

  if (isLoading) {
    return {
      isLoading,
      brand: undefined,
      publicURL: undefined,
      isZazumeBrand: false
    };
  }

  if (error) {
    return {
      error,
      brand: undefined,
      isLoading: false,
      publicURL: undefined,
      isZazumeBrand: false
    };
  }

  if (!data) {
    return {
      error: 'No brand found',
      isLoading: false,
      brand: undefined,
      publicURL: undefined,
      isZazumeBrand: false
    };
  }

  return {
    isLoading: false,
    brand: data,
    publicURL: data.portalURL || data.dashboardURL,
    isZazumeBrand: data._id === Config.ZAZUME_BRAND_ID
  };
};
