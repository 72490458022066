type AddFile = {
  fileToAdd: File | File[];
}

type DeleteFile = {
  deleteFile: boolean;
}

export type FileUploadValue = AddFile | DeleteFile;

export const isDeleteFile = (fileUploadValue: FileUploadValue): fileUploadValue is DeleteFile =>
  typeof (fileUploadValue as DeleteFile).deleteFile !== 'undefined';

export const isAddFile = (fileUploadValue: FileUploadValue): fileUploadValue is AddFile =>
  typeof (fileUploadValue as AddFile).fileToAdd !== 'undefined';

export const toAddFile = (file: File | File[]): AddFile => ({
  fileToAdd: file
});

export const toDeleteFile = (): DeleteFile => ({
  deleteFile: true
});
