import { useRouter } from '#/hooks';
import { useSearchParams } from 'react-router-dom';

interface CopilotChatIdStorage {
  addChatIdQueryParam: (value: string) => void;
  addChatIdLocalStorage: (value: string) => void;
  chatIdQueryParam: string | undefined;
  chatIdLocalStorage: string | null;

}
const LOCAL_QUERY_PARAM_FIELD = 'chatId';
const LOCAL_STORAGE_CHAT_ID_KEY = 'copilotChatId';

export const useCopilotChatIdStorage = (): CopilotChatIdStorage => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { getParam } = useRouter();

  const chatIdQueryParam = getParam(LOCAL_QUERY_PARAM_FIELD);
  const chatIdLocalStorage = localStorage.getItem(LOCAL_STORAGE_CHAT_ID_KEY);

  const addChatIdQueryParam = (value: string) => {
    searchParams.set(LOCAL_QUERY_PARAM_FIELD, value);
    setSearchParams(searchParams);
  };

  const addChatIdLocalStorage = (value: string) => {
    localStorage.setItem(LOCAL_STORAGE_CHAT_ID_KEY, value);
  };

  return {
    addChatIdQueryParam,
    addChatIdLocalStorage,
    chatIdQueryParam,
    chatIdLocalStorage
  };

};
