import { QueryClient as BaseQueryClient } from 'react-query';
import { IdentifierDTO } from '@zazume/zzm-base';

export class QueryClient extends BaseQueryClient {
  readonly userId?: IdentifierDTO;

  constructor(userId?: IdentifierDTO, config?: any) {
    super(config);
    this.userId = userId;
  }
}
