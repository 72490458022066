import { IdentifierDTO } from '@zazume/zzm-base';
import { doPut } from '../../../helpers';
import { BulkAssignApplicationManagerRequest } from './models/BulkAssignApplicationManagerRequest';

export const bulkActions = {
  archive: (organizationId: IdentifierDTO) => (body) =>
    doPut<void>(`/onboarding/bulk-actions/archive/${organizationId}`, body),
  assignManager: (organizationId: IdentifierDTO) => (body: BulkAssignApplicationManagerRequest) =>
    doPut<void>(`/onboarding/bulk-actions/assign-manager/${organizationId}`, body),
  decline: (organizationId: IdentifierDTO) => (body) =>
    doPut<void>(`/onboarding/bulk-actions/decline/${organizationId}`, body),
  sendViewingReminder: (body) =>
    doPut<void>('/onboarding/bulk-actions/viewing-reminder', body)
};
