import React, { FC } from 'react';
import { IdentifierDTO } from '../../../../../models';
import { useGetOwnerAccountOrganizationsAPI, useImgix } from '../../../../../hooks';
import { OrganizationsMenuList } from './OrganizationsMenuList';
import { DropdownProps, Flex, Hidden, Position, Visible, ZazumeLogotype } from '@zazume/zzm-base';
import styled from '@emotion/styled';
import { useAuth } from '#/contexts/AuthProvider';

interface BaseOrganizationOptionsProps {
  ownerAccountId: IdentifierDTO;
  header: DropdownProps['header'];
  onSelect: (organizationId: IdentifierDTO) => void;
  position?: Position;
  topValue?: number;
}

const Image = styled.img<any>`
  padding: 0;
  margin: 0;
  width: ${props => props.size}px;
`;

const getLandscapeLogo = (src) => {
  return src
    ? <Image size={120} src={src}/>
    : <ZazumeLogotype size={120} color="Primary"/>;
};


export const BaseOrganizationOptions: FC<BaseOrganizationOptionsProps> = ({ ownerAccountId, header, onSelect, position, topValue }) => {
  const { organizations } = useGetOwnerAccountOrganizationsAPI(ownerAccountId);
  const { organization } = useAuth();
  const { applyImgix } = useImgix();

  if (!organizations) {
    return null;
  }

  if (organizations.length === 1) {
    return <>
      <Hidden xs sm md>
        <OrganizationsMenuList
          header={header}
          organizations={organizations}
          onSelect={onSelect}/>
      </Hidden>
      <Visible xs sm md>
        <Flex align="center">
          {getLandscapeLogo(organization?.logoLandscape && applyImgix({ src: organization.logoLandscape, width: 150 }))}
        </Flex>
      </Visible>
    </>;
  }

  return <OrganizationsMenuList
    header={header}
    organizations={organizations}
    onSelect={onSelect}
    position={position}
    topValue={topValue}
  />;
};
