import { useI18n } from '#hooks/useI18n';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getIcon, IconType, Info, Regular, ZZMColor } from '@zazume/zzm-base';
import React, { ReactNode, useState } from 'react';
import { Tooltip } from '../../tooltip/Tooltip';
import { Body14 } from '../../typography/Body14';
import { parseErrors, WithErrors } from '../utils';

const Container = styled.div<any>(({ margin, type, hidden, horizontal, theme }) => `
  ${(type === 'hidden' || hidden) ? 'display: none;' : ''}
  width: 100%;
  font-family: ${theme.fonts.base};
  margin-bottom: ${margin ? '8px' : '0'};

  & > fieldset {
    border: none;
    display: flex;
    flex-direction: ${horizontal ? 'row' : 'column'};
    align-items: ${horizontal ? 'center' : 'flex-start'};
    ${horizontal ? `gap: ${theme.spacing.xs}` : ''};
    margin: 0;
    padding: 0;
  }
`);

const InputField = styled.input<any>(({ theme, highlight, hasErrors, autoComplete, withLeftIcon, withRightIcon, responsive }) => css`
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid ${theme.colors.Gray300};
  border-color: ${hasErrors ? theme.colors.Red : highlight ? theme.colors.MostardDark : theme.colors.Gray300};
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0;
  width: 100%;
  height: ${responsive ? 3.375 : 2.5}rem;
  font-family: ${theme.fonts.base};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.Gray600};
  position: relative;
  padding-left: ${withLeftIcon ? 32 : 12}px;
  padding-right: ${withRightIcon ? 32 : 12}px;
  outline: none;

  ::placeholder {
    color: ${theme.colors.Gray400};
    font-family: ${theme.fonts.base};
  }

  :read-only {
    background-color: ${theme.colors.PrimaryLight4};
    color: ${theme.colors.Gray400};
    pointer-events: none;

    &:focus {
      border: 1px solid ${theme.colors.Gray300};
    }
  }

  &:focus {
    border: 1px solid ${theme.colors.Primary};
    border-radius: 8px;
  }

  &:hover {
    border: 1px solid ${theme.colors.Primary};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: ${autoComplete === 'off' ? '0 0 0 1000px white inset !important' : ''};
  }

`);

const Label = styled.label(({ theme }) => css`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: ${theme.colors.Gray600};
  margin: 0;
  width: 100%;
  height: 28px;
`);

const Helper = styled.div(({ theme }) => css`
  color: ${theme.colors.Gray400};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0;
`);

const Error = styled.div(({ theme }) => css`
  color: ${theme.colors.Red};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0;
`);

const Highlight = styled.div(({ theme }) => css`
  color: ${theme.colors.MostardDark};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0;
`);

const IconLeftContainer = styled.div<any>`
  position: absolute;
  height: 20px;
  width: 20px;
  pointer-events: ${props => props.onClick ? 'default' : 'none'};
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  top: 10px;
  left: 8px;
`;

const IconRightContainer = styled.div<any>`
  position: absolute;
  height: 20px;
  width: 20px;
  pointer-events: ${props => props.onClick ? 'default' : 'none'};
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  top: 10px;
  right: 12px;
`;

const InputContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: inherit;
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const TooltipRow = styled(Body14)<any>(({ theme }) => css`
  color: ${theme.colors.White};
  text-align: center;
`);

export interface InputProps extends WithErrors {
  label?: string;
  name?: string;
  type?: string;
  help?: string;
  defaultValue?: string | number;
  onBlur?: (newValue: string) => void;
  onChange?: (newValue: string) => void;
  placeholder?: string;
  autoComplete?: string;
  iconLeft?: IconType;
  iconRight?: IconType;
  iconLeftColor?: ZZMColor;
  iconRightColor?: ZZMColor;
  onClickIcon?: (any) => void;
  maxLength?: number;
  onKeyUp?: any;
  onKeyDown?: any;
  disabled?: boolean;
  readOnly?: boolean;
  withMargin?: boolean;
  min?: string;
  hidden?: boolean;
  highlight?: boolean;
  highlightMessage?: string;
  tooltip?: string | ReactNode;
  step?: string;
  autoFocus?: boolean;
  isOptional?: boolean;
  negativeNumber?: boolean;
  horizontalInput?: boolean;
  responsive?: boolean;
}

/**
 * @deprecated use src/components/forms/input/InputBase.tsx
 */
export const Input = React.forwardRef<any, InputProps>((props, ref) => {
  const {
    name, label, onBlur, type, help, errors, onChange, autoComplete,
    iconLeft,
    iconRight,
    iconLeftColor,
    iconRightColor,
    requiredErrorMessage,
    onClickIcon,
    maxLength,
    onKeyUp,
    onKeyDown,
    disabled = false,
    withMargin = true,
    readOnly = false,
    min = 0,
    hidden,
    highlight,
    highlightMessage,
    tooltip,
    step,
    autoFocus = false,
    isOptional = false,
    negativeNumber = false,
    horizontalInput,
    responsive = false,
    ...rest
  } = props;
  const { t } = useI18n();
  const [baseColor, setBaseColor] = useState<ZZMColor>('Gray400');
  const { hasErrors, errorMessage } = parseErrors(props);

  const handleOnBlur = (event) => {
    handleBaseColor(Boolean(event.currentTarget.value))();
    return onBlur?.(event.currentTarget.value);
  };
  const handleOnChange = (event) => {
    onChange?.(event.currentTarget.value);
  };
  const handleBaseColor = (isFocused: boolean) => () => isFocused && !readOnly
    ? setBaseColor('Gray500')
    : setBaseColor('Gray400');


  const IconLeft = iconLeft && getIcon(iconLeft);
  const IconRight = iconRight && getIcon(iconRight);
  const defaultIconColor: ZZMColor = readOnly ? 'Gray400' : baseColor;

  const preventMinus = (e) => {
    if (type === 'number' && !negativeNumber) {
      if (e.key === '-' || e.code === 'Slash') {
        e.preventDefault();
      }
    }
  };

  const preventPasteNegative = (e) => {
    if (type === 'number' && !negativeNumber) {
      const clipboardData = e.clipboardData;
      if (clipboardData) {
        if (parseFloat(clipboardData.getData('text')) < 0) {
          e.preventDefault();
        }
      }
    }
  };

  return <Container margin={withMargin} type={type} hidden={hidden} horizontal={horizontalInput} responsive={responsive}>
    <fieldset>
      <HeaderContainer>
        {label && <Label htmlFor={name}>{label}</Label>}
        {isOptional && <Regular variant="S" color="Gray400">{t('general.optional')}</Regular>}
        {tooltip && <Tooltip title={<Info size={16} color="Gray500"/>}>
          <TooltipRow>{tooltip}</TooltipRow>
        </Tooltip>}
      </HeaderContainer>
      <InputContainer>
        <InputField
          ref={ref}
          type={type}
          id={name}
          aria-labelledby={name}
          name={name}
          autoComplete={autoComplete}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          hasErrors={hasErrors}
          withLeftIcon={IconLeft}
          withRightIcon={IconRight}
          maxLength={maxLength}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          disabled={disabled}
          readOnly={readOnly}
          onFocus={handleBaseColor(true)}
          min={min}
          hidden={hidden}
          highlight={highlight}
          step={step}
          autoFocus={autoFocus}
          onKeyPress={preventMinus}
          onPaste={preventPasteNegative}
          responsive={responsive}
          {...rest}/>
        {IconLeft && <IconLeftContainer onClick={onClickIcon}><IconLeft size={20} color={iconLeftColor || defaultIconColor}/></IconLeftContainer>}
        {IconRight && <IconRightContainer onClick={onClickIcon}><IconRight size={20} color={iconRightColor || defaultIconColor}/></IconRightContainer>}
      </InputContainer>
      {help && <Helper>{help}</Helper>}
      {highlight && <Highlight>{highlightMessage}</Highlight>}
    </fieldset>
    {errorMessage && <Error>{errorMessage}</Error>}
  </Container>;
});
