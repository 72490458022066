import { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { getStrokeWidth, IconProps } from './index';

export const Key: FC<IconProps> = ({ color = Colors.FONT_3, size = 20 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M19.2384 11.3575C21.0598 9.53606 21.0598 6.58285 19.2384 4.76137C17.4169 2.93989 14.4637 2.93989 12.6422 4.76137C11.2689 6.13469 10.9457 8.18668 11.6433 9.87657L4.97652 16.5433C4.88041 16.6394 4.82757 16.7705 4.83017 16.9065L4.8732 19.1528C4.87834 19.4215 5.09489 19.638 5.36353 19.6431L8.03394 19.6943C8.31754 19.6997 8.54885 19.4684 8.54342 19.1848L8.50993 17.4365C8.5045 17.1529 8.73582 16.9216 9.01942 16.927L10.4251 16.9539C10.6827 16.9588 10.9019 16.7673 10.9314 16.5114L11.1464 14.6493C11.1748 14.4033 11.379 14.2151 11.6265 14.2069L13.4039 14.1479C13.6717 14.139 13.885 13.9206 13.8873 13.6526L13.8355 12.2232C15.5918 13.133 17.7652 12.8307 19.2384 11.3575Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
    />
    <path
        d="M17.5409 6.45873C17.9315 6.84926 17.9315 7.48242 17.5409 7.87295C17.1504 8.26347 16.5172 8.26347 16.1267 7.87295C15.7362 7.48242 15.7362 6.84926 16.1267 6.45873C16.5172 6.06821 17.1504 6.06821 17.5409 6.45873Z"
        stroke={color}
        strokeLinejoin="round"
    />
  </svg>;
};
