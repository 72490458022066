import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BasicOwnerAccount, BasicUser } from '../../models';
import { css } from '@emotion/react';

type BaseProps = {
  size: number;
};

const Base = styled.div<BaseProps>(({ theme, size }) => css`
  width: ${size}px;
  min-width: ${size}px;
  height: ${size}px;
  vertical-align: middle;
  border-radius: ${size / 2}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.Primary};
  font-family: ${theme.fonts.base};
  font-size: ${size * .6}px;
  color: ${theme.colors.White};
  cursor: default;
`);

type ContainerProps = BaseProps & {
  highlighted: boolean;
};

const Container = styled(Base)<ContainerProps>(({ theme, highlighted }) => css`
  // TODO pending to add borders!
    // background: ${highlighted ? theme.colors.Blue : theme.colors.Primary};
`);

type ContainerWithAvatarProps = ContainerProps & {
  avatar: string;
};

const ContainerWithAvatar = styled(Container)<ContainerWithAvatarProps>`
  vertical-align: middle;
  background-image: ${props => 'url(' + props.avatar + ')'};
  background-size: cover;
`;

export const getSize = (size): number => {
  switch (size) {
    case 'small':
      return 28;
    case 'medium':
      return 32;
    case 'large':
      return 36;
    case 'extra-large':
      return 64;
    case 'extra-small':
    default:
      return 16;
  }
};

export interface AvatarProps {
  user: BasicUser | BasicOwnerAccount,
  size?: 'extra-large' | 'large' | 'medium' | 'small' | 'extra-small',
  highlighted?: boolean;
}

/**
 * @deprecated use zzm-base Avatar instead
 */
export const Avatar: FC<AvatarProps> = ({ size = 'medium', user, highlighted = false, ...rest }) => {
  if (user.avatar) {
    return <ContainerWithAvatar avatar={user.avatar} highlighted={highlighted} size={getSize(size)} {...rest}/>;
  }

  return <Container size={getSize(size)} highlighted={highlighted} {...rest}>
    {user.name?.charAt(0).toUpperCase() ?? ''}
  </Container>;
};
