import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { HEADER_HEIGHT } from '../utils/containers/containersConstants';

const Container = styled.div<any>(({ theme }) => css`
  padding: ${theme.spacing.s0} ${theme.spacing.s7};
  max-width: 53.5rem;
  margin: 0 auto;

  ${theme.mq.desktop} {
    padding: ${theme.spacing.s0} ${theme.spacing.s0};
  }
`);

/**
 * @deprecated
 */
const FormBody = styled.div<any>(({ theme, withoutMargin }) => css`
  width: 100%;
  padding-bottom: ${withoutMargin ? theme.spacing.s0 : `calc(${HEADER_HEIGHT}px + ${theme.spacing.s20})`};

  ${theme.mq.tablet} {
    padding-bottom: ${theme.spacing.s0};
  }
`);

/**
 * @deprecated
 */
const Row = styled.div<any>(({ theme, withoutMargin }) => css`
  width: 100%;
  margin: ${withoutMargin ? theme.spacing.s0 : `${theme.spacing.s10} ${theme.spacing.s0} ${theme.spacing.s12}`};
  display: grid;
  grid-template-columns: 1fr;

  ${theme.mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${theme.spacing.s12};
  }

  ${theme.mq.desktop} {
    column-gap: ${theme.spacing.s16};
  }
`);

/**
 * @deprecated
 */
const Column = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s3};
  margin-bottom: ${theme.spacing.s4};

  ${theme.mq.tablet} {
    gap: ${theme.spacing.s0};
    margin-bottom: ${theme.spacing.s0};
  }
`);

/**
 * @deprecated
 */
const Header = styled.div(({ theme }) => css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.s10};
  flex-wrap: wrap;
  column-gap: ${theme.spacing.s8};
`);

/**
 * @deprecated
 */
const Footer = styled.div(({ theme }) => css`
  display: none;

  ${theme.mq.tablet} {
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacing.s8};
    margin: ${theme.spacing.lg} 0;
    margin: ${theme.spacing.s16} ${theme.spacing.s0};
  }

  ${theme.mq.desktop} {
    margin: ${theme.spacing.s20} ${theme.spacing.s0};
  }
`);

interface Atoms {
  Body: any;
  Row: any;
  Column: any;
  Footer: any;
  Header: any;
}

interface Props {
  children: any;
}

type Template = FC<Props> & Atoms;

/**
 * @deprecated use zzm-base
 */
export const ResponsiveModalForm: Template = ({ children }) => {
  return (
    <Container>{children}</Container>
  );
};

ResponsiveModalForm.Body = FormBody;
ResponsiveModalForm.Row = Row;
ResponsiveModalForm.Column = Column;
ResponsiveModalForm.Header = Header;
ResponsiveModalForm.Footer = Footer;
