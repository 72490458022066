import { doGet } from '../../helpers';
import { SessionURL } from './models/SessionURL';
import { SessionStatus } from './models/SessionStatus';
import { CheckoutProductConfig } from '#lib/network/api/checkout/models/CheckoutProductConfig';

export const checkout = {
  getPortalSession: (returnURL: string) =>
    doGet<SessionURL>(`/dashboard/checkout/portal-session?return-url=${returnURL}`),
  getCheckoutSessionStatus: (checkoutSessionId: string) => () =>
    doGet<SessionStatus>(`/dashboard/checkout/session/${checkoutSessionId}/status`),
  getCheckoutSession: (priceId: string, returnURL: string, cancelURL: string, metaData: string) =>
    doGet<SessionURL>(`/dashboard/checkout/session?return-url=${returnURL}&cancel-url=${cancelURL}&price-id=${priceId}${metaData}`),
  getProductConfig: (type: string) => () =>
    doGet<CheckoutProductConfig>(`/dashboard/checkout/product/${type}`)
};
