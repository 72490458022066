import React, { FC } from 'react';
import { Bell as BellIcon } from '@zazume/zzm-base';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Container = styled.div(({ theme }) => css`
  position: relative;
  width: ${theme.spacing.s12};
  height: ${theme.spacing.s12};
`);

const Badge = styled.span(({ theme }) => css`
  position: absolute;
  border-radius: 50%;
  width: ${theme.spacing.s6};
  height: ${theme.spacing.s6};
  background-color: ${theme.colors.Red};
  right: 0.3125rem;
  top: 0.1875rem;
`);

interface BellProps {
  hasUnreadNotifications: boolean;
  onClick: (event) => void;
}

export const Bell: FC<BellProps> = ({ hasUnreadNotifications, onClick }) => {
  return <Container onClick={onClick}>
    {hasUnreadNotifications && <Badge/>}
    <BellIcon size={32} color="Gray500"/>
  </Container>;
};
