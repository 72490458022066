import { Languages, PhoneVO } from '@zazume/zzm-utils';
import * as yup from 'yup';

export const getUpdateProfileSchema = (t) => {
  const requiredMessage = t('general.forms.mandatory');

  return yup.object().shape({
    name: yup.string().required(requiredMessage),
    surnames: yup.string().required(requiredMessage),
    phoneNumber: yup.string()
      .required(requiredMessage)
      .transform(str => str.replace(/ /g, ''))
      .test('is-invalid-phone', t('general.forms.invalid'), PhoneVO.isValid),
    email: yup.string().email(t('general.forms.emailError')).required(requiredMessage),
    avatar: yup.object(),
    bio: yup.string(),
    videoUrl: yup.string(),
    lang: yup.string().oneOf([...Languages]).required(requiredMessage)
  });
};

export type UpdateProfileFormInputs = {
  name: string;
  surnames: string;
  phoneNumber: string;
  email: string;
  avatar: any;
  bio: string;
  videoUrl: string;
  lang: string;
}
