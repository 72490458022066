import { IdentifierDTO } from '@zazume/zzm-base';
import { doDelete, doGet, doPost, parserDataList, toPaginatedURL } from '../../helpers';
import { PaginationOptions } from '#/hooks';
import { DocumentUploadLink } from '#/models/documentUploadLink/DocumentUploadLink';
import { DateVO } from '@zazume/zzm-utils';
import { BasicUserVO } from '#/models';
import { DocumentUploadLinksCreateRequest } from './request/DocumentUploadLinksCreateRequest';

const toDocumentUploadLink = (raw: any): DocumentUploadLink =>
  new DocumentUploadLink({
    ...raw,
    expiresAt: DateVO.fromPrimitive(raw.expiresAt),
    createdBy: new BasicUserVO({ ...raw.createdBy })
  });

export const documentUploadLinks = {
  create: (request: DocumentUploadLinksCreateRequest) =>
    doPost<DocumentUploadLink>('/dashboard/document-upload-link', request.build()),
  delete: (id: IdentifierDTO) =>
    doDelete(`/dashboard/document-upload-link/${id}`),
  findAllByRelatedModelId: (relatedModelId: IdentifierDTO) =>
    (pagination: PaginationOptions) => () =>
      doGet<DocumentUploadLink[]>(toPaginatedURL(`/dashboard/document-upload-link?relatedModelId=${relatedModelId}`, pagination), parserDataList(toDocumentUploadLink))
};
