import { FC, ReactNode } from 'react';
import { CheckCircle, CrossCircle, Flex, Regular } from '@zazume/zzm-base';
import styled from '@emotion/styled';

const BenefitContainer = styled(Flex)`
  svg {
    flex-shrink: 0;
  }
`;

interface BenefitsListProps {
  title?: string | ReactNode;
  benefits: string[];
  notIncluded?: boolean;
}

export const BenefitsList: FC<BenefitsListProps> = ({ title, benefits, notIncluded }) => {
  const icon = notIncluded ? <CrossCircle color="Red"/> : <CheckCircle color="Spring"/>;

  return (
    <Flex direction="column" gap="sm">
      {title && <Regular variant="S" color="Gray500">{title}</Regular>}
      {benefits.map((benefit, index) =>
        <BenefitContainer gap="xs" key={index} align="center">
          {icon}
          <Regular variant="S" color="Gray500">{benefit}</Regular>
        </BenefitContainer>
      )}
    </Flex>
  );
};
