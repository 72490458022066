import { Colors } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { IconProps } from './index';

export const Owner: FC<IconProps> = ({ color = Colors.FONT_3, size = 20 }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" stroke={color}
      d="M18.2675 8.10099V18.4298C18.2675 18.9821 17.8198 19.4298 17.2675 19.4298H4.79724C4.2094 19.4298 3.73287 18.9532 3.73287 18.3654V8.10099M18.2675 8.10099L11.7905 0.921704C11.3678 0.453183 10.6326 0.453182 10.2099 0.921703L3.73287 8.10099M18.2675 8.10099L20.9466 11.0706M3.73287 8.10099L1.05371 11.0706M11 14.3863C12.6843 14.3863 14.0497 13.0209 14.0497 11.3366C14.0497 9.65231 12.6843 8.28691 11 8.28691C9.31574 8.28691 7.95034 9.65231 7.95034 11.3366C7.95034 13.0209 9.31574 14.3863 11 14.3863ZM11 14.3863C8.47358 14.3863 6.42549 16.4344 6.42549 18.9609M11 14.3863C13.5265 14.3863 15.5746 16.4344 15.5746 18.9609"/>
  </svg>;
};
