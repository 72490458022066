import { Router } from './containers/router/Router';
import AppProviders from './contexts/AppProviders';

const App = () => {
  return <AppProviders>
    <Router/>
  </AppProviders>;
};

export default App;
