import { OwnerAccountHelper } from '#/models/helpers/ownerAccount/OwnerAccountHelper';
import { UserHelper } from '#/models/helpers/user/UserHelper';
import styled from '@emotion/styled';
import { getIcon, Regular } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { ResultProps } from './ResultList';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0;
  user-select: none;
  padding: 12px 0;
  gap: 12px;
`;

export const GenericUserResult: FC<ResultProps> = ({ result, onSelect }) => {
  const handleSelect = () => onSelect(result);

  const getProfileIcon = (result) => {
    switch (result.type) {
      case 'ownerAccount':
        return getIcon('owner');
      default:
        return getIcon('profile');
    }
  };

  const getName = (result) => {
    switch (result.type) {
      case 'ownerAccount':
        return OwnerAccountHelper.build(result).composeFullName();
      default:
        return UserHelper.build(result).composeFullName();
    }
  };

  const Icon = getProfileIcon(result);

  const fullName = getName(result);

  return (
    <Container onClick={handleSelect}>
      <Icon size={20} color="Gray500"/><Regular variant="S">{fullName}</Regular>
    </Container>
  );
};
