import { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps } from './index';

export const KeyThin: FC<IconProps> = ({ color = Colors.FONT_3, size = 20 }) =>
  <svg width={size} height={size} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22.9,21.4l-0.3-1.1c-0.2-0.7-0.3-1.5-0.3-2.4C22.4,12.4,26.8,8,32.4,8s10,4.5,10,10.1c0,5.5-4.5,10-10,10
      c-1.8-0.3-3.4-0.9-4.9-1.7l0,0l0,0c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0.4-0.7,0.8l0.2,3.3H22c-0.6,0-1.1,0.4-1.2,1l-1,5h-3.7
      c-0.7,0-1.2,0.6-1.3,1.2c0,0.8-0.1,1.8-0.2,2.8c-0.1,0.5-0.2,0.9-0.3,1.3c-0.1,0.3-0.2,0.5-0.3,0.7c-2.7,0-4,0-5-0.1
      c-0.9-0.1-1.3-0.3-2.3-0.8v-5.2l15.9-14.2C22.9,22,23,21.7,22.9,21.4 M32.3,29.5c-1.4-0.2-2.8-0.6-4.1-1.2l0.1,2.3
      c0,0.7-0.5,1.3-1.2,1.3h-4.9l-1,5c-0.1,0.6-0.6,1-1.2,1h-3.7c0,0.8-0.1,1.8-0.3,2.8c-0.1,0.5-0.2,1-0.3,1.5
      c-0.1,0.5-0.3,0.9-0.6,1.3c-0.3,0.3-0.6,0.5-0.9,0.5c-2.8,0-4.3,0-5.3-0.1c-1.1-0.2-1.8-0.5-2.9-1c-0.4-0.2-0.7-0.6-0.7-1.1v-5.5
      c0-0.4,0.2-0.7,0.4-0.9l15.7-14c-0.3-1-0.5-2.1-0.5-3.3c0-6.4,5.2-11.6,11.6-11.6S44,11.6,44,18s-5.2,11.5-11.5,11.5L32.3,29.5
      L32.3,29.5z M34.3,15.6c0-0.2,0.2-0.4,0.5-0.4s0.5,0.2,0.5,0.4s-0.2,0.4-0.5,0.4S34.3,15.8,34.3,15.6z M34.8,13.7
      c-1.1,0-2,0.9-2,1.9s0.9,1.9,2,1.9s2-0.9,2-1.9S35.9,13.7,34.8,13.7z"/>
  </svg>;
