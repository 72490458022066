import React, { FC } from 'react';
import { IconProps, getStrokeWidth } from './index';
import { Colors } from '@zazume/zzm-base';

export const Euro: FC<IconProps> = ({ size = 20, color = Colors.FONT_3 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" d="M20 5.32051C20 5.32051 18.1062 2.31042 14.5266 2.31042C9.6673 2.31042 8.41865 4.45826 8.41865 7.10774V16.8923C8.41865 19.5417 9.6673 21.6896 14.5266 21.6896C18.1062 21.6896 20 18.6795 20 18.6795M2.5896 9.97364L16.124 9.97364M2.5896 14.0002L16.124 14.0002"/>
  </svg>;
};
