import { FC } from 'react';
import { SectionProps } from '#/containers/asideNavigation/Sections';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { useI18n } from '#hooks/useI18n';
import { Private } from '#/containers/router/routes/Private';

interface OwnerAccountSettingsProps extends SectionProps {
}

export const OwnerAccountSettings: FC<OwnerAccountSettingsProps> = ({ onClickItem, depth, isMobile }) => {
  const { t } = useI18n();
  return <Can hasRoles={['ownerManager']} hideFromZazumeUser>
    <NavigationLink
      to={Private.INVITATIONS.route}
      isMobile={isMobile}
      onClick={onClickItem}
      depth={depth}
      title={t('asideNavigation.ownerInvitations')}
      icon="ticket"
    />
    <NavigationLink
      to={Private.OWNER_ACCOUNT_SETTINGS.route}
      isMobile={isMobile}
      parentPath="/owner-account-settings"
      onClick={onClickItem}
      depth={depth}
      title={t('asideNavigation.settings')}
      icon="settings"
    />
  </Can>;
};
