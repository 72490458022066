import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from './index';

/**
 * @deprecated use base
 * @param color
 * @param size
 * @constructor
 */
export const Pencil: FC<IconProps> = ({ color, size = 20 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        d="M20.6352 8.3934L15.5669 3.29419M3.83308 19.3897L3.37534 16.1927C3.33071 15.881 3.43549 15.5665 3.65814 15.3439L12.7285 6.27348C13.119 5.88296 13.7522 5.88296 14.1427 6.27348L17.7974 9.9282C18.188 10.3187 18.188 10.9519 17.7975 11.3424L8.72707 20.4128C8.50442 20.6354 8.18991 20.7402 7.87822 20.6956L4.68125 20.2379C4.24153 20.1749 3.89604 19.8294 3.83308 19.3897Z"
    />
  </svg>;
};
