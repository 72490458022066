import { useGet, useNotifications } from '#/hooks';
import { useI18n } from '#/hooks/useI18n';
import { API } from '#/lib/network/API';
import { useUpdate } from '#/lib/reactQuery/hooks/useUpdate';
import { NotificationSettings, UpdateNotificationSettingsRequest } from '#/models/notificationSettings/NotificationSettings';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Semibold, Flex, Regular, Spacer, ZZMForm, ZazumeModal } from '@zazume/zzm-base';
import { FC } from 'react';
import { EditNotificationsInputs, getEditNotificationsSchema } from './EditNotifications.schema';
import { useNetworkExceptions } from '#/lib/network/helpers';
import { NotificationSettingsFormBuilder } from '#/pages/workspaces/tenants/components/tenantDetail/editNotificationSettings/NotificationSettingsFormBuilder';

const Container = styled.div(() => css`
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`);

const Footer = styled.div(({ theme }) => css`
  margin-top: ${theme.spacing.lg};
`);

interface EditNotificationsProps {
  close: () => void;
}

export const EditNotifications: FC<EditNotificationsProps> = ({ close }) => {
  const { t } = useI18n();
  const { handleNetworkExceptions } = useNetworkExceptions();
  const { showNotification } = useNotifications();

  const { data: notificationSettings, isLoading } = useGet<NotificationSettings>(['notificationSettings'], API.notificationSettings.getMyNotificationSettings);
  const { mutate: updateNotificationSettings, isLoading: isSubmitting } = useUpdate<NotificationSettings, Partial<NotificationSettings>>(['notificationSettings'], API.notificationSettings.updateMyNotificationSettings, { isOptimistic: false });

  const formSchema = getEditNotificationsSchema(t);

  const handleOnSubmit = async (values: EditNotificationsInputs) => {
    try {
      const notificationSettingsRequestBody = UpdateNotificationSettingsRequest.build(values);
      await updateNotificationSettings(notificationSettingsRequestBody);
      showNotification(t('profile.modal.updated'));
    } catch (e) {
      handleNetworkExceptions(e);
    }
    close();
  };

  const defaultValues = {
    pushNotifications: { all: true },
    emailNotifications: { all: true },
    ...NotificationSettingsFormBuilder.build(notificationSettings)
  };

  if (isLoading) {
    return null;
  }

  return (
    <ZZMForm.Form validation={formSchema} onSubmit={handleOnSubmit} defaultValues={defaultValues}>
      <Container>
        <div>
          <Regular variant="S">{t('profile.modal.notifications.description')}</Regular>
          <Spacer vertical="s12"/>

          <Flex justify="between">
            <Semibold>{t('profile.modal.notifications.emailNotifications')}</Semibold>
            <ZZMForm.Toggle name="emailNotifications.all"/>
            <ZZMForm.Input responsive={false} hidden name="emailNotifications.directMessages" type="checkbox"/>
            <ZZMForm.Input responsive={false} hidden name="emailNotifications.incidents" type="checkbox"/>
            <ZZMForm.Input responsive={false} hidden name="emailNotifications.bookings" type="checkbox"/>
            <ZZMForm.Input responsive={false} hidden name="emailNotifications.featureUpdates" type="checkbox"/>
          </Flex>

          <Spacer vertical="s8"/>

          <Flex justify="between">
            <Semibold>{t('profile.modal.notifications.pushNotifications')}</Semibold>
            <ZZMForm.Toggle name="pushNotifications.all"/>
            <ZZMForm.Input responsive={false} hidden name="pushNotifications.directMessages" type="checkbox"/>
            <ZZMForm.Input responsive={false} hidden name="pushNotifications.incidents" type="checkbox"/>
            <ZZMForm.Input responsive={false} hidden name="pushNotifications.bookings" type="checkbox"/>
            <ZZMForm.Input responsive={false} hidden name="pushNotifications.featureUpdates" type="checkbox"/>
          </Flex>
        </div>

        <Footer>
          <ZazumeModal.Footer
            variant="base"
            onCancel={close}
            acceptButtonType="submit"
            acceptButtonText={t('profile.save')}
            cancelButtonText={t('buttons.cancel')}
            isSubmitting={isSubmitting}
          />
        </Footer>

      </Container>
    </ZZMForm.Form>
  );
};
