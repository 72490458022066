import { FC } from 'react';
import styled from '@emotion/styled';

const SVG = styled.svg`
  cursor: ${props => props.cursor};
  display: block;
`;

interface ImageProps {
  color?: string;
  cursor?: string;
  onClick?: () => void;
  size?: number;
}

export const Image: FC<ImageProps> = ({ onClick, cursor, color = '#FFFFFF', size = 20 }) => {
  return <SVG cursor={cursor} onClick={onClick} width={size} height={size} viewBox="0 0 32 32" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.05005 8.8C4.05005 7.28122 5.28127 6.05 6.80005 6.05H25.2001C26.7188 6.05 27.9501 7.28122 27.9501 8.8V22.4C27.9501 23.9188 26.7188 25.15 25.2001 25.15H6.80005C5.28126 25.15 4.05005 23.9188 4.05005 22.4V8.8ZM6.80005 7.55C6.10969 7.55 5.55005 8.10965 5.55005 8.8V22.4C5.55005 22.7646 5.7061 23.0927 5.95506 23.3211C6.08604 23.0016 6.25827 22.6214 6.4619 22.2034C6.93154 21.2393 7.58361 20.0425 8.32118 18.8727C9.05577 17.7077 9.89158 16.543 10.7318 15.6602C11.1516 15.2191 11.5908 14.8288 12.0366 14.5444C12.4757 14.2643 12.9788 14.05 13.5112 14.05C13.9539 14.05 14.388 14.2117 14.7764 14.4211C15.1737 14.6352 15.5832 14.9322 15.9917 15.2765C16.809 15.9652 17.6898 16.901 18.5498 17.9149C20.1554 19.808 21.7478 22.045 22.8058 23.65H25.2001C25.8904 23.65 26.4501 23.0904 26.4501 22.4V8.8C26.4501 8.10965 25.8904 7.55 25.2001 7.55H6.80005ZM20.9992 23.65H7.44454C7.54521 23.4185 7.66796 23.1527 7.81042 22.8603C8.25744 21.9426 8.88315 20.7938 9.59003 19.6727C10.2999 18.5469 11.0752 17.4752 11.8183 16.6943C12.1901 16.3037 12.5357 16.0053 12.8434 15.809C13.1577 15.6084 13.3768 15.55 13.5112 15.55C13.6018 15.55 13.7806 15.5883 14.0646 15.7414C14.3398 15.8898 14.6623 16.1178 15.0251 16.4235C15.7504 17.0348 16.5695 17.8991 17.4059 18.8851C18.7045 20.4163 20.0035 22.1919 20.9992 23.65ZM19.95 13.2C19.95 11.8469 21.047 10.75 22.4001 10.75C23.7531 10.75 24.85 11.8469 24.85 13.2C24.85 14.5531 23.7531 15.65 22.4001 15.65C21.047 15.65 19.95 14.5531 19.95 13.2ZM22.4001 9.25C20.2185 9.25 18.45 11.0185 18.45 13.2C18.45 15.3815 20.2185 17.15 22.4001 17.15C24.5816 17.15 26.35 15.3815 26.35 13.2C26.35 11.0185 24.5816 9.25 22.4001 9.25Z"/>
  </SVG>;
};
