import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { Shadows } from '@zazume/zzm-base';
import { Subtitle } from '../../typography';
import { css } from '@emotion/react';

const SWITCH_TOTAL_PADDING_WIDTH = 4;
const PILL_TOTAL_PADDING_WIDTH = 24;
const TOTAL_MARGINS_AND_PADDINGS = 52;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Switch = styled.label<any>`
  position: relative;
  display: inline-block;
  min-width: ${props => props.size}px;
  height: 40px;
`;

const Input = styled.input<any>(({ theme, size }) => css`
  opacity: 0;
  width: 0;
  height: 0;

  & ~ div {
    & > div:nth-of-type(1) * {
      color: ${theme.colors.Primary};
    }
    & > div:nth-of-type(2) * {
      color: ${theme.colors.Gray500};
    }
  }

  &:checked {
    & + span:before {
      transform: translateX(${size / 2 - SWITCH_TOTAL_PADDING_WIDTH}px);
    }
    & ~ div{
      & > div:nth-of-type(1) * {
        color: ${theme.colors.Gray500};
      }
      & > div:nth-of-type(2) * {
        color: ${theme.colors.Primary};
      }
    }
  }
`);

const Slider = styled.span<any>(({ theme, size }) => css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.Gray200};
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 8px;

  &:before {
    position: absolute;
    content: "";
    height: 34px;
    width: ${size + PILL_TOTAL_PADDING_WIDTH}px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    box-shadow: ${Shadows.SHADOW_3};
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 6px;
  }
`);

const Labels = styled.div<any>`
  position: absolute;
  width: ${props => props.size - 6}px;
  height: 40px;
  left: 3px;
  top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;

  > div {
    text-align: center;
    -webkit-transition: .4s;
    transition: .4s;
  }
  > div:nth-of-type(1) {
    margin-left: ${props => props.leftMargin}px;
  }
  > div:nth-of-type(2) {
    margin-right: ${props => props.rightMargin}px;
  }
`;

const Wrapper = styled.div<any>`
  width: fit-content;
`;


export interface ToggleWithTextProps {
  name: string;
  enabledLabel: string;
  disabledLabel: string;
  isChecked: boolean;
  onToggle: (checked: boolean) => void;
  onClick?: () => void;
  containerStyle?: any;
}

export const ToggleWithText = React.forwardRef<any, ToggleWithTextProps>((props, ref) => {
  const { name, enabledLabel, disabledLabel, isChecked, onToggle, containerStyle = {}, ...restProps } = props;
  const [totalWidth, setTotalWidth] = useState<number>(142);
  const refLeftPosition = useRef<any>(null);
  const refRightPosition = useRef<any>(null);

  const handleToggle = () => onToggle(!isChecked);

  const getRightSize = () =>
    refRightPosition.current ? refRightPosition.current?.offsetWidth : 70;

  const getLeftSize = () =>
    refLeftPosition.current ? refLeftPosition.current?.offsetWidth : 70;

  const getMaxSize = useCallback(() =>
    getLeftSize() > getRightSize() ? getLeftSize() : getRightSize(), []);

  const getMargin = (size) =>
    (size === getMaxSize()) ? 0 : (getMaxSize() - size) / 2;

  useEffect(()=>{
    setTotalWidth(getMaxSize() * 2 + TOTAL_MARGINS_AND_PADDINGS);
  }, [refLeftPosition, refRightPosition, getMaxSize]);

  return (
    <Container style={containerStyle}>
      <Switch size={totalWidth}>
        <Input ref={ref} name={name} type="checkbox" checked={isChecked} onChange={handleToggle} size={totalWidth}/>
        <Slider {...restProps} size={getMaxSize()}/>
        <Labels size={totalWidth} leftMargin={getMargin(getLeftSize())} rightMargin={getMargin(getRightSize())}>
          <Wrapper ref={refLeftPosition}>
            <Subtitle soft>{enabledLabel}</Subtitle>
          </Wrapper>
          <Wrapper ref={refRightPosition}>
            <Subtitle soft>{disabledLabel}</Subtitle>
          </Wrapper>
        </Labels>
      </Switch>
    </Container>
  );
});
