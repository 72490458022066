import { Colors } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { IconProps } from '.';
import { getStrokeWidth } from './index';

export const CreditCard: FC<IconProps> = ({ size = 20, color = Colors.FONT_3 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} strokeLinejoin="round" d="M3.6001 10.2H20.4001M5.6001 19.2H18.4001C19.5047 19.2 20.4001 18.3046 20.4001 17.2V8C20.4001 6.89543 19.5047 6 18.4001 6H5.6001C4.49553 6 3.6001 6.89543 3.6001 8V17.2C3.6001 18.3046 4.49553 19.2 5.6001 19.2Z"/>
  </svg>;
};
