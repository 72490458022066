// TODO: get the models from the backend project

import { IdentifierDTO } from '@zazume/zzm-base';
import { Application, BasicUnitForApplication, EntityOwnerAccount, Tenant, Unit, User } from '#/models';
import { ApplicationSearch } from '#/models/algolia/ApplicationSearch';
import { OwnerSearch } from '#/models/algolia/OwnerSearch';
import { TenantSearch } from '#/models/algolia/TenantSearch';
import { UnitSearch } from '#/models/algolia/UnitSearch';
import { EntitySearch } from '#/models/algolia/EntitySearch';
import { DeepPartial } from '@zazume/zzm-utils';
import { AgentSearch } from '#/models/algolia/AgentSearch';
import { CitySearch } from '#/models/algolia/CitySearch';
import { City } from '#/models/location/City';

export const GeographicalIndex = <const>['cities'];

export const Index = <const>['agents', 'tenants', 'units', 'applications', 'owners', 'entities', ...GeographicalIndex];
export type IndexName = typeof Index[number];

const Entity = <const>['application', 'tenant', 'brand', 'staff', 'provider', 'unitManager', 'accountManager', 'applicationManager', 'incidentsManager', 'agent', 'user', 'unit', 'incident', 'service', 'building', 'ownerAccount', 'location'];
export type Entities = typeof Entity[number];

export const fromIndexNameToAlgolia = (prefix: string, suffix: string | IdentifierDTO) =>
  (indexName: IndexName): string =>
    `${prefix}${indexName}_${suffix}`;

export const fromTenantSearchToTenant = (tenantSearch: TenantSearch): Partial<Tenant> => {
  return {
    _id: tenantSearch.objectID,
    email: tenantSearch.email,
    name: tenantSearch.name,
    phoneNumber: tenantSearch.phoneNumber,
    roles: tenantSearch.roles,
    fullName: tenantSearch.name,
    organization: tenantSearch.organization,
    tenant: {
      organizationId: tenantSearch.organization,
      idNumber: tenantSearch.idNumber,
      purchaser: false
    }
  };
};

export const fromAgentSearchToUser = (agentSearch: AgentSearch): Partial<User> => {
  return {
    _id: agentSearch.objectID,
    email: agentSearch.email,
    name: agentSearch.name,
    phoneNumber: agentSearch.phoneNumber,
    roles: agentSearch.roles,
    fullName: agentSearch.name,
    organization: agentSearch.organization,
    agent: {
      billingData: {
        idNumber: agentSearch.idNumber
      },
      publicName: agentSearch.publicName,
      company: agentSearch.company,
      serviceAreas: agentSearch.serviceAreas
    }
  };
};

export const fromUnitSearchToUnit = (unitSearch: UnitSearch): Partial<Unit> => {
  return {
    _id: unitSearch.objectID,
    name: unitSearch.name,
    workspaceId: unitSearch.workspaceId,
    organization: unitSearch.organization,
    buildingId: unitSearch.buildingId,
    block: unitSearch.block,
    floor: unitSearch.floor,
    door: unitSearch.door,
    fullAddress: unitSearch.unitAddress,
    address: unitSearch.address,
    characteristics: unitSearch.characteristics,
    cadastralData: unitSearch.cadastralData,
    description: unitSearch.description
  };
};

export const fromApplicationSearchToApplication = (applicationSearch: ApplicationSearch): Partial<Application> => {
  return {
    _id: applicationSearch.objectID,
    organizationId: applicationSearch.organization,
    unit: applicationSearch.unit as BasicUnitForApplication,
    tenant: applicationSearch.tenant
  };
};

export const fromOwnerSearchToOwner = (ownerSearch: OwnerSearch): DeepPartial<User> => {
  return {
    organization: ownerSearch.organization,
    email: ownerSearch.email,
    name: ownerSearch.name,
    phoneNumber: ownerSearch.phoneNumber,
    fullName: ownerSearch.name,
    owner: {
      ownerAccountId: ownerSearch.objectID
    }
  };
};

export const fromEntitySearchToEntityOwnerAccount = (ownerSearch: EntitySearch): Partial<EntityOwnerAccount> => {
  return {
    _id: ownerSearch.objectID,
    organizationId: ownerSearch.organization,
    entityName: ownerSearch.name
  };
};

export const fromCitySearchToCity = (city: CitySearch): Partial<City> => {
  return {
    id: city.objectID,
    name: city.name,
    slug: city.slug
  };
};

export const filterExcludeObjectIds = (objectIds: IdentifierDTO[]): string =>
  `NOT objectID:${objectIds.join(' AND NOT objectID:')}`;
