import { Status } from '#/domain/ownerServiceContract/types';
import { BasicUnit } from '#/models';
import { IdentifierDTO } from '@zazume/zzm-base';
import { DateVO } from '@zazume/zzm-utils';
import { BrokenOwnerServiceContract } from './BrokenOwnerServiceContract';
import { Service } from './Service';
import { ManagementService } from './ManagementService';
import { RentAdvanceService } from './RentAdvanceService';

interface ConstructorParams<SERVICE extends Service> {
  _id: IdentifierDTO;
  organizationId: IdentifierDTO;
  ownerAccountsId: IdentifierDTO[];
  broken?: BrokenOwnerServiceContract;
  /**
   * The id of the document that represents the contract.
   */
  documentId?: IdentifierDTO;
  endsAt?: DateVO;
  service: SERVICE;
  startsAt: DateVO;
  status: Status;
  unit: BasicUnit;
}

export class OwnerServiceContract<SERVICE extends Service> {
  _id: IdentifierDTO;
  organizationId: IdentifierDTO;
  ownerAccountsId: IdentifierDTO[];
  /**
   * The id of the document that represents the contract.
   */
  documentId?: IdentifierDTO;
  service: SERVICE;
  status: Status;
  endsAt?: DateVO;
  startsAt: DateVO;
  broken?: BrokenOwnerServiceContract;
  unit: BasicUnit;

  constructor(params: ConstructorParams<SERVICE>) {
    this._id = params._id;
    this.organizationId = params.organizationId;
    this.ownerAccountsId = params.ownerAccountsId;
    this.service = params.service;
    this.documentId = params.documentId;
    this.status = params.status;
    this.endsAt = params.endsAt;
    this.startsAt = params.startsAt;
    this.broken = params.broken;
    this.unit = params.unit;
  }

  isActive(): boolean {
    return this.status === 'active';
  }

  isFinished(): boolean {
    return this.status === 'finished';
  }

  isUpcoming(): boolean {
    return this.startsAt.withStartOf('day').isFuture();
  }

  isBroken(): boolean {
    return this.status === 'broken';
  }

  isPendingSignature(): boolean {
    return this.status === 'pendingSignature';
  }

  isError(): boolean {
    return this.status === 'error';
  }

  isExpiredSignature(): boolean {
    return this.status === 'expiredSignature';
  }

  isRejected(): boolean {
    return this.status === 'rejected';
  }

  isPendingToBroke(): boolean {
    return this.broken?.at.isFuture() ?? false;
  }

  isAlreadyBroken(): boolean {
    return this.isBroken() && this.broken!.at.isPast();
  }

  isTerminated(): boolean {
    return ['finished', 'broken', 'rejected', 'expiredSignature'].includes(this.status);
  }

  isDraft(): boolean {
    return ['draft'].includes(this.status);
  }

  isManagementService(): this is OwnerServiceContract<ManagementService> {
    return this.service.type === 'Management';
  }

  isRentAdvanceService(): this is OwnerServiceContract<RentAdvanceService> {
    return this.service.type === 'RentAdvance';
  }

  canBeDeleted(): boolean {
    return ['draft'].includes(this.status) && !this.documentId && this.service.canBeDeleted();
  }
}
