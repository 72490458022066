import { FieldErrors } from 'react-hook-form';

const getNestedFieldError = (splitedName: string[], errors: FieldErrors): FieldErrors | null => {
  const error = errors[splitedName[0]];
  if (typeof error?.message === 'string') {
    return error;
  }
  if (!error) {
    return null;
  }
  splitedName.shift();
  return getNestedFieldError(splitedName, error);
};

export const getFieldError = (fieldName: string, formErrors: FieldErrors): FieldErrors | null => {
  const isNestedField = fieldName.includes('.');
  return isNestedField
    ? getNestedFieldError(fieldName.split('.'), formErrors)
    : formErrors[fieldName];
};

export const hasFieldError = (fieldName: string, formErrors: FieldErrors): boolean => {
  return Boolean(getFieldError(fieldName, formErrors));
};
