import { AsideNavigation } from '#/containers/asideNavigation/AsideNavigation';
import { NavigationContextProvider } from '#/containers/asideNavigation/context/NavigationContext';
import { MobileNavigation } from '#/containers/asideNavigation/MobileNavigation';
import { SimpleHeader } from '#/containers/header/SimpleHeader';
import { LoadingPageBody } from '#/containers/loadingPageBody/LoadingPageBody';
import { CopilotWidgetContainer } from '#/pages/copilot/components/widget/CopilotWidgetContainer';
import { CopilotProvider } from '#/pages/copilot/CopilotContext';
import { HEADER_HEIGHT } from '#/utils/containers/containersConstants';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Colors, FCC, Hidden, Visible } from '@zazume/zzm-base';
import React, { Suspense, useState } from 'react';

const FullContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  overflow-x: hidden;
`;

const Base = styled.div(({ theme }) => css`
  width: 100%;
  background: ${Colors.BACKGROUND_3};
  overflow-x: hidden;
  padding-top: ${HEADER_HEIGHT}px;

  ${theme.mq.desktop} {
    padding-top: 0;
  }
`);

interface LeftNavigationTemplateProps {
}

export const LeftNavigationTemplate: FCC<LeftNavigationTemplateProps> = ({ children, ...rest }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const handleIsMobileMenuOpen = () => setIsMobileMenuOpen(prev => !prev);

  return <FullContainer>
    <CopilotProvider>
      <Hidden mobile tablet>
        <NavigationContextProvider>
          <AsideNavigation/>
        </NavigationContextProvider>
      </Hidden>
      <Visible mobile tablet>
        <NavigationContextProvider>
          <MobileNavigation isOpen={isMobileMenuOpen} handleIsOpen={handleIsMobileMenuOpen}/>
        </NavigationContextProvider>
      </Visible>
      <Base>
        <SimpleHeader handleIsMobileMenuOpen={handleIsMobileMenuOpen}/>
        <Suspense fallback={<LoadingPageBody/>}>
          {children}
        </Suspense>
      </Base>
      <CopilotWidgetContainer/>
    </CopilotProvider>
  </FullContainer>;
};
