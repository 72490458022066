import { FC } from 'react';
import { IlustrationProps } from '.';

export const Iceberg: FC<IlustrationProps> = ({ width = '925', height = '571' }) => {
  return <svg width={width} height={height} viewBox="0 0 925 571" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M282.486 430.373C321.518 498.641 458.219 557.047 486.718 568.645C490.834 563.444 495.26 558.521 499.557 553.457C510.427 540.645 528.072 517.305 546.733 491.928C595.598 425.477 679.114 398.812 757.459 373.012C779.739 365.674 799.408 354.178 811.112 336.403C853.071 272.679 840.619 272.974 881.272 233.469C888.796 226.158 894.065 218.619 897.657 211.187C915.732 173.787 906.595 125.684 916.854 85.4321C920.196 72.3213 923.046 58.5758 924.497 45.7361C927.535 18.8568 903.55 2.49996 876.5 2.49996L705.5 2.49997L220.5 2.49999C83.5 2.5 56.4458 -12.2109 25.9406 36.6956C-62.4689 178.435 101.075 213.516 130.5 325.607C144.808 380.109 260.4 391.746 282.486 430.373Z" fill="url(#paint0_linear)"/>
    <defs>
      <linearGradient id="paint0_linear" x1="498.029" y1="570.735" x2="498.029" y2="-25.8879" gradientUnits="userSpaceOnUse">
        <stop offset="0.378125" stopColor="#F2F4FC"/>
        <stop offset="1" stopColor="#F2F4FC" stopOpacity="0"/>
      </linearGradient>
    </defs>
  </svg>;
};
