import React, { FC, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Bell } from './Bell';
import { useClickOutside, useGet } from '../../../../hooks';
import { API } from '../../../../lib/network/API';
import { NotificationKeys } from '../../../../hooks/api/notifications/NotificationKeys';
import { NotificationsOverlay } from './overlay/NotificationsOverlay';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Notifications: FC = () => {
  const [isOverlayOpened, setIsOverlayOpened] = useState(false);

  const overlayRef = useRef<any>();
  useClickOutside(overlayRef, () => setIsOverlayOpened(false));

  const { data: notificationsUnreadSummary, isLoading } = useGet<{ unread: number }>(NotificationKeys.unreadSummary, API.notifications.getUnread);

  const openOverlay = (event) => {
    event.preventDefault();
    setIsOverlayOpened((prevState) => !prevState);
  };

  const closeOverlay = () =>
    setIsOverlayOpened(false);

  const hasUnreadNotifications = !isLoading && notificationsUnreadSummary!.unread > 0;

  return <Container data-id="notifications" ref={overlayRef}>
    <Bell hasUnreadNotifications={hasUnreadNotifications} onClick={openOverlay}/>
    <NotificationsOverlay isOpened={isOverlayOpened} onCloseOverlay={closeOverlay}/>
  </Container>;
};
