import { IdentifierDTO } from '@zazume/zzm-base';
import { WithRequiredProperty } from '@zazume/zzm-utils';
import { CommercialisationFee } from './CommercialisationFee';
import { Payer } from './Payer';
import { Service } from './Service';
import { Split } from './types';

interface ConstructorParams {
  fee: CommercialisationFee;
  payer: Payer;
  applicationId?: IdentifierDTO;
  split?: Split;
}

export class CommercialisationService extends Service {
  fee: CommercialisationFee;
  payer: Payer;
  applicationId?: IdentifierDTO;
  split?: Split;

  constructor(params: ConstructorParams) {
    super('Commercialisation');
    this.fee = params.fee;
    this.payer = params.payer;
    this.applicationId = params.applicationId;
    this.split = params.split;
  }

  static fromRaw(service: any): CommercialisationService {
    return new CommercialisationService({
      fee: CommercialisationFee.fromRaw(service.fee),
      payer: Payer.fromRaw(service.payer),
      applicationId: service.applicationId,
      split: service.split
    });
  }

  isLinkedToLease(): boolean {
    return false;
  }

  canBeDeleted() {
    return !this.isLinkedToLease();
  }

  hasSplits(): this is WithRequiredProperty<CommercialisationService, 'split'> {
    return Boolean(this.split?.times && this.split.times > 1);
  }

  hasDiscount(): this is WithRequiredProperty<CommercialisationService, 'fee'> {
    return Boolean(this.fee.discount);
  }
}
