import { AuthResponse } from '#/models';
import { AcquisitionLanding } from '#/models/AcquisitionLanding';
import { doGet, doPost } from '#lib/network/helpers';

export const landings = {
  get: (slug: string) => () =>
    doGet<AcquisitionLanding>(`/landings/slug/${slug}`),
  signup: (name: string, email: string, password: string, landing: AcquisitionLanding) =>
    doPost<AuthResponse>('/landings/signup', { name, email, password, landing })
};
