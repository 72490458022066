import { SignatureConfiguration } from '../../../../../components/forms/signer/types';

interface Data {
  type: string;
  signers?: any;
  expiredAt?: Date;
  isHiddenFromOwner?: boolean;
  signatureConfiguration?: SignatureConfiguration;
}

export class CreateDocumentRequest {
  private readonly formData: FormData;
  private signatureConfiguration?: SignatureConfiguration;
  readonly data: Data;

  constructor(type: string) {
    this.formData = new FormData();
    this.data = {
      type
    };
  }

  withSignatureConfiguration(configuration: SignatureConfiguration) {
    this.data.signatureConfiguration = configuration;
  }

  build(): FormData {
    this.formData.append('data', JSON.stringify(this.data));
    return this.formData;
  }

  addSigners(signers: any) {
    this.data.signers = signers.map(signer => ({
      name: signer.name,
      email: signer.email.toLowerCase(),
      withCertificate: signer.signType === 'certificate'
    }));
  }

  addExpiredAt(expireAt: Date) {
    this.data.expiredAt = expireAt;
  }

  addHiddenFromOwner(isHiddenFromOwner: boolean) {
    this.data.isHiddenFromOwner = isHiddenFromOwner;
  }

  addFile(file: File) {
    this.formData.append('documents', file, file.name);
  }
}
