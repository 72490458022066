import { ZazumeModal, ZZMForm } from '@zazume/zzm-base';
import React from 'react';
import { useI18n } from '#hooks/useI18n';

export const CreateAlertModalFooter = () => {
  const { t } = useI18n();
  const { formState } = ZZMForm.useFormContext();
  const { isSubmitting } = formState;

  return (
    <ZazumeModal.Footer
      acceptButtonText={t('general.save')}
      cancelButtonText={t('general.cancel')}
      acceptButtonType="submit"
      isSubmitting={isSubmitting}
    />
  );
};
