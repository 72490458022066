import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useBrand } from '#hooks/useBrand';

interface Props {
  fromUser: boolean;
  text: string;
}

const Container = styled.p<{ fromUser: boolean }>(({ fromUser, theme }) => css`
  margin: 0;
  font-size: ${theme.fontsSizes.xs};
  white-space: pre-line;
  word-break: break-word;
  color: ${fromUser ? theme.colors.White : theme.colors.Gray600};

  a {
    color: ${theme.colors.Primary};

    :hover {
      color: ${theme.colors.PrimaryDark1};
    }
  }
`);

export const BodyRenderer: FC<Props> = ({ fromUser, text }) => {
  const navigate = useNavigate();
  const { brand } = useBrand();

  const urlRegex = /https?:\/\/[^\s.]+(?:\.[^\s.]+)+/g;

  const replaceUrlsWithLinks = (text) => {
    return text.split(urlRegex).reduce((acc, part, i) => {
      if (i === 0) {
        return [part];
      }

      const url = text.match(urlRegex)[i - 1];
      acc.push(
        <a
          key={url}
          href="#"
          onClick={(e) => {
            e.preventDefault();

            if (!url.includes(brand?.dashboardURL)) {
              window.open(url);
              return;
            }

            const fullURL = new URL(url);
            navigate({ pathname: fullURL.pathname, search: `?${fullURL.searchParams.toString()}` });
          }}
        >
          {url}
        </a>
      );
      acc.push(part);

      return acc;
    }, []);
  };

  return <Container fromUser={fromUser}>{replaceUrlsWithLinks(text)}</Container>;
};
