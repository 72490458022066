import React, { FC } from 'react';
import { SectionProps } from '#/containers/asideNavigation/Sections';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { useI18n } from '#hooks/useI18n';
import { Private } from '#/containers/router/routes/Private';

interface AgentAccountSettingsProps extends SectionProps {
}

export const AgentAccountSettings: FC<AgentAccountSettingsProps> = ({ onClickItem, depth, isMobile }) => {
  const { t } = useI18n();
  return <Can hasRoles={['agent']} hideFromZazumeUser>
    <NavigationLink
      to={Private.AGENT_ACCOUNT_SETTINGS.route}
      isMobile={isMobile}
      parentPath="/agent-account-settings" onClick={onClickItem} depth={depth} title={t('asideNavigation.settings')} icon="settings"
    />
  </Can>;
};
