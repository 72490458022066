import React, { FC } from 'react';
import { IconProps } from './index';

export const Maintenance: FC<IconProps> = ({ color, size = 24 }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5,17.8c-0.5,0-1.1-0.2-1.5-0.7c-0.8-0.8-0.9-1.8-0.3-2.7c0.2-0.3,0.5-0.6,0.9-1l0.5-0.5
      c1.9-1.8,3.7-3.5,5.6-5.3C9.9,7.3,10,7.2,9.9,6.8c-0.6-1.6-0.4-2.3,1-3.4c0.8-0.6,1.7-1,2.7-1.2l0,0c0.5-0.1,0.9,0.2,1.2,0.6
      C15,3.4,14.9,4,14.5,4.4l-0.7,0.7c-0.1,0.1-0.1,0.1-0.1,0.1c0,0,0,0.1,0.1,0.1l0.5,0.5C14.4,6,14.6,6,14.7,6.1
      c0.2,0,0.3-0.1,0.4-0.2l0.3-0.3c0.5-0.5,1.1-0.6,1.6-0.3c0.4,0.2,0.7,0.7,0.6,1.3c-0.3,1.4-0.9,2.6-2,3.5c-0.5,0.4-1.1,0.6-1.8,0.4
      c-0.3-0.1-0.7-0.2-1-0.3c-0.2-0.1-0.3-0.1-0.5,0.2c-1.9,2.1-3.9,4.2-5.8,6.2c-0.4,0.4-0.7,0.7-1,0.9C5.3,17.6,4.9,17.8,4.5,17.8z
      M12.7,4.1c-0.3,0.1-0.6,0.3-0.9,0.5c-0.9,0.7-0.9,0.7-0.5,1.7c0.3,0.9,0.1,1.7-0.6,2.3c-1.9,1.7-3.8,3.5-5.6,5.2l-0.5,0.5
      c-0.3,0.3-0.5,0.5-0.7,0.7C3.7,15.4,3.7,15.6,4,16c0.4,0.4,0.6,0.3,0.9,0.1c0.2-0.2,0.5-0.4,0.7-0.7c1.9-2.1,3.9-4.1,5.8-6.2
      c0.7-0.8,1.5-0.7,2.1-0.6c0.3,0.1,0.6,0.2,0.9,0.3c0.2,0,0.3,0,0.4-0.1c0.5-0.5,0.9-1,1.2-1.6c-0.3,0.2-0.8,0.4-1.2,0.4
      c-0.5,0-1.1-0.2-1.5-0.6l-0.5-0.5c-0.3-0.3-0.5-0.7-0.5-1.2C12.2,4.8,12.4,4.4,12.7,4.1z M13.8,3.8L13.8,3.8
      C13.8,3.8,13.8,3.8,13.8,3.8C13.8,3.8,13.8,3.8,13.8,3.8z"
    />
  </svg>;
};
