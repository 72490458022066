import { useNotifications } from '#/hooks';
import { useI18n } from '#/hooks/useI18n';
import { API } from '#/lib/network/API';
import { useNetworkExceptions } from '#/lib/network/helpers';
import { User } from '#/models';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar, LinkButton } from '@zazume/zzm-base';
import { FC, useRef, useState } from 'react';

const AvatarWrapper = styled.div(({ theme }) => css`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.s4};
`);

interface EditAvatarProps {
  user: User;
  onUpdate: () => void;
}

export const EditAvatar: FC<EditAvatarProps> = ({ user, onUpdate }) => {
  const { t } = useI18n();
  const { showNotification } = useNotifications();
  const { handleNetworkExceptions } = useNetworkExceptions();

  const imageInput = useRef<HTMLInputElement>(null);
  const [editableUser, setEditableUser] = useState<User>(user);

  const onSelectImage = (event) => {
    event.preventDefault();
    imageInput.current && imageInput.current.click();
  };

  const onAvatarChange = async (event) => {
    event.preventDefault();
    const { target: { files } } = event;
    const image = files ? files[0] : null;
    const avatar = image ? URL.createObjectURL(image) : undefined;
    setEditableUser({ ...editableUser, avatar });

    try {
      if (image) {
        const photoFormData = new FormData();
        photoFormData.append('files', image);
        await API.users.updateAvatar(photoFormData);
      } else if (!avatar) {
        await API.users.deleteAvatar();
      }

      showNotification(t('profile.modal.updated'));
      onUpdate();
    } catch (e) {
      handleNetworkExceptions(e);
    }
  };

  return (
    <AvatarWrapper>
      <Avatar name={editableUser.name} avatar={editableUser.avatar} variant="XL"/>
      {editableUser.avatar
        ? <>
          <LinkButton size="Medium" onClick={onSelectImage}>{t('profile.change')}</LinkButton>
          <LinkButton size="Medium" variant="danger" onClick={onAvatarChange}>{t('profile.remove')}</LinkButton>
        </>
        : <LinkButton size="Medium" onClick={onSelectImage}>{t('profile.upload')}</LinkButton>}
      <input ref={imageInput} type="file" name="avatar" onChange={onAvatarChange} hidden/>
    </AvatarWrapper>
  );
};
