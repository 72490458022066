import React, { FC } from 'react';
import { ZZMForm, ZazumeModal } from '@zazume/zzm-base';
import { useI18n } from '#/hooks/useI18n';

export const EditSignerModalFooter: FC = () => {
  const { t } = useI18n();
  const { formState: { isSubmitting, isDirty } } = ZZMForm.useFormContext();

  return (
    <ZazumeModal.Footer
      acceptButtonType="submit"
      acceptButtonText={t('signatures.sendToSignAgain')}
      cancelButtonText={t('buttons.cancel')}
      isSubmitting={isSubmitting}
      isAcceptDisabled={!isDirty}
    />
  );
};
