import React from 'react';
import styled from '@emotion/styled';
import { Colors, FCC, Fonts } from '@zazume/zzm-base';
import { css } from '@emotion/react';

const Sizes = {
  s: css`
    font-family: ${Fonts.BASE};
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0em;
  `,
  xs: css`
    font-family: ${Fonts.BASE};
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0em;
  `
};

type SizeTypes = keyof typeof Sizes;

interface ContainerProps {
  size?: SizeTypes;
  soft?: boolean;
}
const Container = styled.div<ContainerProps>(({ soft, size = 's' }) => css`
  font-family: ${Fonts.BASE};
  ${Sizes[size]}
  font-style: normal;
  font-weight: ${soft ? 600 : 700};
  color: ${Colors.FONT_3};
`);

interface SubtitleBaseProps {
  emptyText?: string;
}
type SubtitleProps = SubtitleBaseProps & ContainerProps

/**
 * @deprecated use Base paragraph
 * <Semibold variant="S">
 * @param props
 * @constructor
 */
export const Subtitle: FCC<SubtitleProps> = (props) => {
  const { soft, emptyText = '', ...rest } = props;
  const text = typeof props.children === 'number' ? props.children : props.children || emptyText;
  return <Container soft={soft} {...rest}>{text}</Container>;
};
