import React, { useState } from 'react';
import { IconType } from '@zazume/zzm-base';
import { SetValueConfig } from 'react-hook-form';
import { Currency } from '../../../models';
import { Input, InputProps } from './Input';
import { formatNumberNonFixedDecimals } from '../../../utils/stringsHelper';
import { fromUnitsToCents } from '@zazume/zzm-utils';

export interface CurrencyInputProps extends InputProps {
  currency?: Currency;
  displayValue?: 'units' | 'cents';
  setValue?: (name: string, value: any, options?: SetValueConfig) => void;
  required?: boolean
}

/**
 * @deprecated use src/components/forms/select/SelectBase.tsx variant = currency
 */
export const CurrencyInput = React.forwardRef<any, CurrencyInputProps>((props, ref) => {
  const { currency = 'eur', defaultValue, displayValue = 'cents', required = true, onChange, label, errors, name, setValue, clearErrors, ...internalProps } = props;

  const [valueInCents, setValueInCents] = useState<string | number | undefined>(defaultValue);

  const getIcon = (currency: Currency): IconType => {
    //TODO: Switch with more currencies
    if (currency === 'eur') {
      return 'euro';
    }
    return 'euro';
  };

  const onChangeValue = (valueInUnits: string) => {
    const valueInCents = !valueInUnits.length ? '' : fromUnitsToCents(Number(valueInUnits));
    setValueInCents(valueInCents);
    onChange && onChange(formatNumberNonFixedDecimals(valueInCents));
    setValue && name && setValue(name, valueInCents, {
      shouldValidate: true
    });
  };

  const getIsDisplayingCents = () => displayValue === 'cents';

  const getShownValueInUnits = (valueInCents: string | number | undefined): number | undefined => {
    if (valueInCents === undefined) {
      if (required) {
        return Number(null);
      }
      else {
        return undefined;
      }
    }
    const numberValueInCents = typeof valueInCents === 'number' ? valueInCents : parseInt(valueInCents);
    return Number(formatNumberNonFixedDecimals(numberValueInCents / 100));
  };

  const icon = getIcon(currency);
  const isDisplayingCents = getIsDisplayingCents();
  const shownValueInUnits = getShownValueInUnits(valueInCents);

  return isDisplayingCents ? <Input
      ref={ref}
      name={name}
      defaultValue={defaultValue}
      iconLeft={icon}
      type="number"
      step=".01"
      {...props}/> :
    <>
      <Input
        ref={ref}
        defaultValue={shownValueInUnits}
        onChange={onChangeValue}
        iconLeft={icon}
        label={label}
        errors={errors}
        name={`${name}_bis`}
        type="number"
        step=".01"
        {...internalProps}/>
      <input
        ref={ref}
        name={name}
        value={valueInCents}
        step=".01"
        readOnly
        hidden
      />
    </>;
});
