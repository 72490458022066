import { FC } from 'react';
import { useI18n } from '#hooks/useI18n';
import { Document } from '#/models';
import { SecondaryButton, useModal } from '@zazume/zzm-base';
import { RejectConfirmationModal } from '#/components/documentUploader/atoms/RejectConfirmationModal';
import styled from '@emotion/styled';
import { GenericConfirmationModal } from '#/components/modal/GenericConfirmationModal';

const Container = styled.div`
  margin-right: auto;
`;

interface RejectSignatureButtonProps {
  document: Document;
  /**
   * Called when the user wants to reject the document.
   * @param reason
   */
  onRejectDocument: (reason: object) => Promise<void>;
  onAfterReject?: () => Promise<void>;
  canBeRejected?: boolean;
}

export const RejectSignatureButton: FC<RejectSignatureButtonProps> = ({ document, canBeRejected = true, onRejectDocument, onAfterReject }) => {
  const { t } = useI18n();
  const { signers, rejected, state, signatureExternalId, hasSignatureError } = document;

  const { open: openRejectModal, RenderModal: RenderRejectModal, close: hideRejectModal } = useModal({ modalSize: 'm' });

  const showRejectCTA = canBeRejected && state !== 'signed' && state !== 'expired_signature' && !rejected && onRejectDocument;

  if (!signatureExternalId || !signers || !showRejectCTA) {
    return null;
  }

  return (
    <Container>
      <SecondaryButton variant="danger" onClick={openRejectModal}>{t('documents.rejectDocument')}</SecondaryButton>
      <RenderRejectModal>
        {hasSignatureError
          ? <GenericConfirmationModal title={t('signatures.rejectWithEmailError.title')} description={t('signatures.rejectWithEmailError.description')} cancelButtonText={t('general.allright')} onConfirm={hideRejectModal} hideActionButton withCloseButton/>
          : <RejectConfirmationModal onReject={onRejectDocument} onAfterReject={onAfterReject} close={hideRejectModal}/>}
      </RenderRejectModal>
    </Container>
  );
};
