import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useI18n } from '../../../../../hooks/useI18n';
import { getIcon, Semibold } from '@zazume/zzm-base';
import { css } from '@emotion/react';

const ShowMoreButton = styled.div(({ theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s2};
  cursor: pointer;
`);

interface ShowMoreProps {
  handleShowInfo: () => void;
  showMoreInfo: boolean;
}

export const ShowMore: FC<ShowMoreProps> = ({ handleShowInfo, showMoreInfo }) => {
  const { t } = useI18n();

  const Icon = showMoreInfo ? getIcon('minus') : getIcon('plus');
  const text = showMoreInfo ? t('units.detail.about.viewLess') : t('units.detail.about.viewMore');

  return (
    <ShowMoreButton onClick={handleShowInfo} role="button">
      <Icon size={16} color="Primary"/>
      <Semibold variant="S" color="Primary">{text}</Semibold>
    </ShowMoreButton>
  );
};
