import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { getStrokeWidth, IconProps } from './index';

export const Clock: FC<IconProps> = ({ size = 24, color = Colors.FONT_3 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.2928 7.04838V12.2267C11.2928 12.5305 11.4309 12.8178 11.6681 13.0076L14.8296 15.5368M20.4886 12C20.4886 16.688 16.6882 20.4884 12.0001 20.4884C7.31212 20.4884 3.51172 16.688 3.51172 12C3.51172 7.31193 7.31212 3.51154 12.0001 3.51154C16.6882 3.51154 20.4886 7.31193 20.4886 12Z"
    />
  </svg>;
};
