import { IdentifierDTO } from '@zazume/zzm-base';
import { useState } from 'react';
import { API } from '../../lib/network/API';
import { DocumentToDownload } from '../../models/documents/DocumentToDownload';
import { useOS } from '../useOS';

export const useDownloadDocument = (documentId: IdentifierDTO) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isAppleDevice } = useOS();
  const isApple = isAppleDevice();

  const download = async () => {
    setIsLoading(true);
    const { url }: DocumentToDownload = await API.documents.getDownloadURL(documentId);
    window.open(url, isApple ? '_self' : '_blank');
    setIsLoading(false);
  };

  return {
    download,
    isLoading
  };
};
