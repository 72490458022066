import { FCC } from '@zazume/zzm-base';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: row;
  line-height: ${theme.lineHeights.md};
  gap: ${theme.spacing.s4};
  align-items: center;
`);

export const Title: FCC<any> = ({ children }) => {
  return <Container>{children}</Container>;
};
