import React from 'react';
import styled from '@emotion/styled';
import { Colors, FCC, Fonts } from '@zazume/zzm-base';

const Container = styled.div<any>`
  font-family: ${Fonts.BASE};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0;
  color: ${Colors.FONT_2};
  white-space: pre-wrap;
`;

/**
 * @deprecated use Base paragraph
 * <Regular/>
 * @param props
 * @constructor
 */
export const Body16: FCC<any> = (props) => {
  return <Container {...props}>{props.children}</Container>;
};
