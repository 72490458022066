import React, { FC, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNetworkExceptions } from '../../../lib/network/helpers';
import { useNotifications, useYupValidationResolver } from '../../../hooks';
import { Alert, Flex, Regular, Semibold, useModal, useZazumeModalContext, ZazumeModal } from '@zazume/zzm-base';
import { useI18n } from '../../../hooks/useI18n';
import { ManagementService, Organization, Unit } from '../../../models';
import { getRequestUnitServiceUpgradeSchema, RequestUnitServiceUpgradeFormInputs } from './RequestUnitServiceUpgrade.schema';
import { API } from '../../../lib/network/API';
import { ZazumeService } from '@zazume/zzm-utils';
import { I18NValues } from '../../../lib/i18n/generatedKeys';
import { ZazumeServiceModal } from '../../forms/zazumeServicesRadioGroup/atoms/ZazumeServiceModal';
import { UnitHelper } from '../../../models/helpers/unit/UnitHelper';
import { useAuth } from '../../../contexts/AuthProvider';
import { AssignUnitCard } from '../../forms/search/searchUnit/AssignUnitCard';
import { ZazumeServicesHelper } from '#/models/helpers/zazumeServices/ZazumeServicesHelper';
import { FormContent } from './components/FormContent';

const getPaidServices = (organization?: Organization, unit?: Unit): ManagementService[] => {
  return organization?.managementServices
    .filter(service => service.managementFee.value > 0)
    .filter(service => service.type !== unit?.paymentFees.zazumeService)
    .sort((a, b) => a.managementFee.value - b.managementFee.value) || [];
};

export interface RequestUnitServiceUpgradeModalProps {
}

export const RequestUnitServiceUpgradeModal: FC<RequestUnitServiceUpgradeModalProps> = () => {
  const { t, Trans } = useI18n();
  const { handleNetworkExceptions } = useNetworkExceptions();
  const { close } = useZazumeModalContext();
  const { showNotification } = useNotifications();
  const { organization } = useAuth();

  const [unit, setUnit] = useState<Unit>();
  const [targetService, setTargetService] = useState<ZazumeService>();

  const paidServices = useMemo(() => getPaidServices(organization, unit), [organization, unit]);

  const defaultValues = {
    service: paidServices[paidServices.length - 1].type
  };

  const formSchema = getRequestUnitServiceUpgradeSchema(t);
  const { resolver } = useYupValidationResolver(formSchema);
  const formMethods = useForm<RequestUnitServiceUpgradeFormInputs>({ resolver, defaultValues });
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors }
  } = formMethods;

  const { RenderModal: RenderServiceModal, open: openServiceModal, close: closeServiceModal } = useModal({ modalSize: 's' });

  const hasUnitSelected = Boolean(unit);
  const monthlyRent = unit && new UnitHelper(unit).getMonthlyRent();

  const getServicePrice = (service?: ZazumeService) => {
    if (!service || !monthlyRent || !organization) {
      return '-';
    }
    return new ZazumeServicesHelper(organization, service).getManagementPrice(monthlyRent).toString();
  };

  const onSubmitForm = async ({ unitId, service }) => {
    try {
      await API.units.requestServiceUpgrade(unitId)({ service });
      showNotification(t('modals.requestServiceUpgrade.successMessage'));
    } catch (error) {
      handleNetworkExceptions(error);
    }
    close();
  };

  const onSelectUnit = (unit: Unit) =>
    setUnit(unit);

  const onClickInfo = (service: ZazumeService) => () =>{
    if (!hasUnitSelected) {
      return;
    }
    setTargetService(service);
    openServiceModal();
  };

  return <ZazumeModal title={t('modals.requestServiceUpgrade.title')}>
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <FormProvider {...formMethods}>
        <Flex direction="column" gap="lg">
          <Flex direction="column" gap="md">
            <Regular>{t('modals.requestServiceUpgrade.selectProperty')}</Regular>
            <AssignUnitCard
              onSelect={onSelectUnit}
              unitId={unit?._id}
              errors={errors}
              register={register}
              hideLabel
              asProperty
            />
            {unit?.paymentFees.zazumeService &&
              <Alert variant="information">
                <Trans components={{ 1: <Semibold as="span" color="PrimaryDark1" variant="S"/> }}>
                  {t('modals.requestServiceUpgrade.description', {
                    price: getServicePrice(unit.paymentFees.zazumeService),
                    service: t(`zazumeServices.${unit.paymentFees.zazumeService}.name` as I18NValues)
                  })}
                </Trans>
              </Alert>
            }
          </Flex>
          {hasUnitSelected && monthlyRent && <FormContent
            onClickInfo={onClickInfo}
            paidServices={paidServices}
            hasUnitSelected={hasUnitSelected}
            monthlyRent={monthlyRent}
          />}
        </Flex>
        <ZazumeModal.Footer
          cancelButtonText={t('general.cancel')}
          acceptButtonText={t('general.request')}
          acceptButtonType="submit"
          isSubmitting={isSubmitting}
        />
      </FormProvider>
    </form>
    {unit && targetService && monthlyRent && <RenderServiceModal>
      <ZazumeServiceModal
        service={targetService}
        monthlyRent={monthlyRent.toPrimitive()}
        onClose={closeServiceModal}
      />
    </RenderServiceModal>}
  </ZazumeModal>;
};
