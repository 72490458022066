import { BigDecimal, DateVO, PriceVO } from '@zazume/zzm-utils';
import { RentAdvanceProgress } from './RentAdvanceProgress';
import { Service } from './Service';
import { IdentifierDTO } from '@zazume/zzm-base';

export const AfterBrokeMonthlyRentPaymentsTo = ['owner', 'organization'] as const;
export type AfterBrokeMonthlyRentPaymentTo = typeof AfterBrokeMonthlyRentPaymentsTo[number];

interface ConstructorParams {
  afterBrokeMonthlyRentPaymentTo?: AfterBrokeMonthlyRentPaymentTo;
  executedAt?: Date;
  fee: number;
  initialCommission?: PriceVO;
  leaseContractId: IdentifierDTO;
  monthlyRent: PriceVO;
  monthsInAdvance: number;
  progress: RentAdvanceProgress;
}

export class RentAdvanceService extends Service {
  afterBrokeMonthlyRentPaymentTo?: AfterBrokeMonthlyRentPaymentTo;
  fee: number;
  initialCommission?: PriceVO;
  leaseContractId: IdentifierDTO;
  monthsInAdvance: number;
  monthlyRent: PriceVO;
  executedAt?: Date;
  progress: RentAdvanceProgress;

  constructor(params: ConstructorParams) {
    super('RentAdvance');
    this.fee = params.fee;
    this.initialCommission = params.initialCommission;
    this.leaseContractId = params.leaseContractId;
    this.monthsInAdvance = params.monthsInAdvance;
    this.monthlyRent = params.monthlyRent;
    this.executedAt = params.executedAt;
    this.progress = params.progress;
    this.afterBrokeMonthlyRentPaymentTo = params.afterBrokeMonthlyRentPaymentTo;
  }

  static fromRaw(service: any): RentAdvanceService {
    return new RentAdvanceService({
      afterBrokeMonthlyRentPaymentTo: service.afterBrokeMonthlyRentPaymentTo,
      executedAt: service.executedAt && DateVO.fromPrimitive(service.executedAt),
      fee: service.fee,
      initialCommission: service.initialCommission && PriceVO.fromPrimitive(service.initialCommission),
      leaseContractId: service.leaseContractId,
      monthlyRent: PriceVO.fromPrimitive(service.monthlyRent),
      monthsInAdvance: service.monthsInAdvance,
      progress: new RentAdvanceProgress({
        totalFeePaid: PriceVO.fromPrimitive(service.progress.totalFeePaid),
        totalPaid: PriceVO.fromPrimitive(service.progress.totalPaid)
      })
    });
  }

  rentAssigment(): PriceVO {
    return this.calculateTotalRentAssigment();
  }

  isExecuted(): boolean {
    return Boolean(this.executedAt);
  }

  calculateTotalMonthlyRent(): PriceVO {
    return this.monthlyRent.multiply(BigDecimal.fromPrimitive(this.monthsInAdvance));
  }

  calculateTotalFee(): PriceVO {
    return this.calculateTotalMonthlyRent().percentage(this.fee);
  }

  calculateTotalRentAssigment(): PriceVO {
    const totalMonthlyRent = this.calculateTotalMonthlyRent();
    const totalFee = this.calculateTotalFee();

    let result = totalMonthlyRent.subtract(totalFee);

    if (this.initialCommission) {
      result = result.subtract(this.initialCommission);
    }

    return result;
  }

  calculateTotalBasePaid(): PriceVO {
    return this.progress.totalPaid.subtract(this.progress.totalFeePaid);
  }

  calculateTotalBasePaidPercentage(): number {
    return this.calculateTotalBasePaid()
      .multiply(BigDecimal.fromPrimitive(100))
      .divide(BigDecimal.fromPrimitive(this.calculateTotalMonthlyRent().toNumber()))
      .toNumber();
  }

  calculateTotalFeePaidPercentage(): number {
    return this.progress.totalFeePaid
      .multiply(BigDecimal.fromPrimitive(100))
      .divide(BigDecimal.fromPrimitive(this.calculateTotalMonthlyRent().toNumber()))
      .toNumber();
  }

  isLinkedToLease(): boolean {
    return Boolean(this.leaseContractId);
  }

  canBeDeleted(): boolean {
    return !this.isLinkedToLease();
  }
}
