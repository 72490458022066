import React, { FC } from 'react';
import { BaseSelectHeader, Label, SelectHeader, useModal, ZZMForm } from '@zazume/zzm-base';
import { AlgoliaSearcher } from '#/components/searcher/AlgoliaSearcher';
import styled from '@emotion/styled';
import { City } from '#/models/location/City';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface SearchUnitInputProps {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  onSelectCity?: (city: City) => void;
  modalTitle?: string;
}

export const SearchLocationInput: FC<SearchUnitInputProps> = ({ label, name, disabled, placeholder, onSelectCity, modalTitle }) => {
  const { watch, setValue, formState: { errors } } = ZZMForm.useFormContext();
  const { open, RenderModal, close } = useModal({ modalSize: 's' });

  const handleOnSelectCity = (city: City) => {
    setValue(name, city.name);
    onSelectCity?.(city);
    close();
  };

  const cityName = watch(name);

  return <Container>
    <Label>{label}</Label>
    {cityName && <SelectHeader onClick={open} selectedOptionName={cityName} isLocked={disabled}/>}
    {!cityName && <BaseSelectHeader onClick={open} error={errors[name]} disabled={disabled} placeholder={placeholder} variant="normal"/>}
    <RenderModal>
      <AlgoliaSearcher
        title={label || modalTitle}
        placeholder={placeholder}
        entity="location"
        onSelect={handleOnSelectCity}
        hide={close}
        suffix="ES" // TODO: Change for countryCode variable
        indexName="cities"/>
    </RenderModal>
  </Container>;
};
