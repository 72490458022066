/**
 * @deprecated use base DeviceHelper instead
 */
export type OS = 'Unknown' | 'MacOS' | 'iOS' | 'Windows' | 'Android' | 'Linux';

/**
 * @deprecated use base DeviceHelper instead
 */
export const isAppleDevice = () => {
  const os = getOS();
  return os === 'MacOS' || 'iOS';
};

/**
 * @deprecated use base DeviceHelper instead
 */
const getOS = () => {
  const userAgent = window.navigator.userAgent;
  let os: OS = 'Unknown';

  const isIOS = (/iPad|iPhone|iPod/.test(userAgent) ||
    (/Mac|Mac OS|MacIntel/gi.test(userAgent) && (navigator.maxTouchPoints > 1 || 'ontouchend' in document))) && !(window as any).MSStream;

  if (isIOS) {
    os = 'iOS';
  } else if (/Macintosh|Mac|Mac OS|MacIntel|MacPPC|Mac68K/gi.test(userAgent)) {
    os = 'MacOS';
  } else if (/'Win32|Win64|Windows|Windows NT|WinCE/gi.test(userAgent)) {
    os = 'Windows';
  } else if (/Android/gi.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/gi.test(userAgent)) {
    os = 'Linux';
  }

  return os;
};

/**
 * @deprecated use base DeviceHelper instead
 */
export const useOS = () => ({
  getOS,
  isAppleDevice
});
