import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { CheckCircle, FCC } from '@zazume/zzm-base';
import { hasFieldError } from '../../logic/errors';

const Label = styled.label`
  max-width: 100%;
`;

const Content = styled.div<any>(({ theme, hasError, variant, disabled, isSelected }) => css`
  position: relative;
  font-size: ${theme.fontsSizes.base};
  line-height: ${variant === 'M' ? '140%' : '70%'};
  font-weight: ${theme.fontWeights.normal};
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  border: ${theme.borderWeights.light} solid;
  user-select: none;

  color: ${theme.colors.Gray500};
  ${isSelected && `color: ${theme.colors.Primary};`}
  ${hasError && `color: ${theme.colors.Red};`}

  border-color: ${theme.colors.Gray300};
  ${isSelected && `border-color: ${theme.colors.Primary};`}
  ${hasError && `border-color: ${theme.colors.Red};`}

  background: ${theme.colors.White};
  ${isSelected && `background: ${theme.colors.PrimaryLight4};`}
  ${hasError && `background: ${theme.colors.Redlight4};`}

  width: fit-content;
  border-radius: ${theme.radius.lg};
  padding: ${theme.spacing.s4} ${theme.spacing.s8};
  opacity: ${disabled ? 0.5 : 1};

  max-width: 100%;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;

  cursor: pointer;

  &:hover {
    background: ${theme.colors.PrimaryLight4};
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    padding-top: ${variant === 'M' ? theme.spacing.s8 : theme.spacing.s6};
    padding-bottom: ${variant === 'M' ? theme.spacing.s8 : theme.spacing.s6};
  }
`);

const IconContainer = styled.div(() => css`
  position: absolute;
  top: -8px;
  right: -8px;
    `
);

export interface CardProps {
  value: string;
  type?: 'checkbox' | 'radio';
  variant?: 'S' | 'M';
  showCheckIcon?: boolean;
  disabled?: boolean;
  name: string;
}

export type CardInputTemplate = FC<CardProps>;

export const CardInput: FCC<CardProps> = (props) => {
  const { name, value, children, type, variant = 'M', showCheckIcon = false, disabled = false } = props;
  const { register, formState: { errors }, watch } = useFormContext();
  const id = name + '_' + value;

  const hasError = hasFieldError(name, errors);
  const selectedValue = watch(name);
  const isSelected = selectedValue === value;

  return <Label htmlFor={id} >
    <input ref={register} name={name} id={id} type={type} value={value} disabled={disabled} readOnly hidden/>
    <Content hasError={hasError} isSelected={isSelected} variant={variant} disabled={disabled}>
      {children}
      {isSelected && showCheckIcon && <IconContainer>
        <CheckCircle color="Blue"></CheckCircle>
      </IconContainer>}
    </Content>
  </Label>;
};
