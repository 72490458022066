import React from 'react';
import styled from '@emotion/styled';
import { Shadows } from '../../../styles/Base';

const Container = styled.img<any>`
  box-shadow: ${Shadows.SHADOW_3};
  border-radius: 8px;
  background-size: cover;
  height: ${props => props.imageHeight ? props.imageHeight + 'px' : 'auto'};
  width: ${props => props.imageWidth ? props.imageWidth + 'px' : 'auto'};
  object-fit: contain;
`;

export interface ImagePreviewProps {
  imageData: string;
  imageWidth?: number;
  imageHeight?: number;
}

export const ImagePreview = React.forwardRef<any, ImagePreviewProps>((props, ref) => {
  const { imageData, imageHeight, imageWidth, ...rest } = props;

  return <Container ref={ref}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      src={imageData}
      {...rest}/>;
});
