import { IdentifierDTO } from '@zazume/zzm-base';

export type ActorRole = |
  'agent' |
  'buildingManager' |
  'entityOwnerAccount' |
  'individualOwnerAccount' |
  'zazume' |
  'admin' |
  'tenant';

interface ConstructorParams {
  avatar?: string;
  email: string;
  _id: IdentifierDTO;
  name: string;
  role: ActorRole;
}

export class Actor {
  _id: IdentifierDTO;
  avatar?: string;
  email: string;
  name: string;
  role: ActorRole;

  constructor(params: ConstructorParams) {
    this._id = params._id;
    this.avatar = params.avatar;
    this.email = params.email;
    this.name = params.name;
    this.role = params.role;
  }
}
