import { FC } from 'react';
import { API } from '../../../../../lib/network/API';
import { useQueryClient } from 'react-query';
import { IdentifierDTO } from '@zazume/zzm-base';
import { useUpdate } from '../../../../../lib/reactQuery/hooks/useUpdate';
import { UpdateDocumentRequest } from '../../../../../lib/network/api/documents/requests/UpdateDocumentRequest';
import { DocumentsKeys } from '../../../../../hooks/api/documents/Documents.keys';
import { UpdateDocumentModal } from '../../../../../components/modal/UpdateDocumentModal';
import { Document } from '../../../../../models';
import { ProvidersKeys } from '#/hooks/api/providers/Providers.keys';

interface UpdateDocumentWrapperProps {
  document: Document;
  onClose: () => void;
  providerId: IdentifierDTO;
}

export const UpdateDocumentWrapper: FC<UpdateDocumentWrapperProps> = ({ document, onClose, providerId }) => {
  const queryClient = useQueryClient();

  const { mutate: updateDocument } = useUpdate<void, UpdateDocumentRequest>(
    DocumentsKeys.byId(document._id),
    API.providers.updateDocument(providerId));

  const onAfterUpdate = async () =>
    await queryClient.invalidateQueries(ProvidersKeys.getDocuments(providerId));

  return (
    <UpdateDocumentModal
      document={document}
      relatedModel="Provider"
      onUpdate={updateDocument}
      onAfterUpdate={onAfterUpdate}
      close={onClose}
      withHiddenFromOwner={false}
    />
  );
};
