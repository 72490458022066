import { useI18n } from '#hooks/useI18n';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { LeftArrow, PrimaryButton, RightArrow, Shadows } from '@zazume/zzm-base';
import { DateVO, formatDate, getDecimals, getHour, getMinutes, getMinutesFromFloat, getWeekday, setDateTime, Timezone } from '@zazume/zzm-utils';
import React, { FC, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { useClickOutside } from '../../hooks/useClickOutside';
import { Input, OptionType, Select } from '../forms';
import { Separator } from '../other/Separator';


const Container = styled.div<any>(({ theme, position, show }) => css`
  width: 312px;
  border-radius: 8px;
  box-shadow: ${Shadows.SHADOW_3};
  position: absolute;
  ${position === 'bottom' ? 'top: 42px;' : 'bottom: 44px; right: 0;'}
  z-index: 10;
  padding: 0 16px 20px;
  background: ${theme.colors.White};
  display: ${show ? 'block' : 'none'};
`);

const CustomSeparator = styled(Separator)`
  margin: 16px 0;
`;

const CustomCalendar = styled(Calendar)<any>(({ theme }) => css`
  border-radius: 8px;

  * {
    color: ${theme.colors.Gray500};
    font-family: ${theme.fonts.base};
    font-weight: 600;
    font-style: normal;
  }

  .react-calendar {
    &__tile {
      border: none;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      background: none;
      flex-basis: initial !important;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0;
      color: ${theme.colors.Gray600};

      :focus {
        outline: none;
      }

      :hover {
        cursor: pointer;
        background: ${theme.colors.PrimaryLight4};
      }

      &--active {
        background: ${theme.colors.PrimaryLight4};
        border-radius: 20px;

        * {
          color: ${theme.colors.Primary};
        }

        :hover {
          background: ${theme.colors.PrimaryLight4};
        }
      }
    }

    &__navigation {
      justify-content: space-between;
      align-items: center;
      height: 32px;
      width: 280px;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 24px;

      &__label {
        background: none;
        border: none;
        font-size: 20px;
        line-height: 32px;
        cursor: pointer;
        flex-grow: initial;

        span {
          color: ${theme.colors.Gray600};
        }

        :focus {
          outline: none;
        }
      }

      &__prev-button,
      &__next-button {
        border: none;
        background: none;

        :hover {
          cursor: pointer;
        }

        :disabled {
          visibility: hidden;
        }

        :focus {
          outline: none;
        }
      }

      &__prev2-button,
      &__next2-button {
        display: none;
      }
    }

    &__month-view {
      &__weekdays {
        * {
          text-align: center;
          text-decoration: none;
          user-select: none;
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          letter-spacing: 0;
          color: ${theme.colors.Gray500};
          white-space: pre-wrap;
        }
      }

      &__days__day {
        &:disabled {
          background: none;
          cursor: default;

          * {
            color: ${theme.colors.Gray300}
          }
        }

        &--neighboringMonth {
          visibility: hidden;
        }
      }
    }

    &__year-view__months__month,
    &__decade-view__years__year {
      width: 45px;
      height: 30px;

      :disabled {
        cursor: default;

        * {
          color: ${theme.colors.Gray300}
        }

        :hover {
          background: none;
        }
      }
    }
  }
`);

export type CalendarPositions = 'top' | 'bottom';

interface TimeSelectorOption {
  value: number;
  name: string;
}

export const Weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Weekdays', 'Weekend'];
export type Weekday = typeof Weekdays[number];

export type SelectableHours = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24;
export type IntervalTimePickerType = 'full' | 'half' | 'quarter';

export interface CalendarComponentProps {
  snooze: number;
  onSelect: any;
  isVisible: boolean;
  position?: CalendarPositions;
  hide?: () => void;
  selectHour?: boolean;
  confirmationButtonText?: string;
  name?: string;
  register?: any;
  setValue?: any;
  minDate?: Date;
  maxDate?: Date;
  startingHour?: SelectableHours;
  totalSelectableHours?: SelectableHours;
  interval?: IntervalTimePickerType;
  autoSave?: boolean;
  disabledDays?: Weekday[];
  onRemove?: any;
  timezone?: string;
}

const STARTING_HOUR = 9;
const TOTAL_HOURS_A_DAY = 9;

const getOptions = (startingHour: number, totalSelectableHours: number, interval): TimeSelectorOption[] => {
  const options: TimeSelectorOption[] = [];
  if (interval !== 'full') {
    const intervalIncrement = interval === 'quarter' ? 0.25 : 0.5;
    for (let hour = startingHour; hour <= startingHour + totalSelectableHours; hour = hour + intervalIncrement) {
      options.push({ value: hour, name: getOptionName(hour) });
    }
  } else {
    for (let hour = startingHour; hour < startingHour + totalSelectableHours; hour++) {
      options.push({ value: hour, name: hour + ':00' });
    }
  }
  return options;
};

const getOptionName = (time: number) =>
  Math.trunc(time) + ((getDecimals(time) > 0) ? `:${getMinutesFromFloat(time)}` : ':00');

const getDisabledDays = (userSelection?: Weekday[]) => ({ date, view }) => {
  if (!userSelection || view === 'year') {
    return undefined;
  }
  const weekday = getWeekday(date);
  let finalSelection = userSelection;

  if (userSelection.includes('Weekend')) {
    finalSelection = finalSelection.concat(['Saturday', 'Sunday']);
  }

  if (userSelection.includes('Weekdays')) {
    finalSelection = finalSelection.concat(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']);
  }

  return finalSelection.includes(weekday);
};

//TODO Update this component to a default behavior where it returns all hours. US#790
export const CalendarComponent: FC<CalendarComponentProps> = (({
  snooze,
  onSelect,
  isVisible,
  position = 'bottom',
  hide,
  selectHour = true,
  confirmationButtonText,
  name,
  register,
  setValue,
  minDate,
  maxDate,
  startingHour = STARTING_HOUR,
  totalSelectableHours = TOTAL_HOURS_A_DAY,
  interval = 'full',
  autoSave = true,
  disabledDays,
  onRemove,
  timezone
}) => {
  const { t, languageVO } = useI18n();
  const containerRef = useRef();
  useClickOutside(containerRef, () => hide && isVisible && hide());
  const [date, setDate] = useState<Date>(new Date(snooze));
  const [hour, setHour] = useState<number>(getHour(new Date(snooze)));
  const timeOptions: TimeSelectorOption[] = getOptions(startingHour, totalSelectableHours, interval);

  const minutes = getMinutes(date);

  const getNextAvailableTime = (availableTimesInAnHour: number, minuteInterval: number) => (hour - startingHour) <= totalSelectableHours ? timeOptions[(hour - startingHour) * availableTimesInAnHour + Math.ceil(minutes / minuteInterval)] : timeOptions[0];

  const getDefaultTime = () => {
    switch (interval) {
      case 'full':
        return getNextAvailableTime(1, 60);
      case 'half':
        return getNextAvailableTime(2, 30);
      case 'quarter':
        return getNextAvailableTime(4, 15);
    }
  };

  const updateDate = (newDate: Date | null) => {
    const tzNewDate = timezone && newDate ? DateVO.fromDateAsTimezone(newDate, Timezone.fromPrimitive(timezone as any)).toPrimitive() : newDate;
    onSelect(tzNewDate);
    if (name) {
      setValue?.(name, tzNewDate, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  const setDateHour = (newDate: Date, newHour: number) => {
    const newValue = setDateTime(newDate, newHour, getMinutesFromFloat(newHour));
    setDate(newValue);
    if (autoSave) {
      updateDate(newValue);
    }
  };

  const onSelectTime = ({ value }: OptionType) => {
    setHour(value);
    setDateHour(date, value);
  };

  const onSelectDate = (newDate: Date) => {
    setDateHour(newDate, hour);
  };

  const onRescheduleTask = () => {
    updateDate(date);
    hide?.();
  };

  const onClearDate = () => {
    updateDate(null);
    onRemove();
    hide?.();
  };

  return <Container show={isVisible} position={position} ref={containerRef}>
    <CustomCalendar
      onChange={onSelectDate}
      value={date}
      minDate={minDate}
      maxDate={maxDate}
      show={isVisible}
      nextLabel={<RightArrow color="Gray400"/>}
      prevLabel={<LeftArrow color="Gray400"/>}
      formatShortWeekday={(locale, date) => formatDate(date, 'dd')}
      formatMonth={(locale, date) => formatDate(date, 'MMM')}
      minDetail="year"
      locale={languageVO.toPlatform()}
      tileDisabled={getDisabledDays(disabledDays)}
    />
    {selectHour &&
      <>
        <CustomSeparator/>
        <Select
          name="category"
          aria-haspopup="listbox"
          defaultValue={getDefaultTime()}
          options={timeOptions}
          onSelected={onSelectTime}
          headerIcon="clock"
          verticalPosition="top"
        />
      </>
    }
    {!autoSave &&
      <>
        <CustomSeparator/>
        <PrimaryButton
          size="Large"
          onClick={onRescheduleTask}
          type="button"
          block
        >{confirmationButtonText ?? t('general.save')}</PrimaryButton>
      </>
    }
    {onRemove &&
      <>
        <CustomSeparator/>
        <PrimaryButton
          size="Large"
          onClick={onClearDate}
          type="button"
          block
        >{t('sepa.filters.clear')}</PrimaryButton>
      </>
    }
    {name && register && setValue && <Input type="file" {...register(name)} hidden id={name}/>}
  </Container>;
});
