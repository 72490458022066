import React, { FC } from 'react';
import { Autocomplete } from './atoms/Autocomplete';
import { IndexName } from '../../utils/algolia/algoliaHelper';
import { ResultItem, ResultItemBase } from './atoms/ResultItem';
import { useSearchContext } from '../../contexts/SearchProvider';

// TODO move to emotion ASAP
import './style.css';
import { IdentifierDTO } from '@zazume/zzm-base';

interface SearchBarProps {
  indexes: IndexName[] | IndexName;
  maxResults?: number;
  renderItemsAs?: ResultItemBase<{}>;
  organizationId: IdentifierDTO;
  placeholder?: string;
}

const toArray = (items: IndexName | IndexName[]): IndexName[] =>
  !Array.isArray(items) ? [items] : items;

export const SearchBar: FC<SearchBarProps> = ({ indexes, renderItemsAs = ResultItem, organizationId, maxResults, placeholder }) => {
  const { searchClient, indexPrefix } = useSearchContext();

  if (!searchClient) {
    return null;
  }

  return <Autocomplete
    openOnFocus={true}
    searchClient={searchClient}
    organizationId={organizationId}
    indexPrefix={indexPrefix}
    indexes={toArray(indexes)}
    renderItemsAs={renderItemsAs}
    placeholder={placeholder}
  />;
};
