import React from 'react';
import { UnitCell } from './UnitCell';
import { TenantCell } from './TenantCell';
import { ApplicationCell } from './ApplicationCell';
import { OwnerCell } from './OwnerCell';
import { IdentifierDTO } from '@zazume/zzm-base';
import { Private } from '../../../containers/router/routes/Private';
import { ZazumeRoute } from '../../../containers/router/routes/types';
import { EntityCell } from './EntityCell';
import { AgentCell } from './AgentCell';

type SearchEntity = 'agent' | 'unit' | 'tenant' | 'application' | 'owner' | 'entity';

interface BaseSearchElement {
  objectID: IdentifierDTO;
  name: string;
  type: SearchEntity;
  organization: IdentifierDTO;
  unitAddress?: string;
  t: any;
}

interface ResultItemProps {
  hit: BaseSearchElement;
  components: any;
  t: any
}

export type ResultItemBase<T> = React.FC<T & ResultItemProps>;

export const ResultGoToItem = (hit: BaseSearchElement): ZazumeRoute => {
  switch (hit.type) {
    case 'agent':
      return Private.AGENT_DETAILS.to(hit.objectID);
    case 'unit':
      return Private.UNITS_DETAILS.to(hit.objectID);
    case 'tenant':
      return Private.TENANTS_DETAILS.to(hit.objectID);
    case 'application':
      return Private.ONBOARDING_DETAIL.to(hit.objectID);
    case 'owner':
      return Private.OWNER_ACCOUNT_DETAILS.to(hit.objectID);
    case 'entity':
      return Private.ENTITY_DETAILS.to(hit.objectID);
  }
};

export const ResultItem: ResultItemBase<{}> = ({ hit, components, t }) => {
  return <>
    {hit.type === 'unit' && <UnitCell hit={hit} components={components} t={t}/>}
    {hit.type === 'tenant' && <TenantCell hit={hit} components={components} t={t}/>}
    {hit.type === 'application' && <ApplicationCell hit={hit} components={components} t={t}/>}
    {hit.type === 'agent' && <AgentCell hit={hit} components={components} t={t}/>}
    {hit.type === 'owner' && <OwnerCell hit={hit} components={components} t={t}/>}
    {hit.type === 'entity' && <EntityCell hit={hit} components={components} t={t}/>}
  </>;
};
