import styled from '@emotion/styled';
import { FCC } from '@zazume/zzm-base';
import React from 'react';

const Container = styled.div`
  padding: 16px 0;
`;

const FullscreenContainer = styled.div`
  max-width: 856px;
  padding: 8px 24px;
  margin: 0 auto;
`;

/**
 * @deprecated use zzm-base modals
 * @param children
 * @param rest
 * @constructor
 */
export const Body: FCC<any> = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

/**
 * @deprecated use zzm-base modals
 * @param children
 * @param rest
 * @constructor
 */
export const FullscreenBody: FCC<any> = ({ children, ...rest }) => {
  return <FullscreenContainer {...rest}>{children}</FullscreenContainer>;
};
