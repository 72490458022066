import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps, getStrokeWidth } from './index';

/**
 * @deprecated do not use it
 * @param color
 * @param size
 * @constructor
 */
export const Welcome: FC<IconProps> = ({ color = Colors.FONT_3, size = 20 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" d="M7.66634 19.3995L13.1684 21.1299C13.8229 21.3357 14.5281 21.311 15.1665 21.0599L19.5305 19.3435C19.8114 19.2329 20.0363 18.9999 20.0452 18.6982C20.0576 18.2764 19.9393 17.6769 19.2555 17.3614C18.1303 16.842 16.1799 17.0337 16.1799 17.0337M10.7823 16.1682L12.5324 16.7151C13.2083 16.9263 13.9123 17.0337 14.6204 17.0337H16.1799M5.84168 12.3597L8.7664 12.3851C9.31353 12.3851 9.85484 12.4973 10.3569 12.7149L14.9393 14.7006C15.6285 14.9993 16.0922 15.6589 16.14 16.4085L16.1799 17.0337M8.59591 9.85762V7.94249C8.59591 7.65487 8.71975 7.38118 8.9358 7.19132L13.7278 2.98027C14.1053 2.6485 14.6704 2.6485 15.048 2.98027L19.84 7.19132C20.056 7.38118 20.1799 7.65487 20.1799 7.94249V14.2543M12.3879 8.31451V9.31451M16.3879 8.31451V9.31451M4.81982 20.7845H6.67057C7.22285 20.7845 7.67057 20.3367 7.67057 19.7845V13.3597C7.67057 12.8075 7.22285 12.3597 6.67057 12.3597H4.81982C4.26754 12.3597 3.81982 12.8075 3.81982 13.3597V19.7845C3.81982 20.3367 4.26754 20.7845 4.81982 20.7845Z"/>
  </svg>;
};
