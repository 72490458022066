import { BaseRoute } from '#/containers/router/routes/types';

const PublicRoutesIdentifiers = <const>[
  'AGENT_VIEWING_FEEDBACK',
  'DIRECT_DEBIT_DATA_CONFIRMATION',
  'DOWNLOAD_APP',
  'LOGOUT',
  'PAYMENTS_CHECKOUT',
  'REVIEW',
  'REVIEW_VIEWING',
  'SIGNUP',
  'SIGNUP_WITH_SLUG'
];

type PublicRoutesType = typeof PublicRoutesIdentifiers[number];

export const Public = {
  DIRECT_DEBIT_DATA_CONFIRMATION: {
    route: '/direct-debit-confirmation'
  },
  DOWNLOAD_APP: {
    route: '/download-app'
  },
  PAYMENTS_CHECKOUT: {
    route: '/payments/checkout'
  },
  REVIEW: {
    route: '/review'
  },
  REVIEW_VIEWING: {
    route: '/review-viewing'
  },
  AGENT_VIEWING_FEEDBACK: {
    route: '/agent-viewing-feedback'
  },
  SIGNUP: {
    route: '/signup'
  },
  SIGNUP_WITH_SLUG: {
    route: '/signup/:slug'
  },
  LOGOUT: {
    route: '/logout'
  }
} satisfies Record<PublicRoutesType, BaseRoute>;
