import { IBAN, PhoneVO } from '@zazume/zzm-utils';
import * as yup from 'yup';
import { ProviderService } from '../../../../../models';

export const getProviderSchema = (t) => {
  const requiredMessage = t('general.forms.mandatory');
  return yup.object().shape({
    name: yup.string().required(requiredMessage).trim().min(3, t('errorMessages.length', { length: 3 })),
    phone: yup.string()
      .transform(str => str.replace(/ /g, ''))
      .test('is-invalid-phone', t('general.forms.invalid'), (value) => value ? PhoneVO.isValid(value) : true),
    email: yup.string().email(t('general.forms.emailError')).transform(value => value?.trim().length ? value : undefined),
    idNumber: yup.string(),
    address: yup.string(),
    iban: yup.string().test('test-iban-number-length', t('general.forms.mandatory'), function (value) {
      const { path, createError } = this;

      if (!value) {
        return true;
      }

      const ibanCheck = IBAN.isValid(value);
      if (!ibanCheck) {
        return createError({ path, message: t('general.forms.ibanNotValid') });
      }
      return true;
    }).transform(value => value?.trim().length ? value : undefined),
    services: yup.object({})
  });
};

export interface ProviderFormInputs {
  name: string;
  phone?: string;
  email?: string;
  iban?: string;
  idNumber?: string;
  fullAddress?: string;
  services: {
    [key in ProviderService]?: boolean;
  };
}
