import { FC } from 'react';
import { getStrokeWidth, IconProps } from '.';
import { Colors } from '@zazume/zzm-base';

export const Minus: FC<IconProps> = ({ color = Colors.FONT_3, size = 24 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinejoin="round"
        strokeLinecap="round"
        d="M0.799805 1H15.1998"
    />
  </svg>;
};
