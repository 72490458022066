import React from 'react';
import { ResultGoToItem, ResultItemBase } from './ResultItem';
import styled from '@emotion/styled';
import { Welcome } from '../../icons/Welcome';

const SmallDetails = styled.div`
  font-size: 12px;
`;

export const ApplicationCell: ResultItemBase<{}> = ({ hit, components }) => {
  return <a href={ResultGoToItem(hit)} className="aa-ItemLink">
    <div className="aa-ItemContent">
      <Welcome/>
      <div className="aa-ItemTitle">
        <components.Highlight hit={hit} attribute="name"/>
        <SmallDetails>
          <components.Highlight hit={hit} attribute="unitAddress"/>
        </SmallDetails>
        <SmallDetails>
          <components.Highlight hit={hit} attribute="phoneNumber"/>
        </SmallDetails>
      </div>
    </div>
  </a>;
};
