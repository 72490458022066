import { IdentifierDTO } from '@zazume/zzm-base';

interface PaginationOptions {
  minAmount?: string;
  maxAmount?: string;
  minProfitability?: string;
  cityId?: IdentifierDTO;
  minRentAmount?: string;
  maxRentAmount?: string;
  minRooms?: string;
  unitCondition?: string;
  advertiserType?: string;
  characteristics?: string;
}

export const InvestmentOpportunityKeys = {
  base: ['investmentOpportunity'],
  byPagination: (options: PaginationOptions) => [
    ...InvestmentOpportunityKeys.base,
    options.minAmount && `min_a_${options.minAmount}`,
    options.maxAmount && `max_a_${options.maxAmount}`,
    options.minProfitability && `mp_${options.minProfitability}`,
    options.cityId && `c_${options.cityId}`,
    options.minRentAmount && `min_ra_${options.minRentAmount}`,
    options.maxRentAmount && `max_ra_${options.maxRentAmount}`,
    options.minRooms && `min_ro_${options.minRooms}`,
    options.unitCondition && `uc_${options.unitCondition}`,
    options.advertiserType && `at_${options.advertiserType}`,
    options.characteristics && `ch_${options.characteristics}`
  ].filter(Boolean) ?? [],
  byId: (valuationId: IdentifierDTO) => [...InvestmentOpportunityKeys.base, valuationId],
  activeBooking: (plan: string) =>
    [...InvestmentOpportunityKeys.base, 'service-type-booking', plan]
};
