import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router';
import { RouteContainer } from '../../containers/router/components/RouteContainer';

export interface Props extends RouteProps {
}


export const AnonymousRoute: FC<Props> = (props) => {
  // if (isAuthenticated) {
  //   if (forceLogout) {
  //     console.log('ERROR logout!');
  //     return <div>AnonymousRouter</div>;
  //   }
  //   return <Navigate to="/"/>;
  // }

  return <Route {...props} element={<RouteContainer body={props.element}/>}/>;
};
