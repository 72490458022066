import { DEFAULT_STARTING_PAGE, DEFAULT_TABLE_LENGTH, PaginationFetcher, UseAPIResult, useGet, UsePaginationOptions } from '#/hooks';
import { QueryKey } from 'react-query';
import { useURLParams } from '#hooks/useURLParams';
import { useState } from 'react';

export interface UseAPIResultWithPagination<T> extends UseAPIResult<T> {
  nextPage: () => void;
  previousPage: () => void;
  toPage: (newPage: number) => void;
  currentPage: number;
  usedQueryKey: string[];
}

export const usePagination = <T extends any>(uniqueKey: QueryKey, paginationFetcher: PaginationFetcher<T>, options: UsePaginationOptions = {}): UseAPIResultWithPagination<T> => {
  const { getParam, setParam } = useURLParams();
  const { page, size = DEFAULT_TABLE_LENGTH, urlLinked = true, urlParam = 'page', ...rest } = options;

  const currentURLPage = urlLinked ? Number(getParam(urlParam)) : undefined;

  const [currentPage, setCurrentPage] = useState<number>(page || currentURLPage || DEFAULT_STARTING_PAGE);

  if (currentURLPage !== currentPage) {
    currentURLPage && setCurrentPage(currentURLPage);
  }

  // TODO force to use uniqueKey as unknown[] (migration to the new version of react-query)
  const key = Array.isArray(uniqueKey) ? [...uniqueKey, { currentPage }] : [uniqueKey, { currentPage }];
  const { data, isLoading, error, isError, refetch, status } = useGet(key, paginationFetcher({ page: currentPage, size }), {
    ...rest
  });

  const nextPage = () => {
    if (urlLinked) {
      setParam(urlParam, (currentPage + 1).toString());
    }
    setCurrentPage(currentPage + 1);
  };
  const previousPage = () => {
    if (urlLinked) {
      setParam(urlParam, (Math.max(currentPage - 1, 1)).toString());
    }
    setCurrentPage(currentPage - 1);
  };

  const toPage = (newPage: number) => {
    if (urlLinked) {
      setParam(urlParam, newPage.toString());
    }
    setCurrentPage(newPage);
  };

  return {
    data,
    isLoading,
    error,
    refetch,
    nextPage,
    previousPage,
    toPage,
    isError,
    status,
    currentPage,
    usedQueryKey: key as string[]
  };
};
