import React, { FC } from 'react';
import { Semibold, Settings } from '@zazume/zzm-base';
import { useI18n } from '../../../../../hooks/useI18n';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.sm};
  box-shadow: ${theme.shadows.s1};
`);


export const Header: FC = () => {
  const { t } = useI18n();

  return <Container>
    <Semibold variant="M">{t('notifications.title')}</Semibold>
    {false && <Settings color="Gray400" size={20}/>}
  </Container>;
};
