import { PriceVO } from '@zazume/zzm-utils';

interface ConstructorParams {
  value?: number;
  absolutePrice?: PriceVO;
  type: ManagementFeeType;
}

export class ManagementFee {
  value?: number;
  absolutePrice?: PriceVO;
  type: ManagementFeeType;

  constructor(params: ConstructorParams){
    this.value = params.value;
    this.absolutePrice = params.absolutePrice;
    this.type = params.type;
  }

  static fromRaw(commercialisationFee: any): ManagementFee {
    return new ManagementFee({
      value: commercialisationFee.value,
      absolutePrice: commercialisationFee.absolutePrice && PriceVO.fromPrimitive(commercialisationFee.absolutePrice),
      type: commercialisationFee.type
    });
  }
}

export const ManagementFeeTypes = <const>['absolute', 'percentage'];
export type ManagementFeeType = typeof ManagementFeeTypes[number];
