import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Regular } from '@zazume/zzm-base';
import { FC } from 'react';


// TODO: Use new link component from zzm-base
const Link = styled.span(({ theme }) => css`
  color: ${theme.colors.Primary};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 77px;
`;

interface Props {
  text: string;
  linkText?: string;
  onClick?: () => void;
}

export const EmptyState: FC<Props> = ({ text, linkText, onClick }) => {
  return (
    <Container>
      <Regular variant="S" color="Gray500">
        {text} <Link onClick={onClick}>{linkText}</Link>
      </Regular>
    </Container>
  );
};
