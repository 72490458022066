import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from '.';

export const Magnifier: FC<IconProps> = ({ color = '#FFFFFF', size = 24 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        d="M14.8521 14.9212C12.3079 17.4655 8.18288 17.4655 5.63865 14.9212C3.09441 12.377 3.09441 8.25198 5.63865 5.70774C8.18288 3.1635 12.3079 3.1635 14.8521 5.70774C17.3964 8.25198 17.3964 12.377 14.8521 14.9212ZM14.8521 14.9212L20.3633 20.4324"
    />
  </svg>;
};
