import React, { FC } from 'react';
import { Flex, IdentifierDTO, Semibold, ZZMForm, ZazumeModal, useZazumeModalContext } from '@zazume/zzm-base';
import { useI18n } from '#/hooks/useI18n';
import { Signer } from '#/models/contract/Signer';
import { EditSignerModalFormData, getEditSignerSchema } from './EditSignerModal.schema';
import { EditSignerModalFooter } from './EditSignerModalFooter';
import { useUpdate } from '#/lib/reactQuery/hooks/useUpdate';
import { API } from '#/lib/network/API';
import { DocumentsKeys } from '#/hooks/api/documents/Documents.keys';
import { useAsyncMethod } from '#/hooks/useAsyncMethod';

export interface EditSignerModalProps {
  documentId: IdentifierDTO;
  signer: Signer;
  onAfterUpdate?: () => Promise<void>;
}

export const EditSignerModal: FC<EditSignerModalProps> = ({ documentId, signer, onAfterUpdate }) => {
  const { t } = useI18n();
  const { call } = useAsyncMethod();
  const { close } = useZazumeModalContext();

  const { mutate: updateSigner } = useUpdate<void, EditSignerModalFormData>(
    DocumentsKeys.byId(documentId),
    API.documents.updateSignerEmail(documentId, signer.signerExternalIds![0].externalId!), { isOptimistic: false });

  const formSchema = getEditSignerSchema(t);
  const defaultValues = { email: signer.email };

  const onEdit = async (formData: EditSignerModalFormData) => call(async () => {
    await updateSigner(formData);
    await onAfterUpdate?.();
    close();
  });

  return (
    <ZazumeModal title={t('signatures.editSigner')}>
      <ZZMForm.Form validation={formSchema} onSubmit={onEdit} defaultValues={defaultValues}>
        <Flex direction="column" gap="s5">
          <Semibold variant="M">{signer.name}</Semibold>
          <ZZMForm.Input name="email" label={t('general.email')} responsive={false}/>
        </Flex>
        <EditSignerModalFooter/>
      </ZZMForm.Form>
    </ZazumeModal>
  );
};
