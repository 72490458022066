import styled from '@emotion/styled';
import { Colors, FCC, Fonts } from '@zazume/zzm-base';
import React from 'react';

const Container = styled.div`
  width: 100%;
  font-family: ${Fonts.BASE};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0;
  color: ${Colors.FONT_3};
`;

export const Headline2: FCC<any> = (props) => {
  return <Container {...props}>{props.children}</Container>;
};
