import { PaginationOptions } from '#/hooks';
import { AutoReconciliation, BasicUnit } from '#/models';
import { doDelete, doGet, EndpointBuilder, toPaginatedURL } from '#lib/network/helpers';
import { IdentifierDTO } from '@zazume/zzm-base';

export const autoReconciliations = {
  getPaginated: (params: EndpointBuilder) => (pagination: PaginationOptions) => () =>
    doGet<AutoReconciliation[]>(toPaginatedURL(`/dashboard/auto-reconciliations${params.url}${params.queryParams}`, pagination)),
  getUnits: () =>
    doGet<BasicUnit[]>('/dashboard/auto-reconciliations/units'),
  cancel: (autoReconciliationId: IdentifierDTO) =>
    doDelete(`/dashboard/auto-reconciliations/${autoReconciliationId}/cancel`)
};
