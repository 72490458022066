import { ErrorModal } from '../../components/modal/errorModal/ErrorModal';
import { useNotifications } from '../../hooks/useNotifications';

export const ModalContainer = () => {
  const { notifications } = useNotifications();
  const modalNotifications = notifications?.filter(n => n.options.type === 'error');

  if (!modalNotifications.length) {
    return null;
  }

  return <>
    {modalNotifications.map(notification => (
      <ErrorModal
        key={notification.id}
        id={notification.id}
        message={notification.message}
      />
    ))}
  </>;
};
