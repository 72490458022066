import { EmailSettings, NotificationSettings, PushSettings } from '#/models/notificationSettings/NotificationSettings';

export class NotificationSettingsFormBuilder {
  pushNotifications?: PushSettings;
  emailNotifications?: EmailSettings;

  static build(notificationSettings?: NotificationSettings): NotificationSettingsFormBuilder {
    return {
      pushNotifications: {
        all: notificationSettings?.pushNotifications?.all !== undefined ? notificationSettings?.pushNotifications?.all : true,
        directMessages: notificationSettings?.pushNotifications?.directMessages !== undefined ? notificationSettings?.pushNotifications?.directMessages : true,
        incidents: notificationSettings?.pushNotifications?.incidents !== undefined ? notificationSettings?.pushNotifications?.incidents : true,
        bookings: notificationSettings?.pushNotifications?.bookings !== undefined ? notificationSettings?.pushNotifications?.bookings : true,
        featureUpdates: notificationSettings?.pushNotifications?.featureUpdates !== undefined ? notificationSettings?.pushNotifications?.featureUpdates : true
      },
      emailNotifications: {
        all: notificationSettings?.emailNotifications?.all !== undefined ? notificationSettings?.emailNotifications?.all : true,
        directMessages: notificationSettings?.emailNotifications?.directMessages !== undefined ? notificationSettings?.emailNotifications?.directMessages : true,
        incidents: notificationSettings?.emailNotifications?.incidents !== undefined ? notificationSettings?.emailNotifications?.incidents : true,
        bookings: notificationSettings?.emailNotifications?.bookings !== undefined ? notificationSettings?.emailNotifications?.bookings : true,
        featureUpdates: notificationSettings?.emailNotifications?.featureUpdates !== undefined ? notificationSettings?.emailNotifications?.featureUpdates : true
      }
    };
  }
}
