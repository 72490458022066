import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Container = styled.div(({ theme }) => css`
  width: fit-content;
  max-width: 35rem;
  background: ${theme.colors.Gray200};
  padding: ${theme.spacing.s8} ${theme.spacing.s10};
  border-radius: 1.2rem;
`);

const DotsContainer = styled.div(() => css`
  display: flex;
  justify-content: center;
  align-items: center;
`);

const Dot = styled.div(({ theme }) => css`
  width: 8px;
  height: 8px;
  margin: 0 5px;
  background-color: ${theme.colors.Gray500};
  border-radius: 50%;
  animation: blink 1.4s infinite both;

  :nth-of-type(1) {
    animation-delay: -0.32s;
  }

  :nth-of-type(2) {
    animation-delay: -0.16s;
  }

  @keyframes blink {
    0%, 80%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
`);

export const LoadingBubble = () => {
  return <Container>
    <DotsContainer>
      <Dot></Dot>
      <Dot></Dot>
      <Dot></Dot>
    </DotsContainer>
  </Container>;
};
