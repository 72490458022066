import { Admin } from './sections/Admin';
import { AgentAccountSettings } from './sections/AgentAccountSettings';
import { Applications } from './sections/Applications';
import { BankAccounts } from './sections/BankAccounts';
import { Bookings } from './sections/Bookings';
import { Calendar } from './sections/Calendar';
import { Documents } from './sections/Documents';
import { Home } from './sections/Home';
import { Incidents } from './sections/Incidents';
import { Marketplace } from './sections/Marketplace';
import { OrganizationServices } from './sections/OrganizationServices';
import { OwnerAccountSettings } from './sections/OwnerAccountSettings';
import { Payments } from './sections/Payments';
import { People } from './sections/People';
import { Portfolio } from './sections/Portfolio';
import { TaskManager } from './sections/TaskManager';
import { Taxes } from './sections/Taxes';
import { Team } from './sections/Team';
import { Templates } from './sections/Templates';
import { Valuations } from './sections/Valuations';
import { Invoices } from './sections/Invoices';
import { NonPayments } from './sections/NonPayments';
import { Investment } from './sections/Investment';

export interface SectionProps {
  onClickItem?: () => void;
  depth?: number;
  isMobile: boolean;
}

export const Sections = {
  Admin,
  AgentAccountSettings,
  Applications,
  BankAccounts,
  Bookings,
  Calendar,
  Documents,
  Home,
  Incidents,
  Investment,
  Marketplace,
  OrganizationServices,
  OwnerAccountSettings,
  Payments,
  People,
  Portfolio,
  TaskManager,
  Taxes,
  Team,
  Templates,
  Valuations,
  Invoices,
  NonPayments
};
