import { useI18n } from '#hooks/useI18n';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BodyTemplate } from '../templates/BodyTemplate';
import { Colors, Fonts, ZIndex, PrimaryButton, Spacer } from '@zazume/zzm-base';
import { Iceberg } from '../components/ilustrations/Iceberg';
import { LostPerson } from '../components/ilustrations/LostPerson';
import { MAX_WIDTH_WITHOUT_ASIDE_NAVIGATION } from '../utils/containers/containersConstants';
import { useAuth } from '../contexts/AuthProvider';
import { useNavigate } from 'react-router';
import { Private } from '../containers/router/routes/Private';
import { Anonymous } from '../containers/router/routes/Anonymous';

const CenteredBodyTemplate = styled(BodyTemplate)`
  align-items: center;
`;

const Title = styled.div`
  color: ${Colors.FONT_2} ;
  width: 100%;
  font-family: ${Fonts.BASE};
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 2px;
`;

const Headline = styled.div`
  color: ${Colors.FONT_2} ;
  width: 100%;
  font-family: ${Fonts.BASE};
  font-style: normal;
  font-weight: 600;
  font-size: 128px;
  line-height: 110%;
`;

const Subtitle = styled.div`
  color: ${Colors.FONT_5} ;
  font-family: ${Fonts.BASE};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  max-width: 370px;
`;

const Background = styled.div`
  position: absolute;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 8vh;
  z-index: ${ZIndex.NOT_FOUND};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const NotFound404: FC = () => {
  const { t } = useI18n();
  const { isLogged } = useAuth();
  const navigate = useNavigate();

  const handleButtonClick = () => navigate(
    isLogged()
      ? Private.HOME.route
      : Anonymous.LOGIN.route
  );

  const textButton = isLogged() ? t('errorPage.buttonHome') : t('errorPage.buttonLogin');

  return <CenteredBodyTemplate margins={false}>
    <Background><Iceberg height="70vh" width={MAX_WIDTH_WITHOUT_ASIDE_NAVIGATION}/></Background>
    <Content>
      <Body>
        <Title>{t('errorPage.title')}</Title>
        <Headline>{t('errorPage.headline')}</Headline>
        <Subtitle>{t('errorPage.subtitle')}</Subtitle>
        <Spacer vertical="s8"/>
        <PrimaryButton type="button" size="Large" onClick={handleButtonClick}>{textButton}</PrimaryButton>
      </Body>
      <LostPerson height="30vh" width={MAX_WIDTH_WITHOUT_ASIDE_NAVIGATION}/>
    </Content>
  </CenteredBodyTemplate>;
};

export default NotFound404;
