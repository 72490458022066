import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import Container from './style/StyledContainer';
import { Label } from '../components/Label';
import { Error } from '../components/Error';
import { Input, InputProps } from '../Input';
import { E164Number } from 'libphonenumber-js/types';
import { Flex, Regular } from '@zazume/zzm-base';
import { useI18n } from '../../../../hooks/useI18n';

// inputComponent does not accept React.Element
const InputComponent = React.forwardRef<any, any>((props, ref) => {
  const { inputProps, ...rest } = props;

  return <Input ref={ref} {...rest} {...inputProps} showErrors={false}/>;
});

export interface PhoneFieldProps extends InputProps {
  unicodeFlags?: boolean;
  editableCountryCode?: boolean;
  international?: boolean;
  withCountryCallingCode?: boolean;
  isOptional?: boolean;
  placeholder?: string;
}

/**
 * @deprecated use src/components/forms/input/PhoneInput.tsx
 */
export const PhoneField: FC<PhoneFieldProps> = (props) => {
  const {
    name,
    label,
    disabled,
    readOnly,
    unicodeFlags = false,
    editableCountryCode = false,
    international = false,
    withCountryCallingCode = false,
    isOptional = false,
    placeholder
  } = props;

  const { t } = useI18n();
  const { register, getValues, setValue, errors } = useFormContext();
  const inputError = errors[name];

  const defaultValue = getValues(name);
  const onChange = (number: E164Number) => setValue(name, number, { shouldValidate: true });

  return (
    <Container>
      <Flex gap="sm" justify="start" align="center">
        {label && <Label htmlFor={name}>{label}</Label>}
        {isOptional && <Regular variant="S" color="Gray400">{t('general.optional')}</Regular>}
      </Flex>
      <PhoneInput
        {...register(name) as any}
        value={defaultValue}
        onChange={onChange}
        defaultCountry="ES"
        countrySelectProps={{ unicodeFlags }}
        readOnly={readOnly}
        disabled={disabled}
        international={international}
        withCountryCallingCode={withCountryCallingCode}
        editableCountryCode={editableCountryCode}
        inputComponent={InputComponent}
        inputProps={{ disabled, readOnly, name }}
        placeholder={placeholder}
      />
      <Error inputError={inputError}/>
    </Container>
  );
};
