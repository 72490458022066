import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from './index';

export const Profile: FC<IconProps> = ({ size = 20, color = '#FFFFFF' }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9998 13.1295C14.4951 13.1295 16.518 11.1067 16.518 8.61138C16.518 6.11609 14.4951 4.09326 11.9998 4.09326C9.50455 4.09326 7.48172 6.11609 7.48172 8.61138C7.48172 11.1067 9.50455 13.1295 11.9998 13.1295ZM11.9998 13.1295C8.2569 13.1295 5.22266 16.1638 5.22266 19.9067M11.9998 13.1295C15.7428 13.1295 18.777 16.1638 18.777 19.9067"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>;
};
