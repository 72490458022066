import { IdentifierDTO } from '@zazume/zzm-base';
import { doGet, doPost, toPaginatedURL } from '../../helpers';
import { Comment } from '#/models/comment/Comment';
import { PaginationOptions } from '#/hooks';

export const comments = {
  getAll: (relatedModelId: IdentifierDTO) =>
    (pagination: PaginationOptions) =>
      doGet<Comment[]>(toPaginatedURL(`/dashboard/comments/${relatedModelId}`, pagination)),
  create: (relatedModelId: IdentifierDTO, relatedModelName: Comment['relatedModelName']) => (content: string) =>
    doPost<IdentifierDTO>(`/dashboard/comments/${relatedModelName}/${relatedModelId}`, { content })
};
