import { useDelete, useGet, useNotifications } from '#/hooks';
import { useI18n } from '#/hooks/useI18n';
import { API } from '#/lib/network/API';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Bold, Cross, Delete, IdentifierDTO, Regular, Semibold, useModal } from '@zazume/zzm-base';
import { DateVO } from '@zazume/zzm-utils';
import React, { FC, useState } from 'react';
import { HEADER_HEIGHT } from '../header/Header';
import { GenericDeleteConfirmationModal } from '#/components/modal/GenericDeleteConfirmationModal';
import { useNetworkExceptions } from '#lib/network/helpers';
import { CopilotChatKeys } from '#/hooks/api/copilot/Copilot.keys';

const Container = styled.div<{ isWidget: boolean }>(({ theme, isWidget }) => css`
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  ${theme.mq.md} {
    width: ${isWidget ? '100%' : '400px'};
  }
`);

const ActionButton = styled.button(({ theme }) => css`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border-radius: 1.5rem;
  transition: 0.15s;

  :hover {
    background: ${theme.colors.Gray200};
  }
`);

const Header = styled.div(({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.s7} ${theme.spacing.s10};
  height: ${HEADER_HEIGHT}rem;
  border-bottom: 1px solid ${theme.colors.Gray200};
`);

const Body = styled.div(() => css`
  height: calc(100% - ${HEADER_HEIGHT}rem);
  overflow-y: scroll;
`);

const ItemCell = styled.div<{ isSelected: boolean }>(({ theme, isSelected }) => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${theme.spacing.s7} ${theme.spacing.s10};
  cursor: pointer;
  border-bottom: 1px solid ${theme.colors.Gray200};

  :hover {
    p {
      color: ${theme.colors.Primary};
    }

    ${ItemAction} {
      display: flex;
    }
  }
`);

const ItemContent = styled.div<{ isSelected: boolean }>(({ theme, isSelected }) => css`
  flex-grow: 1;
  min-width: 0;

  p {
    ${isSelected && `color: ${theme.colors.Primary};`}
  }

  p:first-of-type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

`);

const ItemAction = styled.div(({ theme }) => css`
  display: none;
  align-items: center;

  :hover {
    display: flex;
  }

  button {
    all: unset;

    :hover {
      svg  > path {
        stroke: ${theme.colors.RedDark2};
      }
    }
  }
`);

interface Props {
  closeChatList: () => void;
  isWidget: boolean;
  onSelectNewChat: (chatId: IdentifierDTO) => void;
  chatId?: IdentifierDTO;
}

export const ChatList: FC<Props> = ({ closeChatList, isWidget, onSelectNewChat, chatId }) => {
  const { languageVO, t } = useI18n();
  const { data } = useGet(CopilotChatKeys.getConversations(), API.copilot.getAllConversations);
  const { showNotification } = useNotifications();
  const { handleNetworkExceptions } = useNetworkExceptions();
  const { mutate: deleteConversation } = useDelete(CopilotChatKeys.getConversations(), API.copilot.deleteConversation, { isOptimistic: false });

  const { open: openRemoveModal, RenderModal: RenderRemoveChatConversationModal, close: closeRemoveChatConversationModal } = useModal({ modalSize: 'm' });
  const [chatSelected, setChatSelected] = useState<string | undefined>();

  const onSelectConversation = (id: IdentifierDTO) => () => {
    onSelectNewChat(id);
    closeChatList();
  };

  const openRemoveChatConversationModal = (id: IdentifierDTO) => (event) => {
    event.stopPropagation();
    setChatSelected(id);
    openRemoveModal();
  };

  const onRemoveConversation = async () => {
    try {
      await deleteConversation(chatSelected!);
      closeRemoveChatConversationModal();
      showNotification(t('copilot.remove.toast'));
    } catch (error) {
      handleNetworkExceptions(error);
    }
  };

  return (
    <Container isWidget={isWidget}>
      <Header>
        <Bold variant="L">Listado de chats</Bold>
        <ActionButton onClick={closeChatList}>
          <Cross color="Gray500" size={20}/>
        </ActionButton>
      </Header>
      <Body>
        {data?.map(chat => {
          const isSelected = Boolean(chatId && chatId === chat._id);
          return <ItemCell key={chat._id} onClick={onSelectConversation(chat._id)} isSelected={isSelected}>
            <ItemContent isSelected={isSelected}>
              <Semibold>{chat.historicMessages[0]?.message || t('copilot.emptyConversation')}</Semibold>
              <Regular variant="S" color="Gray500">
                {t('copilot.created', { date: DateVO.fromISOString(chat.createdAt).format('datetimeMinutes', languageVO.toPlatform()) }) }
              </Regular>
            </ItemContent>
            <ItemAction>
              <button onClick={openRemoveChatConversationModal(chat._id)} title={t('general.delete')}><Delete size={20} cursor="pointer" color="Gray400"/></button>
            </ItemAction>
          </ItemCell>;
        })}
      </Body>

      <RenderRemoveChatConversationModal>
        <GenericDeleteConfirmationModal
          title={t('copilot.remove.title')}
          description={t('copilot.remove.description')}
          onConfirm={onRemoveConversation}
          onCancel={closeRemoveChatConversationModal}
          actionButtonText={t('general.delete')}
        />
      </RenderRemoveChatConversationModal>
    </Container>
  );
};
