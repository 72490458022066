import { BankTransaction } from '#/domain/bank/bankTransaction/BankTransaction';
import { PaginationOptions } from '#/hooks';
import { doGet, doPut, EndpointBuilder, toPaginatedURL } from '#lib/network/helpers';
import { IdentifierDTO } from '@zazume/zzm-base';

export const bankTransactions = {
  discard: (discardData: any) =>
    doPut<BankTransaction>(`/dashboard/bank-transactions/${discardData.id}/discard`, { note: discardData.note }),
  discardAll: (idsToDiscard: IdentifierDTO[]) =>
    doPut<void>('/dashboard/bank-transactions/bulk/discard', { transactionIds: idsToDiscard }),
  enable: (enableData: any) =>
    doPut<BankTransaction>(`/dashboard/bank-transactions/${enableData.id}/enable`),
  get: (id: IdentifierDTO) => () =>
    doGet<BankTransaction>(`/dashboard/bank-transactions/${id}`),
  getLinkedPayments: (id: IdentifierDTO) => () =>
    doGet<any[]>(`/dashboard/bank-transactions/${id}/linked-payments`),
  getPaginated: (params: EndpointBuilder) => (pagination: PaginationOptions) => () =>
    doGet<BankTransaction[]>(toPaginatedURL(`/dashboard/bank-transactions${params.url}${params.queryParams}`, pagination))
};
