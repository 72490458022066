import { useBrand } from '#/hooks/useBrand';
import React, { FC, useEffect } from 'react';
import { Navigate, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { RouteContainer } from '../../containers/router/components/RouteContainer';
import { useAuth } from '../../contexts/AuthProvider';
import { usePermissions } from '../../hooks';
import { UserRolesTypes } from '../../models';


export interface PrivateRouteProps extends RouteProps {
  title?: string;
  withRoles?: UserRolesTypes[];
  onlyDefaultOrganization?: boolean;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ element, title = 'Dashboard', withRoles = [], onlyDefaultOrganization, ...props }) => {
  const { user, logout, organization } = useAuth();
  const { canRender } = usePermissions();
  const { brand } = useBrand();

  useEffect(() => {
    if (brand?.name) {
      document.title = `${title} - ${brand.name}`;
    } else {
      document.title = title;
    }
  }, [title, brand]);

  if (withRoles.length && !canRender(withRoles)) {
    return <Navigate to="/" replace={true}/>;
  }

  if (onlyDefaultOrganization && !organization?.isDefaultOrganization) {
    return <Navigate to="/" replace={true}/>;
  }

  if (!user) {
    logout().catch(console.error);
    return null;
  }

  return <Route {...props} element={<RouteContainer body={element}/>}/>;
};
