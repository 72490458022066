import { I18NValues } from '#lib/i18n/generatedKeys';
import { Language, LanguageVO } from '@zazume/zzm-utils';
import { changeLanguage as i18ChangeLanguage, TOptions } from 'i18next';
import { Trans as I18nTrans, useTranslation } from 'react-i18next';

export type TFunction = (key: I18NValues, options?: TOptions<any> | string) => string;

export interface UseI18NResponse {
  t: TFunction;
  changeLanguage: (lang: Language) => void;
  Trans: typeof I18nTrans;
  /**
   * @deprecated use languageVO instead
   */
  language: Language;
  languageVO: LanguageVO;
}

export const useI18n = (): UseI18NResponse => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang: Language) =>
    i18ChangeLanguage(lang);

  return {
    t,
    changeLanguage,
    Trans: I18nTrans,
    language: i18n.language as Language,
    languageVO: LanguageVO.from(i18n.language)
  };
};
