import { FCC } from '@zazume/zzm-base';
import { FC } from 'react';
import { CardInput as BaseCardInput, CardProps } from '../../atoms/card/CardInput';
import { useRadioGroupContext } from '../RadioGroupContext';

type RadioCardInputProps = Omit<CardProps, 'name'>;
export type RadioCardInputTemplate = FCC<RadioCardInputProps>;

export const RadioCardInput: FC<RadioCardInputProps> = (props) => {
  const { name } = useRadioGroupContext();
  return <BaseCardInput name={name} type="radio" {...props}/>;
};
