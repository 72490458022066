import { AuthResponse, OrganizationToken, TokenData, User } from '#/models';
import { doGet, doPost } from '#lib/network/helpers';

export const auth = {
  acceptInvite: (password: string, token: string, name: string) =>
    doPost<AuthResponse>('/auth/accept-invite', { password, token, name }),
  login: (email: string, password: string) =>
    doPost<AuthResponse>('/auth/signin', { email, password, scope: 'dashboard' }),
  checkSingleSignOn: () =>
    doGet<AuthResponse>('/auth/single-sign-on'),
  logout: () =>
    doPost('/auth/logout'),
  forgot: (email: string) =>
    doPost('/auth/forgot', { email }),
  createPassword: (password: string, token: string) =>
    doPost<any>('/auth/create-password', { password, token }),
  resetPassword: (password: string, token: string) =>
    doPost<any>('/auth/reset-password', { password, token }),
  verifyToken: (token: string) =>
    doPost<TokenData>('/auth/verify-token', { token }),
  profile: () =>
    doGet<User>('/auth/profile'),
  getCurrentOrganizationToken: (organizationId: string) =>
    doGet<OrganizationToken>(`/auth/organization-token/${organizationId}`)
};
