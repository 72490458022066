import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { TopTitleContainer } from './atoms/TopTitleContainer';

const Container = styled.div(({ theme }) => css`
  margin-bottom: ${theme.spacing.s7};
  width: 100%;

  ${theme.mq.tablet} {
    font-size: ${theme.fontsSizes.lg};
  }
`);

const TopSection = styled.div(({ theme }) => css`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
`);

const TopLeftSection = styled.div(({ theme }) => css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${theme.spacing.s3};

  ${theme.mq.tablet} {
    gap: ${theme.spacing.s6};
  }
`);

const TopRightSection = styled.div(({ theme }) => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.s3};
  flex-shrink: 0;
  margin-left: ${theme.spacing.s4};
  margin-top: ${theme.spacing.s3};

  ${theme.mq.tablet} {
    margin-left: ${theme.spacing.s7};
  }
`);


const TopActions = styled.div(({ theme }) => css`
`);

const BottomSection = styled.div<any>(({ theme, justify }) => css`
  margin-top: ${theme.spacing.s8};
  display: flex;
  align-items: center;
  justify-content: ${justify};
  gap: ${theme.spacing.s6};
  flex-wrap: wrap;

  ${theme.mq.tablet} {
    gap: ${theme.spacing.s9};
  }
`);

interface Props {
  icon?: ReactNode;
  rightIcon?: ReactNode;
  title?: string;
  subtitle?: string;
  tags?: any;
  rightContent?: any;
  topActions?: any;
  bottomJustify?: 'flex-start' | 'space-between' | 'flex-end';
  bottomContent?: any;
  topTitleContainer?: ReactNode;
}

export const PageDetailTitle: FC<Props> = ({ icon, rightIcon, title, subtitle, tags, rightContent, topTitleContainer, topActions, bottomJustify = 'flex-start', bottomContent }) => {
  return <Container>
    <TopSection>
      <TopLeftSection>
        {topTitleContainer
          ? topTitleContainer
          : <TopTitleContainer title={title} tags={tags} icon={icon} rightIcon={rightIcon} subtitle={subtitle}/>}
        <TopActions>
          {topActions}
        </TopActions>
      </TopLeftSection>
      {rightContent && <TopRightSection>
        {rightContent}
      </TopRightSection>}
    </TopSection>
    <BottomSection justify={bottomJustify}>
      {bottomContent}
    </BottomSection>
  </Container>;
};
