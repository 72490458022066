import { FCC } from '@zazume/zzm-base';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Container = styled.a<any>(({ theme }) => css`
  display: flex;
  align-items: center;

  font-family: ${theme.fonts.base};
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0;
  color: ${theme.colors.Primary};
`);

interface LinkProps {
  href: string;
  target?: string;
  stopPropagation?: boolean;
  onClick?: () => void;
}

/**
 * @deprecated use zzm-base Link
 * @param href
 * @param target
 * @param onClick
 * @param stopPropagation
 * @param props
 * @constructor
 */
export const Link: FCC<LinkProps> = ({ href, target, onClick, stopPropagation = false, ...props }) => {
  const handleOnClick = e => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    onClick?.();
  };

  return <Container href={href} target={target} {...props} onClick={handleOnClick}>{props.children}</Container>;
};
