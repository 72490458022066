import { useSession } from '#hooks/session/useSession';
import { useGet } from '#/hooks';
import { ActiveProductTypeBooking } from '#lib/network/api/bookings/models/ActiveProductTypeBooking';
import { InvestmentOpportunityKeys } from '#hooks/api/investmentOpportunities/InvestmentOpportunities';
import { API } from '#lib/network/API';
import { CityKeys } from '#lib/network/api/cities/CityKeys';
import { IdentifierDTO } from '@zazume/zzm-base';
import { QueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { City } from '#/models/location/City';

const getCity = (cityId: IdentifierDTO) => {
  return new QueryClient().fetchQuery(CityKeys.byId(cityId), API.cities.get(cityId), { retry: false });
};

export const usePurchase = (cityId?: IdentifierDTO, disablePurchaseEvenWhenCityDoesNotFit: boolean = false) => {
  const { isOwner, isAgent } = useSession();
  const [city, setCity] = useState<City | undefined>(undefined);

  useEffect(() => {
    if (!cityId){
      return;
    }
    const setter = async () => {
      const newCity = await getCity(cityId);
      setCity(newCity);
    };

    setter();
  }, [cityId]);

  const { data } = useGet<ActiveProductTypeBooking>(
    InvestmentOpportunityKeys.activeBooking('real_estate_opportunities'),
    API.bookings.findActiveProductType('real_estate_opportunities'));

  const { data: dataByArea } = useGet<ActiveProductTypeBooking>(
    InvestmentOpportunityKeys.activeBooking('real_estate_opportunities_area'),
    API.bookings.findActiveProductType('real_estate_opportunities_area'));

  const canPurchase = (isOwner || isAgent)
    && data && dataByArea
    && !data.isActive
    && (!disablePurchaseEvenWhenCityDoesNotFit && (!dataByArea.isActive || city?.autonomousCommunityCode !== dataByArea.booking?.externalData?.area));


  const subscriptionInArea = dataByArea?.isActive ? dataByArea?.booking : undefined;

  return {
    canPurchase,
    subscriptionInArea
  };
};
