import { FC } from 'react';
import { useCopilotContext } from '../../CopilotContext';
import { CopilotWidget } from './CopilotWidget';

interface Props {
}

export const CopilotWidgetContainer: FC<Props> = () => {
  const { isOpen } = useCopilotContext();

  if (!isOpen) {
    return null;
  }

  return <CopilotWidget/>;
};
