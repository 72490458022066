import { IdentifierDTO } from '@zazume/zzm-base';

export type NotificationChannel = 'email' | 'push';
export type NotificationSubject = 'directMessages' | 'incidents' | 'bookings' | 'featureUpdates';

export interface NotificationSettings {
  userId: IdentifierDTO;
  pushNotifications: PushSettings;
  emailNotifications: EmailSettings
}

export interface PushSettings {
  all: boolean;
  directMessages: boolean;
  incidents: boolean;
  bookings: boolean;
  featureUpdates: boolean;
}

export interface EmailSettings {
  all: boolean;
  directMessages: boolean;
  incidents: boolean;
  bookings: boolean;
  featureUpdates: boolean;
}

export class UpdateNotificationSettingsRequest {
  static build(data): Partial<NotificationSettings> {
    return {
      pushNotifications: {
        all: data.pushNotifications.all,
        directMessages: data.pushNotifications.all,
        incidents: data.pushNotifications.all,
        bookings: data.pushNotifications.all,
        featureUpdates: data.pushNotifications.all
      },
      emailNotifications: {
        all: data.emailNotifications.all,
        directMessages: data.emailNotifications.all,
        incidents: data.emailNotifications.all,
        bookings: data.emailNotifications.all,
        featureUpdates: data.emailNotifications.all
      }
    };
  }
}
