import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps, getStrokeWidth } from './index';

/**
 * @deprecated use zzm-base
 * @param size
 * @param color
 * @constructor
 */
export const Settings: FC<IconProps> = ({ size = 20, color = Colors.FONT_3 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} d="M16.791 17.2174L18.012 17.6303C18.4602 17.7819 18.9532 17.5986 19.1936 17.1912L20.1051 15.6464C20.346 15.2381 20.267 14.7167 19.9161 14.3979L18.9254 13.4984C18.6707 13.267 18.5585 12.9214 18.5902 12.5787C18.6272 12.1797 18.6274 11.7782 18.5911 11.3792C18.5593 11.0314 18.6759 10.681 18.9377 10.4499L19.8993 9.60142C20.2589 9.28419 20.3426 8.7564 20.099 8.34344L19.1889 6.80088C18.9506 6.39708 18.4638 6.21301 18.018 6.35816L16.7733 6.76341C16.4465 6.86981 16.0911 6.79646 15.8102 6.5984C15.4824 6.36724 15.135 6.16513 14.772 5.99439C14.4594 5.84731 14.2191 5.57237 14.1513 5.23353L13.8983 3.96844C13.8048 3.50101 13.3944 3.16455 12.9177 3.16455H11.0838C10.6063 3.16455 10.1954 3.50212 10.1028 3.97054L9.84899 5.25386C9.78099 5.59766 9.5354 5.87547 9.21742 6.02283C8.8578 6.18949 8.51412 6.38859 8.19064 6.61764C7.9044 6.82032 7.5409 6.89511 7.20866 6.78275L5.98767 6.36982C5.5395 6.21825 5.04644 6.40149 4.80604 6.80896L3.89463 8.35373C3.65371 8.76206 3.73264 9.28346 4.08363 9.60219L5.07426 10.5018C5.32901 10.7331 5.44123 11.0787 5.40948 11.4214C5.37252 11.8204 5.37224 12.2219 5.40863 12.621C5.44034 12.9687 5.32381 13.3192 5.06197 13.5502L4.10034 14.3987C3.74081 14.7159 3.65705 15.2437 3.9007 15.6567L4.8108 17.1992C5.04905 17.603 5.53585 17.7871 5.98166 17.642L7.22635 17.2367C7.55315 17.1303 7.90863 17.2037 8.1895 17.4017C8.51731 17.6329 8.86468 17.835 9.22764 18.0057C9.54032 18.1528 9.78063 18.4277 9.8484 18.7666L10.1014 20.0317C10.1949 20.4991 10.6053 20.8356 11.082 20.8356H12.9159C13.3934 20.8356 13.8043 20.498 13.8969 20.0296L14.1507 18.7463C14.2187 18.4025 14.4643 18.1247 14.7823 17.9773C15.1419 17.8106 15.4856 17.6115 15.809 17.3825C16.0953 17.1798 16.4588 17.105 16.791 17.2174Z"/>
    <path stroke={color} strokeWidth={strokeWidth} d="M14.9998 12.0001C14.9998 13.6569 13.6567 15.0001 11.9998 15.0001C10.343 15.0001 8.99984 13.6569 8.99984 12.0001C8.99984 10.3432 10.343 9.00006 11.9998 9.00006C13.6567 9.00006 14.9998 10.3432 14.9998 12.0001Z"/>
  </svg>;
};
