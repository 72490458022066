import { Divider, Semibold, ZZMForm } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { useI18n } from '#hooks/useI18n';
import { SearchLocationInput } from '#/components/forms/search/searchLocation/SearchLocationInput';
import styled from '@emotion/styled';
import { City } from '#/models/location/City';
import { css } from '@emotion/react';

const Body = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s10};

  & > * {
    padding-bottom: 8px;
  }
`);

type CreateAlertModalBodyProps = {
  setCity: (city: City) => void;
}

export const CreateAlertModalBody: FC<CreateAlertModalBodyProps> = ({ setCity }) => {
  const { t } = useI18n();

  return (
    <Body>
      <SearchLocationInput
        onSelectCity={setCity}
        name="location"
        label={t('investment.alertModal.form.location')}
        placeholder={t('general.forms.selectAnOption')}
      />
      <ZZMForm.InputCurrency
        name="maxBudget"
        label={t('investment.alertModal.form.maxBudget')}
        placeholder="150.000"
        responsive={false}
        min={0}
      />
      <ZZMForm.Input
        name="profitability"
        label={t('investment.alertModal.form.profitability')}
        placeholder="5"
        min={0}
        step={0.1}
        type="number"
        inputMode="decimal"
        allowDecimal={true}
        responsive={false}
        iconLeft="percentage"
      />
      <ZZMForm.InputCurrency
        name="minRentPrice"
        label={t('investment.alertModal.form.minRentPrice')}
        placeholder="800"
        min={0}
        responsive={false}
      />
      <ZZMForm.Stepper
        name="rooms"
        label={t('investment.alertModal.form.rooms')}
        min={1}
        step={1}
        responsive={false}
      />
      <ZZMForm.CardGroup name="status" label={t('investment.alertModal.form.condition')} inline={true}>
        <ZZMForm.CardGroup.Item value="good">{t('investment.alertModal.form.goodCondition')}</ZZMForm.CardGroup.Item>
        <ZZMForm.CardGroup.Item value="renew">{t('investment.alertModal.form.needsRenovation')}</ZZMForm.CardGroup.Item>
        <ZZMForm.CardGroup.Item value="newdevelopment">{t('investment.alertModal.form.newConstruction')}</ZZMForm.CardGroup.Item>
      </ZZMForm.CardGroup>
      <ZZMForm.CardGroup name="advertiser" label={t('investment.alertModal.form.advertiser')} inline={true}>
        <ZZMForm.CardGroup.Item value="professional">{t('investment.alertModal.form.all')}</ZZMForm.CardGroup.Item>
        <ZZMForm.CardGroup.Item value="private">{t('investment.alertModal.form.privateListingsOnly')}</ZZMForm.CardGroup.Item>
      </ZZMForm.CardGroup>
      <ZZMForm.CardGroup name="elevator" label={t('investment.alertModal.form.elevator')} inline={true}>
        <ZZMForm.CardGroup.Item value="N/A">{t('investment.alertModal.form.noPreference')}</ZZMForm.CardGroup.Item>
        <ZZMForm.CardGroup.Item value="elevator">{t('investment.alertModal.form.withElevator')}</ZZMForm.CardGroup.Item>
        <ZZMForm.CardGroup.Item value="no_elevator">{t('investment.alertModal.form.withoutElevator')}</ZZMForm.CardGroup.Item>
      </ZZMForm.CardGroup>
      <Divider/>
      <Semibold>{t('investment.alertModal.form.notificationPreferences')}</Semibold>
      <ZZMForm.CardGroup name="channel" label={t('investment.alertModal.form.whereReceiveAlerts')} inline={true}>
        <ZZMForm.CardGroup.Item value="whatsapp">{t('investment.alertModal.form.whatsapp')}</ZZMForm.CardGroup.Item>
        <ZZMForm.CardGroup.Item value="email">{t('investment.alertModal.form.email')}</ZZMForm.CardGroup.Item>
        <ZZMForm.CardGroup.Item value="both">{t('investment.alertModal.form.both')}</ZZMForm.CardGroup.Item>
      </ZZMForm.CardGroup>
    </Body>
  );
};
