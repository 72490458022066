import { IdentifierDTO } from '@zazume/zzm-base';
import { doGet, doPost, doPut } from '../../helpers';
import { Viewing } from '../../../../models/Viewing';
import { ViewingRating } from '../../../../models/ViewingRating';

export const viewings = {
  get: (viewingId: IdentifierDTO) => () =>
    doGet<Viewing>(`/viewings/${viewingId}`),
  getByOnboardingId: (onboardingId: IdentifierDTO) => () =>
    doGet<Viewing>(`/viewings/onboarding/${onboardingId}`),
  scheduleViewing: (applicationId: IdentifierDTO) => (data) =>
    doPost(`/viewings/${applicationId}`, data),
  rescheduleViewing: (applicationId: IdentifierDTO) => (data) =>
    doPut(`/viewings/${applicationId}/reschedule`, data),
  commercialNote: (viewingId: IdentifierDTO) => (data) =>
    doPut(`/viewings/${viewingId}/commercial-note`, data),
  cancelViewing: (viewingId: IdentifierDTO) => () =>
    doPut(`/viewings/${viewingId}/cancel`),
  cancelViewingByOnboardingId: (onboardingId: IdentifierDTO) => () =>
    doPut(`/viewings/onboarding/${onboardingId}/cancel`),
  getRating: (viewingId: IdentifierDTO) => () =>
    doGet<ViewingRating>(`/viewing-rating/${viewingId}`),
  assignCommercial: (viewingId: IdentifierDTO) => (commercialId) =>
    doPut(`/viewings/commercials/${viewingId}`, commercialId),
  markAsNoShow: (viewingId: IdentifierDTO) => () =>
    doPut<void>(`/viewings/${viewingId}/no-show`),
  markAsCompleted: (viewingId: IdentifierDTO) => () =>
    doPut<void>(`/viewings/${viewingId}/completed`)
};
