import { IdentifierDTO } from '@zazume/zzm-base';
import { RelatedModelType, DocumentType } from '#/models';

export class DocumentUploadLinksCreateRequest {
  private readonly relatedModelId: IdentifierDTO;
  private readonly relatedModelType: RelatedModelType;
  private readonly type: DocumentType;
  private readonly description?: string;

  constructor(
    relatedModelId: IdentifierDTO,
    relatedModelType: RelatedModelType,
    type: DocumentType,
    description?: string
  ) {
    this.relatedModelId = relatedModelId;
    this.relatedModelType = relatedModelType;
    this.type = type;
    this.description = description;
  }

  build() {
    return {
      relatedModelId: this.relatedModelId,
      relatedModelType: this.relatedModelType,
      type: this.type,
      description: this.description
    };
  }
}
