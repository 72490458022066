import { useEffect } from 'react';
import { User } from '#/models';
import { isProductionEnvironment } from '#lib/Config';
import { UserHelper } from '#/models/helpers/user/UserHelper';

export const useUsetiful = (user?: User) => {
  useEffect(() => {
    if (!isProductionEnvironment || user === undefined) {
      return;
    }

    const userHelper = UserHelper.build(user);
    if (!userHelper.isOwner() && !userHelper.isAgent()) {
      return;
    }

    const roleTag = getRoleTag(userHelper);
    localStorage.setItem('usetifulRoleTag', roleTag);

    const script = document.createElement('script');

    script.src = 'https://www.usetiful.com/dist/usetiful.js';
    script.async = true;
    script.setAttribute('id', 'usetifulScript');
    script.dataset.token = '5a1c063ee7543e447e9e0920ba9c39e3';

    document.head.appendChild(script);

    (window as any).usetifulTags = {
      userId: user._id,
      language: user.lang,
      firstName: user.name,
      role: roleTag
    };

    return () => {
      document.head.removeChild(script);
    };
  }, [user]);
};

type RoleTagType = 'owner' | 'agent' | 'other';

const getRoleTag = (userHelper: UserHelper): RoleTagType => {
  if (userHelper.isOwner()) {
    return 'owner';
  }
  if (userHelper.isAgent()) {
    return 'agent';
  }
  return 'other';
};
