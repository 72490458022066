import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Container = styled.label<any>(({ theme, disabled }) => css`
  display: flex;
  font-family: ${theme.fonts.base};
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  color: ${disabled ? theme.colors.Gray300 : theme.colors.Gray600};
  cursor: pointer;
`);

const Input = styled.input(({ theme }) => css`
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 10px;
  background: ${theme.colors.White};
  border: 1px solid ${theme.colors.Gray300};
  box-sizing: border-box;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  outline: none;
  display: inline-block;
  vertical-align: top;
  margin: 3px 8px 3px 0;
  position: relative;
  cursor: pointer;

  --active: #275EFE;
  --active-inner: #fff;
  --border: #BBC1E1;
  --border-hover: #275EFE;
  --background: #fff;
  --disabled: #F6F8FF;
  --disabled-inner: #E1E6F9;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: background .3s, border-color .3s, box-shadow .2s;

  &:checked {
    background: ${theme.colors.Primary};
    border: 0;
  }

  &:enabled:after, &:disabled:checked:after {
    content: '';
    display: block;
    position: absolute;
    transition: transform .3s cubic-bezier(.2, .85, .32, 1.2), opacity .2s;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: var(--active-inner);
    left: 6px;
    top: 6px;

    &:checked {
      display: block;
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(39, 94, 254, .3);
  }

  & + label {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }

  &:disabled {
    background: ${theme.colors.PrimaryLight4};
    border-color: ${theme.colors.Gray200};

    &:checked {
      background: ${theme.colors.PrimaryLight2};
      border: 0;
    }
  }
`);

export interface RadioProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  value: string;
  label?: string;
  checked?: boolean;
}

/**
 * @deprecated use RadioBase from zzm-base
 */
export const Radio = React.forwardRef<any, RadioProps>((props, ref) => {
  const { name, label, checked = false, value, className, ...rest } = props;

  return <Container {...className as any} disabled={rest.disabled}>
    <Input ref={ref} name={name} checked={checked} value={value} type="radio" {...rest}/>
    {label}
  </Container>;
});
