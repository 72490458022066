import { useI18n } from '#/hooks/useI18n';
import styled from '@emotion/styled';
import { FCC, ResponsiveFilters } from '@zazume/zzm-base';

const Container = styled.div`
  flex-grow: 1;
  min-width: 2.625rem;
  overflow-y: visible;
`;

interface FiltersContainerProps {
  title?: string;
}

export const FiltersContainer: FCC<FiltersContainerProps> = ({ title = '', children, ...props }) => {
  const { t } = useI18n();
  const filtersTitle = title ? title : t('general.filters.title');

  return <Container {...props}>
    <ResponsiveFilters title={filtersTitle}>
      {children}
    </ResponsiveFilters>
  </Container>;
};
