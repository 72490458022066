import { FC } from 'react';
import { Flex, IdentifierDTO, Plus, PrimaryButton, Regular, ZazumeModal, useModal } from '@zazume/zzm-base';
import { useI18n } from '#hooks/useI18n';
import { DocumentCategories, RelatedModelType } from '#/models';
import { usePagination } from '#/lib/reactQuery/hooks/usePagination';
import { DocumentUploadLink } from '#/models/documentUploadLink/DocumentUploadLink';
import { DocumentUploadLinkKeys } from '#/hooks/api/documentUploadLink/DocumentUploadLink.keys';
import { API } from '#/lib/network/API';
import { AsyncComponent } from '#/components/async/AsyncComponent';
import { DocumentUploadLinksTable } from './DocumentUploadLinkTable';
import { CreateDocumentUploadLinkModal } from '../createDocumentUploadLinkModal/CreateDocumentUploadLinkModal';

interface DocumentUploadLinkModalProps {
  relatedModel: RelatedModelType
  relatedModelId: IdentifierDTO;
  category: DocumentCategories;
}

export const DocumentUploadLinkModal: FC<DocumentUploadLinkModalProps> = ({ relatedModelId, category, relatedModel }) => {
  const { t } = useI18n();
  const { open: openCreateModal, RenderModal: RenderCreateModal } = useModal({ modalSize: 'l' });

  const queryKey = DocumentUploadLinkKeys.allByRelatedModelId(relatedModelId);
  const result = usePagination<DocumentUploadLink[]>(queryKey, API.documentUploadLinks.findAllByRelatedModelId(relatedModelId));

  return (<>
    <ZazumeModal title={t('documentUploadLink.detail.title')}>
      <Flex direction="column" gap="s10">
        <Regular variant="S" color="Gray500">{t('documentUploadLink.detail.description')}</Regular>
        <AsyncComponent apiResult={result}>
          {uploadLinks => <DocumentUploadLinksTable
            uploadLinks={uploadLinks}
            nextPage={result.nextPage}
            previousPage={result.previousPage}
            currentPage={result.currentPage}
            openCreate={openCreateModal}
            queryKey={queryKey}
          />}
        </AsyncComponent>
        <PrimaryButton onClick={openCreateModal}>
          <Plus/>{t('documentUploadLink.detail.action')}
        </PrimaryButton>
      </Flex>
    </ZazumeModal>
    <RenderCreateModal>
      <CreateDocumentUploadLinkModal
        relatedModelId={relatedModelId}
        relatedModelType={relatedModel}
        category={category}
      />
    </RenderCreateModal>
  </>);
};
