import React, { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Private } from '#/containers/router/routes/Private';
import { Bold, getIcon, IconType, Regular, Semibold, Theme } from '@zazume/zzm-base';
import { useNavigation } from '#/containers/asideNavigation/context/NavigationContext';

const Link = styled(RouterLink)`
  width: 100%;
`;

const getDepthStyles = (theme: Theme, depth: number, isSelected: boolean) => {
  if (depth < 1) {
    return css`
      border-radius: 8px;
    `;
  }

  return css`
    padding: 0.25rem 0;
    border-left: 0.125rem solid ${isSelected ? theme.colors.Primary : theme.colors.PrimaryLight2};

    div {
      padding: 0.65rem 1rem;
    }

    &:hover {
      border-left: 0.125rem solid ${isSelected ? theme.colors.PrimaryDark2 : theme.colors.Gray400};
    }
  `;
};

const MainContainer = styled.div<{ depth: number, isSelected: boolean }>(({ theme, depth, isSelected }) => css`
  display: flex;

  p {
    color: ${isSelected ? theme.colors.Primary : theme.colors.Gray600};
  }

  svg {
    & > * {
      stroke: ${isSelected ? theme.colors.Primary : theme.colors.Gray600};
    }
  }

  &:hover {
    p {
      color: ${theme.colors.Gray600};
    }

    svg {
      & > * {
        stroke: ${theme.colors.Gray600};
      }
    }
  }

  ${getDepthStyles(theme, depth, isSelected)};
`);


const Container = styled.div<{ isChild: boolean, isSelected: boolean }>(({ theme, isChild, isSelected }) => css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //padding: 10px 8px;
  padding: 0.5rem 1rem;
  //border-radius: 8px;
  gap: ${theme.spacing.s4};
  user-select: none;
  border-radius: 8px;

  margin-left: ${isChild ? '0.5rem' : '0'};
  background: ${isSelected ? theme.colors.PrimaryLight1 : 'none'}; // '#F8F6FE'

  p {
    color: ${isSelected ? theme.colors.Primary : theme.colors.Gray600};
  }

  svg {
    & > * {
      stroke: ${isSelected ? theme.colors.Primary : theme.colors.Gray600};
    }
  }

  &:hover {
    background: ${isSelected ? theme.colors.PrimaryLight1 : theme.colors.Gray100};

    svg {
      & > * {
        stroke: ${isSelected ? theme.colors.PrimaryDark2 : theme.colors.Gray600};
      }
    }
  }

  &:hover > p {
    color: ${isSelected ? theme.colors.PrimaryDark2 : theme.colors.Gray600};
  }
`);


const isSelected = (pathname: string, to: string, parentPath?: string): boolean => {
  if (parentPath && pathname.startsWith(parentPath)) {
    return true;
  }
  return (pathname === '/' && to === Private.DASHBOARD.route) || pathname.startsWith(to);
};

const getTitle = (isChild: boolean, isSelected: boolean) => {
  if (isSelected) {
    return Bold;
  }

  if (isChild) {
    return Regular;
  }

  return Semibold;
};

interface NavigationLinkProps {
  to: string;
  icon: IconType;
  title: string;
  parentPath?: string;
  onClick?: () => void;
  depth?: number;
  isMobile: boolean;
}

export const NavigationLink: FC<NavigationLinkProps> = ({ to, isMobile, icon, parentPath, onClick, title, depth = 0 }) => {
  const { pathname } = useLocation();
  const { isCollapsed } = useNavigation();
  const onItemClick = () => onClick?.();

  if (isCollapsed && !isMobile) {
    return null;
  }

  const Icon = getIcon(icon);

  const selected = isSelected(pathname, to, parentPath);
  const isChild = Boolean(depth > 0);
  const Title = getTitle(isChild, selected);
  return <MainContainer depth={depth} isSelected={selected} onClick={onItemClick}>
    <Link to={to}>
      <Container isChild={isChild} isSelected={selected}>
        <Icon size={isChild ? 16 : 24}/>
        <Title color="Gray600" variant="S">{title}</Title>
      </Container>
    </Link>
  </MainContainer>;
};
