import { FC } from 'react';
import { Document, DocumentCategories, RelatedModelType } from '../../models';
import { getDocumentCategoriesOptions, getDocumentTypesOptions, findDocumentCategory } from './utils/utils';
import { Flex, Label, Regular, ResponsiveModalForm, ZZMForm, ZazumeModal, AttachedDocument } from '@zazume/zzm-base';
import { getToday } from '@zazume/zzm-utils';
import { useI18n } from '../../hooks/useI18n';
import { Can } from '../roles/Can';
import { SigningDocumentStateTag } from '../forms/signingDocumentPreview/SigningDocumentStateTag';

interface Props {
  document: Document;
  relatedModel: RelatedModelType;
  isSelectCategoryLocked?: boolean;
  withHiddenFromOwner?: boolean;
}

export const UpdateDocumentModalForm: FC<Props> = ({ document, isSelectCategoryLocked = false, relatedModel, withHiddenFromOwner = true }) => {
  const { t, language } = useI18n();

  const { setValue, watch, formState: { isSubmitting } } = ZZMForm.useFormContext();

  const isExpirable = watch('isExpirable');
  const hasSelectedFile = Boolean(watch('document.fileToAdd')?.length);
  const isSignatureDocument = document.signatureExternalId;
  const withFileUpload = !isSignatureDocument;

  const documentCategory = findDocumentCategory(document.type, document.relatedModel.name);
  const selectedCategory: DocumentCategories = watch('category') || documentCategory;

  const documentCategoriesOptions = getDocumentCategoriesOptions(relatedModel, t);
  const documentTypesOptions = getDocumentTypesOptions(selectedCategory, relatedModel, t);

  const isHiddenFromOwner = watch('isHiddenFromOwner');

  const selectCategoryHandler = () =>
    setValue('type', undefined, { shouldValidate: true });

  const SignatureTag = (isSignatureDocument && document.state)
    ? <SigningDocumentStateTag state={document.state} hasSignatureError={document.hasSignatureError}/>
    : undefined;

  return (<>
    <ResponsiveModalForm.Body>
      <ResponsiveModalForm.Row>
        <ResponsiveModalForm.Column>
          <Flex direction="column" gap="md">
            <ZZMForm.Select
              name="category"
              options={documentCategoriesOptions}
              placeholder={t('documentsModals.selectCategory')}
              label={t('documentsModals.category')}
              onChange={selectCategoryHandler}
              isLocked={isSelectCategoryLocked}
            />
            <ZZMForm.Select
              name="type"
              options={documentTypesOptions}
              placeholder={t('documentsModals.selectDocumentType')}
              label={t('documentsModals.documentType')}
            />
            <Flex direction="column" gap="s9">
              <Flex direction="column" gap="xs">
                <ZZMForm.Toggle name="isExpirable" label={t('documentsModals.isExpirable')}/>
                {isExpirable &&
                  <ZZMForm.DatePicker
                    label={t('documentsModals.when')}
                    name={'expireAt'}
                    placeholder={t('documentsModals.selectDate')}
                    minDate={getToday()}
                    help={t('documents.expirableDocumentsLength')}
                    language={language}
                  />}
              </Flex>
              <ZZMForm.Toggle name="isSignable" label={t('documentsModals.isSignable')} readOnly/>
              {withHiddenFromOwner && <Can hasRoles={['admin', 'buildingManager']}>
                <Flex gap="s6">
                  <ZZMForm.Toggle name="isHiddenFromOwner"/>
                  <Flex direction="column" gap="s2">
                    <Regular variant="S">{t('documents.isHiddenFromOwnerToggle.label')}</Regular>
                    <Regular variant="S" color="Gray400">
                      {t(`documents.isHiddenFromOwnerToggle.${isHiddenFromOwner ? 'active' : 'disabled'}`)}
                    </Regular>
                  </Flex>
                </Flex>
              </Can>}
            </Flex>
          </Flex>
        </ResponsiveModalForm.Column>
        <ResponsiveModalForm.Column>
          <Flex direction="column" gap="md">
            <Label htmlFor="document">{t('documentsModals.uploadDocument')}</Label>
            {withFileUpload && <ZZMForm.FileUpload
              name="document"
              variant="dragAndDrop"
              singleFile={true}
              dragAndDropHelp={t('documentsModals.documentSpecs', { maxFileSizeMB: 10 })}
              dragAndDropLabel={t('documentsModals.uploadDocument')}
            />}
            {!hasSelectedFile && <AttachedDocument
              icon="document"
              title={document.filename}
              tag={SignatureTag}
            />}
          </Flex>
        </ResponsiveModalForm.Column>
      </ResponsiveModalForm.Row>
    </ResponsiveModalForm.Body>
    <ZazumeModal.Footer
      acceptButtonText={t('general.saveChanges')}
      cancelButtonText={t('general.cancel')}
      acceptButtonType="submit"
      isSubmitting={isSubmitting}
    />
  </>);
};
