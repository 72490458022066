// TODO use common library (front, mobile..)
import { IdentifierDTO } from '@zazume/zzm-base';
import { arrayIsNotEmpty } from '../utils/arraysHelper';
import { Agent } from './user/Agent';
import { Owner } from './user/Owner';

export interface AuthResponse {
  authToken: string;
  token: string;
  user: User;
  organization: OrganizationResponse;
}

export interface OrganizationResponse {
  organizationId: IdentifierDTO;
  token: string;
  isDefaultOrganization: boolean;
}

export interface TokenData {
  _id: IdentifierDTO;
  name: string;
  role: UserRolesTypes;
  redirect: 'dashboard' | 'portal';
}

export interface OrganizationToken {
  token: string;
  role: UserRolesTypes;
  isDefaultOrganization: boolean;
}

export const hasRole = role => (tokenData: TokenData): boolean => tokenData?.role === role;
export const isTenant = (tokenData: TokenData) => hasRole('tenant')(tokenData);

export const UserRoles = <const>[
  'admin',
  'agent',
  'buildingManager',
  'commercial',
  'ownerBasic',
  'ownerManager',
  'ownerMiddle',
  'staff',
  'tenant',
  'viewer',
  'zazume'
];
export type UserRolesTypes = typeof UserRoles[number];
export type UserAdvanceRolesTypes = typeof UserRoles[number] | `${UserRolesTypes}:${string}`;

export interface User {
  _id: IdentifierDTO;
  email: string;
  name: string;
  fullName: string;
  surnames: string;
  roles: UserAdvanceRolesTypes[];
  countryCode: string;
  // TODO use LanguageVO directly
  lang: string;
  organization: IdentifierDTO;
  workspaceIds?: IdentifierDTO[];
  createdAt: Date,
  updatedAt: Date,
  avatar?: string;
  phoneNumber: string;
  idNumber: string;
  alias: string;
  appAccess: boolean;
  bio: string;
  videoUrl: string;
  owner?: Owner;
  activatedAt?: Date;
  createdByOwner?: IdentifierDTO;
  agent?: Agent;
  slug: string;
}

/**
 * @deprecated use UserHelper
 * @param user
 */
export const isZazumeUser = (user?: User): boolean => {
  if (!user) {
    return false;
  }
  return user?.roles.includes('zazume');
};

/**
 * @deprecated use UserHelper.hasSomeRole
 * @param user
 * @param roles
 */
export const hasUserRoles = (user: User | undefined, roles: UserAdvanceRolesTypes[]): boolean => {
  if (!user) {
    return false;
  }
  return roles.some(role => hasUserRole(user, role));
};

/**
 * @deprecated use UserHelper.hasRole
 * @param user
 * @param role
 */
export const hasUserRole = (user: User, role: UserAdvanceRolesTypes): boolean =>
  user.roles.includes(role);

/**
 * @deprecated use UserHelper
 * @param user
 * @param role
 */
const hasAdvanceRole = (user: User, role: UserRolesTypes): boolean => {
  const userRoles = user.roles;

  if (!userRoles) {
    return false;
  }

  return userRoles.some(userRole => {
    const destructuredRole = userRole.split(':');
    return arrayIsNotEmpty(destructuredRole) && destructuredRole[0] === role;
  });

};

/**
 * @deprecated use   const { isOwner } = useSession();
 * @param user
 */
export const isOwnerUser = (user?: User): boolean => {
  if (!user) {
    return false;
  }
  return hasAdvanceRole(user, 'ownerBasic') || hasAdvanceRole(user, 'ownerManager') || hasAdvanceRole(user, 'ownerMiddle');
};

/**
 * @deprecated use userHelper
 * @param user
 */
export const isOnlyAgent = (user?: User): boolean => {
  if (!user || isOwnerUser(user)) {
    return false;
  }
  return hasUserRoles(user, ['agent']);
};
