import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

const animation = keyframes`
  100% {
    height: 0
  }
`;

const Spinner = styled.span(({ theme }) => css`
  height: 30px;
  width: 10px;
  border-radius: 4px;
  color: var(--primary);
  background: currentColor;
  position: relative;
  animation: ${animation} 1s ease-in infinite alternate;
  box-shadow: 15px 0 0 -1px , -15px 0 0 -1px,
  30px 0 0 -2px , -30px 0 0 -2px,
  45px 0 0 -3px , -45px 0 0 -3px;
`);

export default Spinner;
