import { Colors } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from './index';

export const DirectOrder: FC<IconProps> = ({ color = Colors.FONT_1, size = 24 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M20 6.5V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V6.5M20 6.5V17.5M20 6.5H4M20 17.5V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V17.5M20 17.5H4M4 17.5V6.5M13.5 9H17.5M13.5 12H17.5M13.5 15H17.5M10.5 10C10.5 10 10.2055 9 9 9C7.79454 9 7.11174 9.70551 7.11174 10.5758V11.2721M10.5 14C10.5 14 10.1699 15.0758 9 15.0758C7.83011 15.0758 7.11174 14.3703 7.11174 13.5V12.9911M6 11.2721H7.11174M7.11174 11.2721H8.30436M7.11174 11.2721V12.9911M6 12.9911H7.11174M7.11174 12.9911H8.30436"
        stroke={color} strokeWidth={strokeWidth} strokeLinecap="round"/>
  </svg>;
};
