import { useI18n } from '#hooks/useI18n';
import { InvestmentAlert } from '#/models/investment/alert/InvestmentAlert';
import { IdentifierDTO, Table } from '@zazume/zzm-base';
import { PaginationContainer } from '#/components/table/components/paginator/PaginationContainer';
import { PaginationButton } from '#/components/table/components/paginator/PaginationButton';
import { DEFAULT_TABLE_LENGTH } from '#/hooks';
import { AlertTableItem } from '#/pages/investment/alerts/components/table/AlertTableItem';
import { AlertShortTableItem } from '#/pages/investment/alerts/components/table/AlertShortTableItem';
import { FC } from 'react';
import { EmptyTable } from '#/pages/investment/alerts/components/table/EmptyTable';


type MyAlertsTableProps = FC<{
  alerts: InvestmentAlert[];
  currentPage: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onEditAlert: (task: InvestmentAlert) => void;
  onClickAlert?: (id: IdentifierDTO) => void;
}> & {
  EmptyState: typeof EmptyTable;
}

export const MyAlertsTable: MyAlertsTableProps = ({ onEditAlert, onClickAlert, alerts, currentPage, onPreviousPage, onNextPage }) => {
  const { t } = useI18n();

  return <div>
    <Table>
      <Table.Short>
        {alerts.map(alert =>
          <AlertShortTableItem
            key={alert._id}
            alert={alert}
            onClick={onClickAlert}
            onEditAlert={onEditAlert}
          />
        )}
      </Table.Short>
      <Table.Base>
        <Table.Base.Header>
          <Table.Base.Title>{t('investment.myAlerts.table.header.location')}</Table.Base.Title>
          <Table.Base.Title>{t('investment.myAlerts.table.header.maxBudget')}</Table.Base.Title>
          <Table.Base.Title>{t('investment.myAlerts.table.header.profitability')}</Table.Base.Title>
          <Table.Base.Title>{t('investment.myAlerts.table.header.channel')}</Table.Base.Title>
          <Table.Base.Title>{t('investment.myAlerts.table.header.status')}</Table.Base.Title>
        </Table.Base.Header>
        <Table.Base.Body>
          {alerts.map(alert =>
            <AlertTableItem
              key={alert._id}
              alert={alert}
              onClick={onClickAlert}
              onEditAlert={onEditAlert}
            />
          )}
        </Table.Base.Body>
      </Table.Base>
    </Table>
    <PaginationContainer currentPage={currentPage} arrayLength={alerts.length}>
      <PaginationButton id="paginationPrevious" onClick={onPreviousPage} type="first" value={1} disabled={currentPage === 1}/>
      <PaginationButton id="paginationCurrent" value={currentPage} type="page" disabled/>
      <PaginationButton id="paginationNext" onClick={onNextPage} type="last" value={1} disabled={alerts.length < DEFAULT_TABLE_LENGTH}/>
    </PaginationContainer>
  </div>;
};

MyAlertsTable.EmptyState = EmptyTable;
