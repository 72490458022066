import { usePageContext, Variant } from '#/components/pages/detail/PageContext';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FCC } from '@zazume/zzm-base';
import React from 'react';

const getVariantStylesForDesktop = (theme, variant: Variant) => {
  if (variant === 'small') {
    return css`
      flex-direction: column;
      padding: 0;
    `;
  }

  return css`
    flex-direction: row;
  `;
};

const getVariantStylesBase = (theme, variant) => {
  if (variant === 'small') {
    return css`
      gap: ${theme.spacing.s8};
    `;
  }

  return css`
  `;
};

interface ContainerStyleProps {
  variant: Variant;
}

const Container = styled.div<ContainerStyleProps>(({ theme, variant }) => css`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  ${getVariantStylesBase(theme, variant)}
  ${theme.mq.desktop} {
    ${getVariantStylesForDesktop(theme, variant)}
  }
`);

interface PageDetailBodyProps {
}

export const PageDetailBody: FCC<PageDetailBodyProps> = ({ children }) => {
  const { variant } = usePageContext();
  return <Container variant={variant}>
    {children}
  </Container>;
};
