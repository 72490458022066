import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import React, { FC } from 'react';
import { QueryClient } from '../lib/reactQuery/QueryClient';

interface QueryClientProvidersProps {
  disableDevTools?: boolean;
  queryClient: QueryClient;
}

export const QueryClientProviders: FC<QueryClientProvidersProps> = ({ children, disableDevTools = false, queryClient }) => {
  return <QueryClientProvider client={queryClient}>
    {children}
    {!disableDevTools && <ReactQueryDevtools initialIsOpen={false}/>}
  </QueryClientProvider>;
};
