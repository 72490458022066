import React, { FC } from 'react';
import { IconProps } from './index';

export const EyeCrossed: FC<IconProps> = ({ color, size = 20 }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
        d="M9.82309 11.2376L10.5303 10.5303L11.2376 9.82308C11.2458
      9.88088 11.25 9.93994 11.25 10C11.25 10.6904 10.6904 11.25 10 11.25C9.93995 11.25 9.88088 11.2458 9.82309
      11.2376ZM7.59848 11.3409C7.37653 10.9442 7.25 10.4869 7.25 10C7.25 8.48122 8.48122 7.25 10 7.25C10.4869 7.25
      10.9442 7.37652 11.3409 7.59848L12.6139 6.3254C11.8685 5.97404 11.0012 5.75 10 5.75C7.77899 5.75 6.21669 6.85259
      5.18248 8.00172C4.66484 8.57687 4.28905 9.15456 4.04315 9.58851C3.95215 9.74908 3.87959 9.88883 3.82476 10C3.87959
      10.1112 3.95215 10.2509 4.04315 10.4115C4.28905 10.8454 4.66484 11.4231 5.18248 11.9983C5.44762 12.2929 5.74747
      12.5844 6.08423 12.8551L7.59848 11.3409ZM8.76243 10.1769C8.75424 10.1191 8.75 10.0601 8.75 10C8.75 9.30964 9.30965
      8.75 10 8.75C10.0601 8.75 10.1191 8.75423 10.1769 8.76242L9.46967 9.46967L8.76243 10.1769ZM7.38606 13.6746L8.65914
      12.4015C9.05582 12.6235 9.51314 12.75 10 12.75C11.5188 12.75 12.75 11.5188 12.75 10C12.75 9.51313 12.6235 9.05582
      12.4015 8.65914L13.9158 7.14488C14.2525 7.41558 14.5524 7.70712 14.8175 8.00172C15.3352 8.57687 15.711 9.15456
      15.9569 9.58851C16.0479 9.74908 16.1204 9.88883 16.1752 10C16.1204 10.1112 16.0479 10.2509 15.9569 10.4115C15.711
      10.8454 15.3352 11.4231 14.8175 11.9983C13.7833 13.1474 12.221 14.25 10 14.25C8.99883 14.25 8.13151 14.026 7.38606
      13.6746ZM5.01877 13.9206L3.46967 15.4697L4.53033 16.5303L6.27304 14.7876C7.29096 15.3576 8.52736 15.75 10 15.75C12.779
      15.75 14.7167 14.3526 15.9325 13.0017C16.5398 12.3269 16.9766 11.6546 17.2619 11.151C17.405 10.8985 17.5112 10.6863
      17.5827 10.5347C17.6185 10.4588 17.6457 10.3978 17.6645 10.3544C17.6739 10.3326 17.6813 10.3152 17.6866 10.3025L17.693
      10.2869L17.695 10.2818L17.6958 10.2799L17.6961 10.2792C17.6962 10.2789 17.6964 10.2785 17 10L17.6964 10.2785L17.8078
      10L17.6964 9.72146L17 10C17.6964 9.72146 17.6962 9.72114 17.6961 9.72081L17.6958 9.72005L17.695 9.71819L17.693
      9.71309L17.6866 9.69751C17.6813 9.68478 17.6739 9.66738 17.6645 9.64564C17.6457 9.60217 17.6185 9.54124 17.5827
      9.46534C17.5112 9.31367 17.405 9.10151 17.2619 8.84899C16.9766 8.34544 16.5398 7.67313 15.9325 6.99828C15.6538
      6.68864 15.3372 6.37656 14.9812 6.07942L16.5303 4.53033L15.4697 3.46967L13.727 5.21238C12.7091 4.64242 11.4727
      4.25 10 4.25C7.22102 4.25 5.28332 5.6474 4.06753 6.99828C3.46017 7.67313 3.02346 8.34544 2.73811 8.84899C2.59502
      9.10151 2.48882 9.31367 2.41731 9.46534C2.38153 9.54124 2.35436 9.60217 2.33552 9.64564C2.3261 9.66738 2.31875
      9.68478 2.31346 9.69751L2.30703 9.71309L2.30496 9.71819L2.30421 9.72005L2.30391 9.72081C2.30377 9.72114 2.30365
      9.72146 3.00001 10L2.30365 9.72146L2.19223 10L2.30365 10.2785L3.00001 10C2.30365 10.2785 2.30377 10.2789 2.30391
      10.2792L2.30421 10.2799L2.30496 10.2818L2.30703 10.2869L2.31346 10.3025C2.31875 10.3152 2.3261 10.3326 2.33552
      10.3544C2.35436 10.3978 2.38153 10.4588 2.41731 10.5347C2.48882 10.6863 2.59502 10.8985 2.73811 11.151C3.02346 11.6546
      3.46017 12.3269 4.06753 13.0017C4.34621 13.3114 4.66281 13.6234 5.01877 13.9206Z"
    />
  </svg>;
};
