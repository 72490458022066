import React, { FC } from 'react';
import { Bold, Regular, Semibold } from '@zazume/zzm-base';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NotificationIcon } from '#/components/notifications/NotificationIcon';
import { useMarkAsReadNotification } from '#hooks/api/notifications/useMarkAsReadNotification';
import { Notification } from '#/models/notifications/Notifications';
import { useDeepLinking } from '#hooks/router/useDeepLinking';
import { getCurrentUILanguage } from '#lib/i18n/utils';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: row;
  background-color: ${theme.colors.White};
  box-shadow: ${theme.shadows.s1};
  align-items: flex-start;
  padding: ${theme.spacing.md};
  gap: ${theme.spacing.xs};

  &:hover {
    background: ${theme.colors.Gray100};
    cursor: pointer;
  }
`);

const Description = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing.s2};
`);

const ReadEllipse = styled.div(({ theme }) => css`
  border-radius: 50%;
  min-height: 12px;
  min-width: 12px;
  width: 12px;
  height: 12px;
  background-color: ${theme.colors.Primary};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.Gray300};
  }
`);

const UnReadEllipse = styled.div(({ theme }) => css`
  border-radius: 50%;
  min-height: ${theme.spacing.s6};
  min-width: ${theme.spacing.s6};
  width: ${theme.spacing.s6};
  height: ${theme.spacing.s6};
  border: ${theme.borderWeights.light} solid ${theme.colors.Gray300};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.Primary};
  }
`);

interface SmallNotificationRowProps {
  notification: Notification;
  onCloseOverlay: () => void;
}

export const SmallNotificationRow: FC<SmallNotificationRowProps> = ({ notification, onCloseOverlay }) => {
  const { markAsRead, markAsUnRead } = useMarkAsReadNotification();
  const { navigateTo } = useDeepLinking();
  const locale = getCurrentUILanguage();

  const handleOnClick = async () => {
    onCloseOverlay();
    if (!notification.isRead()) {
      await markAsRead(notification._id);
    }
    navigateTo(notification.route);
  };

  const handleMarkAsRead = async (event) => {
    event.stopPropagation();
    await markAsRead(notification._id);
  };

  const handleMarkAsUnRead = async (event) => {
    event.stopPropagation();
    await markAsUnRead(notification._id);
  };

  return <Container onClick={handleOnClick}>
    <NotificationIcon category={notification.category} isAlert={notification.isAlert} variant="small"/>
    <Description>
      <Bold variant="S" color="Gray500">{notification.title}</Bold>
      <Semibold variant="S" color="Gray500">{notification.message}</Semibold>
      <Regular variant="XXS" color="Gray400">{notification.getCreateAt(locale)}</Regular>
    </Description>
    {!notification.isRead() && <ReadEllipse onClick={handleMarkAsRead}/>}
    {notification.isRead() && <UnReadEllipse onClick={handleMarkAsUnRead}/>}
  </Container>;
};
