import { FC } from 'react';
import { useI18n } from '#hooks/useI18n';
import { Document } from '#/models';
import { RejectedSignatureInfo } from '../../RejectedSignatureInfo';
import { Divider, Flex, SecondaryButton, Semibold, useButton } from '@zazume/zzm-base';
import { useNotifications } from '#/hooks';
import { useNetworkExceptions } from '#lib/network/helpers';
import { SignerStatus } from '#/components/documentUploader/documentModalInfo/components/SignerStatus';
import { DownloadAuditTrailButton } from '#/components/documentUploader/documentModalInfo/components/DownloadAuditTrailButton';

interface SignaturesModalInfoProps {
  document: Document;
  onSendReminder: () => Promise<void>;
  onAfterUpdate?: () => Promise<void>;
}

export const SignaturesModalInfo: FC<SignaturesModalInfoProps> = ({ document, onSendReminder, onAfterUpdate }) => {
  const { t } = useI18n();
  const { signers, rejected, state, signatureExternalId } = document;
  const { handleNetworkExceptions } = useNetworkExceptions();

  const { showNotification } = useNotifications();

  const handleOnSendReminder = async () => {
    try {
      await onSendReminder();
      showNotification(t('onboarding.contractSigning.notifications.resend'));
    } catch (e) {
      handleNetworkExceptions(e);
    }
  };

  const sendReminderButton = useButton(handleOnSendReminder, { afterSuccessDisable: true });

  if (!signatureExternalId || !signers) {
    return null;
  }

  const showSendReminder = state !== 'signed' && !rejected && state !== 'expired_signature' && onSendReminder;

  return (
    <>
      <Divider/>
      <Flex direction="column" gap="md">
        <Semibold variant="M">{t('documents.signatures')}</Semibold>
        {signers.map((signer, position) =>
          <SignerStatus
            key={signer.email}
            documentId={document._id}
            signer={signer}
            position={position}
            isRejected={Boolean(rejected)}
            onAfterUpdate={onAfterUpdate}/>)}
        {showSendReminder && <SecondaryButton {...sendReminderButton}>{t('onboarding.contractSigning.resend')}</SecondaryButton>}
        {rejected && <RejectedSignatureInfo rejected={rejected}/>}
        <DownloadAuditTrailButton document={document}/>
      </Flex>
    </>
  );
};
