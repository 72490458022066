import { I18nextProvider } from 'react-i18next';
import { DashboardLightTheme } from '@zazume/zzm-base';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';

import i18n from '../lib/i18n';
import { NotificationsContainer } from '#/containers';
import { AuthProvider } from './AuthProvider';
import { SearchProvider } from './SearchProvider';
import { QueryClientProviders } from './QueryClientProviders';
import { QueryClient } from '#lib/reactQuery/QueryClient';
import { OtherScriptsContainer } from '#/containers/misc/OtherScriptsContainer';
import { ScrollManager } from '#/contexts/ScrollManager';

const theme = DashboardLightTheme;
const queryClient = new QueryClient();

const AppProviders = ({ children }) => {

  return <RecoilRoot>
    <QueryClientProviders queryClient={queryClient}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <ScrollManager>
            <AuthProvider>
              <SearchProvider>
                <ThemeProvider theme={theme}>
                  {children}
                  <NotificationsContainer/>
                  <OtherScriptsContainer/>
                </ThemeProvider>
              </SearchProvider>
            </AuthProvider>
          </ScrollManager>
        </BrowserRouter>
      </I18nextProvider>
    </QueryClientProviders>
  </RecoilRoot>;
};

export default AppProviders;
