import { IdentifierDTO } from '@zazume/zzm-base';
import { BillingData } from './BillingData';

export interface Provider {
  _id: IdentifierDTO;
  name: string;
  organizationId: IdentifierDTO;
  phone?: string;
  email?: string;
  billingData?: BillingData;
  services: ProviderServices;
  relatedModel: ProviderRelatedModel;
  createdAt: Date;
}

export const ProviderRelatedModels = <const>[
  'Organization',
  'User'
];
export type ProviderRelatedModelType = typeof ProviderRelatedModels[number];

export interface ProviderRelatedModel {
  id: IdentifierDTO;
  name: ProviderRelatedModelType;
}

export const ProviderServicesTypes = <const>[
  'realStateAgent',
  'antennaOperator',
  'elevator',
  'boilers',
  'carpentry',
  'locksmith',
  'cee',
  'pestControl',
  'plumbing',
  'fireExtinguishers',
  'photography',
  'gardening',
  'intercom',
  'cleaning',
  'handyman',
  'personalShopper',
  'painter',
  'swimmingPools',
  'repairSkylights',
  'insurances',
  'supplies',
  'others'
];
export type ProviderService = typeof ProviderServicesTypes[number];

export type ProviderServices = {
  [key in ProviderService]: boolean;
}
