import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useSearch } from '#/hooks';
import { Colors } from '@zazume/zzm-base';
import { Caption } from '../typography';
import { UserResult } from './UserResult';
import { UnitResult } from './UnitResult';
import { IncidentResult } from './IncidentResult';
import { DefaultResult } from './DefaultResult';
import { ServiceResult } from './ServiceResult';
import { BuildingResult } from './BuildingResult';
import { OwnerAccountResult } from './OwnerAccountResult';
import { GenericUserResult } from './GenericUserResult';
import { useI18n } from '#hooks/useI18n';
import { AgentResult } from './AgentResult';
import { LocationResult } from '#/components/searcher/LocationResult';
import { Entities } from '#/utils/algolia/algoliaHelper';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 156px;
  max-height: 360px;
  cursor: pointer;
  padding: 0;
  user-select: none;
  overflow: auto;
`;

export const ResultCaption = styled(Caption)`
  padding-bottom: 8px;
  color: ${Colors.GREY_4};
`;

export interface ResultProps {
  result: any;
  onSelect: (result: any) => void;
}

export interface ResultListProps {
  name: string;
  entity: Entities;
  onSearch: any;
  selectAction: (selected: any) => void;
}

export const getResultComponent = (entity: Entities): FC<ResultProps> => {
  switch (entity) {
    case 'tenant':
    case 'staff':
    case 'provider':
      return UserResult;
    case 'unitManager':
    case 'accountManager':
    case 'applicationManager':
    case 'incidentsManager':
    case 'agent':
      return AgentResult;
    case 'user':
      return GenericUserResult;
    case 'unit':
      return UnitResult;
    case 'incident':
      return IncidentResult;
    case 'service':
      return ServiceResult;
    case 'building':
      return BuildingResult;
    case 'ownerAccount':
      return OwnerAccountResult;
    case 'location':
      return LocationResult;
    default:
      return DefaultResult;
  }
};

export const ResultList: FC<ResultListProps> = ({ name, entity, onSearch, selectAction }) => {
  const { t } = useI18n();

  const searchQueryUniqueKey = `search_${name}`;
  const { data: results, isLoading } = useSearch<any[]>(searchQueryUniqueKey, onSearch(name));

  const Result = getResultComponent(entity);

  return (
    <Container>
      <ResultCaption>{t('search.results')}</ResultCaption>
      {isLoading && <span>{t('general.loading')}</span>}
      {!isLoading && results && name &&
        results.map((result) => <Result key={result._id} result={result} onSelect={selectAction}/>)
      }
    </Container>
  );
};
