import { FC } from 'react';
import styled from '@emotion/styled';
import { Colors, Hamburger, Hidden, Visible } from '@zazume/zzm-base';
import { ProfileNavigation } from './ProfileNavigation';
import { HEADER_HEIGHT } from '../../utils/containers/containersConstants';
import { ResultItem } from '../../components/searchBar/atoms/ResultItem';
import { SearchBar } from '../../components';
import { useCurrentOrganization, useIsDefaultOrganization } from '../../contexts/AuthProvider';
import { OrganizationSelector } from './components/OrganizationSelector';
import { IndexName } from '../../utils/algolia/algoliaHelper';
import { css } from '@emotion/react';
import { MobileOrganizationSelector } from './components/MobileOrganizationSelector';
import { Notifications } from './components/notifications/Notifications';
import { useSession } from '../../hooks/session/useSession';
import { CommercializationUpgradeButton } from '../asideNavigation/components/CommercializationUpgradeButton';
import { isOnlyAgent, isOwnerUser } from '#/models';
import { useI18n } from '#hooks/useI18n';
import { HelpHub } from './components/help/HelpHub';
import { useBrand } from '#/hooks/useBrand';
import { CopilotIconButton } from './components/CopilotIconButton';

const Container = styled.div<any>(({ theme }) => css`
  position: fixed;
  top: 0;
  left: 0;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${Colors.BACKGROUND_0};
  box-shadow: inset 0px -1px 0px ${Colors.BACKGROUND_6};
  padding: ${theme.spacing.s8} ${theme.spacing.s7};
  gap: ${theme.spacing.s0};
  z-index: ${theme.zIndices.i4};

  ${theme.mq.tablet} {
    gap: ${theme.spacing.s12};
    padding: ${theme.spacing.s8} ${theme.spacing.s15};
  }

  ${theme.mq.desktop} {
    position: relative;
    padding: ${theme.spacing.s8} ${theme.spacing.s16};
  }
`);

const LeftHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;

  > div {
    width: 100%;
  }
`;

const RightHeader = styled.div<any>(({ theme }) => css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing.s8};
`);

const HamburgerButton = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  handleIsMobileMenuOpen: () => void;
}

const getIndexesToSearch = (user): IndexName[] => {
  if (isOwnerUser(user)) {
    return ['applications', 'tenants', 'units'];
  } else if (isOnlyAgent(user)) {
    return ['units'];
  } else {
    return ['applications', 'tenants', 'units', 'owners', 'entities', 'agents'];
  }
};

export const SimpleHeader: FC<Props> = ({ handleIsMobileMenuOpen }) => {
  const { isZazumeBrand } = useBrand();
  const organizationId = useCurrentOrganization();
  const isDefaultOrganization = useIsDefaultOrganization();
  const { t } = useI18n();
  const { user, isOwner } = useSession();

  const indexesToSearch: IndexName[] = getIndexesToSearch(user);
  const customPlaceholder = isOnlyAgent(user) ? t('globalSearch.placeholderAgent') : undefined;

  return <Container>
    <LeftHeader>
      <Hidden mobile tablet>
        <SearchBar indexes={indexesToSearch} organizationId={organizationId} renderItemsAs={ResultItem} placeholder={customPlaceholder}/>
      </Hidden>
      <Visible mobile tablet>
        <MobileOrganizationSelector/>
      </Visible>
    </LeftHeader>

    <RightHeader>
      <Hidden mobile>
        {isOwner && user.owner?.ownerAccountId && isDefaultOrganization && <CommercializationUpgradeButton ownerAccountId={user.owner?.ownerAccountId}/>}
      </Hidden>
      <Hidden mobile tablet>
        <OrganizationSelector/>
      </Hidden>
      <CopilotIconButton/>
      {isZazumeBrand && <HelpHub/>}
      <Notifications/>
      <ProfileNavigation/>
      <Visible mobile tablet>
        <HamburgerButton onClick={handleIsMobileMenuOpen}>
          <Hamburger color="Gray600"/>
        </HamburgerButton>
      </Visible>
    </RightHeader>
  </Container>;
};
