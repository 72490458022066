import { IdentifierDTO, ModelId } from '../../models';
import { QueryKey, useMutation, useQueryClient } from 'react-query';

type Mutator = (_id: ModelId) => Promise<any>;

interface UseDeleteProps {
  isOptimistic?: boolean;
}

const withNoId = (_id: IdentifierDTO) => item => item._id !== _id;

// TODO change UniqueKey to unknown[] (migration to the new version of react-query)
export const useDelete = (uniqueKey: QueryKey, mutator: Mutator, options: UseDeleteProps = {}) => {
  const { isOptimistic = true } = options;
  // TODO force to use uniqueKey as unknown[] (migration to the new version of react-query)
  const key = Array.isArray(uniqueKey) ? uniqueKey : [uniqueKey];

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(mutator, {
    onMutate: async (_id: any) => {
      await queryClient.cancelQueries(key);

      const result = queryClient.getQueryData<unknown[]>(key) || [];
      if (isOptimistic) {
        return result.filter(withNoId(_id));
      }
      return result;
    },
    onSettled: async () =>
      queryClient.invalidateQueries(key),

    onError: (error, variables, context) => {
      queryClient.setQueryData<unknown[]>(key, context as unknown[]);
    }
  });

  return {
    mutate: mutateAsync,
    isLoading
  };
};
