import { ZendeskButton } from '#/components/buttonsContact/ZendeskButton';
import { FC } from 'react';
import { Stack } from '../layout/Stack';
import { Can } from '../roles/Can';

export interface ButtonsContactProps {
  email?: string;
}

export const ButtonsEmailContact: FC<ButtonsContactProps> = ({ email }) => {
  return (
    <Stack spacing={8} align="center">
      {email && <Can hasRoles={['admin', 'buildingManager']}>
        <ZendeskButton email={email}/>
      </Can>}
    </Stack>
  );
};
