import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGet } from '../../../hooks';
import { API } from '../../../lib/network/API';
import { PageDetailBody, PageDetailHeader, PageDetailMain as Main } from '../../../components/pages/detail';
import { Private } from '../../../containers/router/routes/Private';
import styled from '@emotion/styled';
import { ResponsiveBody } from '../../../templates/ResponsiveBody';
import { Provider } from '../../../models';
import { ProvidersKeys } from '../../../hooks/api/providers/Providers.keys';
import { Spinner } from '@zazume/zzm-base';
import { useI18n } from '../../../hooks/useI18n';
import { ProviderDetailContent } from './components/providerDetail/Content';

const PageDetailMain = styled(Main)`
  margin: 0;
  align-items: center;
`;

const ProviderDetailPage: FC = () => {
  const { providerId } = useParams();
  const { t } = useI18n();

  const { data: provider, isLoading } = useGet<Provider>(ProvidersKeys.byId(providerId), API.providers.findById(providerId));

  return (
    <ResponsiveBody>
      <PageDetailBody>
        <PageDetailMain>
          <PageDetailHeader title={t('providers.title')} goBackRoute={Private.PROVIDERS.route}/>
          {isLoading && <Spinner color="Primary" size="extra-large"/>}
          {!isLoading && provider &&
            <ProviderDetailContent provider={provider}/>
          }
        </PageDetailMain>
      </PageDetailBody>
    </ResponsiveBody>
  );
};

export default ProviderDetailPage;
