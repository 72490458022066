import { FCC } from '@zazume/zzm-base';
import React, { createContext, useContext } from 'react';

interface DetailFieldsContextValue {
  areSectionsHidden: boolean;
}

export const useDetailFieldsContext = () => {
  const ctx = useContext(DetailFieldsContext);
  if (!ctx) {
    throw new Error('DetailFieldsContext must be used within DetailFields');
  }
  return ctx;
};

const DetailFieldsContext = createContext<DetailFieldsContextValue | null>(null);

interface DetailFieldsProviderProps {
  areSectionsHidden: boolean;
}

export const DetailFieldsProvider: FCC<DetailFieldsProviderProps> = ({ areSectionsHidden, children }) => {

  const ctx: DetailFieldsContextValue = {
    areSectionsHidden
  };

  return (
    <DetailFieldsContext.Provider value={ctx}>
      {children}
    </DetailFieldsContext.Provider>
  );
};
