import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BackButton, ForwardButton } from '../../buttons';
import { Headline3 } from '../../typography';
import { InfiniteGetData, useRouter } from '../../../hooks';
import { ZazumeRoute } from '../../../containers/router/routes/types';
import { useLocation } from 'react-router';
import { LocationState } from '../../../hooks/router/useRouter';
import { useQueryClient } from 'react-query';
import { Caret, FCC, Flex, IdentifierDTO } from '@zazume/zzm-base';
import { css } from '@emotion/react';
import { PageDetailHeaderSkeleton } from './skeleton/PageDetailHeaderSkeleton';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

const ActionsContainer = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing.s5};
`);

const getElementsFromQuery = (queryData?: InfiniteGetData | any[]): string[] | undefined => {
  if (!queryData) {
    return undefined;
  }

  return 'pages' in queryData
    ? queryData.pages.reduce((acc: IdentifierDTO[], element) => [...acc, ...element.data.map(e => e._id)], [])
    : queryData.map(e => e._id);
};


type Props = FCC<{
  title: string;
  /**
   * Dispatched when the user clicks on the BackButton.
   * If the function returns *true* the go back action by default (history(-1)) will be canceled.
   */
  onGoBack?: () => boolean;
  /**
   *
   */
  goBackRoute?: ZazumeRoute;
  currentElement?: IdentifierDTO;
  actionButton?: React.ReactElement;
}> & {
  Skeleton: FC;
}

export const PageDetailHeader: Props = ({ title, onGoBack, goBackRoute, currentElement, actionButton, children }) => {
  const { goBack, navigate, pathname } = useRouter();
  const { state } = useLocation();
  const queryClient = useQueryClient();

  const navigationQuery = (state as LocationState)?.navigationQuery;
  const elements = navigationQuery && getElementsFromQuery(queryClient.getQueryData(navigationQuery));
  const elementIndex: any = elements && currentElement && (elements.indexOf(currentElement));
  const nextElement = elements && elements[(elementIndex ?? -2) + 1];
  const previousElement = elements && elements[(elementIndex ?? 0) - 1];

  const showActions = Boolean(elements || actionButton);

  const handleOnGoBack = () => {
    const cancelGoBack = onGoBack?.() || false;
    if (!cancelGoBack) {
      (state as LocationState)?.canGoBack
        ? goBack()
        : goBack(goBackRoute);
    }
  };

  const onClickGoForward = () => {
    currentElement && nextElement && navigate(pathname.replace(currentElement, nextElement), { navigationQuery, replace: true });
  };

  const onClickGoBackward = () => {
    currentElement && previousElement && navigate(pathname.replace(currentElement, previousElement), { navigationQuery, replace: true });
  };

  return (
    <Container>
      <Flex justify="between" align="center">
        <Flex align="center">
          <BackButton onClick={handleOnGoBack}/>
          <Headline3>{title}</Headline3>
        </Flex>
        {showActions && <ActionsContainer>
          {actionButton}
          {Boolean(elements) && <>
            <BackButton onClick={onClickGoBackward} withMargin={false} disabled={!Boolean(previousElement)}><Caret color="Gray600" size={20} direction="left"/></BackButton>
            <ForwardButton onClick={onClickGoForward} withMargin={false} disabled={!Boolean(nextElement)}><Caret color="Gray600" size={20} direction="right"/></ForwardButton>
          </>}
        </ActionsContainer>}
      </Flex>
      {children}
    </Container>
  );
};

PageDetailHeader.Skeleton = PageDetailHeaderSkeleton;
