import React, { FC, ReactNode } from 'react';
import { Document, Hidden } from '@zazume/zzm-base';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Container = styled.div(({ theme }) => css`
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.s3};

  ${theme.mq.tablet} {
    gap: ${theme.spacing.s6};
  }
`);

const TagsContainer = styled.div(({ theme }) => css`
  display: flex;
  gap: ${theme.spacing.s4};
  margin-top: ${theme.spacing.s3};
  flex-wrap: wrap;
`);

const Title = styled.h1(({ theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s3};
  flex-wrap: wrap;
  margin: 0;
  font-size: ${theme.fontsSizes.sm};
  font-weight: bold;

  ${theme.mq.tablet} {
    font-size: ${theme.fontsSizes.md};
  }
`);

const Subtitle = styled.div(({ theme }) => css`
  font-size: ${theme.fontsSizes.xs};
  font-weight: normal;
  color: ${theme.colors.Gray400};

  ${theme.mq.tablet} {
    font-size: ${theme.fontsSizes.base};
  }
`);

const TitleContainer = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
`);

interface TopTitleContainerProps {
  icon?: ReactNode;
  rightIcon?: ReactNode;
  title?: string;
  subtitle?: string;
  tags?: any;
}

export const TopTitleContainer: FC<TopTitleContainerProps> = ({ icon, rightIcon, subtitle, title, tags }) => {
  return <Container>
    {icon && <Hidden mobile>
      {icon}
    </Hidden>}
    <TitleContainer>
      <Title>{title} {rightIcon}</Title>
      <Subtitle>{subtitle}</Subtitle>
      {tags && <TagsContainer>
        {tags}
      </TagsContainer>}
    </TitleContainer>
  </Container>;
};
