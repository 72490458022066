import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isLocalEnvironment } from '../Config';
import translationEN from './translations/en';
import translationES from './translations/es';

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  }
};

const options = {
  order: ['localStorage', 'navigator']
};

i18n
  .use(LanguageDetector as any)
  .use(initReactI18next as any)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'es'],
    debug: isLocalEnvironment,
    detection: options,
    interpolation: {
      escapeValue: false
    },
    resources: resources as any
  }).catch(console.error);

export default i18n;
