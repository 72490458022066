import { DashboardCard } from '#/models/dashboard/DashboardCard';
import { Dashboard } from '#lib/network/api/dashboard/models/dashboard';
import { ShareMeProfitability } from '#lib/network/api/dashboard/models/ShareMeProfitability';
import { Percentage, PriceVO } from '@zazume/zzm-utils';
import { DashboardRow } from './models/DashboardRow';

const toDashboardCard = (card: any): DashboardCard => {
  return { ...card };
};

const toDashboardRow = (row: any): DashboardRow => {
  return {
    ...row,
    cards: row.cards?.map(toDashboardCard),
    sections: row.sections?.map(toDashboardRow)
  };
};

export const toDashboard = (rawData: any): Dashboard => {
  return {
    rows: rawData.rows.map(toDashboardRow)
  };
};

export const toShareMeProfitability = (rawData: any): ShareMeProfitability => {
  return {
    years: rawData.years,
    grossProfitability: Percentage.fromPrimitive(rawData.grossProfitability),
    netProfitability: Percentage.fromPrimitive(rawData.netProfitability),
    cashOnCash: Percentage.fromPrimitive(rawData.cashOnCash),
    shortLink: rawData.shortLink,
    rentIncomes: PriceVO.fromPrimitive(rawData.rentIncomes),
    totalMonthlyRent: PriceVO.fromPrimitive(rawData.totalMonthlyRent)
  };
};
