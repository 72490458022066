import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ClipboardHelper, IdentifierDTO, Regular, ZZMForm, ZazumeModal, useZazumeModalContext } from '@zazume/zzm-base';
import { useI18n } from '#hooks/useI18n';
import { DocumentCategories, RelatedModelType } from '#/models';
import { CreateDocumentUploadLinkForm, getCreateDocumentUploadLinkSchema } from './CreateDocumentUploadLink.schema';
import { useAsyncMethod } from '#/hooks/useAsyncMethod';
import { useUpdate } from '#/lib/reactQuery/hooks/useUpdate';
import { DocumentUploadLinkKeys } from '#/hooks/api/documentUploadLink/DocumentUploadLink.keys';
import { API } from '#/lib/network/API';
import { DocumentUploadLinksCreateRequest } from '#/lib/network/api/documentUploadLink/request/DocumentUploadLinksCreateRequest';
import { getDocumentTypesOptions } from '#/components/modal/utils/utils';
import { CreateDocumentUploadLinkModalFooter } from './CreateDocumentUploadLinkModalFooter';
import { useNotifications } from '#/hooks';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s10};
`);

interface CreateDocumentUploadLinkModalProps {
  relatedModelId: IdentifierDTO;
  relatedModelType: RelatedModelType;
  category: DocumentCategories;
}

export const CreateDocumentUploadLinkModal: FC<CreateDocumentUploadLinkModalProps> = ({ relatedModelId, relatedModelType, category }) => {
  const { t } = useI18n();
  const { call } = useAsyncMethod();
  const { close } = useZazumeModalContext();
  const { showNotification } = useNotifications();

  const { mutate: create } = useUpdate(
    DocumentUploadLinkKeys.allByRelatedModelId(relatedModelId),
    API.documentUploadLinks.create);

  const onSubmit = (form: CreateDocumentUploadLinkForm) => call(async () => {
    const request = new DocumentUploadLinksCreateRequest(relatedModelId, relatedModelType, form.type, form.description);
    const newLink = await create(request);
    ClipboardHelper.writeText(newLink.url);
    showNotification(t('documentUploadLink.create.success'));
    close();
  });

  const schema = getCreateDocumentUploadLinkSchema(t);

  const documentTypesOptions = getDocumentTypesOptions(category, relatedModelType, t);

  return (
    <ZazumeModal title={t('documentUploadLink.create.title')}>
      <Container>
        <Regular variant="S" color="Gray500">{t('documentUploadLink.create.description')}</Regular>
        <ZZMForm.Form onSubmit={onSubmit} validation={schema}>
          <Container>
            <ZZMForm.Select
              name="type"
              label={t('documentUploadLink.fields.subcategory')}
              placeholder={t('general.forms.chooseSubcategory')}
              options={documentTypesOptions}
            />
            <ZZMForm.Textarea
              name="description"
              label={t('documentUploadLink.create.filesDescription')}
              placeholder={t('general.forms.extraIformation')}
              optionalText={t('general.optional')}
            />
            <CreateDocumentUploadLinkModalFooter/>
          </Container>
        </ZZMForm.Form>
      </Container>
    </ZazumeModal>
  );
};
