import React from 'react';
import { Dropdown } from '../../dropdown/Dropdown';
import styled from '@emotion/styled';
import { Check, FCC } from '@zazume/zzm-base';
import { DropdownItemProps } from '../../dropdown/atoms/Item';
import { css } from '@emotion/react';

const Container = styled.div(({ theme }) => css`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  background: rgba(255, 255, 255, 0.01);
  box-shadow: inset 0px -1px 0px #EBEEF4;

  &:hover {
    background: ${theme.colors.PrimaryLight4};
    color: ${theme.colors.Primary};
  }
`);

const ContainerDisabled = styled(Container)`
  cursor: not-allowed;

  &:hover {
    background: rgba(255, 255, 255, 0.01);
  }
`;

const Title = styled.div<any>(({ theme, icon }) => css`
  padding: 12px;
  ${icon && 'padding-left: 4px;'}
  color: ${theme.colors.Gray600};
  font-family: ${theme.fonts.base};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
`);

const SelectedTitle = styled(Title)(({ theme, icon }) => css`
  color: ${theme.colors.Primary};
  font-family: ${theme.fonts.base};
  ${icon && 'padding-left: 4px;'}
`);

const TitleDisabled = styled(Title)(({ theme }) => css`
  color: ${theme.colors.Gray400};
  font-family: ${theme.fonts.base};
  pointer-events: none;
`);

export interface OptionProps extends DropdownItemProps {
  name: string;
  value: string;
  currentValue?: any;
  disabled?: boolean;
  icon?: any;
  visible?: boolean
}

export const SelectOption: FCC<OptionProps> = ({ onClick, currentValue, value, name, disabled = false, icon, visible = true }) => {
  if (!visible) {
    return <></>;
  }
  if (disabled) {
    return <Dropdown.Item key={'option-' + value}>
      <ContainerDisabled>
        <TitleDisabled>{name}</TitleDisabled>
      </ContainerDisabled>
    </Dropdown.Item>;
  }

  const isSelected: boolean = value === currentValue;
  if (isSelected) {
    return <Dropdown.Item onClick={onClick} key={'option-' + value}>
      <Container>
        {isSelected
          ? <>
            {icon ?? null}
            <SelectedTitle icon={icon}>{name}</SelectedTitle>
            <Check color="Primary"/>
          </>
          : <>
            {icon ?? null}
            <Title icon={icon}>{name}</Title>
          </>
        }
      </Container>
    </Dropdown.Item>;
  }

  return <Dropdown.Item onClick={onClick} key={'option-' + name + value}>
    <Container>
      {icon ?? null}
      <Title icon={icon}>{name}</Title>
    </Container>
  </Dropdown.Item>;
};
