import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { PrimaryButton, TertiaryButton, ZazumeModal } from '@zazume/zzm-base';
import { Download } from '../icons/Download';
import { useGet } from '../../hooks/api/useGet';
import { API } from '../../lib/network/API';
import { FC } from 'react';
import { isImage } from '../../utils/fileHelper';
import { useI18n } from '../../hooks/useI18n';
import { Document } from '../../models';
import { PdfViewer } from './PdfViewer';
import { DocumentModalInfo } from './documentModalInfo/DocumentModalInfo';
import { useSigningDocument } from '../../hooks/application/useSigningDocument';
import { RejectSignatureButton } from './atoms/RejectSignatureButton';

const Footer = styled.div(({ theme }) => css`
  margin: ${theme.spacing.s10} ${theme.spacing.s0};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing.s8};
`);

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s10};

  ${theme.mq.tablet} {
    flex-direction: row;
  }
`);

const Image = styled.img(({ theme }) => css`
  max-width: 100%;

  ${theme.mq.tablet} {
    width: 31.25rem;
  }
`);

interface DocumentModalProps {
  document: Document;
  close: () => void;
  download: () => void;
  onAfterUpdate?: () => Promise<void>;
  canBeRejected?: boolean;
}

// TODO: create DocumentModalContext to avoid passing props between multiple components

export const DocumentModal: FC<DocumentModalProps> = ({ close, document, canBeRejected = true, download, onAfterUpdate }) => {
  const { t } = useI18n();
  const { _id: documentId, filename } = document;
  const { rejectSigningDocument, sendSignatureReminder } = useSigningDocument(documentId);
  const { data, isLoading: isLoadingData } = useGet(['document_url', documentId], API.documents.getURL(documentId), { refetchOnWindowFocus: false });

  if (isLoadingData || !data) {
    return null;
  }

  const isDocumentImage = isImage(filename);

  return (
    <ZazumeModal title={filename} onClose={close}>
      <Container>
        {
          isDocumentImage
            ? <Image src={data.url} alt={filename}/>
            : <PdfViewer document={data}/>
        }
        <DocumentModalInfo document={document} onSendReminder={sendSignatureReminder} onAfterUpdate={onAfterUpdate}/>
      </Container>
      <Footer>
        <RejectSignatureButton document={document} canBeRejected={canBeRejected} onRejectDocument={rejectSigningDocument} onAfterReject={onAfterUpdate}/>
        <TertiaryButton onClick={close}>
          {t('general.close')}
        </TertiaryButton>
        <PrimaryButton onClick={download}>
          <Download/> {t('documentsModals.downloadDocument')}
        </PrimaryButton>
      </Footer>
    </ZazumeModal>
  );
};
