import { Variants } from '@zazume/zzm-base';

export type GetStateStyle = {
  label: string;
  // TODO use new ZazumeColors
  lightColor: any;
  darkColor: any;
  regularColor: any;
}

export const getState = (state: number): GetStateStyle => {
  switch (state) {
    case 0:
      return {
        label: 'open',
        lightColor: 'OrangeLight',
        darkColor: 'OrangeDark',
        regularColor: 'Orange'
      };
    case 1:
      return {
        label: 'inProgress',
        lightColor: 'MostardLight',
        darkColor: 'MostardDark',
        regularColor: 'Mostard'
      };
    case 2:
      return {
        label: 'closed',
        lightColor: 'GreenLight2',
        darkColor: 'GreenDark2',
        regularColor: 'Green'
      };
    case 3:
    default:
      return {
        label: 'rejected',
        lightColor: 'Gray200',
        darkColor: 'Gray500',
        regularColor: 'Gray400'
      };
  }
};

export const StateTagVariants: { [key: number]: Variants } = {
  0: 'orange',
  1: 'warning',
  2: 'success',
  3: 'error'
};
