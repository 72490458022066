import { FCC } from '@zazume/zzm-base';
import React from 'react';
import styled from '@emotion/styled';
import { HEADER_HEIGHT } from '../../../utils/containers/containersConstants';
import { css } from '@emotion/react';

interface ContainerProps {
  width?: number;
  position?: 'left' | 'right';
}
const Container = styled.div<ContainerProps>(({ position, theme }) => css`
  flex: 1;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  width: 100%;
  overflow-y: hidden;
  border-left: 1px solid ${theme.colors.Gray200};

  position: sticky;
  top: 0;
  ${position === 'right' ? 'right: 0;' : 'left: 0;'}

  ${theme.mq.desktop} {
    width: 360px;
  }
`);

const ScrollableContent = styled.div`
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface DetailItems {
  ScrollableContent: any;
}

export const PageDetailAside: FCC<ContainerProps> & DetailItems = ({ children, position }) => {

  return <Container position={position}>
    { children }
  </Container>;
};

PageDetailAside.ScrollableContent = ScrollableContent;
