import { Regular, TertiaryButton, useModal, ZazumeModal } from '@zazume/zzm-base';
import { useNotifications } from '../../../hooks/useNotifications';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useI18n } from '../../../hooks/useI18n';

const Body = styled.div(({ theme }) => css`
  padding: 0 0 ${theme.spacing.s8} 0;
  min-height: 4.375rem;
`);

const Footer = styled.div(({ theme }) => css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${theme.spacing.s10};
`);

export const ErrorModal = ({ id, message }) => {
  const { t } = useI18n();
  const { closeNotification } = useNotifications();
  const { RenderModal, close } = useModal({ isOpen: true, modalSize: 's', alert: true, withMobileFullscreen: false });

  const handleOnClose = () => {
    close();
    closeNotification(id);
  };

  return (
    <RenderModal>
      <ZazumeModal
        title={t('general.errorOcurred')}
        onClose={handleOnClose}
        leftIcon="cross"
        circleIcon
      >
        <Body>
          <Regular color="Gray500" variant="S">{message}</Regular>
        </Body>
        <Footer>
          <TertiaryButton size="Large" onClick={handleOnClose}>{t('buttons.accept')}</TertiaryButton>
        </Footer>
      </ZazumeModal>
    </RenderModal>
  );
};
