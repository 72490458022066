import { FCC } from '@zazume/zzm-base';
import React, { createContext, useContext } from 'react';

interface RadioGroupContextValue {
  name: string;
}

export const useRadioGroupContext = () => {
  const ctx = useContext(RadioGroupContext);
  if (!ctx) {
    throw new Error('RadioGroupContext must be used within RadioGroup');
  }
  return ctx;
};

export const RadioGroupContext = createContext<RadioGroupContextValue | null>(null);

interface RadioGroupProviderProps {
  name: string;
}

export const RadioGroupProvider: FCC<RadioGroupProviderProps> = ({ name, children }) => {
  const ctx: RadioGroupContextValue = {
    name
  };

  return (
    <RadioGroupContext.Provider value={ctx}>
      {children}
    </RadioGroupContext.Provider>
  );
};
