import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isGoogleAnalyticsEnabled, isSegmentEnabled } from '../../lib/Config';
import GoogleAnalytics from '../../utils/metrics/gaHelper';
import { useAuth } from '../../contexts/AuthProvider';
import { isOwnerUser } from '../../models';
import Segment from '../../utils/metrics/segmentHelper';

const usePageTracking = () => {
  const location = useLocation();
  const { user } = useAuth();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (isOwnerUser(user)) {
      isGoogleAnalyticsEnabled() && GoogleAnalytics.startGoogleAnalytics(user);
      isSegmentEnabled() && Segment.initialize(user);
      setInitialized(true);
    } else if (user === undefined) {
      GoogleAnalytics.stopGoogleAnalytics();
      Segment.logout();
    }
  }, [user]);

  useEffect(() => {
    if (initialized) {
      isGoogleAnalyticsEnabled() && GoogleAnalytics.sendPageView(location.pathname + location.search);
      isSegmentEnabled() && Segment.trackPageView(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export default usePageTracking;
