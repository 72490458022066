import React, { FC, useState } from 'react';
import { Download, TertiaryButton, WindowHelper } from '@zazume/zzm-base';
import { useI18n } from '#hooks/useI18n';
import { Document } from '#/models';
import { API } from '#lib/network/API';
import { useNetworkExceptions } from '#lib/network/helpers';

interface DownloadAuditTrailButtonProps {
  document: Document;
}

export const DownloadAuditTrailButton: FC<DownloadAuditTrailButtonProps> = ({ document }) => {
  const { t } = useI18n();
  const [isLoading, setIsLoading] = useState(false);
  const { handleNetworkExceptions } = useNetworkExceptions();

  const showDownloadAuditTrail = document.state === 'signed';
  if (!showDownloadAuditTrail) {
    return null;
  }

  const handleDownloadAuditTrail = async () => {
    setIsLoading(true);
    try {
      const { url } = await API.documents.downloadAuditTrail(document._id);
      WindowHelper.open(url);
    } catch (e) {
      handleNetworkExceptions(e);
    } finally {
      setIsLoading(false);
    }
  };

  return <TertiaryButton onClick={handleDownloadAuditTrail} isSubmitting={isLoading}>
    <Download/>
    {t('documents.downloadAuditTrail')}
  </TertiaryButton>;
};
