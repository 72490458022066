import { Colors } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from '.';

export const Document: FC<IconProps> = ({ color = Colors.GREY_6, size = 20 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth}
      d="M7.80414 7.80404H14.7974M7.80414 12H15.9934M7.80414 16.1959H14.7974M6.00684 20.3919H17.9934C18.5457 20.3919 18.9934 19.9442 18.9934 19.3919V5.5C18.9934 3.84315 17.6502 3.60803 15.9934 3.60803H6.00684C5.45455 3.60803 5.00684 4.05575 5.00684 4.60803V19.3919C5.00684 19.9442 5.45455 20.3919 6.00684 20.3919Z"
    />
  </svg>;
};
