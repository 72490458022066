import React, { FC, ReactNode } from 'react';
import { Bold, Flex, getIcon, IconType, Regular, Semibold, Spacer } from '@zazume/zzm-base';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useI18n } from '../../../../hooks/useI18n';
import { currencySymbols, PricePrimitive } from '@zazume/zzm-utils';
import { I18NValues } from '../../../../lib/i18n/generatedKeys';

const Container = styled(Flex)(({ theme }) => css`
  text-align: center;
  padding-top: ${theme.spacing.s4};
`);

const IconContainer = styled.div`
  display: flex;
  position: relative;
`;

const IconOverlay = styled.div(({ theme }) => css`
  position: absolute;
  top: ${theme.spacing.s3};
  right: ${theme.spacing.s3};
  background: ${theme.colors.PrimaryLight4};
  border-radius: ${theme.radius.full};
  width: 2rem;
  height: 2rem;
`);

const PriceContainer = styled.div(({ theme }) => css`
  display: flex;
  align-items: baseline;
  margin-top: ${theme.spacing.s2};
  * {
    line-height: revert !important;
  }
`);

const Span = styled.div(({ theme }) => css`
  font-weight: ${theme.fontWeights.bold};
`);

interface ZazumeServiceDetailsProps {
  icon: IconType;
  quota?: number;
  amount?: string | ReactNode;
  serviceName?: string;
  monthlyRent?: PricePrimitive;
  withFootnote?: boolean;
  includeCurrency?: boolean;
}

export const ZazumeServiceDetails: FC<ZazumeServiceDetailsProps> = ({ icon, quota, amount, serviceName, monthlyRent, withFootnote = true, includeCurrency = true }) => {
  const { t } = useI18n();

  const Icon = getIcon(icon);

  if (!serviceName || !monthlyRent) {
    return (
      <Container direction="column" gap="s4" align="center" justify="center">
        <IconContainer>
          <IconOverlay/>
          <IconContainer>
            <Icon size={32} color="Primary"/>
          </IconContainer>
        </IconContainer>
        <Semibold variant="L">{(t('zazumeServices.noManagement'))}</Semibold>
      </Container>
    );
  }

  const currency = currencySymbols[monthlyRent.currency].symbol;

  const title = t(`zazumeServices.${serviceName}.title` as I18NValues);
  const titleHighlight = t(`zazumeServices.${serviceName}.titleHighlight` as I18NValues);

  const isServicePaid = Boolean(quota);

  return (
    <Container direction="column" gap="s4" align="center">
      <IconContainer>
        <IconOverlay/>
        <IconContainer>
          <Icon size={32} color="Primary"/>
        </IconContainer>
      </IconContainer>
      <Regular variant="L">{title} <Span>{titleHighlight}</Span></Regular>
      {isServicePaid
        ? <PriceContainer>
          <Bold variant="XXXXL" color="Primary">{amount}</Bold>
          {includeCurrency && <Bold variant="M" color="Primary">{currency}</Bold>}
        </PriceContainer>
        : <Bold variant="XXXXL" color="Primary">{t('zazumeServices.free')}</Bold>
      }
      {withFootnote &&
        (isServicePaid
          ? <>
            <Regular variant="S" color="Primary">{t('zazumeServices.receivedEachMonth')}</Regular>
            <Regular variant="XXS" color="Gray400">{t('zazumeServices.ofTheServie', { quota })}</Regular>
          </>
          : <>
            <Regular variant="XXS" color="Gray400">{t('zazumeServices.noSmallPrint')} :)</Regular>
            <Spacer vertical="s2"/>
          </>
        )
      }
    </Container>
  );
};
