import React, { createElement, FC, Fragment, useEffect, useRef } from 'react';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import { render } from 'react-dom';
import { SearchClient } from 'algoliasearch';
import { fromIndexNameToAlgolia, IndexName } from '../../../utils/algolia/algoliaHelper';
import { ResultGoToItem, ResultItemBase } from './ResultItem';
import styled from '@emotion/styled';
import { IdentifierDTO } from '@zazume/zzm-base';
import { useRouter } from '../../../hooks';
import { useI18n } from '#/hooks/useI18n';
import { ThemeProvider, useTheme } from '@emotion/react';

const Container = styled.div`
  display: flex;
  flex: 1;
`;

interface AutocompleteProps {
  openOnFocus?: boolean;
  searchClient: SearchClient;
  organizationId: IdentifierDTO;
  indexPrefix: string;
  indexes: IndexName[];
  renderItemsAs: ResultItemBase<{}>;
  placeholder?: string;
}

export const Autocomplete: FC<AutocompleteProps> = (props) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useI18n();
  const { navigate } = useRouter();

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const Component: any = props.renderItemsAs;
    const toQueries = (query: string) =>
      props.indexes.map(index => ({
        indexName: fromIndexNameToAlgolia(props.indexPrefix, props.organizationId)(index),
        query,
        params: {
          hitsPerPage: 10
        }
      }));

    const search = autocomplete({
      container: containerRef.current,
      defaultActiveItemId: 0,
      renderer: { createElement: createElement as any, Fragment },
      placeholder: props.placeholder ?? t('globalSearch.placeholder'),
      render({ children }, root) {
        render(children as any, root);
      },
      getSources: ({ query }) => [
        {
          sourceId: props.indexes.join('_'),
          getItems: () => {
            return getAlgoliaResults({
              searchClient: props.searchClient,
              queries: toQueries(query)
            });
          },
          templates: {
            item({ item, components }) {
              return <ThemeProvider theme={theme}>
                <Component hit={item} components={components} t={t}/>
              </ThemeProvider>;
            }
          },
          onSelect(params) {
            navigate(ResultGoToItem(params.item as any));
          }
        }
      ]
    });

    return () => {
      search.destroy();
    };
  }, [props, t, theme, navigate]);

  return <Container ref={containerRef}/>;
};
