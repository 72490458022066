import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FC } from 'react';

const cssPosition = (asideNav = false, paddingRight, theme) => {
  if (asideNav) {
    return css`
      padding-right: ${theme.spacing.md};
    `;
  } else if (paddingRight) {
    return css`
      padding-right: ${theme.spacing.s17};
    `;
  }
};

const Container = styled.div<any>(({ theme, paddingTop, paddingBottom, paddingLeft, paddingRight, asideNav }) => css`
  padding-top: ${paddingTop ? theme.spacing.s8 : theme.spacing.s0};
  padding-bottom: ${paddingBottom ? theme.spacing.s8 : theme.spacing.s0};
  padding-left: ${paddingLeft ? theme.spacing.s7 : theme.spacing.s0};
  padding-right: ${paddingRight ? theme.spacing.s7 : theme.spacing.s0};

  ${theme.mq.tablet} {
    padding-top: ${paddingTop ? theme.spacing.s12 : theme.spacing.s0};
    padding-bottom: ${paddingBottom ? theme.spacing.s12 : theme.spacing.s0};
    padding-left: ${paddingLeft ? theme.spacing.s15 : theme.spacing.s0};
    padding-right: ${paddingRight ? theme.spacing.s15 : theme.spacing.s0};
  }

  ${theme.mq.desktop} {
    padding-top: ${paddingTop ? theme.spacing.s14 : theme.spacing.s0};
    padding-bottom: ${paddingBottom ? theme.spacing.s14 : theme.spacing.s0};
    padding-left: ${paddingLeft ? theme.spacing.s17 : theme.spacing.s0};
    ${cssPosition(asideNav, paddingRight, theme)};
  }
`);

interface Props {
  paddingTop?: boolean;
  paddingBottom?: boolean;
  paddingLeft?: boolean;
  paddingRight?: boolean;
  children: any;
  asideNav?: boolean;
}

export const ResponsiveContainer: FC<Props> = ({ children, paddingTop = true, paddingBottom = true, paddingLeft = true, paddingRight = true, asideNav = false }) => {
  return (
    <Container paddingTop={paddingTop} paddingBottom={paddingBottom} paddingLeft={paddingLeft} paddingRight={paddingRight} asideNav={asideNav}>
      {children}
    </Container>
  );
};
