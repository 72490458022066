import React, { useEffect, useState } from 'react';

export const useOnScreen = (ref: React.MutableRefObject<any>) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isIntersectingBottom, setIsIntersectingBottom] = useState(false);
  const [isIntersectingTop, setIsIntersectingTop] = useState(false);
  const [isIntersectingLeft, setIsIntersectingLeft] = useState(false);
  const [isIntersectingRight, setIsIntersectingRight] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const { boundingClientRect, intersectionRect, intersectionRatio } = entry;

        if (intersectionRatio === 1) {
          setIsVisible(true);
          return;
        }

        setIsVisible(false);

        const isIntersectingVertical = boundingClientRect.height > intersectionRect.height;
        const isIntersectingHorizontal = boundingClientRect.width > intersectionRect.width;

        if (isIntersectingVertical) {
          const isBottom = intersectionRatio !== 0
            ? boundingClientRect.bottom > intersectionRect.bottom
            : boundingClientRect.top > boundingClientRect.height;

          const isTop = intersectionRatio !== 0
            ? boundingClientRect.top < intersectionRect.top
            : boundingClientRect.top < boundingClientRect.height;

          setIsIntersectingBottom(isBottom);
          setIsIntersectingTop(isTop);
        } else {
          setIsIntersectingBottom(false);
          setIsIntersectingTop(false);
        }

        if (isIntersectingHorizontal) {
          const isRight = boundingClientRect.right > intersectionRect.right;
          const isLeft = boundingClientRect.width > intersectionRect.left;

          setIsIntersectingRight(isRight);
          setIsIntersectingLeft(isLeft);
        } else {
          setIsIntersectingLeft(false);
          setIsIntersectingRight(false);
        }
      },
      { threshold: [0.0, 1] }
    );

    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref]);

  return { isVisible, isIntersectingBottom, isIntersectingTop, isIntersectingLeft, isIntersectingRight };
};
