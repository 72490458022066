import { useNotifications } from '../../hooks/useNotifications';
import { createPortal } from 'react-dom';
import { Toast } from '../../components/toast/Toast';

export const ToastContainer = () => {
  const { notifications } = useNotifications();
  const domEL = document.getElementById('toast') || document.body;
  const toastNotifications = notifications?.filter(n => n.options.type === 'info');

  if (!toastNotifications.length) {
    return null;
  }

  return createPortal(<>
    {toastNotifications.map(notification => {
      return <Toast
        key={notification.id}
        id={notification.id}
        message={notification.message}
        timeout={notification.options.timeout}
      />;
    }
    )}
  </>, domEL);
};
