import { IdentifierDTO } from '@zazume/zzm-base';
import { doGet, toPaginatedURL } from '../../helpers';
import { ActivityChange } from '#/models/activity/ActivityChange';
import { PaginationOptions } from '#/hooks';

export const activity = {
  getAll: (relatedModelId: IdentifierDTO) =>
    (pagination: PaginationOptions) =>
      doGet<ActivityChange[]>(toPaginatedURL(`/dashboard/activity/${relatedModelId}`, pagination))
};
