import { IdentifierDTO } from '@zazume/zzm-base';
import { SignatureConfiguration } from '../../../../../components/forms/signer/types';

export class UpdateDocumentRequest {
  private readonly formData: FormData;
  private signatureConfiguration?: SignatureConfiguration;
  private readonly data: any;
  readonly documentId: IdentifierDTO;

  constructor(documentId: IdentifierDTO, type: string) {
    this.formData = new FormData();
    this.documentId = documentId;
    this.data = {
      type
    };
  }

  withSignatureConfiguration(configuration: SignatureConfiguration) {
    this.data.signatureConfiguration = configuration;
  }

  build(): FormData {
    this.formData.append('data', JSON.stringify(this.data));
    return this.formData;
  }

  addExpiredAt(expireAt?: Date) {
    this.data.expireAt = expireAt;
  }

  addFile(file: File) {
    this.formData.append('documents', file, file.name);
  }

  addHiddenFromOwner(isHiddenFromOwner: boolean) {
    this.data.isHiddenFromOwner = isHiddenFromOwner;
  }
}
