import { FCC } from '@zazume/zzm-base';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollManager: FCC<any> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.['noScrollToTop']) {
      return;
    }
    window?.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};
