import React, { FC } from 'react';
import styled from '@emotion/styled';
import { AvatarWithName } from '../avatar/AvatarWithName';
import { ResultProps } from './ResultList';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0;
  user-select: none;
  padding: 12px 0;
`;

export const UserResult: FC<ResultProps> = ({ result, onSelect }) => {
  const handleSelect = () => onSelect(result);

  return (
    <Container onClick={handleSelect}>
      <AvatarWithName user={result} avatarSize="small" thin/>
    </Container>
  );
};
