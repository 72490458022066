import React, { FC } from 'react';
import { Flex, Regular, Semibold } from '@zazume/zzm-base';
import { formatBytes } from '@zazume/zzm-utils';
import { useI18n } from '#hooks/useI18n';

interface FileSizeProps {
  fileSize?: number;
}

export const FileSize: FC<FileSizeProps> = ({ fileSize }) => {
  const { t } = useI18n();

  if (!fileSize) {
    return null;
  }

  return <Flex direction="column" gap="s2">
    <Regular variant="XS" color="Gray500">{t('documents.size')}</Regular>
    <Semibold variant="S">{formatBytes(fileSize, 2)}</Semibold>
  </Flex>;
};
