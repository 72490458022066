import React, { FC } from 'react';
import { BodyTemplate } from '../../templates/BodyTemplate';
import { Headline } from '../../components/typography';
import { PageHeader } from '../../components/pages/pageHeader/PageHeader';

export const LoadingPageBody: FC = () => {
  return <BodyTemplate>
    <PageHeader>
      <Headline/>
    </PageHeader>
  </BodyTemplate>;
};
