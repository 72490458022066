import { IdentifierDTO } from '@zazume/zzm-base';

interface ConstructorParameters {
  relatedModel: PayerModel;
  id?: IdentifierDTO;
  name?: string;
  surnames?: string;
  avatar?: string;
}


export class Payer {
  relatedModel: PayerModel;
  id?: IdentifierDTO;
  name?: string;
  surnames?: string;
  avatar?: string;

  constructor(params: ConstructorParameters) {
    this.relatedModel = params.relatedModel;
    this.id = params.id;
    this.name = params.name;
    this.surnames = params.surnames;
    this.avatar = params.avatar;
  }

  static fromRaw(payer: any): Payer {
    return new Payer({
      relatedModel: payer.relatedModel,
      id: payer.id,
      name: payer.name,
      surnames: payer.surnames,
      avatar: payer.avatar
    });
  }
}

export const PayerModels = <const>['Owner', 'User'];
export type PayerModel = typeof PayerModels[number];
