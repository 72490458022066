import { CommercialisationService } from '#/domain/ownerServiceContract/CommercialisationService';
import { ManagementService } from '#/domain/ownerServiceContract/ManagementService';
import { OwnerServiceContract } from '#/domain/ownerServiceContract/OwnerServiceContract';
import { RentAdvanceService } from '#/domain/ownerServiceContract/RentAdvanceService';
import { Service } from '#/domain/ownerServiceContract/Service';
import { ServiceType } from '#/domain/ownerServiceContract/types';
import { assertUnreachable, DateVO } from '@zazume/zzm-utils';

// TODO typescript helper to remove the functions
const fromRawBase = (raw: any): Omit<OwnerServiceContract<Service>, 'service'> => ({
  _id: raw._id,
  ownerAccountsId: raw.ownerAccountsId,
  organizationId: raw.organizationId,
  documentId: raw.documentId,
  status: raw.status,
  endsAt: raw.endsAt ? DateVO.fromPrimitive(raw.endsAt) : undefined,
  startsAt: DateVO.fromPrimitive(raw.startsAt),
  unit: {
    ...raw.unit
  },
  broken: raw.broken ? {
    ...raw.broken,
    at: DateVO.fromPrimitive(raw.broken.at)
  } : undefined
} as any);

const toRentAdvance = (raw: any): OwnerServiceContract<RentAdvanceService> => {
  return new OwnerServiceContract<RentAdvanceService>({
    ...fromRawBase(raw),
    service: RentAdvanceService.fromRaw(raw.service)
  });
};

const toCommercialisation = (raw: any): OwnerServiceContract<CommercialisationService> => {
  return new OwnerServiceContract<CommercialisationService>({
    ...fromRawBase(raw),
    service: CommercialisationService.fromRaw(raw.service)
  });
};

const toManagement = (raw: any): OwnerServiceContract<ManagementService> => {
  return new OwnerServiceContract<ManagementService>({
    ...fromRawBase(raw),
    service: ManagementService.fromRaw(raw.service)
  });
};


export const fromRaw = (raw: any): OwnerServiceContract<Service> => {
  const type = raw.service.type as ServiceType;
  switch (type) {
    case 'RentAdvance':
      return toRentAdvance(raw);
    case 'Commercialisation':
      return toCommercialisation(raw);
    case 'Management':
      return toManagement(raw);
    default:
      assertUnreachable(type);
  }
};
