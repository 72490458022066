import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GoTo, Minus, Plus, Spinner, ZZMColor } from '@zazume/zzm-base';
import { FC, useState } from 'react';
import { useI18n } from '../../hooks/useI18n';
import { Document as DocumentPdf, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const zoomStep = 15;

const alignContent = (zoom) => {
  if (zoom <= 20) {
    return css`
      > div {
        display: flex;
        justify-content: center;
      }
    `;
  }
};

const PdfFrame = styled(DocumentPdf)(({ theme, zoom = 0 }) => css`
  background-color: ${theme.colors.Gray300};
  border-radius: ${theme.radius.xl};
  overflow: scroll;
  border: ${theme.borderWeights.light} solid ${theme.colors.Gray300};
  width: 100%;
  height: 30rem;

  .react-pdf__message--error {
    text-align: center;
    margin-top: ${theme.spacing.s16};
  }

  canvas {
    width: ${100 + zoom}% !important;
    height: auto !important;
  }

  ${theme.mq.tablet} {
    width: 31.25rem;
    height: 35rem;
  }

  ${theme.mq.desktop} {
    ${alignContent(zoom)}
    padding: ${theme.spacing.s8} ${theme.spacing.s0};
    canvas {
      width: ${80 + zoom}% !important;
      height: auto !important;
    }
  }
`);

const Container = styled.div(({ theme }) => css`
  position: relative;
`);

const LoadingSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Actions = styled.div(({ theme }) => css`
  position: absolute;
  bottom: ${theme.spacing.s8};
  left: 50%;
  transform: translateX(-50%);
  min-width: 9rem;
  height: 2.5rem;
  box-shadow: ${theme.shadows.md};
  border-radius: ${theme.radius.lg};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff8f;
  backdrop-filter: blur(15px);
  gap: ${theme.spacing.s6};
  cursor: pointer;
  transition: .15s;
  padding: ${theme.spacing.s8};
  :hover {
    background-color: ${theme.colors.White};
  }
`);

const Action = styled.div(({ theme }) => css`
  color: ${theme.colors.Gray500};
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: .15s;

  :active {
    transform: scale(0.8);
  }
`);

const FloatingButton = styled.div(({ theme }) => css`
  position: absolute;
  top: ${theme.spacing.s8};
  right: ${theme.spacing.s8};
  background-color: #ffffff8f;
  backdrop-filter: blur(15px);
  box-shadow: ${theme.shadows.sm};
  padding: ${theme.spacing.s4};
  border-radius: ${theme.radius.lg};
  cursor: pointer;
  transition: .15s;
  display: flex;
  justify-content: center;
  align-items: center;

  :active {
    transform: scale(0.8);
  }

  :hover {
    background-color: ${theme.colors.White};
  }
`);

const CustomPage = styled(Page)(({ theme }) => css`
  margin-bottom: ${theme.spacing.s6};
`);

interface Props {
  document: any;
}

export const PdfViewer: FC<Props> = ({ document }) => {
  const { t } = useI18n();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [numPages, setNumPages] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(15);

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const onLoadError = () =>
    setIsLoading(false);

  const onZoomIn = () =>
    setZoom(prev => prev + zoomStep);

  const onZoomOut = () =>
    (zoom - zoomStep >= 0) && setZoom(prev => prev - zoomStep);

  const onOpenTab = () => window.open(document.url);

  const zoomOutIconColor: ZZMColor = zoom === 0 ? 'Gray300' : 'Gray500';
  const scale = zoom <= 30 ? 1 : 3;

  return (
    <Container>
      <PdfFrame
        file={document.url}
        onLoadSuccess={onLoadSuccess}
        onLoadError={onLoadError}
        loading=""
        zoom={zoom}
        error={t('documentsModals.loadingError')}
      >
        {Array.from(Array(numPages), (el, index) => (
          <CustomPage
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={scale}
            renderAnnotationLayer={false}
          />
        ))}
        <Actions>
          <Action onClick={onZoomOut}>
            <Minus color={zoomOutIconColor}/>
          </Action>
          <Action>{zoom + 100}%</Action>
          <Action onClick={onZoomIn}>
            <Plus color="Gray500"/>
          </Action>
        </Actions>
        <FloatingButton onClick={onOpenTab}>
          <GoTo color="Gray500"/>
        </FloatingButton>
      </PdfFrame>

      {isLoading && <LoadingSpinner>
        <Spinner color="Primary" size="extra-large"/>
      </LoadingSpinner>}
    </Container>
  );
};
