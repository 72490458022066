import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { Colors, FCC, Fonts } from '@zazume/zzm-base';
import { Cross } from '../icons';
import { Body } from './atom/Body';
import { Footer } from './atom/Footer';

type ContainerSize = 'small' | 'medium' | 'default'
interface ContainerProps {
  size?: ContainerSize
}

//TODO Refactor line 17. Move this logic to a function i.e. getContainerSize, which returns the vale for each size type (400px, 490px, default)
const Container = styled.div<ContainerProps>(({ size }) => css`
  padding: 24px;
  width: ${size === 'small' ? '400px' : size === 'medium' ? '490px' : 'default'};
`);

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > h1 {
    font-family: ${Fonts.BASE};
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: ${Colors.FONT_6};
    width: 100%;
    margin: 0;
  }
`;

interface BaseModalAtoms {
  Body: FCC<any>;
  Footer: FCC<any>;
}

interface BaseModalProps {
  title?: string;
  hide: () => void;
  cross?: boolean;
}

export type BaseModalType = FCC<BaseModalProps & ContainerProps> & BaseModalAtoms;

/**
 * @deprecated use zzm-base modals
 * @param title
 * @param hide
 * @param cross
 * @param children
 * @param props
 * @constructor
 */
export const BaseModal: BaseModalType = ({ title = '', hide, cross = true, children, ...props }) => {

  return <Container {...props }>
    <Header>
      <h1>{title}</h1>
      {cross && <Cross onClick={hide}/>}
    </Header>
    {children}
  </Container>;
};

BaseModal.Body = Body;
BaseModal.Footer = Footer;
