import { Regular, ZZMForm } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { useI18n } from '#hooks/useI18n';
import styled from '@emotion/styled';
import { City } from '#/models/location/City';
import { CreateAlertModalBody } from '#/pages/investment/alerts/components/modal/CreateAlertModalBody';
import { css } from '@emotion/react';

const Body = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s10};

  & > * {
    padding-bottom: 8px;
  }
`);

const InputContainer = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s10};
`);

const InputRow = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${theme.spacing.s10};
`);

type CreateAlertModalBodyProps = {
  setCity: (city: City) => void;
}

export const EditAlertModalBody: FC<CreateAlertModalBodyProps> = ({ setCity }) => {
  const { t } = useI18n();

  return (
    <Body>
      <InputContainer>
        <Regular variant="L">{t('investment.alertModal.form.status')}</Regular>
        <InputRow>
          <ZZMForm.Toggle name="isActive" label={t('investment.alertModal.form.isActive')}/>
          <Regular variant="S" color="Gray500">{t('investment.alertModal.form.statusInfo')}</Regular>
        </InputRow>
      </InputContainer>
      <CreateAlertModalBody setCity={setCity}/>
    </Body>
  );
};
