import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { InputHTMLAttributes } from 'react';

type StyleProps = {
  hasErrors?: boolean;
  hasHighlight?: boolean;
  autoComplete?: boolean;
};

const InputFieldBase = styled.input<StyleProps>(({ theme, hasErrors, hasHighlight, autoComplete }) => css`
  position: relative;
  width: 100%;
  height: 2.5rem;
  margin: 0;
  padding-top: ${theme.spacing.xs};
  padding-bottom: ${theme.spacing.xs};
  padding-left: ${theme.spacing.sm};
  padding-right: ${theme.spacing.sm};
  border-radius: ${theme.radius.lg};
  border: ${theme.borderWeights.light} solid ${theme.colors.Gray300};
  border-color: ${hasErrors ? theme.colors.Red : hasHighlight ? theme.colors.MostardDark : theme.colors.Gray300};
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: ${theme.fontsSizes.base};
  line-height: ${theme.lineHeights.md};
  color: ${theme.colors.Gray600};
  outline: none;

  ::placeholder {
    color: ${theme.colors.Gray400};
  }

  &:focus {
    border: ${theme.borderWeights.light} solid ${theme.colors.Blue};
    border-radius: ${theme.radius.lg};
  }

  &:hover {
    border: ${theme.borderWeights.light} solid ${theme.colors.Blue};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: ${autoComplete === 'off' ? '0 0 0 1000px white inset !important' : ''};
  }

  :read-only {
    background-color: ${theme.colors.BlueLight};
    color: ${theme.colors.Gray400};
    pointer-events: none;

    &:focus {
      border: ${theme.borderWeights.light} solid ${theme.colors.Gray300};
    }
  }
`);

interface InputStyleProps {
  hasErrors?: boolean;
  hasHighlight?: boolean;
  autoComplete?: boolean;
}

type InputBaseProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> & InputStyleProps;

/**
 * @deprecated use src/components/forms/input/InputBase.tsx
 */
export const InputBase = React.forwardRef<any, InputBaseProps>((props, ref) => {
  return <InputFieldBase {...props} ref={ref}/>;
});
