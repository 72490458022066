import { useAuth } from '#/contexts/AuthProvider';
import { usePermissions } from '#/hooks';
import { UserRolesTypes } from '#/models';
import { FCC } from '@zazume/zzm-base';
import { BooleanHelper } from '@zazume/zzm-utils';
import React from 'react';

export interface CanProps {
  if?: boolean;
  hasRoles: UserRolesTypes[];
  inDefaultOrganization?: boolean;
  hideFromZazumeUser?: boolean;
  shouldHideToBlacklistUsers?: boolean;
}

export const Can: FCC<CanProps> = ({ hasRoles, inDefaultOrganization = false, children, hideFromZazumeUser, shouldHideToBlacklistUsers = false, ...rest }) => {
  const { canRender } = usePermissions();
  const { organization, isBlacklisted } = useAuth();

  if (shouldHideToBlacklistUsers && isBlacklisted()) {
    return null;
  }

  if (BooleanHelper.isFalse(rest.if)) {
    return null;
  }

  if (!canRender(hasRoles, hideFromZazumeUser)) {
    return null;
  }

  if (inDefaultOrganization) {
    if (!organization?.isDefaultOrganization) {
      return null;
    }
  }

  return <>{children}</>;
};
