import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Spinner from '../components/spinner/Spinner';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--primary-light);
`);

export const LoadingPage: FC = () => {
  return <Container>
    <Spinner/>
  </Container>;
};
