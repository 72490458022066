import { IdentifierDTO } from '@zazume/zzm-base';
import { PaginationOptions } from '../../../../hooks';
import { BasicUnit, BasicUser, Incident, IncidentComment } from '../../../../models';
import { doGet, doPostFileUpload, doPut, doPutFileUpload, EndpointBuilder, toPaginatedURL } from '../../helpers';
import { CreateDocumentRequest } from '../documents/requests/CreateDocumentRequest';
import { UpdateDocumentRequest } from '../documents/requests/UpdateDocumentRequest';
import { UpdateNoteFormData } from '#/pages/workspaces/incidents/components/incidentDetail/notesTab/UpdateNoteForm.schema';

export const incidents = {
  create: (body) =>
    doPostFileUpload<any>('/incidents/dashboard', body),
  createDocument: (id: IdentifierDTO) => (request: CreateDocumentRequest) =>
    doPostFileUpload<void>(`/incidents/documents/${id}/`, request.build()),
  getAllIncidentUnits: (params: EndpointBuilder) => () =>
    doGet<BasicUnit[]>(`/incidents/units${params.url}${params.queryParams}`),
  getAllIncidentStaff: (params: EndpointBuilder) => () =>
    doGet<BasicUser[]>(`/incidents/staff${params.url}${params.queryParams}`),
  getById: (id: IdentifierDTO) => () =>
    doGet<any>(`/incidents/${id}`),
  getByTenant: (tenantId: IdentifierDTO) => () =>
    doGet<Incident[]>(`/incidents/tenant/${tenantId}?sort=date`),
  getByUnitId: (unitId: IdentifierDTO) => () =>
    doGet<Incident[]>(`/incidents/unit/${unitId}`),
  getDocuments: (id: IdentifierDTO) => () =>
    doGet<Document[]>(`/incidents/${id}/documents`),
  getPaginated: (params: EndpointBuilder) => (pagination: PaginationOptions) => () =>
    doGet<Incident[]>(toPaginatedURL(`/incidents${params.url}${params.queryParams}`, pagination)),
  getRecent: (organizationId: IdentifierDTO) => () =>
    doGet<Incident[]>(`/incidents/search/${organizationId}`),
  searchByName: (organizationId: IdentifierDTO) => (name: string) => () =>
    doGet<Incident[]>(`/incidents/search/${organizationId}?name=${name}`),
  sendMessage: (id: IdentifierDTO) => (body: object) =>
    doPut<IncidentComment[]>(`/incidents/${id}/comments`, body),
  update: (id: IdentifierDTO) => (body: object) =>
    doPut<Incident>(`/incidents/${id}`, body),
  updateCategory: (id: IdentifierDTO) => (body: object) =>
    doPut<void>(`/incidents/${id}/category`, body),
  updateDocument: (id: IdentifierDTO) => (request: UpdateDocumentRequest) =>
    doPutFileUpload<void>(`/incidents/documents/${id}/${request.documentId}`, request.build()),
  updateNote: (id: IdentifierDTO) => (body: UpdateNoteFormData) =>
    doPut<void>(`/incidents/${id}/note`, body),
  updatePriority: (id: IdentifierDTO) => (body: object) =>
    doPut<any>(`/incidents/${id}/priority`, body),
  updateState: (id: IdentifierDTO) => (body: object) =>
    doPut<any>(`/incidents/${id}/state`, body),
  updateStaff: (id: IdentifierDTO) => (body: object) =>
    doPut<any>(`/incidents/${id}/staff`, body),
  updateUnreadComments: (incidentId: IdentifierDTO) =>
    doPut(`/incidents/${incidentId}/read`)
};
