import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { getIcon, IconType, ZZMColor } from '@zazume/zzm-base';
import { ObjectHelper } from '@zazume/zzm-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { TinyArrow } from '../..';
import { Dropdown, DropdownVariant } from '../../dropdown/Dropdown';
import { Body12 } from '../../typography/Body12';
import { parseErrors, WithErrors } from '../utils';
import { SelectOption } from './SelectOption';

const Container = styled.div<any>`
  position: relative;
  width: ${props => props.style && props.style.width ? props.style.width : '100%'};
  font-family: ${props => props.theme.fonts.base};
  margin-bottom: ${props => props.style && props.style.marginBottom ? props.style.marginBottom : '0px'};
  cursor: ${props => props.isLocked && 'pointer'};

  & > fieldset {
    border: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
  }
`;

const Header = styled.div<any>(({ theme, size, borderColor, bold, textSize, textColor, isLocked, isUsingPlaceholder, backgroundColor, trim, hoverColor }) => css`
  font-family: ${theme.fonts.base};
  padding: ${size === 'small' ? '6px 0 6px 8px' : '8px 0 8px 12px'};
  display: flex;
  align-items: center;
  border: 1px solid ${borderColor ? borderColor : theme.colors.Gray300};
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0;
  width: 100%;
  height: ${size === 'small' ? '32px' : '40px'};
  appearance: none;
  font-style: normal;
  font-weight: ${bold ? '600' : 'normal'};
  font-size: ${textSize ? textSize + 'px' : size === 'small' ? '14px' : '16px'};
  line-height: ${size === 'small' ? '16px' : '24px'};
  color: ${textColor || ((isLocked || isUsingPlaceholder) && theme.colors.Gray400) || theme.colors.Gray600};
  user-select: none;
  background: ${backgroundColor ? backgroundColor : theme.colors.White};

  ${trim ?
    `padding-right: 32px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;` : ''}
  & option {
    display: none;
  }

  &:hover {
    ${isLocked ? null :
    `border: 1px solid ${hoverColor ? hoverColor : theme.colors.Primary};`}
  }

  &:focus {
    outline: none;
    border: 1px solid ${theme.colors.Primary};
    border-radius: 8px;
  }

  & > svg {
    margin-right: 4px;
    margin-left: -6px;
  }
`);

const Highlight = styled(Body12)(({ theme }) => css`
  color: ${theme.colors.MostardDark};
  margin: 2px 0;
`);

const Label = styled.label(({ theme }) => css`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: ${theme.colors.Gray600};
  margin: 0;
  width: 100%;
  height: 28px;
`);

const Helper = styled.div(({ theme }) => css`
  color: ${theme.colors.Gray400};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0;
`);

const Error = styled.div(({ theme }) => css`
  color: ${theme.colors.Red};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0;
`);

const SelectorWrapper = styled.div<any>(({ theme, hasErrors, highlight, verticalPosition, elementHeight, size }) => css`
  display: flex;
  position: relative;
  width: 100%;

  > div {
    ${hasErrors && `border-color: ${theme.colors.Red};`}
    ${highlight && `border-color: ${theme.colors.MostardDark};`}
  }

  > ul {
    position: absolute;
    top: ${verticalPosition === 'top' ? -(elementHeight + 5) : 45}px;
  }
`);

const TinyArrowCustom = styled(TinyArrow)(({ size }) => css`
  position: absolute;
  top: ${size}px;
  right: ${size}px;
  pointer-events: none;
`);

export type SizeTypes = 'medium' | 'small';

export interface SelectProps extends WithErrors {
  defaultValue?: OptionType;
  label?: string;
  name?: string;
  help?: string;
  options: OptionType[];
  placeholder?: string;
  onSelected?: (option: OptionType) => void;
  forceUpdate?: OptionType;
  backgroundColor?: string;
  textColor?: string;
  textSize?: number;
  borderColor?: string;
  hoverColor?: string;
  bold?: boolean;
  headerIcon?: IconType;
  headerIconColor?: ZZMColor;
  verticalPosition?: VerticalPositions;
  isLocked?: boolean;
  trimSelected?: boolean;
  register?: any;
  setValue?: any;
  readOnly?: boolean;
  size?: SizeTypes;
  /** @deprecated use clearErrors instead */
  clearError?: (inputName: string) => void;
  /** @deprecated avoid inline styles props */
  style?: ContainerStyle;
  highlight?: boolean;
  highlightMessage?: string;
  variant?: DropdownVariant;
  hidden?: boolean;
}

/**
 * @deprecated
 */
export interface OptionType {
  value: any;
  name: string;
  disabled?: boolean;
  icon?: any;
}

export interface ContainerStyle {
  marginBottom?: string;
  width?: string;
}

export type VerticalPositions = 'top' | 'bottom';

const OPTION_HEIGHT = 44;

const isOptionsEqual = (option1, option2) => {
  if (typeof option1 === 'object') {
    return ObjectHelper.isDeepEqual(option1, option2);
  }
  return option1 === option2;
};

//TODO Refactor this component, difficult to maintain
/**
 * @deprecated use src/components/forms/select/SelectBase.tsx
 */
export const Select = React.forwardRef<any, SelectProps>((props, ref) => {
  const {
    name,
    label,
    help,
    options,
    defaultValue,
    placeholder,
    onSelected,
    backgroundColor,
    textColor,
    textSize,
    borderColor,
    hoverColor,
    bold,
    forceUpdate,
    headerIcon,
    headerIconColor,
    verticalPosition,
    isLocked = false,
    trimSelected = false,
    readOnly = false,
    register,
    setValue,
    clearErrors,
    size = 'medium',
    highlight,
    highlightMessage,
    style, // TODO: remove inline style props,
    variant = 'normal',
    hidden = false,
    ...styles
  } = props;

  const { colors: themeColors } = useTheme();

  const [selected, setSelected] = useState<OptionType | undefined>(defaultValue);
  const [headerText, setHeaderText] = useState<string>(selected?.name || placeholder || '');
  const { hasErrors, errorMessage } = parseErrors(props);
  const optionsScrollHeight = Object.keys(options).length * OPTION_HEIGHT;
  const optionsWrapperHeight = optionsScrollHeight > 248 ? 248 : optionsScrollHeight;
  const addRegister = register ? { ...register(name) } : { ref };
  const [baseColor, setBaseColor] = useState<ZZMColor>(selected?.name ? 'Gray500' : 'Gray400');

  const isSelectedOptionRemoved = !Boolean(options.find(option => option.value === selected?.value));
  const isSelectedOptionNameChanged = !Boolean(options.find(option => option.name === selected?.name));

  const updateSelect = useCallback((headerText: string, selected?: OptionType) => {
    const handleBaseColor = (hasValue: boolean) => () => hasValue ? setBaseColor('Gray500') : setBaseColor('Gray400');
    handleBaseColor(Boolean(selected));
    setSelected(selected);
    setHeaderText(headerText);
    setValue && name && setValue(name, undefined, {
      shouldDirty: true
    });
  }, [name, setValue]);

  useEffect(() => {
    forceUpdate && updateSelect(forceUpdate.name, forceUpdate);
  }, [options, forceUpdate, updateSelect]);

  useEffect(() => {
    if (isSelectedOptionNameChanged || isSelectedOptionRemoved) {
      const option = options.find(option => isOptionsEqual(option.value, selected?.value));
      const headerText = option?.name || placeholder || '';
      setHeaderText(headerText);
    }
  }, [options, placeholder, selected, isSelectedOptionNameChanged, isSelectedOptionRemoved]);

  useEffect(() => {
    defaultValue && setValue && name && selected === defaultValue && setValue(name, defaultValue.value, {
      shouldDirty: false
    });
  }, [defaultValue, setValue, name, selected]);


  const Icon = headerIcon && getIcon(headerIcon);
  const RenderedIcon = Icon ? <Icon color={headerIconColor || baseColor} size={20}/> : null;

  const headerBackground = readOnly ? themeColors.PrimaryLight4 : backgroundColor;

  const header = <Header
    backgroundColor={headerBackground}
    borderColor={borderColor}
    textColor={textColor}
    textSize={textSize}
    hoverColor={hoverColor}
    bold={bold}
    isLocked={isLocked || readOnly}
    isUsingPlaceholder={!Boolean(selected?.name)}
    trim={trimSelected}
    size={size}
  >{selected?.icon ?? RenderedIcon ?? null}{headerText}</Header>;

  const handleOnClickOption = option => () => {
    setSelected(option);
    name && clearErrors?.(name);
    setValue && name && setValue(name, option.value, {
      shouldValidate: true,
      shouldDirty: true
    });
    setHeaderText(option.name);
    if (onSelected) {
      onSelected(option);
    }
  };

  if (hidden) {
    return <input type="hidden" {...addRegister} name={name} aria-labelledby={name}/>;
  }

  return <Container style={style} {...styles}>
    <fieldset>
      {label && <Label htmlFor={name} id={name}>{label}</Label>}
      <input type="hidden" {...addRegister} name={name} defaultValue={selected?.value} aria-labelledby={name}/>
      <SelectorWrapper hasErrors={hasErrors} verticalPosition={verticalPosition} elementHeight={optionsWrapperHeight} highlight={highlight}>
        <Dropdown variant={variant} header={header} position="all" topValue={label !== undefined ? 74 : 40} readOnly={isLocked || readOnly}>
          {!isLocked && !readOnly &&
            options.map(option =>
              <SelectOption key={`${option.value}-${option.name}`}
                            value={option.value}
                            icon={option.icon}
                            name={option.name}
                            currentValue={selected?.value}
                            onClick={handleOnClickOption(option)}
                            disabled={option.disabled}
              >
                {option.name}
              </SelectOption>
            )
          }
        </Dropdown>
        {!isLocked && <TinyArrowCustom color={borderColor || themeColors.Gray400} size={size === 'small' ? 10 : 14}/>}
      </SelectorWrapper>
      {help && <Helper>{help}</Helper>}
      {errorMessage && <Error>{errorMessage}</Error>}
      {highlight && <Highlight>{highlightMessage}</Highlight>}
    </fieldset>
  </Container>;
});
