import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router';
import { RouteContainer } from '../../containers/router/components/RouteContainer';

export interface PublicRouteProps extends RouteProps {
}


export const PublicRoute: FC<PublicRouteProps> = (props) => {
  return <Route {...props} element={<RouteContainer body={props.element}/>}/>;
};
