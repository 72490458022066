import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { BaseSelectHeader, IdentifierDTO, Label, useModal } from '@zazume/zzm-base';
import { useAuth } from '#/contexts/AuthProvider';
import { useI18n } from '#/hooks/useI18n';
import { Unit } from '#/models';
import { API } from '#/lib/network/API';
import { I18NValues } from '#lib/i18n/generatedKeys';
import { SelectedUnitCard } from '#/pages/onboarding/components/addTenantModal';
import { AlgoliaSearcher } from '#/components/searcher/AlgoliaSearcher';

const MainContainer = styled.div<any>(({ theme, withMargin, hidden }) => css`
  width: 100%;
  ${withMargin && `margin-bottom: ${theme.spacing.s4};`}
  ${hidden && 'display: none;'}
`);

interface AssignUnitCardProps {
  onSelect: (unit: Unit) => void;
  unitId?: IdentifierDTO | null;
  errors?: any;
  register?: any;
  disabled?: boolean;
  name?: string;
  hideLabel?: boolean;
  label?: string;
  withMargin?: boolean;
  asProperty?: boolean;
  hidden?: boolean;
}

/**
 * @deprecated use SearchUnitInput with ZZMForm.Form
 * @param onSelect
 * @param unitId
 * @param errors
 * @param register
 * @param disabled
 * @param name
 * @param hideLabel
 * @param label
 * @param withMargin
 * @param asProperty
 * @param hidden
 * @constructor
 */
export const AssignUnitCard: FC<AssignUnitCardProps> = ({ onSelect, unitId, errors = {}, register, disabled, name = 'unitId', hideLabel, label, withMargin = true, asProperty = false, hidden }) => {
  const { organization } = useAuth();
  const { t } = useI18n();
  const { open: openModal, RenderModal, close } = useModal({ modalSize: 's' });
  const organizationId = organization?._id;

  const onSelectUnit = async (unit: Unit) => {
    const fullUnit = await API.units.get(unit._id)();
    onSelect(fullUnit);
    close();
  };

  const placeholder = t(`onboarding.inviteModal.${asProperty ? 'chooseProperty' : 'chooseUnit'}` as I18NValues);
  const selectLabel = label || (asProperty ? t('general.property') : t('general.unit'));

  return <MainContainer withMargin={withMargin} hidden={hidden}>
    {!hideLabel && <Label>{selectLabel}</Label>}
    {unitId
      ? <>
        {register && <input type="hidden" ref={register} name={name} aria-labelledby={name} value={unitId} readOnly/>}
        <SelectedUnitCard onChangeUnit={openModal} unitId={unitId} disabled={disabled} onSelect={onSelect}/>
      </>
      : <BaseSelectHeader
        onClick={openModal}
        error={errors[name]}
        disabled={disabled}
        placeholder={placeholder}
        variant="normal"
      />
    }
    {organizationId && <RenderModal>
      <AlgoliaSearcher
        title={t(`tenants.detail.${asProperty ? 'assignProperty' : 'assignUnit'}` as I18NValues)}
        placeholder={t(`tenants.detail.${asProperty ? 'searchProperties' : 'searchUnits'}` as I18NValues)}
        entity="unit"
        indexName="units"
        onSelect={onSelectUnit}
        hide={close}
        suggestedResultsHint={t(`tenants.detail.${asProperty ? 'suggestedProperties' : 'suggestedUnits'}` as I18NValues)}
        onSuggestedResults={API.units.getOrganizationUnits(organizationId)}
      />
    </RenderModal>}
  </MainContainer>;
};
