import { UnitType } from '#/models';
import { IdentifierDTO } from '@zazume/zzm-base';

interface ConstructorParams {
  _id: IdentifierDTO;
  name: string;
  type: UnitType;
  ownerAccountIds: IdentifierDTO[];
}

export class BasicUnit {
  _id: IdentifierDTO;
  name: string;
  type: UnitType;
  ownerAccountIds?: IdentifierDTO[];

  constructor(params: ConstructorParams) {
    this._id = params._id;
    this.name = params.name;
    this.type = params.type;
    this.ownerAccountIds = params.ownerAccountIds;
  }

  static fromRaw(raw: any): BasicUnit {
    return new BasicUnit({
      _id: raw._id,
      name: raw.name,
      type: raw.type,
      ownerAccountIds: raw.ownerAccountIds
    });
  }
}
