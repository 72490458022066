import { Organization } from '../../../models';
import { useGet } from '../index';
import { API } from '../../../lib/network/API';
import { OrganizationKeys } from './OrganizationKeys';

export const useGetOwnerAccountOrganizationsAPI = (ownerAccountId) => {
  const { isLoading, error, data } = useGet<Organization[]>(OrganizationKeys.allByOwner(ownerAccountId), API.ownerAccount.getOwnerAccountOrganizations());

  return {
    organizations: data,
    isLoading,
    error
  };
};
