import { IdentifierDTO } from '@zazume/zzm-base';
import { doGet, doPatch } from '../../helpers';
import { PaymentsLedgerAccount } from '../../../../models/accounting/PaymentsLedgerAccount';

export const accounting = {
  paymentsLedgerAccount: {
    getPaymentsLedgerAccount: (organizationId: IdentifierDTO) =>
      () =>
        doGet<PaymentsLedgerAccount | undefined>(`/dashboard/accounting/payments/ledger-account/${organizationId}`),
    updatePaymentsLedgerAccount: (organizationId: IdentifierDTO) =>
      (body: PaymentsLedgerAccount) =>
        doPatch(`/dashboard/accounting/payments/ledger-account/${organizationId}`, body)
  }
};
