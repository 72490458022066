import { isHotjarEnabled } from '../../lib/Config';
import { hotjar } from 'react-hotjar';
import { User } from '../../models';


export default class Hotjar {
  static startHotjar() {
    if (isHotjarEnabled()) {
      hotjar.initialize(2537147, 6);
    }
  }

  static setUser(user: User) {
    if (isHotjarEnabled()) {
      hotjar.identify(user._id, { email: user.email });
    }
  }
}
