import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps } from './index';

export const StaffThin: FC<IconProps> = ({ size = 20, color = Colors.FONT_3 }) =>
  <svg width={size} height={size} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M35,32H13c-1,0-1.8-0.8-1.8-1.8v-3.8c0-0.1,0.1-12.8,12.8-12.8c12.6,0,12.8,12.6,12.8,12.8v3.8 C36.8,31.2,36,32,35,32z
    M12.8,26.4v3.8c0,0.1,0.1,0.2,0.2,0.2h22c0.1,0,0.2-0.1,0.2-0.2v-3.8c0-0.5-0.2-11.2-11.2-11.2 S12.8,25.9,12.8,26.4z"/>
    <rect x="7.2" y="36.9" width="33.6" height="1.5"/>
    <rect x="22.2" y="9.6" width="3.6" height="4.8"/>
  </svg>;
