import { IdentifierDTO, UserAvatar } from '@zazume/zzm-base';
import { OwnerAccount } from '../../OwnerAccount';

export class OwnerAccountHelper {
  private readonly ownerAccount: OwnerAccount;

  constructor(ownerAccount: OwnerAccount) {
    this.ownerAccount = ownerAccount;
  }

  static build(ownerAccount: OwnerAccount) {
    return new OwnerAccountHelper(ownerAccount);
  }

  canInvite(): boolean {
    return !this.ownerAccount.isActive;
  }

  isInvited(): boolean {
    return this.ownerAccount.isInvited;
  }

  ownerCanUploadDraft() {
    return this.ownerAccount.isInvited || this.ownerAccount.isActive;
  }

  isAuthor(userId?: IdentifierDTO): boolean {
    return userId === this.ownerAccount.author;
  }

  isIndividual(): boolean {
    return this.ownerAccount.ownerAccountType === 'individual';
  }

  composeFullName(): string {
    if (this.ownerAccount.ownerAccountType === 'individual') {
      return this.ownerAccount.surnames ? `${this.ownerAccount.name} ${this.ownerAccount.surnames}` : this.ownerAccount.name;
    } else {
      return this.ownerAccount.entityName!;
    }
  }

  toAvatarWithNameUser = (extraContent?: string): UserAvatar => {
    return {
      fullName: this.composeFullName(),
      avatar: this.ownerAccount.avatar,
      extraContent: extraContent ?? ''
    };
  };
}
