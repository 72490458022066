import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FCC } from '@zazume/zzm-base';
import React from 'react';
import ReactDOM from 'react-dom';

const Background = styled.div(({ theme }) => css`
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  z-index: ${theme.zIndices.i10};
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
`);

const Container = styled.div(({ theme }) => css`
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: ${theme.colors.White};
`);

interface FullscreenModalProps {
  close: () => void;
  children?: React.ReactNode;
}

/**
 * @deprecated use zzm-base
 */
export const FullscreenModal: FCC<FullscreenModalProps> = React.memo<FullscreenModalProps>(({ children, close }) => {
  const domEL = document.getElementById('modal');

  if (!domEL) {
    return null;
  }

  return ReactDOM.createPortal(<Background>
    <Container>
      {children}
    </Container>
  </Background>, domEL);
});
