import { FC } from 'react';
import styled from '@emotion/styled';
import { Table } from '@zazume/zzm-base';
import { useI18n } from '#/hooks/useI18n';
import { DocumentUploadLink } from '#/models/documentUploadLink/DocumentUploadLink';
import { PaginationContainer } from '#/components/table/components/paginator/PaginationContainer';
import { PaginationButton } from '#/components/table/components/paginator/PaginationButton';
import { DEFAULT_TABLE_LENGTH } from '#/hooks';
import { EmptyState } from './EmptyState';
import { DocumentUploadLinkTableItem } from './DocumentUploadLinkTableItem';

const Container = styled.div`
  width: 100%;
`;

interface DocumentUploadLinksTableProps {
  uploadLinks: DocumentUploadLink[];
  nextPage: () => void;
  previousPage: () => void;
  currentPage: number;
  openCreate: () => void;
  queryKey: string[];
}

export const DocumentUploadLinksTable: FC<DocumentUploadLinksTableProps> = ({ uploadLinks, nextPage, previousPage, currentPage, openCreate, queryKey }) => {
  const { t } = useI18n();

  const contentCount = uploadLinks?.length;
  if (!contentCount) {
    return <EmptyState openCreate={openCreate}/>;
  }

  return (
    <Container>
      <Table>
        <Table.Short>
          {uploadLinks.map(item => <DocumentUploadLinkTableItem key={item._id} uploadLink={item} type="short" queryKey={queryKey}/>)}
        </Table.Short>
        <Table.Base>
          <Table.Base.Header>
            <Table.Base.Title>{t('documentUploadLink.fields.link')}</Table.Base.Title>
            <Table.Base.Title>{t('documentUploadLink.fields.subcategory')}</Table.Base.Title>
            <Table.Base.Title>{t('documentUploadLink.fields.createdBy')}</Table.Base.Title>
            <Table.Base.Title>{t('documentUploadLink.fields.expiresOn')}</Table.Base.Title>
            <Table.Base.Title>{t('documentUploadLink.fields.status')}</Table.Base.Title>
            <Table.Base.Title/>
          </Table.Base.Header>
          <Table.Base.Body>
            {uploadLinks.map(item => <DocumentUploadLinkTableItem key={item._id} uploadLink={item} type="base" queryKey={queryKey}/>)}
          </Table.Base.Body>
        </Table.Base>
      </Table>
      <PaginationContainer currentPage={currentPage} arrayLength={contentCount}>
        <PaginationButton id="paginationPrevious" onClick={previousPage} type="first" value={1} disabled={currentPage === 1}/>
        <PaginationButton id="paginationCurrent" value={currentPage} type="page" disabled/>
        <PaginationButton id="paginationNext" onClick={nextPage} type="last" value={1} disabled={contentCount <= DEFAULT_TABLE_LENGTH}/>
      </PaginationContainer>
    </Container>
  );
};
