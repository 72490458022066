import { useI18n } from '#hooks/useI18n';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { getState } from '../../utils/incidents/incidentConstants';
import { Caption, Subtitle } from '../typography';
import { ResultProps } from './ResultList';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  padding: 0;
  user-select: none;
  padding: 12px 0;
`;

const IncidentInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const IncidentTitle = styled(Subtitle)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 235px;
`;

const StateTag = styled(Subtitle)<any>`
  border-radius: 4px;
  padding: 0 4px;
  text-align: center;
  background: ${props => props.colors.lightColor};
  color: ${props => props.colors.darkColor};
  margin-left: 8px;
  float: right;
`;

export const IncidentResult: FC<ResultProps> = ({ result, onSelect }) => {
  const { t } = useI18n();

  const stateProps = getState(result.state);
  const stateLabel = t(`incidents.detail.states.${stateProps.label}` as any);

  const handleSelect = () => onSelect(result);

  return (
    <Container onClick={handleSelect}>
      <IncidentInfo>
        <IncidentTitle soft>{result.title}</IncidentTitle>
        <StateTag soft colors={stateProps}>{stateLabel}</StateTag>
      </IncidentInfo>
      <Caption>{result.unit.name}</Caption>
    </Container>
  );
};
