import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Delete, Profile, Semibold, getFieldError } from '@zazume/zzm-base';
import { useFormContext } from 'react-hook-form';
import { SignatureTypeInfo } from './atoms/SignatureTypeInfo';
import { Input } from '../input/Input';
import { useI18n } from '../../../hooks/useI18n';
import { SelectField } from '../select/SelectField';
import { SignerType } from '#/models/Contract';

const BodyContainer = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: row;
  grid-gap: ${theme.spacing.md};
  align-items: center;
`);

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  gap: ${theme.spacing.s6};
  width: fit-content;
`);

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Content = styled.div(({ theme }) => css`
  width: 100%;
  display: grid;
  grid-gap: ${theme.spacing.md};
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
`);

const DeleteContainer = styled.div(({ theme }) => css`
  cursor: pointer;

  &:hover {
    & > * > path {
      stroke: ${theme.colors.Primary};
    }
  }
`);


interface SignerProps {
  fieldPrefix?: string;
  title?: string;
  isBulk?: boolean;
  type?: SignerType;
  readOnly?: boolean;
  canRemove?: boolean;
  onRemove?: () => void;
}

export const Signer: FC<SignerProps> = ({ title, fieldPrefix = 'signer_', isBulk, readOnly = false, canRemove = false, onRemove, type }) => {
  const { t } = useI18n();
  const { watch, register, setValue, errors: formErrors } = useFormContext();

  const errors = !isBulk
    ? { name: getFieldError(`${fieldPrefix}name`, formErrors), email: getFieldError(`${fieldPrefix}email`, formErrors), signType: getFieldError(`${fieldPrefix}signType`, formErrors) }
    : formErrors[fieldPrefix];

  const options = useMemo(() => [
    { value: 'biometrics', name: t('onboarding.contractSigning.signatureBiometrics') },
    { value: 'certificate', name: t('onboarding.contractSigning.signatureCertificate') }
  ], [t]);

  const prefix = isBulk ? `${fieldPrefix}.` : fieldPrefix;

  const signType = watch(`${prefix}signType`);

  return <Container>
    {title && <Title><Profile color="Primary"/><Semibold>{title}</Semibold></Title>}
    <BodyContainer>
      <Content>
        <Input name={`${prefix}name`} placeholder={t('general.name')} ref={register} errors={errors?.name} withMargin={false} readOnly={readOnly}/>
        <Input name={`${prefix}email`} placeholder="name@domain.com" ref={register} errors={errors?.email} withMargin={false} readOnly={readOnly}/>
        <SelectField name={`${prefix}signType`} options={options} ref={register} setValue={setValue} defaultValue={signType} errors={errors?.signType} placeholder={t('general.forms.selectAnOption')}/>
        <input type="hidden" name={`${prefix}type`} ref={register} value={type}/>
      </Content>
      {canRemove && <DeleteContainer onClick={onRemove}>
        <Delete size={24} secondaryColor="Primary" color="Gray400"/>
      </DeleteContainer>}
    </BodyContainer>
    <SignatureTypeInfo signType={signType}/>
  </Container>;
};
