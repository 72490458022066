import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps, getStrokeWidth } from './index';

/**
 * @deprecated use zzm-base
 * @param color
 * @param size
 * @constructor
 */
export const RepeatArrows: FC<IconProps> = ({ color = Colors.FONT_3, size = 20 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" d="M18 17.9001L15.3333 20.9001M18 17.9001H8C6.89543 17.9001 6 17.0047 6 15.9001L6 12.5001M18 17.9001L15.3333 14.9001M6 6.1001L8.66667 3.1001M6 6.1001L16 6.1001C17.1046 6.1001 18 6.99553 18 8.1001L18 11.5001M6 6.1001L8.66667 9.1001"/>
  </svg>;
};
