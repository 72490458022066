import { IdentifierDTO } from '@zazume/zzm-base';

export const CopilotChatKeys = {
  base: 'copilotChat',
  getConversations: () =>
    [CopilotChatKeys.base],
  getMessages: (chatId: IdentifierDTO) =>
    [CopilotChatKeys.base, chatId, 'messages'],
  sendReview: (chatId: IdentifierDTO, messageId: IdentifierDTO) =>
    [CopilotChatKeys.base, chatId, messageId, 'sendReview']
};
