import { FC } from 'react';
import { BaseToggle, BaseToggleProps } from '@zazume/zzm-base';
import { useFormContext } from 'react-hook-form';

interface ToggleProps extends BaseToggleProps {
  name: string;
}

/**
 * @deprecated use ZZMForm.Toggle instead
 * @param props
 * @constructor
 */
export const Toggle: FC<ToggleProps> = (props) => {
  const { register } = useFormContext();

  return (
    <BaseToggle
      {...props}
      ref={register}
    />
  );
};
