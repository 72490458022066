import { FC } from 'react';
import { TableSkeleton } from '#/components/skeleton/atoms/TableSkeleton';
import { Regular } from '@zazume/zzm-base';

type SkeletonProps = FC & {
  Table: typeof TableSkeleton;
}

export const Skeleton: SkeletonProps = () => {
  return <>
    <Regular.Skeleton/>
  </>;
};

Skeleton.Table = TableSkeleton;
