import { doGet, doPut, EndpointBuilder, parserDataList, toPaginatedURL } from '../helpers';
import { NotificationsSummary } from '../../../models/notifications/NotificationsSummary';
import { IdentifierDTO } from '@zazume/zzm-base';
import { PaginationOptions } from '../../../hooks';
import { Notification } from '../../../models/notifications/Notifications';
import { DateVO } from '@zazume/zzm-utils';

const toNotification = (rawNotification: any): Notification =>
  new Notification({
    ...rawNotification,
    readAt: rawNotification.readAt && DateVO.fromPrimitive(rawNotification.readAt),
    createdAt: DateVO.fromPrimitive(rawNotification.createdAt)
  });


export const notifications = {
  getUnread: () =>
    doGet<NotificationsSummary>('/dashboard/notification/unread'),
  markAsRead: () =>
    (notificationId: IdentifierDTO) =>
      doPut(`/dashboard/notification/${notificationId}`),
  markAllAsRead: () =>
    doPut('/dashboard/notification/all'),
  markAsUnRead: () =>
    (notificationId: IdentifierDTO) =>
      doPut(`/dashboard/notification/${notificationId}/unread`),
  getPaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () =>
        doGet<Notification[]>(toPaginatedURL(`/dashboard/notification/all${params.url}${params.queryParams}`, pagination), parserDataList(toNotification)),
  getAll:
    () =>
      doGet<Notification[]>('/dashboard/notification/all', parserDataList(toNotification)),
  getById: (notificationId: IdentifierDTO) =>
    () =>
      doGet<Notification>(`/dashboard/notification/${notificationId}`, toNotification)
};
