import * as yup from 'yup';
import { DocumentCategories, DocumentTypes } from '../../models';
import { getToday } from '@zazume/zzm-utils';
import { TFunction } from '../../hooks/useI18n';
import { SignatureConfiguration, SignatureConfigurations, SignType, SignTypes } from '../../components/forms/signer/types';

export const getCreateDocumentSchema = (t: TFunction) => {
  const requiredMessage = t('general.forms.mandatory');

  return yup.object().shape({
    category: yup.string().required(requiredMessage),
    type: yup.string().oneOf([...DocumentTypes], t('general.forms.invalid')).required(requiredMessage),
    isExpirable: yup.boolean().required(requiredMessage),
    expireAt: yup.date().when('isExpirable', {
      is: true,
      then: yup.date().typeError(requiredMessage).min(getToday(), t('general.forms.invalidDate')).required(requiredMessage)
    }),
    isSignable: yup.boolean().required(requiredMessage),
    signers: yup.array().when('isSignable', {
      is: true,
      then: yup.array().required(requiredMessage).of(
        yup.object().shape({
          name: yup.string().required(requiredMessage),
          email: yup.string().required(requiredMessage).email(t('general.forms.emailError')),
          signType: yup.mixed<SignType>().oneOf([...SignTypes], requiredMessage).required(requiredMessage)
        })
      ),
      otherwise: yup.array().optional()
    }),
    document: yup.object().required(t('general.forms.noFile')).nullable(),
    signatureConfiguration: yup.string().when('isSignable', {
      is: true,
      then: yup.string()
        .oneOf([...SignatureConfigurations], requiredMessage)
        .required(requiredMessage)
        .typeError(requiredMessage),
      otherwise: yup.string().optional()
    }),
    isHiddenFromOwner: yup.boolean().default(false)
  });
};

export type CreateDocumentFormInputs = {
  category: DocumentCategories;
  type: string;
  isExpirable: boolean;
  expireAt: Date;
  isSignable: boolean;
  signers: {
    name: string;
    email: string;
    signType: SignType;
  };
  document: any;
  signatureConfiguration: SignatureConfiguration;
  isHiddenFromOwner: boolean;
}
