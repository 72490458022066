import { Can } from '#/components/roles/Can';
import { useI18n } from '#/hooks/useI18n';
import { Flex, Regular, Semibold } from '@zazume/zzm-base';
import { FC } from 'react';

interface OwnerVisibilityProps {
  isHiddenFromOwner?: boolean;
}

export const OwnerVisibility: FC<OwnerVisibilityProps> = ({ isHiddenFromOwner }) => {
  const { t } = useI18n();

  return (<Can hasRoles={['admin', 'buildingManager']}>
    <Flex direction="column" gap="s2">
      <Regular variant="XS" color="Gray500">{t('documents.isHiddenFromOwner')}</Regular>
      <Semibold variant="S">{isHiddenFromOwner ? t('general.yes') : t('general.no')}</Semibold>
    </Flex>
  </Can>);
};
