import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps, getStrokeWidth } from './index';

export const Unlock: FC<IconProps> = ({ size = 20, color = Colors.FONT_3 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} d="M15.2366 9.94895V5.59766C15.2366 4.49309 14.3411 3.59766 13.2366 3.59766H10.7638C9.65919 3.59766 8.76376 4.49308 8.76376 5.59765M6.8584 20.4018H17.1419C17.6942 20.4018 18.1419 19.9541 18.1419 19.4018V11.949C18.1419 10.8444 17.2465 9.94895 16.1419 9.94895H7.8584C6.75383 9.94895 5.8584 10.8444 5.8584 11.949V19.4018C5.8584 19.9541 6.30611 20.4018 6.8584 20.4018ZM13.0002 14.649C13.0002 15.2012 12.5524 15.649 12.0002 15.649C11.4479 15.649 11.0002 15.2012 11.0002 14.649C11.0002 14.0967 11.4479 13.649 12.0002 13.649C12.5524 13.649 13.0002 14.0967 13.0002 14.649Z"/>
  </svg>;
};
