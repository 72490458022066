import React, { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { isOnlyAgent } from '#/models';
import { Private } from '#/containers/router/routes/Private';
import { useSession } from '#hooks/session/useSession';
import { useI18n } from '#hooks/useI18n';
import { SectionProps } from '#/containers/asideNavigation/Sections';

interface PeopleProps extends SectionProps {
}

export const People: FC<PeopleProps> = ({ onClickItem, isMobile }) => {
  const { t } = useI18n();
  const { user } = useSession();
  const peopleRoute = isOnlyAgent(user) ? Private.OWNER_ACCOUNTS.route : Private.TENANTS.route;

  return <Can hasRoles={['buildingManager', 'staff', 'viewer', 'admin', 'ownerBasic', 'ownerMiddle', 'ownerManager', 'commercial', 'agent']}>
    <NavigationLink to={peopleRoute} parentPath="/people" onClick={onClickItem} icon="people" title={t('asideNavigation.people')} isMobile={isMobile}/>
  </Can>;
};
