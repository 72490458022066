import { FC } from 'react';
import styled from '@emotion/styled';
import { BasicUser } from '../../models';
import { Subtitle, Body14, Headline2 } from '../typography';
import { Avatar, AvatarProps, getSize } from './Avatar';
import { BasicOwnerAccount } from '../../models/OwnerAccount';


const Container = styled.div<any>`
  display: flex;
  flex-direction: ${props => getDirection(props.orientation)};
  gap: ${props => props.gap === 'small' ? 8 : 12}px;
  align-items: center;
  justify-content: flex-start;
`;

const Name = styled(Subtitle)<any>`
  font-size: ${props => props.size - 18}px;
`;

const NameThin = styled(Body14)<any>`
  font-size: ${props => props.size - 18}px;
`;

const NameHeadline = styled(Headline2)<any>``;


export interface AvatarWithNameProps extends AvatarProps{
  user: BasicUser | BasicOwnerAccount;
  orientation?: 'right' | 'left' | 'top';
  thin?: boolean;
  headline?: boolean;
  avatarSize?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
  fontSize?: 'small' | 'medium' | 'large';
  marginSize?: 'small' | 'large';
}

export const getDirection = orientation => {
  switch (orientation) {
    case 'left':
      return 'row';
    case 'right':
      return 'row-reverse';
    case 'top':
      return 'column';
  }
};

/**
 * @deprecated use zzm-base Avatar instead
 */
export const AvatarWithName: FC<AvatarWithNameProps> = ({ user, avatarSize = 'medium', fontSize = 'medium', orientation = 'left', marginSize = 'small', thin = false, headline }) => {
  const hasSurname = Boolean(user['surnames']);
  const fullName = hasSurname ? `${user.name} ${(user as BasicUser).surnames}` : user.name;

  return <Container orientation={orientation}>
    <Avatar size={avatarSize} user={user}/>
    {thin && !headline && <NameThin size={getSize(fontSize)} margin={marginSize} orientation={orientation}>{fullName}</NameThin>}
    {headline && !thin && <NameHeadline size={getSize(fontSize)} margin={marginSize} orientation={orientation}>{fullName}</NameHeadline>}
    {!thin && !headline && <Name soft size={getSize(fontSize)} margin={marginSize} orientation={orientation}>{fullName}</Name>}
  </Container>;
};
