import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FCC } from '@zazume/zzm-base';

const getAlign = (align: ContainerProps['align']) => {
  switch (align) {
    case 'center':
      return css`
        align-items: center;
      `;
    case 'end':
      return css`
        align-items: flex-end;
      `;
    default:
      return css`
        align-items: flex-start;
      `;
  }
};

const getJustify = (align: ContainerProps['justify']) => {
  switch (align) {
    case 'center':
      return css`
        justify-content: center;
      `;
    case 'end':
      return css`
        justify-content: flex-end;
      `;
    default:
      return css`
        justify-content: flex-start;
      `;
  }
};

const getGrow = (grow: ContainerProps['grow']) => {
  return css`
    flex-grow: ${grow ?? 'auto'};
  `;
};


interface ContainerProps {
  direction?: 'row' | 'column';
  spacing?: number
  align?: 'center' | 'start' | 'end'
  justify?: 'center' | 'start' | 'end'
  grow?: number
}
const Container = styled.div<ContainerProps>(({ spacing, direction, align, justify, grow }) => css`
  display: flex;
  flex-direction: ${direction};
  gap: ${spacing}px;
  ${getAlign(align)};
  ${getJustify(justify)}
  ${getGrow(grow)}
`);

type StackProps = ContainerProps
/**
 * @deprecated use Flex directly
 * @param children
 * @param spacing
 * @param direction
 * @param align
 * @param justify
 * @param grow
 * @constructor
 */
export const Stack: FCC<StackProps> = ({ children, spacing, direction = 'row', align, justify, grow }) => {
  return (
    <Container spacing={spacing} direction={direction} align={align} justify={justify} grow={grow}>
      {children}
    </Container>
  );
};
