import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from '.';

export const Calendar: FC<IconProps> = ({ color = '#FFFFFF', size = 24 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        d="M3.87451 10.3H20.1251M7.99978 3.28931V5.55482M15.9998 3.28931V5.55482M5.87451 20.4087H18.1251C19.2296 20.4087 20.1251 19.5133 20.1251 18.4087V7.55484C20.1251 6.45027 19.2296 5.55484 18.1251 5.55484H5.87451C4.76994 5.55484 3.87451 6.45027 3.87451 7.55484V18.4087C3.87451 19.5133 4.76994 20.4087 5.87451 20.4087Z"
    />
  </svg>;
};
