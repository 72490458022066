export const defaultParams = '?auto=format,compress';
export type FitTypes = 'crop' | 'fill';
export type FillTypes = 'blur' | 'solid';
const MonochromeValue = '666';

export interface UseImgixProps {
  src: string;
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  fit?: FitTypes;
  densityPixel?: number;
  monochrome?: boolean;
  fill?: FillTypes;
  fillColor?: string;
}

const toImageURL = (props: UseImgixProps) => {
  let result = props.src + defaultParams;
  const { width, height, maxWidth, maxHeight, fit = 'crop', densityPixel = 2, monochrome = false } = props;

  result += '&fit=' + fit;

  if (width) {
    result += '&w=' + width;
  }

  if (height) {
    result += '&h=' + height;
  }

  if (maxWidth) {
    result += '&max-w=' + maxWidth;
  }

  if (maxHeight) {
    result += '&max-h=' + maxHeight;
  }
  if (densityPixel) {
    result += '&dpr=' + densityPixel;
  }

  if (monochrome) {
    result += '&monochrome=' + MonochromeValue;
  }

  if (props.fill) {
    if (props.fill === 'solid' && props.fillColor) {
      result += '&fill=solid&fill-color=' + props.fillColor;
    } else {
      result += '&fill=' + props.fill;
    }
  }

  return result;
};

export const useImgix = () => {
  return {
    applyImgix: toImageURL
  };
};
