import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { TFunction } from '#hooks/useI18n';
import { toFormValidation } from '#/utils/formValidation/toFormValidation';
import { FormValidation } from '@zazume/zzm-base';
import { NotificationSettings } from '#/models/notificationSettings/NotificationSettings';

export type EditNotificationsInputs = Omit<NotificationSettings, 'userId'>

const schema = (t: TFunction): SchemaOf<EditNotificationsInputs> => {
  const mandatoryError = t('general.forms.mandatory');

  return yup.object().shape({
    pushNotifications: yup.object().shape({
      all: yup.boolean().required(mandatoryError),
      directMessages: yup.boolean().required(mandatoryError),
      incidents: yup.boolean().required(mandatoryError),
      bookings: yup.boolean().required(mandatoryError),
      featureUpdates: yup.boolean().required(mandatoryError)
    }),
    emailNotifications: yup.object().shape({
      all: yup.boolean().required(mandatoryError),
      directMessages: yup.boolean().required(mandatoryError),
      incidents: yup.boolean().required(mandatoryError),
      bookings: yup.boolean().required(mandatoryError),
      featureUpdates: yup.boolean().required(mandatoryError)
    })
  });
};

export const getEditNotificationsSchema = (t: TFunction): FormValidation =>
  toFormValidation(t, schema(t));
