import { Organization } from '../../../models';
import { useGet } from '../index';
import { API } from '../../../lib/network/API';
import { OrganizationKeys } from './OrganizationKeys';

export const useGetAllOrganizationsAPI = () => {
  const { isLoading, error, data } = useGet<Organization[]>(OrganizationKeys.all, API.organizations.getAll);

  return {
    organizations: data,
    isLoading,
    error
  };
};
