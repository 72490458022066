import React, { FC } from 'react';
import { IdentifierDTO, Pencil, Regular, Table, Tag } from '@zazume/zzm-base';
import { useI18n } from '#hooks/useI18n';
import { InvestmentAlert } from '#/models/investment/alert/InvestmentAlert';
import styled from '@emotion/styled';

interface AlertTableItemProps {
  alert: InvestmentAlert;
  onEditAlert: (alert: InvestmentAlert) => void;
  onClick?: (id: IdentifierDTO) => void;
}

const StatusField = styled.div`
  width: fit-content;
`;

export const AlertTableItem: FC<AlertTableItemProps> = ({ alert, onClick, onEditAlert }) => {
  const { t, languageVO } = useI18n();

  const handleOnClick = (id: IdentifierDTO) => () => onClick?.(id);
  const handleEditTask = (alert: InvestmentAlert) => () => onEditAlert(alert);

  return (
    <Table.Base.Item item={alert} onClick={handleOnClick(alert._id)}>
      <Table.Base.Field field="location.city.name" color="Black"/>
      <Table.Base.PriceField field="maxBudget" language={languageVO} color="Gray500"/>
      <Table.Base.CustomField>
        <Regular variant="S" color="Gray500">{alert.profitability}%</Regular>
      </Table.Base.CustomField>
      <Table.Base.CustomField>
        <Regular variant="S" color="Gray500">{t(`investment.myAlerts.table.channel.${alert.channel}`)}</Regular>
      </Table.Base.CustomField>
      <Table.Base.CustomField>
        <StatusField>
          <Tag variant={alert.isActive ? 'green' : 'white'}>{alert.isActive ? t('investment.myAlerts.table.status.active') : t('investment.myAlerts.table.status.disabled')}</Tag>
        </StatusField>
      </Table.Base.CustomField>
      <Table.Base.Options>
        <Table.Base.Options.Item onClick={handleEditTask(alert)}>
          <Pencil color="Gray500" size={20}/><Regular variant="S" color="Gray600">{t('investment.myAlerts.table.editAlert')}</Regular>
        </Table.Base.Options.Item>
      </Table.Base.Options>
    </Table.Base.Item>
  );
};
