import { OwnerLead } from '#/models/OwnerLead';
import { BasicUser, OnboardingBasicTenant, User } from '../models';

export const composeFullName = (user: BasicUser | User | OnboardingBasicTenant | OwnerLead | any) =>
  user.surnames ? `${user.name} ${user.surnames}` : user.name;

export const getFullUrl = (path: string, urlParams: string): string =>
  urlParams.length > 0 ? [path, urlParams].join('?') : path;

export const emailValidationRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Format price with currency
 * @param price in cents
 */
export const formatPrice = (price, currency, decimals: number = 2) => {
  const amount = (price / 100).toFixed(decimals);
  return formatPriceWithCurrency(amount, currency);
};

const formatPriceWithCurrency = (price, currency) => {
  const currencySymbol = currencySymbols[currency];
  return currencySymbol.position === 'after'
    ? `${price}${currencySymbol.symbol}`
    : `${currencySymbol.symbol}${price}`;
};

export const compareStringsByField = (field: string) => (a, b): number => {
  if (a[field] < b[field]){
    return -1;
  }
  if (a[field] > b[field]){
    return 1;
  }
  return 0;
};


export const currencySymbols = {
  'eur': {
    symbol: '€',
    position: 'after'
  },
  'usd': {
    symbol: '$',
    position: 'before'
  },
  'gbp': {
    symbol: '£',
    position: 'after'
  }
};

export const formatNumberNonFixedDecimals = (price) =>
  (Math.round(price * 100) / 100).toString();

export const isValidURL = (url: string): boolean => {
  const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const regex = new RegExp(expression);
  return Boolean(url.match(regex));
};

export const toHiddenIban = (last4NumbersOfIban?: string) => {
  if (!last4NumbersOfIban) {
    return '';
  }
  return `(***${last4NumbersOfIban})`;
};
