import { IdentifierDTO } from '@zazume/zzm-base';
import { arrayIsNotEmpty, DateVO, PriceVO } from '@zazume/zzm-utils';
import { BasicOwnerAccount, Unit } from '#/models';
import { API } from '#lib/network/API';

export class UnitHelper {
  private readonly unit: Unit;

  constructor(unit: Unit) {
    this.unit = unit;
  }

  static build(unit: Unit) {
    return new UnitHelper(unit);
  }

  getMonthlyRent(): PriceVO {
    return PriceVO.fromPrimitive({
      amount: this.unit.rentalConditions.monthlyRent,
      currency: this.unit.rentalConditions.currency
    });
  }

  getOwnerAccounts(): IdentifierDTO[] | undefined {
    return this.unit.ownerAccounts?.map(ownerAccount => ownerAccount._id);
  }

  getOwnershipPercentage(ownerId?: IdentifierDTO): number | undefined {
    return this.unit.ownershipDistribution?.find(ownership => ownership.ownerAccountId === ownerId)?.percentage;
  }

  /**
   * @deprecated use API instead
   * - API.ownerAccounts.getForUnit(unitId)
   */
  getMainOwnerAccount(): BasicOwnerAccount | undefined {
    return this.unit.ownerAccounts && arrayIsNotEmpty(this.unit.ownerAccounts) ? this.unit.ownerAccounts[0] : undefined;
  }

  isAuthor(userId?: IdentifierDTO): boolean {
    return userId === this.unit.author;
  }

  // TODO: we should extract this value from the unit (directly from the backend) and have only a one single of truth in the backend.
  getPublicLocation(): string {
    if (this.unit.addressVisibility) {
      const isFullAddressVisible = ['showFullAddress', 'showFullAddressAndComplements'].includes(this.unit.addressVisibility);
      if (isFullAddressVisible) {
        return this.unit.fullAddress;
      }
    }

    const { address, city } = this.unit;
    return `${address}, ${city}`;
  }

  getYearsSinceCreation(includeCurrentYear?: boolean): number[] {
    let lastYear = new DateVO().subtract(1, 'year').get('year');
    if (includeCurrentYear) {
      lastYear++;
    }
    const startingYear = DateVO.fromPrimitive(this.unit.createdAt).get('year');
    const yearsCount = lastYear - startingYear + 1;
    return Array.from(new Array(yearsCount).keys()).map((_, index)=> lastYear - index);
  }
}

/**
 * Calculate if a unit is under management or not using the current contract.
 * Do not use the Unit paymentFees directly because it can be outdated and don't reflect the current contract.
 * @param unitId the unit identifier
 * @param handleNetworkExceptions to show network errors as always
 */
export const calculateIsUnitUnderManagement = async (unitId: IdentifierDTO, handleNetworkExceptions: (error: any, values?: any) => void): Promise<boolean> => {
  try {
    const contract = await API.contracts.getCurrentContractByUnit(unitId)();
    if (!contract) {
      // If not contract, the unit is not under management
      return false;
    }

    return contract.rentalConditions?.directDebitOrderFrom === 'organization';
  } catch (error) {
    handleNetworkExceptions(error);
    // In case of error, the unit is not under management
    return false;
  }
};
