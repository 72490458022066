import React, { FC } from 'react';
import { useI18n } from '#hooks/useI18n';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';
import { useSession } from '#hooks/session/useSession';
import { SectionProps } from '#/containers/asideNavigation/Sections';
import { UserHelper } from '#/models/helpers/user/UserHelper';

interface Props extends SectionProps {
}

export const Portfolio: FC<Props> = ({ onClickItem, isMobile }) => {
  const { t } = useI18n();
  const { isOwner, user } = useSession();

  //TODO: Move to common place
  const getLabelText = () => {
    if (isOwner) {
      return t('asideNavigation.myProperties');
    }
    if (UserHelper.build(user).isAgent()) {
      return t('asideNavigation.properties');
    }
    return t('asideNavigation.portfolio');
  };

  const portfolioText = getLabelText();

  return <Can hasRoles={['buildingManager', 'staff', 'viewer', 'admin', 'ownerBasic', 'ownerMiddle', 'ownerManager', 'commercial', 'agent']}>
    <NavigationLink to={Private.UNITS.route} icon="workspace" parentPath="/portfolio" onClick={onClickItem} title={portfolioText} isMobile={isMobile}/>
  </Can>;
};
