import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IdentifierDTO, PrimaryButton, useModal } from '@zazume/zzm-base';
import { FC } from 'react';
import { RequestUnitServiceUpgradeModal } from '../../../components/modal/RequestUnitServiceUpgrade/RequestUnitServiceUpgrade.modal';
import { useGet } from '../../../hooks';
import { UnitKeys } from '../../../hooks/api/units/UnitKeys';
import { useI18n } from '../../../hooks/useI18n';
import { API } from '../../../lib/network/API';
import { Unit } from '../../../models';

const GoldenPrimary = styled(PrimaryButton)(({ theme }) => css`
  background: ${theme.colors.Mostard};
  color: ${theme.colors.Black};

  &:hover {
    /* TODO: Create mostard semi dark ????? */
    background: #f5c000 !important;
  }
`);

interface Props {
  ownerAccountId: IdentifierDTO;
}

export const CommercializationUpgradeButton: FC<Props> = ({ ownerAccountId }) => {
  const { t } = useI18n();
  const { RenderModal: RenderServiceModal, open: openServiceModal } = useModal({ modalSize: 'm' });
  const { data: commercializedUnits = [], isLoading } = useGet<Unit[]>(UnitKeys.allCommercializedByOwnerAccount(ownerAccountId), API.units.findCommercializedUnitsByOwnerAccount());

  if (isLoading || commercializedUnits.length) {
    return null;
  }

  return (
    <>
      <GoldenPrimary onClick={openServiceModal}>{t('modals.requestServiceUpgrade.title')}</GoldenPrimary>
      <RenderServiceModal>
        <RequestUnitServiceUpgradeModal/>
      </RenderServiceModal>
    </>
  );
};
