import { Colors } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from '.';

export const Documents: FC<IconProps> = ({ color = Colors.GREY_6, size = 20 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" d="M7.19267 17.548H6.0466C5.60625 17.548 5.24927 17.1911 5.24927 16.7507V4.9631C5.24927 4.52275 5.60624 4.16577 6.0466 4.16577H14.0092C15.1756 4.16577 16.1471 5.00073 16.3585 6.1056M10.5002 9.79754H14.8851M10.5002 13.1431H16.0002M10.5002 16.4886H14.8851M8.39608 19.8342H17.9533C18.3937 19.8342 18.7506 19.4772 18.7506 19.0369V8.84393C18.7506 7.52287 17.6797 6.45194 16.3587 6.45194H8.39608C7.95573 6.45194 7.59875 6.80892 7.59875 7.24927V19.0369C7.59875 19.4772 7.95573 19.8342 8.39608 19.8342Z"/>
  </svg>;
};
