import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Header } from './components/header/Header';
import { FC, useState } from 'react';
import { ChatList } from './components/body/ChatList';
import { IdentifierDTO } from '@zazume/zzm-base';
import { API } from '#/lib/network/API';
import { useCopilotContext } from './CopilotContext';
import { useCopilotChatIdStorage } from './components/useCopilotChatIdStorage';
import { ChatLayer } from './components/body/ChatLayer';
import { useUpdate } from '#/lib/reactQuery/hooks/useUpdate';
import { CopilotChatKeys } from '#/hooks/api/copilot/Copilot.keys';

const Container = styled.div(({ theme }) => css`
  position: relative;
  overflow: hidden;
  background: ${theme.colors.White};
  height: 100%;
`);

interface Props {
  isWidget?: boolean;
  onClose?: () => void;
  chatId: IdentifierDTO;
}

export interface NewChatResponse {
  conversationId: string;
  response: Message;
}

export interface Conversation {
  _id: IdentifierDTO;
  historicMessages: Message[];
  createdAt: string;
}

export type MessageType = 'user' | 'assistant' | 'system' | 'tool';
export type ReviewType = 'positive' | 'negative';

export interface Message {
  _id: IdentifierDTO;
  message?: string;
  type: MessageType;
  toolCallId?: string;
  visibleForFinalUser: boolean;
  createdAt: string;
  review?: ReviewType;
}

export const CopilotChat: FC<Props> = ({ isWidget = false, chatId }) => {
  const { handleIsOpen } = useCopilotContext();
  const { addChatIdQueryParam, addChatIdLocalStorage } = useCopilotChatIdStorage();
  const [isChatListOpen, setIsChatListOpen] = useState<boolean>(false);
  const [currentChatId, setCurrentChatId] = useState<IdentifierDTO>(chatId);

  const { mutate } = useUpdate<IdentifierDTO, void>(CopilotChatKeys.getConversations(), API.copilot.createConversation);

  const handleOnCreateNewChat = async () => {
    const chatId = await mutate();
    setCurrentChatId(chatId);
    addChatIdLocalStorage(chatId);
    addChatIdQueryParam(chatId);
  };

  const onSelectNewChat = (chatId: IdentifierDTO) => {
    setCurrentChatId(chatId);
    addChatIdLocalStorage(chatId);
    addChatIdQueryParam(chatId);
  };

  const handleOnOpenChatList = () =>
    setIsChatListOpen(prev => !prev);

  return (
    <Container>
      <Header
        onCreateNewChat={handleOnCreateNewChat}
        openChatList={handleOnOpenChatList}
        isWidget={isWidget}
        onClose={handleIsOpen}
        conversationId={currentChatId}
      />
      {currentChatId && <ChatLayer
        conversationId={currentChatId}
        isWidget={isWidget}
      />}
      {isChatListOpen && <ChatList
        chatId={currentChatId}
        closeChatList={handleOnOpenChatList}
        isWidget={isWidget}
        onSelectNewChat={onSelectNewChat}
      />}
    </Container>
  );
};
