import { Invitation } from '#/domain/invitation/Invitation';
import { doGet, doPost, parserDataList } from '#lib/network/helpers';

export const invitations = {
  sendByEmail: (email: string) =>
    doPost<void>('/invitation/send-by-email', { email }),
  getAll: () =>
    doGet<Invitation[]>('/invitation/all', parserDataList(Invitation.fromRaw)),
  getAllReceived: () =>
    doGet<Invitation[]>('/invitation/received', parserDataList(Invitation.fromRaw))
};
