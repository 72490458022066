import React, { FC } from 'react';
import { IconProps } from './index';

export const Cleaning: FC<IconProps> = ({ color, size = 24 }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M16.4,8.9l0.1-0.5c0.2-0.8-0.3-1.6-1-2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2l0,0l0,0
    c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.4-0.6-0.9-1.2-1.2c-0.8-0.4-1.7-0.2-2.4,0c-0.4,0.2-0.8,0.3-1.2,0.4c0,0,0,0-0.1,0c0,0,0,0,0,0
    c0,0-0.1-0.1-0.1-0.2l0,0C9.9,4.5,9.7,4.2,9.4,3.9C8.7,3.4,8,3.2,7.1,3.2C6,3.2,5.3,3.9,4.9,4.7C4.6,5.2,4.5,5.8,4.5,6.4
    c-0.7,0.3-1.1,1.1-1,1.9l1.4,7c0.2,0.8,0.9,1.4,1.7,1.4h6.7c0.8,0,1.5-0.6,1.7-1.4l0.3-1.4c0.4,0,0.7-0.1,1-0.3
    c0.4-0.2,1.4-0.9,1.4-2.2C17.7,10.3,17.1,9.5,16.4,8.9z M12,5.9c0.5-0.2,0.9-0.2,1.2-0.1c0.1,0.1,0.3,0.2,0.4,0.4h-2.4
    C11.5,6.2,11.8,6,12,5.9z M6.2,5.3c0.2-0.5,0.5-0.7,0.9-0.7c0.6,0,1,0.1,1.4,0.5c0,0,0.1,0.1,0.2,0.3l0,0C8.9,5.7,9.1,6,9.4,6.2l0,0
    H6C6,5.9,6.1,5.6,6.2,5.3z M14.2,12.3c-0.5-0.2-1-0.4-1.5-0.7c-1.3-0.8-2.5-1.8-3.1-2.5l-1.1,1c0.7,0.8,2.1,1.9,3.5,2.8
    c0.6,0.4,1.3,0.7,1.9,0.9l-0.3,1.3c0,0.1-0.1,0.2-0.2,0.2H6.7c-0.1,0-0.2-0.1-0.2-0.2L5,8.1c0-0.2,0.1-0.3,0.2-0.3h9.5
    c0.2,0,0.3,0.1,0.2,0.3L14.2,12.3z M15.7,12.3l0.3-1.6c0.2,0.3,0.2,0.5,0.2,0.8C16.2,11.9,16,12.2,15.7,12.3z"
    />
  </svg>;
};
