import { useCallback } from 'react';
import { AnySchema, ValidationError } from 'yup';

const doValidation = (schema: AnySchema) => async (formData) => {
  const values = await schema.validate(formData, { abortEarly: false });
  return {
    values,
    errors: {}
  };
};

const generatedNewObject = (currentError: ValidationError) => (keys: string[]) => {
  if (keys.length === 1) {
    return {
      [keys[0]]: {
        type: currentError.type ?? 'validation',
        message: currentError.message
      }
    };
  }
  const newObject = {};
  const key = keys.shift();
  newObject[key!] = generatedNewObject(currentError)(keys);
  return newObject;
};

const deepMergeObjects = (target: object, ...sources) => {
  const isObject = (item) =>
    (item && typeof item === 'object' && !Array.isArray(item));

  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        deepMergeObjects(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return deepMergeObjects(target, ...sources);
};

const processErrors = (errors: ValidationError, customErrors?) => {
  const newErrors = errors.inner?.reduce((allErrors, currentError) => {
    const keyPath = currentError.path?.split('.');
    if (!keyPath) {
      return {};
    }

    const newError = generatedNewObject(currentError)([...keyPath]);
    return deepMergeObjects(allErrors, newError);
  }, {});

  return {
    values: {},
    errors: {
      ...newErrors,
      ...customErrors
    }
  };
};

/**
 * @deprecated use zzm-form package instead
 * @param validationSchema
 */
export const useYupValidationResolver = (validationSchema: AnySchema) => {
  const resolver = useCallback(async (formData, context = {}) => {
    const { customErrors } = context as any;
    try {
      const validation = await doValidation(validationSchema)(formData);
      if (customErrors && Object.keys(customErrors).length) {
        throw Error;
      }
      return validation;
    } catch (errors: any) {
      return processErrors(errors, customErrors);
    }
  }, [validationSchema]);

  return {
    resolver,
    validationSchema
  };
};
