import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useAuth } from '../../../contexts/AuthProvider';
import { Flex, TinyArrow, ZazumeLogotype } from '@zazume/zzm-base';
import { OrganizationOptions } from '../../../components/forms/select/organization/OrganizationOptions';
import { css } from '@emotion/react';
import { useImgix } from '../../../hooks';
import { UserHelper } from '#/models/helpers/user/UserHelper';

const Container = styled.div`
  user-select: none;
  position: relative;
`;

const OrganizationBtn = styled.button(({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  background: none;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
  gap: ${theme.spacing.xs};

  :hover {
    background: none;
    box-shadow: none;
  }

  :active {
    border: none;
  }
`);

const Image = styled.img<any>`
  padding: 0;
  margin: 0;
  width: ${props => props.size}px;
`;

const getLandscapeLogo = (src) => {
  return src
    ? <Image size={120} src={src}/>
    : <ZazumeLogotype size={120} color="Primary"/>;
};

export const MobileOrganizationSelector: FC = () => {
  const { organization, user, changeOrganization } = useAuth();
  const userHelper = user && new UserHelper(user);
  const { applyImgix } = useImgix();

  const onSelectOrganization = async (id) =>
    await changeOrganization(id);

  if (!organization || !user) {
    return null;
  }

  const showSelector = userHelper?.isZazumeUser() || userHelper?.isOwner();
  if (!showSelector) {
    return <Flex align="center">
      {getLandscapeLogo(organization?.logoLandscape && applyImgix({ src: organization.logoLandscape, width: 150 }))}
    </Flex>;
  }

  const header = <OrganizationBtn>
    {getLandscapeLogo(organization?.logoLandscape && applyImgix({ src: organization.logoLandscape, width: 150 }))}
    <TinyArrow color="Gray400"/>
  </OrganizationBtn>;

  return <Container>
    <OrganizationOptions
      header={header}
      onSelect={onSelectOrganization}
      user={user}
      position="all"
      topValue={50}
    />
  </Container>;
};
