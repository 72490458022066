import styled from '@emotion/styled';
import { CopilotChat } from '../../CopilotChat';
import { css } from '@emotion/react';
import { FC } from 'react';
import { useCopilotChat } from '../useCopilotChat';

const maxHeightChat = 600;

const Container = styled.div(({ theme }) => css`
  @keyframes slideIn {
    0% {
      transform: scale(0);
      border-radius: 50%;
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  animation: 150ms ease-in-out 0s 1 slideIn;
  transform-origin: bottom right;

  z-index: 999;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  ${theme.mq.lg} {
    border-radius: 1.125rem;
    height: ${maxHeightChat}px;
    right: 25px;
    bottom: 25px;
    width: 450px;
    height: 85%;
  }
`);

interface Props {
}

export const CopilotWidget: FC<Props> = () => {
  const { chatId, isLoading } = useCopilotChat();

  if (isLoading || !chatId) {
    return null;
  }

  return <Container>
    <CopilotChat isWidget chatId={chatId}/>
  </Container>;
};
