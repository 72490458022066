import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Input } from './Input';
import { FC, useRef, useState } from 'react';

const Container = styled.div<{ isWidget: boolean }>(({ theme, isWidget }) => css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${theme.spacing.s7};
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);

  ${theme.mq.md} {
    padding: ${isWidget ? theme.spacing.s7 : `${theme.spacing.s7} ${theme.spacing.s12}`};
  }
`);

interface Props {
  onSendMessage: (message: string) => Promise<void>;
  isWidget: boolean;
  isSendingMessage: boolean;
}

export const Footer: FC<Props> = ({ onSendMessage, isWidget, isSendingMessage }) => {
  const inputRef = useRef<any>();
  const [content, setContent] = useState<string>('');

  const handleOnChangeContent = (event: React.ChangeEvent<HTMLInputElement>) =>
    setContent(event.target.value);

  const handleOnSendMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!content || isSendingMessage) {
      return;
    }
    await onSendMessage(content);
    setContent('');
    inputRef.current?.focus();
  };

  return <form onSubmit={handleOnSendMessage}>
    <Container isWidget={isWidget}>
      <Input
        ref={inputRef}
        value={content}
        onChange={handleOnChangeContent}
        isSendingMessage={isSendingMessage}
      />
    </Container>
  </form>;
};
