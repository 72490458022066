import { doDelete, doGet, doPost, doPut, EndpointBuilder, toPaginatedURL } from '../../helpers';
import { PaginationOptions } from '#/hooks';
import { TaskTemplate } from '#/models/TaskTemplate';
import { AddTaskTemplateFormData } from '#/pages/templates/taskTemplates/components/AddTaskTemplateForm.schema';
import { IdentifierDTO } from '@zazume/zzm-base';
import { EditTaskTemplateFormData } from '#/pages/templates/taskTemplates/components/EditTaskTemplateForm.schema';

export const taskTemplate = {
  create: (body: AddTaskTemplateFormData) =>
    doPost<TaskTemplate>('/dashboard/task-templates', body),
  delete: (id: IdentifierDTO) =>
    doDelete(`/dashboard/task-templates/${id}`),
  edit: (id: IdentifierDTO) => (body: EditTaskTemplateFormData) =>
    doPut<void>(`/dashboard/task-templates/${id}`, body),
  findAll: () =>
    doGet<TaskTemplate[]>('/dashboard/task-templates?page=1&size=100'),
  findPaginated: (params: EndpointBuilder) => (pagination: PaginationOptions) => () =>
    doGet<TaskTemplate[]>(toPaginatedURL(`/dashboard/task-templates${params.url}${params.queryParams}`, pagination)),
  getOne: (id: IdentifierDTO) =>
    () => doGet<TaskTemplate>(`/dashboard/task-templates/${id}`)
};
