import React, { FC } from 'react';
import { IconProps } from '.';

interface CaretProps extends IconProps {
  direction: 'down' | 'top' | 'left' | 'right';
}

const Left: FC<IconProps> = ({ color }) => <path d="M12 15L7 10L12 5" stroke={color} strokeWidth="1.5"/>;
const Right: FC<IconProps> = ({ color }) => <path d="M8 5L12.5 10L8 15" stroke={color} strokeWidth="1.5"/>;
const Top: FC<IconProps> = ({ color }) => <path d="M5 12L10 7L15 12" stroke={color} strokeWidth="1.5"/>;
const Down: FC<IconProps> = ({ color }) => <path d="M15 8L10 13L5 8" stroke={color} strokeWidth="1.5"/>;

const Directions = {
  'down': Down,
  'left': Left,
  'top': Top,
  'right': Right
};

export const Caret: FC<CaretProps> = ({ color = '#28292C', direction = 'down', size = 20 }) => {
  const Content = Directions[direction];
  return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Content color={color}/>
  </svg>;
};
