import { FC } from 'react';
import { useI18n } from '#hooks/useI18n';
import { IdentifierDTO, Options, useModal, Dropdown } from '@zazume/zzm-base';
import { DocumentUploadLinkModal } from '../documentUploadLinkModal/DocumentUploadLinkModal';
import { DocumentCategories, RelatedModelType } from '#/models';

interface DropdownMenuProps {
  relatedModel: RelatedModelType;
  relatedModelId: IdentifierDTO;
  category: DocumentCategories;
}

export const DropdownMenu: FC<DropdownMenuProps> = ({ relatedModel, relatedModelId, category }) => {
  const { t } = useI18n();
  const { open: openUploadLinksModal, RenderModal: RenderUploadLinksModal } = useModal({ modalSize: 'l' });

  return (<>
    <Dropdown header={<Options size={24} cursor="pointer" color="Gray600"/>} position="right" topValue={30}>
      <Dropdown.Item onClick={openUploadLinksModal} icon="link">{t('documents.manageLinks')}</Dropdown.Item>
    </Dropdown>
    <RenderUploadLinksModal>
      <DocumentUploadLinkModal relatedModel={relatedModel} relatedModelId={relatedModelId} category={category}/>
    </RenderUploadLinksModal>
  </>
  );
};
