import { useI18n } from '#hooks/useI18n';
import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { formatDate, getTimeStamp } from '@zazume/zzm-utils';
import { CalendarComponent, CalendarPositions, IntervalTimePickerType, SelectableHours, Weekday } from './CalendarComponent';
import { Calendar, TinyArrow } from '..';
import { Regular } from '@zazume/zzm-base';
import { ErrorType } from '../../utils/formsHelper';
import { Body16 } from '../typography';
import { getCurrentUILanguage } from '../../lib/i18n/utils';
import { css, useTheme } from '@emotion/react';

const Container = styled.div<any>`
  ${props => props.maxWidth ? `max-width: ${props.maxWidth};` : ''}
  ${props => props.width ? `width: ${props.width};` : 'width: 100%'}
`;

const CustomTertiaryButton = styled.button<any>(({ theme, hoverColor, isLocked, errors }) => css`
  background: ${theme.colors.White};
  color: ${theme.colors.Gray400};
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: ${theme.radius.lg};
  min-width: 5.375rem;
  height: 2.5rem;
  padding: ${theme.spacing.s4} ${theme.spacing.s8};
  padding-left: ${theme.spacing.s6};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${theme.colors.White};
    ${isLocked ? null : `border: ${theme.borderWeights.light} solid ${hoverColor ? hoverColor : theme.colors.Primary};`}
  }

  :disabled {
    background: none;
    border: ${theme.borderWeights.light} solid ${theme.colors.Gray200};
  }

  border: ${theme.borderWeights.light} solid ${errors ? theme.colors.Red : theme.colors.Gray300};
`);

const CalendarWrapper = styled.div`
  position: relative;

  > button {
    width: 100%;

    > span {
      width: 100%;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const Label = styled.label(({ theme }) => css`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: ${theme.colors.Gray600};
  margin: 0;
  height: 28px;
`);

const CalendarButtonContent = styled(Body16)<any>(({ theme, hasValue }) => css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: ${hasValue ? theme.colors.Gray600 : theme.colors.Gray400};

  svg {
    margin-right: 4px;
  }
`);

const TinyArrowCustom = styled(TinyArrow)`
  position: absolute;
  top: 14px;
  right: 14px;
  pointer-events: none;
`;

const Helper = styled.div(({ theme }) => css`
  margin: ${theme.spacing.s1} 0;
`);

const Error = styled.div(({ theme }) => css`
  color: ${theme.colors.Red};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0;
`);

export interface DatePickerProps {
  register?: any;
  setValue?: any;
  getValue?: any;
  name: string;
  label?: string;
  help?: string;
  width?: string;
  maxWidth?: string;
  confirmButton?: string;
  defaultDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  errors?: ErrorType;
  placeholder?: string;
  selectHour?: boolean;
  startingHour?: SelectableHours;
  totalSelectableHours?: SelectableHours;
  interval?: IntervalTimePickerType;
  position?: CalendarPositions;
  autoSave?: boolean;
  onSelected?: any;
  disabledDays?: Weekday[];
  onRemove?: any;
  closeOnSelect?: boolean;
  isOptional?: boolean;
  timezone?: string;
  showErrorsAlways?: boolean;
}

/**
 * @deprecated use src/components/calendar/DatePickerBase.tsx
 */
export const DatePicker: FC<DatePickerProps> = ({
  register,
  setValue,
  getValue,
  name,
  label,
  maxWidth,
  width,
  confirmButton,
  defaultDate, minDate, maxDate,
  disabled = false,
  errors,
  placeholder = 'dd/mm/yy',
  selectHour = false,
  startingHour,
  totalSelectableHours,
  interval,
  position,
  autoSave = true,
  onSelected,
  disabledDays,
  onRemove,
  help,
  closeOnSelect = true,
  isOptional,
  timezone,
  showErrorsAlways = false
}) => {
  const initialValue = defaultDate || getValue?.(name) || null;
  const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(false);
  const [date, setDate] = useState<Date | null>(initialValue);
  const { colors: themeColors } = useTheme();
  const { t } = useI18n();

  useEffect(() => {
    /* Fix not setting default values received from useForm */
    setDate(initialValue);
  }, [initialValue]);

  const updateDate = newDate => {
    setDate(newDate);

    onSelected?.(newDate);

    if (closeOnSelect) {
      toggleCalendar();
    }
  };

  const toggleCalendar = () =>
    setIsCalendarVisible(!isCalendarVisible);

  defaultDate && setValue && name && setValue(name, date, {
    shouldValidate: true,
    shouldDirty: false
  });

  const locale = getCurrentUILanguage();
  const formatPattern = selectHour ? 'ddd D, MMM - HH:mm' : 'DD MMM YYYY';
  const dateWithFormat = date && formatDate(date, formatPattern, locale);

  return <Container maxWidth={maxWidth} width={width}>
    <HeaderContainer>
      {label && <Label>{label}</Label>}
      {isOptional && <Regular variant="S" color="Gray400">{t('general.optional')}</Regular>}
    </HeaderContainer>
    <CalendarWrapper>
      <CustomTertiaryButton onClick={toggleCalendar} size="Large" type="button" disabled={disabled} errors={errors}>
        <CalendarButtonContent hasValue={Boolean(date)}>
          <Calendar color={date ? themeColors.Gray500 : themeColors.Gray400} size={20}/>
          {date ? dateWithFormat : placeholder}
        </CalendarButtonContent>
        {!disabled && <TinyArrowCustom color={themeColors.Gray400} size={14}/>}
      </CustomTertiaryButton>
      <CalendarComponent
        position={position}
        snooze={getTimeStamp()}
        onSelect={updateDate}
        isVisible={isCalendarVisible}
        hide={toggleCalendar}
        selectHour={selectHour}
        name={name}
        register={register}
        setValue={setValue}
        confirmationButtonText={confirmButton}
        minDate={minDate}
        maxDate={maxDate}
        startingHour={startingHour}
        totalSelectableHours={totalSelectableHours}
        interval={interval}
        autoSave={autoSave}
        disabledDays={disabledDays}
        onRemove={onRemove}
        timezone={timezone}
      />
    </CalendarWrapper>
    {help && <Helper><Regular variant="XS" color="Gray400">{help}</Regular></Helper>}
    {errors && (!disabled || showErrorsAlways) && <Error>{errors.message}</Error>}
  </Container>;
};
