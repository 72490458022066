import React, { FC } from 'react';
import { Input } from '../../forms';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IconType } from '@zazume/zzm-base';

const Search = styled.div<any>(({ theme }) => css`
  padding-top: ${theme.spacing.xs};
  padding-bottom: ${theme.spacing.xs};
  padding-left: ${theme.spacing.xs};
  padding-right: ${theme.spacing.xs};
  background-color: ${theme.colors.Gray100};
`);

export interface SearchInputProps {
  placeholder?: string;
  iconLeft?: IconType;
  onChange: (value: string) => void;
  autoFocus: boolean;
}

export const SearchInput: FC<SearchInputProps> = props => {
  return <Search><Input {...props}/></Search>;
};
