import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { Checkbox, Input } from '../../../../../components/forms';
import { Provider, ProviderServicesTypes } from '../../../../../models';
import { useI18n } from '../../../../../hooks/useI18n';
import { Bold, Flex, Regular } from '@zazume/zzm-base';
import { ResponsiveModalForm } from '../../../../../templates/ResponsiveModalForm';
import { ProviderFormInputs } from './Provider.schema';
import { css } from '@emotion/react';
import { PhoneInput } from '../../../../../components/forms/input/PhoneInput';
import GooglePlacesAutocomplete from '../../../../../components/maps/GooglePlacesAutocomplete';
import { Config } from '../../../../../lib/Config';
import { I18NValues } from '../../../../../lib/i18n/generatedKeys';

const CheckboxContainer = styled.div(({ theme }) => css`
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;

  ${theme.mq.tablet} {
    flex-direction: row;
    gap: 4.25rem;
  }
`);

const CheckboxColumn = styled.div(({ theme }) => css`
  width: 100%;
  & > * {
    margin-bottom: 1.5rem;
    padding-top: 0.1875rem;
    padding-bottom: 0.375rem;
  }

  ${theme.mq.tablet} {
    width: 50%;
  }
`);

const servicesMiddleIndex = Math.ceil(ProviderServicesTypes.length / 2);
const editableArray = [...ProviderServicesTypes];
const SERVICES_GROUP_1 = editableArray.splice(0, servicesMiddleIndex);
const SERVICES_GROUP_2 = editableArray.splice(-servicesMiddleIndex);

interface ProviderFormProps {
  provider?: Provider;
}

export const ProviderForm: FC<ProviderFormProps> = ({ provider }) => {
  const { t } = useI18n();
  const { Body: FormBody, Header: FormHeader, Row: FormRow, Column: FormColumn } = ResponsiveModalForm;
  const { register, formState: { errors }, setValue, setError, clearErrors } = useFormContext<ProviderFormInputs>();

  return (
    <ResponsiveModalForm>
      <FormBody>
        <FormHeader>
          <Bold variant="XL">{t('providers.common.details')}</Bold>
        </FormHeader>
        <FormRow>
          <FormColumn>
            <Input
              name="name"
              ref={register}
              label={t('providers.common.taxName')}
              type="text"
              errors={errors.name}
              defaultValue={provider?.name}
              placeholder={t('general.forms.placeholders.example', { value: 'Enterprise SL' })}
            />
            <PhoneInput
              name="phone"
              label={t('general.phone')}
              type="phone"
              isOptional
              placeholder={t('general.forms.placeholders.phone')}
            />
            <GooglePlacesAutocomplete
              name="fullAddress"
              apiKey={Config.GOOGLE_MAPS_API_KEY}
              ref={register}
              label={t('general.address')}
              setValue={setValue}
              setErrors={setError}
              clearErrors={clearErrors}
              errors={errors.fullAddress}
              defaultValue={provider?.billingData?.fullAddress}
              isOptional
              placeholder={t('general.forms.placeholders.address')}
            />
          </FormColumn>
          <FormColumn>
            <Input
              name="idNumber"
              ref={register}
              label={t('general.idNumber')}
              type="text"
              errors={errors.idNumber}
              defaultValue={provider?.billingData?.idNumber}
              isOptional
              placeholder={t('general.forms.placeholders.example', { value: 'B1234323' })}
            />
            <Input
              name="email"
              ref={register}
              label={t('general.email')}
              type="text"
              errors={errors.email}
              defaultValue={provider?.email}
              isOptional
              placeholder={t('general.forms.placeholders.example', { value: 'alex@email.com' })}
            />
            <Input
              name="iban"
              ref={register}
              label={t('general.iban')}
              type="text"
              errors={errors.iban}
              defaultValue={provider?.billingData?.iban}
              isOptional
              placeholder={t('general.forms.placeholders.example', { value: 'ES384798948748392' })}
            />
          </FormColumn>
        </FormRow>
        <FormHeader>
          <Flex gap="md" align="center">
            <Bold variant="XL">{t('providers.modals.edition.services')}</Bold>
            <Regular color="Gray400" variant="XL">{t('general.optional')}</Regular>
          </Flex>
        </FormHeader>
        <FormRow>
          <FormColumn>
            <CheckboxContainer>
              <CheckboxColumn>
                {SERVICES_GROUP_1.map(serviceKey =>
                  <Checkbox
                    ref={register}
                    name={`services.${serviceKey}`}
                    label={t(`providers.services.${serviceKey}` as I18NValues)}
                    defaultChecked={provider?.services[serviceKey]}
                  />
                )}
              </CheckboxColumn>
              <CheckboxColumn>
                {SERVICES_GROUP_2.map(serviceKey =>
                  <Checkbox
                    ref={register}
                    name={`services.${serviceKey}`}
                    label={t(`providers.services.${serviceKey}` as I18NValues)}
                    defaultChecked={provider?.services[serviceKey]}
                  />
                )}
              </CheckboxColumn>
            </CheckboxContainer>
          </FormColumn>
        </FormRow>
      </FormBody>
    </ResponsiveModalForm>
  );
};
