import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { API } from '../../../../../lib/network/API';
import { useI18n } from '../../../../../hooks/useI18n';
import { useZazumeModalContext, ZazumeFullscreenModal } from '@zazume/zzm-base';
import { useYupValidationResolver } from '../../../../../hooks';
import { getProviderSchema, ProviderFormInputs } from './Provider.schema';
import { ProviderForm } from './Provider.form';
import { useUpdate } from '../../../../../lib/reactQuery/hooks/useUpdate';
import { useNetworkExceptions } from '../../../../../lib/network/helpers';
import { Provider } from '../../../../../models';

interface EditProviderModalProps {
  provider: Provider;
  queryKey: string[];
}

export const EditProviderModal: FC<EditProviderModalProps> = ({ provider, queryKey }) => {
  const { t } = useI18n();
  const { mutate: editProvider } = useUpdate(queryKey, API.providers.update(provider._id));
  const { close } = useZazumeModalContext();
  const { handleNetworkExceptions } = useNetworkExceptions();

  const defaultValues = {
    name: provider.name,
    phone: provider.phone ?? undefined,
    email: provider.email,
    iban: provider.billingData?.iban,
    idNumber: provider.billingData?.idNumber,
    fullAddress: provider.billingData?.fullAddress,
    services: provider.services
  };
  const formSchema = getProviderSchema(t);
  const { resolver } = useYupValidationResolver(formSchema);
  const formMethods = useForm<ProviderFormInputs>({ resolver, defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formMethods;

  const handleNewProvider = async (formData) => {
    try {
      await editProvider(formData);
      close();
    } catch (error: any) {
      handleNetworkExceptions(error);
    }
  };

  return (
    <ZazumeFullscreenModal
      title={t('providers.table.options.edit')}
      isSubmitting={isSubmitting }
      onSave={handleSubmit(handleNewProvider)}
      buttonSave={t('general.saveChanges')}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleNewProvider)}>
          <ProviderForm provider={provider}/>
        </form>
      </FormProvider>
    </ZazumeFullscreenModal>
  );
};
