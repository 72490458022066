import { IdentifierDTO } from '@zazume/zzm-base';
import { DateVO } from '@zazume/zzm-utils';

interface Base {
  enabled: boolean;
}

export interface Respondio extends Base {
  workspaceId: string;
}

export interface Zendesk extends Base {
  username: string;
  subdomain: string;
}

interface OrganizationIntegrationsSettingsProps {
  _id: IdentifierDTO;
  organizationId: IdentifierDTO;
  respondio?: Respondio;
  zendesk?: Zendesk;
  createdAt: DateVO;
}

export class OrganizationIntegrationsSettings {
  _id: IdentifierDTO;
  organizationId: IdentifierDTO;
  respondio?: Respondio;
  zendesk?: Zendesk;
  createdAt: DateVO;

  constructor({ _id, organizationId, respondio, zendesk, createdAt }: OrganizationIntegrationsSettingsProps) {
    this._id = _id;
    this.organizationId = organizationId;
    this.respondio = respondio;
    this.zendesk = zendesk;
    this.createdAt = createdAt;
  }

  isRespondioEnabled(): boolean {
    return Boolean(this.respondio?.enabled);
  }

  isRespondioIntegrated(): boolean {
    return Boolean(this.respondio);
  }

  isZendeskEnabled(): boolean {
    return Boolean(this.zendesk?.enabled);
  }

  isZendeskIntegrated(): boolean {
    return Boolean(this.zendesk);
  }

  hasMessagingIntegrationToCreate(): boolean {
    return !this.isRespondioIntegrated() || !this.isZendeskIntegrated();
  }

}
