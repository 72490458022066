import { Delete, Flex, PrimaryButton, SecondaryButton, ZZMForm } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { useI18n } from '#hooks/useI18n';

type EditAlertModalFooterProps = {
  onDelete: () => void;
}

export const EditAlertModalFooter: FC<EditAlertModalFooterProps> = ({ onDelete }) => {
  const { t } = useI18n();
  const { formState } = ZZMForm.useFormContext();
  const { isSubmitting } = formState;

  return (
    <Flex justify="between">
      <SecondaryButton type="button" onClick={onDelete} variant="danger">
        <Delete/> {t('general.delete')}
      </SecondaryButton>
      <PrimaryButton type="submit" disabled={isSubmitting}>
        {t('general.save')}
      </PrimaryButton>
    </Flex>
  );
};
