import { createContext, useContext, useReducer } from 'react';
import { Storage } from '#/utils/Storage';
import { CURRENT_VERSION, reducer, State } from './reducer';

interface NavigationContextProps {
  state: State;
  toggleSectionGroup: (sectionId: string) => void;
  toggleCollapseNavigation: () => void;
  setCollapseNavigation: (isCollapsed: boolean) => void;
  isCollapsed: boolean;
}

const NavigationContext = createContext<NavigationContextProps | null>(null);

const initialState: State = {
  version: CURRENT_VERSION,
  sectionGroups: {},
  isCollapsed: false
};

const loadStoredState = (): State => {
  try {
    const raw = Storage.navigation.getRawState();
    if (!raw) {
      return initialState;
    }

    const state = JSON.parse(raw);
    if (state.version !== CURRENT_VERSION) {
      return initialState;
    }
    return state;
  } catch (e) {
    return initialState;
  }
};

export const NavigationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, () => loadStoredState());

  const value: NavigationContextProps = {
    state: state,
    isCollapsed: state.isCollapsed,
    toggleSectionGroup: (id: string) =>
      dispatch({ type: 'TOGGLE_SECTION_GROUP', sectionId: id }),
    toggleCollapseNavigation: () =>
      dispatch({ type: 'TOGGLE_COLLAPSE_NAVIGATION' }),
    setCollapseNavigation: (newValue: boolean) =>
      dispatch({ type: 'SET_COLLAPSE_NAVIGATION', newValue })
  };

  return <NavigationContext.Provider value={value}>
    {children}
  </NavigationContext.Provider>;
};

export const useNavigation = (): NavigationContextProps => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationContextProvider');
  }
  return context;
};
