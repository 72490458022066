import { AccountingNote } from '#/domain/accountingNote/AccountingNote';
import { BasicUnit } from '#/domain/unit/BasicUnit';
import { DateVO, PriceVO } from '@zazume/zzm-utils';

export const fromRaw = (raw: any): AccountingNote => ({
  _id: raw._id,
  accountingNoteType: raw.accountingNoteType,
  attributedExpenseType: raw.attributedExpenseType,
  dueDate: raw.dueDate ? DateVO.fromPrimitive(new Date(raw.dueDate)) : undefined,
  isGuaranteedRentalService: raw.isGuaranteedRentalService,
  organizationId: raw.organizationId,
  status: raw.status,
  title: raw.title,
  totalPrice: PriceVO.fromPrimitive(raw.totalPrice),
  totalReconciledPrice: PriceVO.fromPrimitive(raw.totalReconciledPrice),
  type: raw.type,
  unit: BasicUnit.fromRaw(raw.unit)
});
