import { FC } from 'react';
import { IlustrationProps } from './index';

export const LostPerson: FC<IlustrationProps> = ({ width = '135', height = '233' }) => {
  return <svg width={width} height={height} viewBox="0 0 135 233" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M47.4237 66.3958C46.6084 64.9762 63.567 59.0483 64.4303 73.3596C64.4303 73.3596 96.5635 71.2589 80.4681 53.955C74.9047 47.96 75.2405 42.8091 75.2021 38.0227C75.1637 33.2363 71.1255 19.491 59.2794 21.3806C58.1064 21.0668 56.8816 20.9968 55.6806 21.175C54.4795 21.3532 53.3278 21.7757 52.2964 22.4165C43.6349 27.3756 25.6307 72.5922 47.4237 66.3958Z" fill="#1A3760"/>
    <path d="M100.553 77.4086C100.553 77.4086 102.52 74.1281 104.208 74.0034C105.896 73.8787 109.004 72.8619 108.754 73.5046C108.505 74.1473 106.596 74.2816 106.424 74.6652C106.251 75.0489 109.301 75.4806 110.212 75.5093C111.124 75.5381 113.148 75.3846 113.09 75.8259C113.032 76.2671 109.464 78.2335 106.874 78.0896C104.916 78.0871 102.971 78.4113 101.119 79.0488L100.553 77.4086Z" fill="#F6AA78"/>
    <path d="M70.3101 79.673C71.2978 81.3473 72.4377 82.9271 73.7152 84.3923C76.3338 87.4042 80.3145 90.8477 85.1585 91.3657C87.4359 91.5505 89.7187 91.0813 91.7386 90.0132C97.4938 87.1356 101.638 81.0255 101.638 81.0255C101.917 80.2371 101.985 79.3895 101.835 78.5667C101.685 77.7439 101.323 76.9745 100.784 76.335C100.784 76.335 89.0816 79.4716 87.9305 78.9536C86.7795 78.4357 79.2402 63.3762 77.8877 60.8727C76.393 57.8498 73.8596 55.4663 70.7513 54.1583L70.3101 79.673Z" fill="#68B0D8"/>
    <path d="M73.7165 84.3907C76.3351 87.4025 80.3158 90.8461 85.1597 91.364C87.4371 91.5489 89.7199 91.0796 91.7398 90.0115C91.5096 90.0115 85.6968 91.0187 83.1933 86.9421C80.6898 82.8655 77.8027 76.1415 74.3687 78.5395C72.882 79.5755 73.045 81.9831 73.7165 84.3907Z" fill="#449DD7"/>
    <path d="M31.4534 209C31.4534 209 27.9907 212.434 28.7869 214.755C29.583 217.076 32.9019 218.486 33.4582 221.325C34.0145 224.164 35.837 225.651 37.4197 225.594L44.9686 225.277C44.9686 225.277 46.0525 223.224 43.2132 222.735C40.374 222.246 39.175 218.899 39.223 215.8C39.1955 213.37 39.0065 210.944 38.657 208.539L32.6237 207.398L31.4534 209Z" fill="#0D4B93"/>
    <path d="M62.0224 209.155C62.0224 209.155 61.303 223.14 62.9144 223.85L83.1631 222.986C83.1631 222.986 83.0672 220.646 79.7963 220.262C76.5255 219.879 70.7319 218.2 69.5904 213.999C68.449 209.797 62.0224 209.155 62.0224 209.155Z" fill="#0D4B93"/>
    <path d="M40.7866 71.4703C40.7866 71.4703 43.4628 68.0076 43.9712 73.101C44.3069 76.4774 45.1222 88.6016 45.6498 89.7047C46.9831 92.4672 48.3739 92.8605 48.3739 92.8605L59.1169 95.0379L62.8962 95.7956L74.5408 91.7958C74.5408 91.7958 79.3368 76.5829 76.795 68.2378C75.3449 63.3231 73.3198 58.5967 70.7616 54.1568C70.7616 54.1568 62.6372 51.2792 48.5753 54.2623L40.7866 71.4703Z" fill="#68B0D8"/>
    <path d="M44.3257 82.0799C44.671 85.4563 45.1122 88.6024 45.6398 89.7055C46.9731 92.468 48.3639 92.8613 48.3639 92.8613L59.1069 95.0386L63.6727 93.4943C63.6727 93.4943 48.1529 91.787 49.2848 78.4541L44.3257 82.0799Z" fill="#449DD7"/>
    <path d="M73.8882 94.0963C73.8882 94.0963 80.4683 112.033 81.0918 118.211C81.7152 124.388 73.5333 206.572 73.1784 209.44C72.8522 212.145 69.591 213.996 69.591 213.996C68.4519 214.612 67.1208 214.77 65.8693 214.437C62.7998 213.699 61.7927 212.711 61.7927 212.711C61.7927 212.711 54.8769 154.641 56.7953 133.682C57.9841 120.741 58.4037 107.74 58.0518 94.7486L73.8882 94.0963Z" fill="#265188"/>
    <path d="M49.1219 94.5674C49.1219 94.5674 40.7001 106.884 40.2013 114.998C39.7026 123.113 40.3932 151.505 38.9448 159.457C37.4964 167.409 31.6165 182.823 32.6236 207.417C33.2975 208.432 34.3221 209.163 35.5012 209.47C36.3091 209.702 37.1287 209.891 37.9568 210.036C38.8005 210.191 39.6718 210.059 40.4316 209.661C41.1914 209.263 41.7959 208.621 42.1485 207.839C46.1772 198.804 59.0112 169.701 59.4812 163.716C59.8169 159.505 63.107 131.583 72.0851 114.902C74.2337 110.903 69.8598 95.517 69.8598 95.517L49.1219 94.5674Z" fill="#2C60AB"/>
    <path d="M74.7042 91.2574C74.7042 91.2574 72.4309 93.1758 67.7788 93.4924C62.2251 93.8281 48.3742 92.8593 48.3742 92.8593C48.044 94.0123 47.8952 95.2096 47.933 96.4083C47.933 96.4083 64.9396 99.967 74.5508 95.9383C75.2164 94.4591 75.2716 92.7771 74.7042 91.2574V91.2574Z" fill="#203865"/>
    <path d="M66.6557 95.4612C66.6967 94.9699 66.8787 94.5009 67.1797 94.1105C67.4807 93.7201 67.8881 93.4249 68.3528 93.2603C68.8174 93.0956 69.3198 93.0687 69.7994 93.1825C70.2791 93.2964 70.7157 93.5463 71.0568 93.9022C71.3979 94.2581 71.6291 94.7049 71.7225 95.1889C71.816 95.673 71.7677 96.1737 71.5835 96.631C71.3994 97.0883 71.0872 97.4827 70.6844 97.767C70.2815 98.0512 69.8053 98.2131 69.3127 98.2333C68.5935 98.2156 67.9105 97.9143 67.4128 97.3949C66.915 96.8756 66.6428 96.1804 66.6557 95.4612ZM67.4998 95.4612C67.5713 95.7818 67.7327
    96.0754 67.9652 96.3075C68.1977 96.5396 68.4916 96.7005 68.8124 96.7714C69.1332 96.8423 69.4675 96.8202 69.7762 96.7077C70.0848 96.5953 70.355 96.397 70.5549 96.1364C70.7549 95.8757 70.8763 95.5635 70.905 95.2362C70.9336 94.909 70.8684 94.5803 70.7168 94.2889C70.5652 93.9974 70.3337 93.7553 70.0493 93.5908C69.7649 93.4264 69.4395 93.3465 69.1113 93.3605C68.6297 93.4364 68.1951 93.6929 67.8957 94.0777C67.5964 94.4626 67.4549 94.9469 67.4998 95.4324V95.4612Z" fill="white"/>
    <path d="M54.2921 53.2549C55.1229 50.4576 55.4286 47.5307 55.1937 44.6221L55.7309 44.6988L64.7857 46.1184C64.7857 46.1184 64.6993 48.996 64.7857 51.605V51.6722C64.8624 53.9838 66.5314 56.238 62.8673 57.3123C61.745 57.6288 52.7669 57.4274 54.2921 53.2549Z" fill="#F6AA78"/>
    <path d="M64.7856 46.1075C64.7856 46.1075 64.6993 48.9851 64.7856 51.5941C59.7594 51.6613 56.9202 47.1914 55.7212 44.6879L64.7856 46.1075Z" fill="#F2904F"/>
    <path d="M66.5601 48.5543C66.5601 48.5543 54.6853 49.2354 54.3783 41.8399C54.0714 34.4445 52.3256 29.543 59.8937 28.7948C67.4618 28.0467 69.054 30.8092 69.7638 33.2551C70.4737 35.7011 69.6487 48.1707 66.5601 48.5543Z" fill="#F6AA78"/>
    <path d="M65.2939 28.3827C65.2939 28.3827 62.4163 37.1977 57.4572 38.6941C52.4982 40.1904 50.292 38.9243 50.292 38.9243C52.6703 36.6638 54.1865 33.645 54.5796 30.3874C54.5796 30.3874 62.6752 24.3445 65.2939 28.3827Z" fill="#1A3760"/>
    <path d="M64.5176 29.1206C65.9164 29.5914 67.1425 30.4699 68.0379 31.6433C69.3277 33.6422 69.9954 35.979 69.9563 38.3577C69.9563 38.3577 72.172 32.3531 69.2752 29.1206C66.177 25.725 64.5176 29.1206 64.5176 29.1206Z" fill="#1A3760"/>
    <path d="M48.5748 54.2621C48.5748 54.2621 43.2129 55.6817 40.9684 59.1444C38.7238 62.6071 36.9877 90.0978 41.6014 91.4023C46.2152 92.7068 51.2798 90.3088 51.04 79.489C50.6637 71.0424 49.8409 62.6217 48.5748 54.2621V54.2621Z" fill="#68B0D8"/>
    <path d="M61.5917 68.6511C59.4911 70.6557 57.2294 72.4843 54.8293 74.1186C50.5225 76.9962 45.362 79.6244 44.2781 81.3893C42.2063 84.6985 41.8034 89.7535 44.2781 90.3194C46.7529 90.8854 49.9374 89.9262 54.7046 84.1997C57.8659 80.3141 60.8057 76.2535 63.5101 72.0371L61.5917 68.6511Z" fill="#F6AA78"/>
    <path d="M61.5913 68.6516C61.5913 68.6516 60.9102 64.0091 61.2364 62.8964C61.5625 61.7837 67.1258 58.7143 67.2601 59.6735C67.3944 60.6327 65.5048 62.0907 65.8117 63.6542C66.157 65.4095 66.5695 67.5389 65.687 68.4502C64.8459 69.5516 64.1165 70.734 63.5097 71.98L61.5913 68.6516Z" fill="#F6AA78"/>
    <path d="M61.592 68.6511C61.2278 67.3987 60.736 66.1869 60.1244 65.0349C59.4913 64.1908 61.1412 64.5841 61.6879 65.6104C62.2346 66.6368 61.592 68.6511 61.592 68.6511Z" fill="#F6AA78"/>
    <path d="M127.094 72.5165L82.9131 81.0643L85.8196 53.7238C85.8977 52.9877 86.2103 52.2961 86.7113 51.7511C87.2123 51.2061 87.8751 50.8365 88.6021 50.6968L127.316 43.2068C127.66 43.141 128.015 43.1568 128.352 43.2529C128.689 43.3489 128.999 43.5226 129.257 43.7599C129.515 43.9972 129.713 44.2916 129.837 44.6195C129.961 44.9474 130.006 45.2997 129.969 45.6483L127.094 72.5165Z" fill="#AABDE2"/>
    <path d="M127.645 72.4083L84.1249 80.8282L87.0535 53.4659C87.1317 52.7297 87.4443 52.0382 87.9452 51.4931C88.4462 50.9481 89.1091 50.5785 89.8361 50.4388L128.533 42.9521C128.877 42.8864 129.232 42.9022 129.569 42.9982C129.906 43.0942 130.216 43.2679 130.474 43.5052C130.732 43.7425 130.93 44.0369 131.054 44.3648C131.178 44.6927 131.223 45.045 131.186 45.3936L128.387 71.6053C128.364 71.8006 128.281 71.9838 128.147 72.1283C128.014 72.2727 127.838 72.3708 127.645 72.4083V72.4083Z" fill="#E1E8F6"/>
    <path d="M104.697 62.5221C104.867 63.4023 105.295 64.2122 105.925 64.8495C106.556 65.4867 107.361 65.9227 108.24 66.1022C109.118 66.2817 110.03 66.1967 110.86 65.8579C111.69 65.5192 112.401 64.9419 112.903 64.1991C113.405 63.4563 113.675 62.5814 113.68 61.6848C113.685 60.7883 113.424 59.9106 112.929 59.1626C112.435 58.4145 111.73 57.8298 110.904 57.4824C110.077 57.135 109.166 57.0405 108.286 57.2108C107.106 57.4391 106.065 58.127 105.392 59.1231C104.718 60.1191 104.469 61.3418 104.697 62.5221Z" fill="#AABDE2"/>
    <path d="M116.929 3.85613C118.236 5.1502 122.65 8.42495 119.584 20.1468C116.518 31.8686 89.816 36.3967 92.2791 14.2916C93.1218 6.62241 104.739 -4.52418 116.929 3.85613Z" fill="#F0A64F"/>
    <path d="M108.534 22.5417C108.768 22.7777 109.561 23.3772 108.99 25.4901C108.42 27.6029 103.584 28.3819 104.072 24.3907C104.227 23.0062 106.344 21.0093 108.534 22.5417Z" fill="white"/>
    <path d="M105.197 19.2442C104.535 13.9504 103.518 10.5688 103.615 5.57061C103.629 4.94063 104.262 3.89043 106.549 3.95106C107.151 3.98089 108.914 4.01152 108.85 6.47547L107.575 19.2608C107.57 19.5392 107.461 19.8059 107.27 20.0088C107.079 20.2116 106.82 20.3362 106.542 20.3582L106.403 20.3681C105.741 20.4312 105.278 19.897 105.197 19.2442Z" fill="white"/>
  </svg>;
};
