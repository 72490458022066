import { RandomHelper } from '#/utils/RandomHelper';
import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

interface Notification {
  id: string;
  message: string;
  options: NotificationOptions;
}

interface NotificationOptions {
  type: 'error' | 'info';
  timeout: number;
}

const notificationsAtom = atom<Notification[]>({
  key: 'notifications',
  default: []
});

export const useNotifications = () => {
  const [notifications, setNotifications] = useRecoilState<Notification[]>(notificationsAtom);

  const closeNotification = useCallback((id: string) => {
    setNotifications(currentNotifications =>
      currentNotifications.filter(notification => notification.id !== id));
  }, [setNotifications]);

  const showNotification = (message: string, options?: any) => {
    const newNotification = {
      id: RandomHelper.alphaNumeric(),
      message,
      options: {
        type: options?.type || 'info',
        timeout: options?.timeout || 5000
      }
    };

    setNotifications(currentNotifications => [...currentNotifications, newNotification]);
  };

  const stopNotification = () => notifications.length && setNotifications([]);

  return {
    notifications,
    showNotification,
    closeNotification,
    stopNotification
  };
};
