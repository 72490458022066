import { FCC } from '@zazume/zzm-base';
import { Route, Routes } from 'react-router-dom';
import ErrorPage from '../../pages/Error.page';
import NotFound404 from '../../pages/NotFound404.page';
import { Anonymous } from './routes/Anonymous';

export const BaseRoutes: FCC<any> = ({ children }) => {
  return <Routes>
    {children}
    <Route path={Anonymous.ERROR.route} element={<ErrorPage/>}/>
    <Route path="*" element={<NotFound404/>}/>
  </Routes>;
};
