import { fromAgentSearchToUser, fromApplicationSearchToApplication, fromCitySearchToCity, fromEntitySearchToEntityOwnerAccount, fromOwnerSearchToOwner, fromTenantSearchToTenant, fromUnitSearchToUnit, IndexName } from '#/utils/algolia/algoliaHelper';
import { useI18n } from '#hooks/useI18n';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Regular } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { Caption } from '../typography';
import { DefaultResult } from './DefaultResult';
import { EntityResult } from './EntityResult';
import { OwnerAccountResult } from './OwnerAccountResult';
import { UnitResult } from './UnitResult';
import { UserResult } from './UserResult';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 22.5rem;
  cursor: pointer;
  padding: 0;
  user-select: none;
  overflow: auto;
`;

export const ResultCaption = styled(Caption)(({ theme }) => css`
  padding-bottom: ${theme.spacing.s4};
  color: ${theme.colors.Gray400};
`);

export interface ResultProps {
  result: any;
  onSelect: (result: any) => void;
}

export interface AlgoliaResultListProps {
  indexName: IndexName;
  results: any[];
  selectAction: (selected: any) => void;
}

export const getResultComponent = (entity: IndexName): FC<ResultProps> => {
  switch (entity) {
    case 'agents':
      return UserResult;
    case 'tenants':
      return UserResult;
    case 'units':
      return UnitResult;
    case 'applications':
      return DefaultResult;
    case 'owners':
      return OwnerAccountResult;
    case 'entities':
      return EntityResult;
    default:
      return DefaultResult;
  }
};

export const getResultParser = (entity: IndexName) => {
  switch (entity) {
    case 'agents':
      return fromAgentSearchToUser;
    case 'tenants':
      return fromTenantSearchToTenant;
    case 'units':
      return fromUnitSearchToUnit;
    case 'applications':
      return fromApplicationSearchToApplication;
    case 'owners':
      return fromOwnerSearchToOwner;
    case 'entities':
      return fromEntitySearchToEntityOwnerAccount;
    case 'cities':
      return fromCitySearchToCity;
  }
};

export const AlgoliaResultList: FC<AlgoliaResultListProps> = ({ indexName, results, selectAction }) => {
  const { t } = useI18n();
  const Result = getResultComponent(indexName);

  const handleSelect = (value) => {
    const parser = getResultParser(indexName);
    selectAction(parser(value));
  };

  return (
    <Container>
      <ResultCaption>{t('search.results')}</ResultCaption>
      {Boolean(results && results.length)
        ? results.map((result) => <Result key={result.objectID} result={result} onSelect={handleSelect}/>)
        : <Regular>{t('general.noResults')}</Regular>
      }
    </Container>
  );
};
