import { TFunction } from '#hooks/useI18n';
import { FormValidation } from '@zazume/zzm-base';
import { SchemaOf } from '@zazume/zzm-utils';
import { AnySchema } from 'yup';

type Schema = AnySchema | SchemaOf<any>;

/**
 * @deprecated use createFormValidation
 * @param t
 * @param schema
 */
export const toFormValidation = (t: TFunction, schema: Schema): FormValidation => ({
  schema,
  unexpectedErrorMessage: t('errorCodes.91')
});

export const createFormValidation = (t: TFunction, validationSchema: SchemaOf<any>) => ({
  validationSchema,
  unexpectedErrorMessage: t('errorCodes.91')
});
