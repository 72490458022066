import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { Colors, FCC, ZIndex } from '@zazume/zzm-base';
import { Body14 } from '../typography';
import { useOnScreen } from './logic/useOnScreen';

const Container = styled.div`
  position: relative;
  width: fit-content;
  :hover {
    * {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const Title = styled(Body14)<any>`
  display: flex;
  cursor: default;

  ${props => props.underline === 'true' && `
    text-decoration: underline;
  `}

  ${props => !props.color && `
    color: ${Colors.FONT_3};
  `}

  ${props => props.level === 'warning' && `
    color: ${Colors.RED};
  `}
`;

const TooltipBox = styled.div<any>`
  z-index: ${ZIndex.TOOLTIP};
  white-space: nowrap;
  width: max-content;
  visibility: hidden;
  position: absolute;
  min-width: 112px;
  max-width: 300px;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.5s;
  background-color: ${Colors.GREY_6};

  ${props => props.position === 'bottom' && `
    top: 100%;
    left: 50%;
    margin-top: 8px;
    transform: translateX(-50%);
  `}

  ${props => props.position === 'top' && `
    bottom: 100%;
    left: 50%;
    margin-bottom: 8px;
    transform: translateX(-50%);
  `}

  ${props => props.position === 'left' && `
    right: 100%;
    top: 50%;
    margin-right: 8px;
    transform: translateY(-50%);
  `}

  ${props => props.position === 'right' && `
    left: 100%;
    top: 50%;
    margin-left: 8px;
    transform: translateY(-50%);
  `}

  &:after {
    content: " ";
    position: absolute;
    margin-left: -7px;
    border-width: 7px;
    border-style: solid;

    ${props => props.position === 'bottom' && `
      top: -14px;
      left: 50%;
      border-color: transparent transparent ${Colors.GREY_6} transparent;
    `}

    ${props => props.position === 'top' && `
      top: 100%;
      left: 50%;
      border-color: ${Colors.GREY_6} transparent transparent transparent;
    `}

    ${props => props.position === 'left' && `
      right: -14px;
      top: 50%;
      transform: translateY(-50%);
      border-color: transparent transparent transparent ${Colors.GREY_6};
    `}

    ${props => props.position === 'right' && `
      left: -7px;
      top: 50%;
      transform: translateY(-50%);
      border-color: transparent ${Colors.GREY_6} transparent transparent;
    `}
  }
`;

export interface TooltipProps {
  title: React.ReactNode;
  underline?: string
  level?: string
}


export const Tooltip: FCC<TooltipProps> = ({ title, underline, level, children }) => {
  const ref = useRef(null);
  const { isIntersectingBottom, isIntersectingRight, isIntersectingLeft } = useOnScreen(ref);

  const isHorizontal = isIntersectingRight || isIntersectingLeft;

  const position = isHorizontal
    ? isIntersectingRight ? 'left' : 'right'
    : isIntersectingBottom ? 'top' : 'bottom';

  return <Container>
    <Title underline={underline} level={level}>{title}</Title>
    <TooltipBox ref={ref} position={position}>
      {children}
    </TooltipBox>
  </Container>;
};
