import { FCC } from '@zazume/zzm-base';
import React from 'react';
import { usePermissions } from '../../hooks';
import { BooleanHelper } from '@zazume/zzm-utils';

interface ZazumeCanProps {
  if?: boolean;
}

export const ZazumeCan: FCC<ZazumeCanProps> = (props) => {
  const { includesRoles } = usePermissions();

  if (BooleanHelper.isFalse(props.if)) {
    return null;
  }

  if (!includesRoles(['zazume'])) {
    return null;
  }

  return <>{props.children}</>;
};
