import { IdentifierDTO, ZZMColor } from '@zazume/zzm-base';
import { DateVO, PriceVO } from '@zazume/zzm-utils';
import { BasicUserVO } from './BasicUser';

export class SepaPayments {
  _id: IdentifierDTO;
  sepaIdentifier: string;
  payoutIds: IdentifierDTO[];
  documentId: IdentifierDTO;
  paymentsTotal: PriceVO;
  organizationId: IdentifierDTO;
  state: SepaPaymentsState;
  createdAt: DateVO;
  basicAuthor: BasicUserVO;

  constructor({ _id, sepaIdentifier, payoutIds, paymentsTotal, organizationId, state, createdAt, documentId, basicAuthor }: SepaPayments) {
    this._id = _id;
    this.sepaIdentifier = sepaIdentifier;
    this.payoutIds = payoutIds;
    this.documentId = documentId;
    this.paymentsTotal = paymentsTotal;
    this.organizationId = organizationId;
    this.state = state;
    this.createdAt = createdAt;
    this.basicAuthor = basicAuthor;
  }

  isProcessed(): boolean {
    return this.state === 'linked';
  }

  getAmountColor(): ZZMColor {
    if (this.paymentsTotal.isPositive()) {
      return 'GreenDark1';
    }
    if (this.paymentsTotal.isZero()) {
      return 'Gray500';
    }
    return 'RedDark1';
  }
}

export type SepaPaymentsState = 'initiated' | 'linked';
