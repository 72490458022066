import { Key } from 'react';
import { useLocation } from 'react-router-dom';
import { useRouter, LocationState } from '../index';

const getNewURL = (pathname: string, search: string, newTabId: Key, tabURLParameterName: string) => {
  const currentURLParams = new URLSearchParams(search);
  currentURLParams.set(tabURLParameterName, `${newTabId}`);
  return [pathname, currentURLParams.toString()].join('?');
};

const getDefaultSelectedKey = (search: string, paramName: string): string | undefined =>
  search ? new URLSearchParams(search).get(paramName) || undefined : undefined;

export const useURLTabs = (paramName = 'tab') => {
  const { search, pathname, state } = useLocation();
  const { navigate } = useRouter();

  const handleOnSelectionChange = (selectedTabKey: Key) => {
    const newURL = getNewURL(pathname, search, selectedTabKey, paramName);
    navigate(newURL, { replace: true, canGoBack: false, navigationQuery: (state as LocationState)?.navigationQuery });
  };

  const defaultSelectedKey = getDefaultSelectedKey(search, paramName);

  return {
    onSelectionChange: handleOnSelectionChange,
    defaultSelectedKey
  };
};
