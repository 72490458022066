import { IdentifierDTO } from '@zazume/zzm-base';
import { doGet, doPut } from '../../helpers';
import { NotificationSettings } from '#/models/notificationSettings/NotificationSettings';

export const notificationSettings = {
  getMyNotificationSettings: () =>
    doGet<NotificationSettings>('/dashboard/notification-settings'),
  updateMyNotificationSettings: (notificationSettings: Partial<NotificationSettings>): Promise<NotificationSettings> =>
    doPut<NotificationSettings>('/dashboard/notification-settings', notificationSettings),
  getUserNotificationSettings: (userId: IdentifierDTO) => () =>
    doGet<NotificationSettings>(`/dashboard/notification-settings/${userId}`),
  editUserNotificationSettings: (userId: IdentifierDTO) => (notificationSettings: Partial<NotificationSettings>) =>
    doPut<NotificationSettings>(`/dashboard/notification-settings/${userId}`, notificationSettings),
  owner: {
    getOwnerNotificationSettings: (ownerAccountId: IdentifierDTO) => () =>
      doGet<NotificationSettings>(`/dashboard/notification-settings/owner/${ownerAccountId}`),
    editOwnerNotificationSettings: (ownerAccountId: IdentifierDTO) => (notificationSettings: Partial<NotificationSettings>) =>
      doPut<NotificationSettings>(`/dashboard/notification-settings/${ownerAccountId}/owner`, notificationSettings)
  }
};
