/**
 * @deprecated use the zzm-base package
 */
export const Shadows = {
  SHADOW_0: '0 1px 2px rgba(43, 55, 157, 0.2)',
  SHADOW_1: 'inset 0px -1px 0px #EBEEF4',
  SHADOW_2: '0px 1px 2px rgba(0, 0, 0, 0.08)',
  SHADOW_3: '0 2px 3px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.2)',
  SHADOW_4: 'inset 1px 0px 0px 0px #EBEEF4',
  SHADOW_5: 'inset 0px 1px 0px 0px #EBEEF4',
  SHADOW_6: 'inset -1px -1px 0px 0px #EBEEF4',
  SHADOW_7: '0px 0px 1px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.1)',
  DEPTH_1: 'drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2))'
};

export const Sizes = {
  PAGE_BODY_LEFT_MARGIN: 62
};
