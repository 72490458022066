import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Container = styled.div(({ theme }) => css`
  --PhoneInput-color--focus: ${theme.colors.Primary};
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);

  width: 100%;
  margin-bottom: 8px;

  .PhoneInput {
    display: flex;
    align-items: center;
  }

  .PhoneInputInput {
    flex: 1;
    min-width: 0;
  }

  .PhoneInputCountryIcon {
    width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
    height: var(--PhoneInputCountryFlag-height);
  }

  .PhoneInputCountryIcon--square {
    width: var(--PhoneInputCountryFlag-height);
  }

  .PhoneInputCountryIcon--border {
    background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
      inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
  }

  .PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
  }

  .PhoneInputInternationalIconPhone {
    opacity: var(--PhoneInputInternationalIconPhone-opacity);
  }

  .PhoneInputInternationalIconGlobe {
    opacity: var(--PhoneInputInternationalIconGlobe-opacity);
  }

  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountrySelect[disabled],
  .PhoneInputCountrySelect[readonly] {
    cursor: default;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 0.3em;
    height: 0.3em;
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity: 0.45;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
      inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
  }
`);

export default Container;
