import React, { FC } from 'react';
import { Avatar, Flex, IdentifierDTO, Regular } from '@zazume/zzm-base';
import { SignerStateTag } from '#/components/forms/signer/atoms/SignerStateTag';
import { Signer } from '#/models/contract/Signer';
import { useI18n } from '#hooks/useI18n';
import styled from '@emotion/styled';
import { SignerEmailErrorInfo } from '../../SignerEmailErrorInfo';

const Body = styled(Flex)`
  * {
    white-space: nowrap;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface SignerStatusProps {
  documentId: IdentifierDTO;
  signer: Signer;
  position: number;
  isRejected: boolean;
  onAfterUpdate?: () => Promise<void>;
}

export const SignerStatus: FC<SignerStatusProps> = ({ documentId, signer, position, isRejected, onAfterUpdate }) => {
  const { t } = useI18n();
  return <Flex direction="column" gap="s2">
    <Regular variant="XS" color="Gray500">{t('signatures.signerCount', { number: position + 1 })}</Regular>
    <Body gap="xs" align="center">
      <Avatar name={signer.name}/>
      <Regular variant="S">{signer.name}</Regular>
      <SignerStateTag signerStatus={signer.status} hasEmailError={signer.hasEmailError}/>
    </Body>
    {signer.hasEmailError && !isRejected && <SignerEmailErrorInfo documentId={documentId} onAfterUpdate={onAfterUpdate} signer={signer}/>}
  </Flex>;
};
