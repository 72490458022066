import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useAuth } from '../../../contexts/AuthProvider';
import { isOwnerUser, isZazumeUser } from '../../../models';
import { OrganizationLabel } from './OrganizationLabel';
import { OrganizationOptions } from '../../../components/forms/select/organization/OrganizationOptions';
import { css } from '@emotion/react';
import { Regular } from '@zazume/zzm-base';

const Container = styled.div`
  user-select: none;
  position: relative;
`;

const OrganizationBtn = styled.button(({ theme }) => css`
  user-select: none;
  background: none;
  border: none;
  border-radius: ${theme.radius.lg};
  box-shadow: none;
  padding: ${theme.spacing.s2} ${theme.spacing.s6};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  outline: none;

  :hover {
    background: ${theme.colors.PrimaryLight4};
    box-shadow: none;
  }

  :active {
    border: none;
  }
`);

export const OrganizationSelector: FC = () => {
  const { organization, user, changeOrganization } = useAuth();

  if (!organization || !user) {
    return null;
  }

  const showSelector = isZazumeUser(user) || isOwnerUser(user);
  if (!showSelector) {
    return <OrganizationLabel organizationName={organization.name}/>;
  }

  const header = <OrganizationBtn>
    <Regular color="Gray500">{organization.name}</Regular>
  </OrganizationBtn>;

  const onSelectOrganization = async (id) =>
    await changeOrganization(id);

  return <Container>
    <OrganizationOptions
      header={header}
      onSelect={onSelectOrganization}
      user={user}
      position="left"
    />
  </Container>;
};
