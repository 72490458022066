import { IdentifierDTO } from '@zazume/zzm-base';
import { ZazumeService, ZazumeServices } from '@zazume/zzm-utils';
import * as yup from 'yup';
import { TFunction } from '../../../hooks/useI18n';

export const getRequestUnitServiceUpgradeSchema = (t: TFunction) => {

  return yup.object().shape({
    service: yup.mixed().required(t('general.forms.mandatory')).oneOf([...ZazumeServices], t('general.forms.mandatory')),
    unitId: yup.string().required(t('general.forms.mandatory'))
  });
};

export type RequestUnitServiceUpgradeFormInputs = {
  service: ZazumeService;
  unitId: IdentifierDTO;
}
