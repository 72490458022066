import React, { FC } from 'react';
import { IconProps } from '.';
import { Colors } from '@zazume/zzm-base';

export const DocumentThin: FC<IconProps> = ({ color = Colors.FONT_3, size = 40 }) => {
  return <svg width={size} height={size} viewBox={'0 0 40 40'} fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11 7.75C10.0335 7.75 9.25 8.5335 9.25 9.5V31.5C9.25 32.4665
    10.0335 33.25 11 33.25H29C29.9665 33.25 30.75 32.4665 30.75 31.5V11.5C30.75 9.42893 29.0711 7.75 27 7.75H11ZM10.75
    9.5C10.75 9.36193 10.8619 9.25 11 9.25H27C28.2426 9.25 29.25 10.2574 29.25 11.5V31.5C29.25 31.6381 29.1381 31.75
    29 31.75H11C10.8619 31.75 10.75 31.6381 10.75 31.5V9.5ZM14 15.25H24V13.75H14V15.25ZM26 21.25H14V19.75H26V21.25ZM14
    27.25H24V25.75H14V27.25Z"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11 7.75C10.0335 7.75 9.25 8.5335 9.25 9.5V31.5C9.25 32.4665 10.0335
    33.25 11 33.25H29C29.9665 33.25 30.75 32.4665 30.75 31.5V11.5C30.75 9.42893 29.0711 7.75 27 7.75H11ZM10.75 9.5C10.75
    9.36193 10.8619 9.25 11 9.25H27C28.2426 9.25 29.25 10.2574 29.25 11.5V31.5C29.25 31.6381 29.1381 31.75 29 31.75H11C10.8619
    31.75 10.75 31.6381 10.75 31.5V9.5ZM14 15.25H24V13.75H14V15.25ZM26 21.25H14V19.75H26V21.25ZM14 27.25H24V25.75H14V27.25Z"/>
  </svg>;
};
