import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from '.';

export const PaperPlane: FC<IconProps> = ({ size = 20, color = '#FFFFFF' }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9395 12.0001L5.06064 20.3595L9.23369 12.0001M18.9395 12.0001L5.06064 3.64062L9.23369 12.0001M18.9395 12.0001L9.23369 12.0001"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </svg>
  ;
};
