import { useI18n } from '#hooks/useI18n';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@zazume/zzm-base';
import { Document } from '../../models';
import { Footnote } from '../typography';
import { formatBytes, isPassedDate, formatDate, subtract } from '@zazume/zzm-utils';

const Info = styled(Footnote)<any>`
  color: ${props => props.color ? props.color : Colors.FONT_5};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;

interface DocumentCardFootnoteProps {
  document: Document;
  relatedUser?: string;
}

export const DocumentCardFootnote: FC<DocumentCardFootnoteProps> = ({ document, relatedUser }) => {
  const { t } = useI18n();

  const { expireAt, size } = document;

  const isExpired = expireAt ? isPassedDate(expireAt) : false;
  const isExpiring = expireAt ? isPassedDate(subtract(expireAt, 1, 'month')) : false;

  if (isExpired) {
    return <Info>{t('documents.expired')} {formatDate(expireAt!, 'DD/MM/YYYY')}</Info>;
  }

  if (isExpiring) {
    return <Info color={Colors.RED}>{t('documents.expiring')} {formatDate(expireAt!, 'DD/MM/YYYY')}</Info>;
  }

  return <Info>{relatedUser || formatBytes(size, 0)}</Info>;
};
