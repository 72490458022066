import React, { FC } from 'react';
import { Regular, Table } from '@zazume/zzm-base';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s7};
`);

type SkeletonProps = {
  headerTitles?: string[];
  rows?: number;
  columns?: number;
};

export const TableSkeleton: FC<SkeletonProps> = ({ headerTitles, rows = 5, columns = 4 }) => {
  return <Table>
    <Container>
      {[...Array(rows).keys()].map(index =>
        <Table.Short.Item.Skeleton key={index}>
          <Table.Short.Header.Skeleton/>
          <Table.Short.Body>
            {headerTitles ? headerTitles.map(title => <Table.Short.Field.Skeleton key={title} title={title}/>)
              : [...Array(columns).keys()].map(index => <Table.Short.Field.Skeleton key={index} title="-"/>)
            }
          </Table.Short.Body>
        </Table.Short.Item.Skeleton>
      )}
    </Container>
    <Table.Base>
      <Table.Base.Header>
        {headerTitles ? headerTitles.map(title => <Table.Base.Title key={title}>{title}</Table.Base.Title>)
          : [...Array(columns).keys()].map(index => <Table.Base.Title key={index}><Regular.Skeleton/></Table.Base.Title>)
        }
      </Table.Base.Header>
      <Table.Base.Body.Skeleton rows={rows}/>
    </Table.Base>
  </Table>;
};
