import { add, toPlainISOWithoutMilliseconds, skipComas } from '@zazume/zzm-utils';

type CheckFileError = 'fileTooBig' | 'filesTooBig' | 'wrongFile';

export const toBytes = (sizeMB: number): number => {
  return sizeMB * Math.pow(1024, 2);
};

export const checkFiles = (files: File[], maxSize: number, type: string[] | RegExp, filesSize: number, maxBunchFilesSize: number, selectedFilesSize: number): CheckFileError | null => {
  let error;

  for (let i = 0; i < files.length; i++) {
    error = checkFile(files[i], maxSize, type, filesSize, maxBunchFilesSize, selectedFilesSize);
    if (error) {
      break;
    }
  }
  return error;
};


export const checkFile = (file: File, maxSize: number, type: string[] | RegExp, filesSize: number, maxBunchFilesSize: number, selectedFilesSize: number): CheckFileError|null => {
  if (Array.isArray(type) && !type.includes(file.type)) {
    return 'wrongFile';
  }

  if (type instanceof RegExp && !file.type.match(type)) {
    return 'wrongFile';
  }

  if (file.size > toBytes(maxSize)) {
    return 'fileTooBig';
  }

  if (filesSize > toBytes(maxBunchFilesSize)) {
    return 'filesTooBig';
  }
  if ((selectedFilesSize + filesSize) > toBytes(maxBunchFilesSize)) {
    return 'filesTooBig';
  }

  return null;
};

export const isImage = (filename: string): boolean => {
  const splitName = filename.split('.');
  if (splitName[splitName.length - 1].toLowerCase().match('(jpg|jpeg|png|gif)')) {
    return true;
  }
  return false;
};

export interface ICSObject {
  date: Date;
  title: string;
  description: string;
  location?: string;
  durationInMinutes?: number;
}

export const generateICSFile = ({ date, title, description, location, durationInMinutes = 30 }: ICSObject): string => {
  const eventDate = {
    start: toPlainISOWithoutMilliseconds(date),
    end: toPlainISOWithoutMilliseconds(add(date, durationInMinutes, 'minute'))
  };
  return makeIcsFile(eventDate, title, skipComas(description), location && skipComas(location));
};

// FIXME: use the organization email instead of info@zazume.com in the UID
const makeIcsFile = (date, title, description, location) => {
  const fileContent = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//${title}//Calendar
CALSCALE:GREGORIAN
BEGIN:VEVENT
DTSTAMP:${toPlainISOWithoutMilliseconds(new Date())}
UID:info@zazume.com
DTSTART:${date.start}
DTEND:${date.end}
SUMMARY:${title}
DESCRIPTION:${description}
LOCATION:${location}
END:VEVENT
END:VCALENDAR`;

  const data = new File([fileContent], `${title}`, { type: 'text/calendar' });

  return window.URL.createObjectURL(data);
};

export const ONLY_IMAGES: RegExp = /image\/.*/g;
export const ONLY_VIDEOS: RegExp = /video\/.*/g;

export const OnlyVideosTypes = ['video/mp4', 'video/ogg', 'video/webm', 'video/quicktime']
  .join(',');

export const getImagePreview = (imageFile: File | null): string | null =>
  imageFile ? URL.createObjectURL(imageFile) : null;

export const getAllFileTypes = () => ['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'image/jpg', 'image/jpeg'];

export const getAttachmentsTypes = () => ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg', 'image/jpeg'];

export const getAttachmentsTypeOnlyPDF = () => ['application/pdf'];

export const getCSVTypes = () => ['text/csv'];
