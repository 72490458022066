import React, { FC } from 'react';
import { IdentifierDTO } from '@zazume/zzm-base';
import styled from '@emotion/styled';
import { Colors } from '@zazume/zzm-base';
import { useDownloadDocument } from '../../../../hooks/documents/useDownloadDocument';

const Link = styled.a`
  color: ${Colors.FONT_3};
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

interface DownloadLinkProps {
  documentId: IdentifierDTO;
  filename: string;
}

export const DownloadLink: FC<DownloadLinkProps> = ({ documentId, filename }) => {
  const { download } = useDownloadDocument(documentId);

  return <Link onClick={download} target="_blank">
    {filename}
  </Link>;
};
