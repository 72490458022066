import React, { FC } from 'react';
import { Steps } from '#/pages/investment/components/productChoose/Steps';
import { ModalContextProvider } from '#/pages/investment/components/productChoose/ModalContext';

interface PurchaseInvestmentModalProps {
  onClose: () => void;
}

export const PurchaseInvestmentModal: FC<PurchaseInvestmentModalProps> = ({ onClose }) => {
  return (
    <ModalContextProvider onCloseModal={onClose}>
      <Steps/>
    </ModalContextProvider>
  );
};
