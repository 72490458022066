import { useI18n } from '#hooks/useI18n';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Key } from '../../components/icons';
import { Body14 } from '../../components/typography';
import { ResultProps } from './ResultList';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0;
  user-select: none;
  padding: 12px 0;

  svg {
    flex-shrink: 0;
  }
`;

const UnitName = styled(Body14)`
  margin-left: 8px;
`;

export const UnitResult: FC<ResultProps> = ({ result, onSelect }) => {
  const { t } = useI18n();
  const handleSelect = () => onSelect(result);

  return (
    <Container onClick={handleSelect}>
      <Key size={20}/>
      <UnitName>{t('units.unitWithId', { id: result.name })}</UnitName>
    </Container>
  );
};
