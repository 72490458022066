import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const Container = styled.div<{ height: number, width?: number }>(({ theme, height, width }) => css`
  position: relative;
  background: ${theme.colors.Gray200};
  width: ${width ? `${width}px` : '100%'};
  max-width: 100%;
  overflow: hidden;
  border-radius: ${theme.radius.lg};
  height: ${height}px;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, transparent, ${theme.colors.BlueLight}, transparent);
    transform: translateX(-100%);
    animation-name: ${shimmer};
    animation-direction: normal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`);

interface Props {
  height?: number;
  width?: number;
}

export const BlockSkeleton: FC<Props> = ({ height = 250, width }) => {
  return <Container height={height} width={width}/>;
};
