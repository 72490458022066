import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps, getStrokeWidth } from './index';

/**
 * @deprecated use zzm-base
 * @param size
 * @param color
 * @constructor
 */
export const Chat: FC<IconProps> = ({ size = 20, color = Colors.FONT_3 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} strokeLinejoin="round" d="M20.264 2.4032H5.0691C3.59683 2.4032 2.40332 3.59671 2.40332 5.06898V15.4655C2.40332 16.2017 3.00008 16.7984 3.73621 16.7984H7.20172V21.5968L13.2797 17.0383C13.4873 16.8826 13.7399 16.7984 13.9995 16.7984H18.9312C20.4034 16.7984 21.5969 15.6049 21.5969 14.1326V3.73609C21.5969 2.99995 21.0002 2.4032 20.264 2.4032Z"/>
  </svg>;
};
