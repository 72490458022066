import { IdentifierDTO } from '@zazume/zzm-base';

export type SimpleRoles = 'buildingManager' | 'tenant';

export interface BasicUser {
  _id: IdentifierDTO;
  avatar?: string;
  email: string;
  name: string;
  /**
   * @deprecated do not use it
   */
  role?: SimpleRoles;
  surnames: string;
}

export interface OnboardingBasicTenant extends BasicUser {
  profileValidated?: boolean;
  isInvited: boolean;
}

export const toBasicUser = (user): BasicUser => {
  return {
    _id: user._id,
    name: user.name,
    email: user.email,
    surnames: user.surnames,
    avatar: user.avatar,
    role: user.role
  };
};

interface BasicUserProps {
  _id: IdentifierDTO;
  avatar?: string;
  email: string;
  name: string;
  surnames: string;
}

export class BasicUserVO {
  _id: IdentifierDTO;
  email: string;
  name: string;
  surnames?: string;
  fullName: string;
  avatar?: string;

  constructor({ _id, email, name, surnames, avatar }: BasicUserProps) {
    this._id = _id;
    this.email = email;
    this.name = name;
    this.surnames = surnames;
    this.fullName = [name, surnames].filter(Boolean).join(' ');
    this.avatar = avatar;
  }

  isSameUser(userId: IdentifierDTO): boolean {
    return userId === this._id;
  }

  getFullName(): string {
    return [this.name, this.surnames].filter(Boolean).join(' ');
  }

}
