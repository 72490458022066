import { useI18n } from '#hooks/useI18n';
import React from 'react';
import { BaseModal } from './BaseModal';
import { Body14 } from '../typography';
import styled from '@emotion/styled';

import { FCC, PrimaryButton, TertiaryButton, ZazumeModal } from '@zazume/zzm-base';
import { css } from '@emotion/react';


const Body = styled(BaseModal.Body)(({ theme }) => css`
  padding: 0 0 ${theme.spacing.s8} 0;
  `);

const StyledBody = styled(Body14)(({ theme }) => css`
  margin-top: ${theme.spacing.s8};
  max-width: 350px;
`);

const Footer = styled(BaseModal.Footer)(({ theme }) => css`
  height: 3.5rem;
  margin-bottom: ${theme.spacing.xs};
  button {
    margin-left: ${theme.spacing.s8};
  }
`);

export interface GenericDeleteConfirmationModalProps {
  onConfirm: () => Promise<void> | void;
  onCancel: () => void;
  title: string;
  description: string;
  actionButtonText?: string;
  cancelButtonText?: string;
  isSubmitting?: boolean;
}

export const GenericDeleteConfirmationModal: FCC<GenericDeleteConfirmationModalProps> = ({
  onConfirm,
  onCancel,
  title,
  description,
  actionButtonText,
  cancelButtonText,
  isSubmitting,
  children }) => {

  const { t } = useI18n();

  const cancelText = cancelButtonText ? cancelButtonText : t('buttons.cancel');
  const confirmText = actionButtonText ? actionButtonText : t('general.delete');


  return (
    <ZazumeModal title={title} onClose={onCancel} withCloseButton={false}>
      <Body>
        <StyledBody>{description}</StyledBody>
        {children}
      </Body>
      <Footer>
        <TertiaryButton onClick={onCancel} size="Large">{cancelText}</TertiaryButton>
        <PrimaryButton onClick={onConfirm} variant="danger" size="Large" isSubmitting={isSubmitting}>{confirmText}</PrimaryButton>
      </Footer>
    </ZazumeModal>
  );
};
