import React, { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { useAuth } from '#/contexts/AuthProvider';
import { useI18n } from '#hooks/useI18n';
import { SectionProps } from '#/containers/asideNavigation/Sections';

interface IncidentsProps extends SectionProps {
}

export const Incidents: FC<IncidentsProps> = ({ onClickItem, depth, isMobile }) => {
  const { organization } = useAuth();
  const { t } = useI18n();

  if (!organization || !organization.incidents) {
    return null;
  }

  return <Can hasRoles={['buildingManager', 'viewer', 'admin', 'staff', 'ownerManager', 'ownerMiddle']}>
    <NavigationLink to={'/incidents'} onClick={onClickItem} depth={depth} icon="warning" title={t('asideNavigation.incidents')} isMobile={isMobile}/>
  </Can>;
};
