import { API } from '../../../lib/network/API';
import { UnitKeys } from './UnitKeys';
import { IdentifierDTO, Unit } from '../../../models';
import { useGet } from '../useGet';

export const useGetUnit = (unitId: IdentifierDTO) => {

  const {
    data: unit,
    isLoading,
    error
  } = useGet<Unit>(
    UnitKeys.byId(unitId),
    API.units.get(unitId));


  if (isLoading) {
    return { isLoading };
  }
  if (error) {
    return { isError: true, isLoading };
  }

  return {
    unit
  };
};
