import { BlockSkeleton } from '#/components/skeleton/block/BlockSkeleton';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing.s8};
  margin-bottom: ${theme.spacing.s10};
`);

export const PageDetailHeaderSkeleton = () => {
  return <Container>
    <BlockSkeleton width={38} height={38}/>
    <BlockSkeleton width={150} height={38}/>
  </Container>;
};
