import { Zendesk, Spinner } from '@zazume/zzm-base';
import { FC } from 'react';
import styled from '@emotion/styled';
import { useZendesk } from '#hooks/messaging/useZendesk';

const Container = styled.div`
  display: flex;
  cursor: pointer;
`;

export interface ZendeskButtonProps {
  email: string;
}

export const ZendeskButton: FC<ZendeskButtonProps> = ({ email }) => {
  const { isEnabled,isLoading, openZendesk } = useZendesk(email);

  if (!isEnabled) {
    return null;
  }

  return (
    <Container onClick={openZendesk}>
      {isLoading
        ? <Spinner color="Gray400" size="small"/>
        : <Zendesk color="Gray400" size={20}/>}
    </Container>
  );
};
