import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FCC, Flex, getIcon, IconType, Semibold } from '@zazume/zzm-base';
import { useDetailFieldsContext } from './FieldsSectionContext';

interface ContainerProps {
  columns?: number;
}

const FieldsContainer = styled.div<ContainerProps>(({ theme, columns }) => css`
  width: 100%;
  display: grid;
  grid-row-gap: ${theme.spacing.md};
  grid-column-gap: ${theme.spacing.sm};

  ${theme.mq.tablet} {
    padding-left: ${theme.spacing.md};
    grid-template-columns: repeat(${columns}, 1fr);
  }
`);

export interface FieldsSectionProps extends ContainerProps {
  title?: string;
  isDefaultHidden?: boolean;
  icon?: IconType;
}

export const FieldsSection: FCC<FieldsSectionProps> = ({ title, children, isDefaultHidden, columns = 2, icon }) => {
  const { areSectionsHidden } = useDetailFieldsContext();

  if (isDefaultHidden && areSectionsHidden) {
    return null;
  }

  const Icon = icon && getIcon(icon);

  return (
    <Flex direction="column" gap="sm">
      {title &&
        <Flex gap="xs" align="center">
          {Icon && <Icon size={24} color="Primary"/>}
          <Semibold variant="M">{title}</Semibold>
        </Flex>
      }
      <FieldsContainer columns={columns}>
        {children}
      </FieldsContainer>
    </Flex>
  );
};
