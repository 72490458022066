import { ResultGoToItem, ResultItemBase } from './ResultItem';
import styled from '@emotion/styled';
import { Agent, Flex } from '@zazume/zzm-base';
import { AgentSearch } from '#/models/algolia/AgentSearch';
import { css } from '@emotion/react';

const SmallDetails = styled.div`
  font-size: 12px;
`;

const AreasContainer = styled.div(({ theme }) => css`
  text-transform: capitalize;
  display: flex;
  gap: ${theme.spacing.s2};
`);

export const AgentCell: ResultItemBase<{}> = ({ hit, components }) => {
  const serviceAreas = (hit as any as AgentSearch).serviceAreas;

  return <a href={ResultGoToItem(hit)} className="aa-ItemLink">
    <div className="aa-ItemContent">
      <Agent color="Gray600"/>
      <div className="aa-ItemTitle">
        <components.Highlight hit={hit} attribute="name"/>
        <SmallDetails>
          <components.Highlight hit={hit} attribute="email"/>
        </SmallDetails>
        <SmallDetails>
          <Flex gap="xs">
            <components.Highlight hit={hit} attribute="phoneNumber"/>
            {Boolean(serviceAreas?.length) &&
              <AreasContainer>
                {serviceAreas.map((_, i) => <SmallDetails>
                  <components.Highlight hit={hit} attribute={['serviceAreas', i]}/>
                </SmallDetails>)}
              </AreasContainer>}
          </Flex>
        </SmallDetails>
      </div>
    </div>
  </a>;
};
