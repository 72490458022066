import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SmallNotificationRow } from './SmallNotificationRow';
import { useI18n } from '../../../../../hooks/useI18n';
import { Private } from '../../../../router/routes/Private';
import { useRouter } from '../../../../../hooks';
import { LinkButton, Regular } from '@zazume/zzm-base';
import { useMarkAsReadNotification } from '#/hooks/api/notifications/useMarkAsReadNotification';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div(({ theme }) => css`
  padding: ${theme.spacing.sm} ${theme.spacing.s8};
  cursor: default;
  user-select: none;
  display: flex;
  justify-content: space-between;

  &:hover {
    background: ${theme.colors.Gray100};
    border-radius: 0 0 ${theme.radius.lg} ${theme.radius.lg};
  }

  & > a:hover {
    color: ${theme.colors.Primary};
    cursor: pointer;
  }
`);


const Link = styled.a(({ theme }) => css`
  font-size: ${theme.fontsSizes.tiny};
  line-height: ${theme.lineHeights.xs};
  margin: 0;
  font-family: ${theme.fonts.base};
  color: ${theme.colors.Gray400};
  font-weight: ${theme.fontWeights.semibold};
  font-style: normal;
  -webkit-font-smoothing: antialiased;
`);

const CustomLinkButton = styled(LinkButton)(({ theme }) => css`
  padding: 0;
  margin: 0;
  height: fit-content;
  font-size: ${theme.fontsSizes.tiny};
  color: ${theme.colors.Gray400};

  &:hover:not(:disabled) {
    background: ${theme.colors.White};
    color: ${theme.colors.Primary};
    border: none;
    box-shadow: none;
  }
`);

const EmptyState = styled.div(({ theme }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.White};
  height: 10.25rem;
  box-shadow: 0 1px 0 #EBEEF4;
`);

const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 18.75rem);
`;

interface NotificationListProps {
  notifications?: any[];
  onCloseOverlay: () => void;
}

export const NotificationList: FC<NotificationListProps> = ({ notifications, onCloseOverlay }) => {
  const { t } = useI18n();
  const { navigate } = useRouter();
  const { markAllAsRead } = useMarkAsReadNotification();

  const hasNotifications = Boolean(notifications && notifications?.length > 0);

  const goToNotificationsPage = () => {
    navigate(Private.NOTIFICATIONS.toPage!(1));
    onCloseOverlay();
  };

  return <Container>
    {hasNotifications && <ScrollableContent>{notifications!.slice(0, 5).map(notification => <SmallNotificationRow key={notification._id} notification={notification} onCloseOverlay={onCloseOverlay}/>)}</ScrollableContent>}
    {!hasNotifications && <EmptyState><Regular variant="S" color="Gray400">{t('notifications.noNotifications')}</Regular></EmptyState>}
    <Footer>
      <Link onClick={goToNotificationsPage}>{t('notifications.seeAll')}</Link>
      <CustomLinkButton onClick={markAllAsRead}>{t('notifications.markAllRead')}</CustomLinkButton>
    </Footer>
  </Container>;
};
