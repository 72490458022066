import { Key } from '../../icons';
import React from 'react';
import { ResultGoToItem, ResultItemBase } from './ResultItem';
import styled from '@emotion/styled';

const SmallDetails = styled.div`
  font-size: 12px;
`;

export const UnitCell: ResultItemBase<{}> = ({ hit, components }) => {
  return <a href={ResultGoToItem(hit)} className="aa-ItemLink">
    <div className="aa-ItemContent">
      <Key/>
      <div className="aa-ItemTitle">
        <components.Highlight hit={hit} attribute="name"/>
        <SmallDetails>
          <components.Highlight hit={hit} attribute="address"/>
        </SmallDetails>
      </div>
    </div>
  </a>;
};
