import React, { FC } from 'react';
import { useI18n } from '../../../../hooks/useI18n';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Bold, Info, Regular } from '@zazume/zzm-base';
import { SignType } from '../types';

const Container = styled.div(({ theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s5};
`);

const Block = styled.div`
  display: block;
`;

export interface SignatureTypeInfoProps {
  signType?: SignType;
}

export const SignatureTypeInfo: FC<SignatureTypeInfoProps> = ({ signType }) => {
  const { t } = useI18n();

  if (signType === 'certificate') {
    return <Container>
      <Block><Info color="Gray400"/></Block>
      <Regular variant="XS" color="Gray400"><Bold variant="XS" color="Gray400" as="span">{t('onboarding.contractSigning.signatureCertificate')}: </Bold>{t('onboarding.contractSigning.signatureCertificateInfo')}</Regular>
    </Container>;
  }

  return <Container>
    <Block><Info color="Gray400"/></Block>
    <Regular variant="XS" color="Gray400"><Bold variant="XS" color="Gray400" as="span">{t('onboarding.contractSigning.signatureBiometrics')}: </Bold>{t('onboarding.contractSigning.signatureBiometricsInfo')}</Regular>
  </Container>;
};
