import { Respondio, Spinner } from '@zazume/zzm-base';
import { FC } from 'react';
import { useRespondio } from '#/hooks/messaging/useRespondio';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  cursor: pointer;
`;

export interface RespondioButtonProps {
  email: string;
}

export const RespondioButton: FC<RespondioButtonProps> = ({ email }) => {
  const { isEnabled,isLoading, openRespondio } = useRespondio(email);

  if (!isEnabled) {
    return null;
  }

  return (
    <Container onClick={openRespondio}>
      {isLoading
        ? <Spinner color="Gray400" size="small"/>
        : <Respondio color="Gray400" size={20}/>}
    </Container>
  );
};
