import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@zazume/zzm-base';


const Line = styled.div`
  height: 1px;
  background: ${Colors.BACKGROUND_6};
  margin: 24px 0;
  width: 100%;
`;

/**
 * @deprecated use Divider from zzm-base
 * @param props
 * @constructor
 */
export const Separator: FC = ({ ...props }) => {
  return <Line {...props}/>;
};
