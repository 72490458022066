import { FCC } from '@zazume/zzm-base';
import React from 'react';
import styled from '@emotion/styled';
import { DEFAULT_TABLE_LENGTH } from '../../../../hooks/api/usePagination';

const Container = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  justify-content: flex-end;
`;

export interface PaginationContainerProps {
  currentPage: number;
  arrayLength: number;
  pageSize?: number;
}

export const PaginationContainer: FCC<PaginationContainerProps> = ({ children, currentPage, arrayLength, pageSize }) => {

  const isNotVisible = currentPage === 1 && arrayLength < (pageSize || DEFAULT_TABLE_LENGTH);

  return isNotVisible
    ? null
    : <Container>
      {children}
    </Container>;
};
