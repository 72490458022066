import { doDelete, doGet, parserDataList, toPaginatedURL } from '../../helpers';
import { PaginationOptions } from '#/hooks';
import { SepaPayments } from '#/models/SepaPayments';
import { DateVO, PriceVO } from '@zazume/zzm-utils';
import { BasicUserVO } from '#/models';
import { IdentifierDTO } from '@zazume/zzm-base';
import { Payout } from '#/models/Payout';

const toSepaPayments = (raw: any): SepaPayments =>
  new SepaPayments({
    ...raw,
    paymentsTotal: PriceVO.fromPrimitive(raw.paymentsTotal),
    createdAt: DateVO.fromPrimitive(raw.createdAt),
    basicAuthor: new BasicUserVO({ ...raw.basicAuthor })
  });


export const sepaPayments = {
  delete: (sepaPaymentId: IdentifierDTO) =>
    doDelete<void>(`/dashboard/sepa-payments/${sepaPaymentId}`),
  findPaginated: () => (pagination: PaginationOptions) => () =>
    doGet<SepaPayments[]>(toPaginatedURL('/dashboard/sepa-payments', pagination), parserDataList(toSepaPayments)),
  getLinkedPayouts: (sepaPaymentId: IdentifierDTO) => () =>
    doGet<Payout[]>(`/dashboard/sepa-payments/linked-payouts/${sepaPaymentId}`)
};
