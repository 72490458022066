import { useI18n } from '#hooks/useI18n';
import { Language } from '@zazume/zzm-utils';

const noSelection = 0;

interface LanguageOptions {
  value: Language | number;
  name: string;
  disabled?: boolean;
}

export const useLangOptions = (withPlaceholder = true) => {
  const { t } = useI18n();

  const options: LanguageOptions[] = [
    { value: 'en', name: t('language.en') },
    { value: 'es', name: t('language.es') }
  ];

  if (withPlaceholder) {
    options.unshift({ value: noSelection, name: t('language.default'), disabled: true });
  }

  return options;
};

export const isLanguageSelected = (value) => value.toString() !== noSelection.toString();
