import { FiltersTypes } from '#/hooks/useFilter';

export const Storage = {
  setAuthToken: (authToken: string) =>
    localStorage.setItem('authToken', authToken),
  getAuthToken: (): string | null =>
    localStorage.getItem('authToken'),
  clearSession: () => {
    localStorage.removeItem('authToken');
    // TODO reduce this to only the necessary
    localStorage.removeItem('user');
    localStorage.removeItem('organization');
    localStorage.removeItem('currentOrganizationToken');
  },
  setStripeSessionKey: (sessionId: string) => {
    localStorage.setItem('stripeCheckoutSessionId', sessionId);
  },
  navigation: {
    saveRawState: (rawNavigationState: string) =>
      localStorage.setItem('navigationState', rawNavigationState),
    getRawState: (): string | null =>
      localStorage.getItem('navigationState')
  },
  filters: {
    getFilter: (filter: FiltersTypes): string | null =>
      localStorage.getItem(filter),
    setFilter: (filter: FiltersTypes, value: string) =>
      localStorage.setItem(filter, value)
  }
};
