import { LongTermLeaseSettings } from '#/domain/organization/LongTermLeaseSettings';
import { MidTermLeaseSettings } from '#/domain/organization/MidTermLeaseSettings';
import { NonPaymentSettings } from '#/domain/organization/NonPaymentSettings';
import { OrganizationBankAccount } from '#/domain/organization/OrganizationBankAccount';

export const toMidTermLeaseSettings = (rawData: any): MidTermLeaseSettings => {
  return {
    ...rawData
  };
};

export const toLongTermLeaseSettings = (rawData: any): LongTermLeaseSettings => {
  return {
    ...rawData
  };
};

export const toNonPaymentSettings = (rawData: any): NonPaymentSettings => {
  return {
    ...rawData
  };
};

export const toOrganizationBankAccount = (rawData: any): OrganizationBankAccount => {
  return {
    ...rawData
  };
};
