import { FCC } from '@zazume/zzm-base';
import React from 'react';
import styled from '@emotion/styled';
import { Sizes } from '../styles/Base';
import { HEADER_HEIGHT } from '../utils/containers/containersConstants';
import { css } from '@emotion/react';

const BaseBody = styled.div<any>(({ theme }) => css`
  display: flex;
  flex-direction: column;
  padding: 40px 56px 84px ${Sizes.PAGE_BODY_LEFT_MARGIN}px;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: 6px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.Primary};
  }
`);

const BodyWithTabs = styled(BaseBody)`
  padding: 40px 56px 84px ${Sizes.PAGE_BODY_LEFT_MARGIN - 7}px;
`;

const BodyWithoutMargins = styled(BaseBody)`
  padding: 0;
`;

interface BodyTemplateProps {
  margins?: boolean;
  withTabs?: boolean;
}

export const BodyTemplate: FCC<BodyTemplateProps> = ({ margins = true, withTabs = false, ...rest }) => {
  if (withTabs) {
    return <BodyWithTabs {...rest}/>;
  }

  if (!margins) {
    return <BodyWithoutMargins {...rest}/>;
  }

  return <BaseBody {...rest}/>;
};
