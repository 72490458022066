import React, { FC } from 'react';
import { IconProps } from './index';

/**
 * @deprecated use zzm-base
 * @param color
 * @param size
 * @constructor
 */
export const Bell: FC<IconProps> = ({ color = '#FFFFFF', size = 24 }) => {
  return <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.05 8.72726C8.05 8.14751 8.41739 7.42549 9.18723 6.80667C9.9409 6.20085 10.9609 5.79999 12 5.79999C14.1032 5.79999 15.5 7.39633 15.5 8.72726V12.2655C15.5 12.7659 15.6876 13.2481 16.0257 13.617L16.9268 14.6L12 14.6H6.82227L7.63934 13.5304C7.9057 13.1817 8.05 12.7551 8.05 12.3163V8.72726ZM12 3.79999C10.4676 3.79999 9.01267 4.38095 7.9342 5.24785C6.8719 6.10176 6.05 7.34338 6.05 8.72726V12.3163L5.23293 13.3859C4.22753 14.7021 5.16604 16.6 6.82227 16.6H12H16.9268C18.667 16.6 19.577 14.5313 18.4011 13.2485L17.5 12.2655V8.72726C17.5 6.13092 15.0397 3.79999 12 3.79999ZM12 18.7692L9.84254 18.2298L9.35747 20.1701L11.5149 20.7095C11.8334 20.7891 12.1666 20.7891 12.4851 20.7095L14.6425 20.1701L14.1575 18.2298L12 18.7692Z"/>
  </svg>;
};
