import React, { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';
import { SectionProps } from '#/containers/asideNavigation/Sections';
import { useI18n } from '#hooks/useI18n';

interface TemplatesProps extends SectionProps {
}

export const Templates: FC<TemplatesProps> = ({ onClickItem, depth, isMobile }) => {
  const { t } = useI18n();
  return <Can hasRoles={['admin']}>
    <NavigationLink to={Private.TEMPLATES.route} onClick={onClickItem} depth={depth} icon="documents" title={t('asideNavigation.templates')} isMobile={isMobile}/>
  </Can>;
};
