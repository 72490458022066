import { Colors } from '@zazume/zzm-base';
import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from '.';

/**
 * @deprecated use zzm-base
 * @param color
 * @param size
 * @constructor
 */
export const Download: FC<IconProps> = ({ color = Colors.GREY_6, size = 20 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth}
      d="M4.7998 15.2625V19.0625C4.7998 19.6148 5.24752 20.0625 5.7998 20.0625H18.1998C18.7521 20.0625 19.1998 19.6148 19.1998 19.0625V15.2625M11.9998 14.8525L8.9998 12.1859M11.9998 14.8525L11.9998 4.3125M11.9998 14.8525L14.9998 12.1859"
    />
  </svg>;
};
