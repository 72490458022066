import { Bold, Flex, IconType, Info, OptionType, Regular, ZazumeFormProvider, ZazumeModal } from '@zazume/zzm-base';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';

import { css } from '@emotion/react';

import { Trans } from 'react-i18next';
import { ZazumeServiceDetails } from '#/components/forms/zazumeServicesRadioGroup/atoms/ZazumeServiceDetails';
import { InputGroup } from '#/components/forms/inputGroup/InputGroup';
import { BenefitsList } from '#/pages/workspaces/units/components/contractTenantSearchPlanModal/steps/components/BenefitsList';
import { OPPORTUNITIES_PLANS, useModalContext } from '#/pages/investment/components/productChoose/ModalContext';
import { useI18n } from '#hooks/useI18n';
import { I18NValues } from '#lib/i18n/generatedKeys';
import { Select } from '#/components/forms/select/SelectBase';
import React, { useMemo } from 'react';
import { useGet } from '#/hooks';
import { API } from '#lib/network/API';
import { CityKeys } from '#lib/network/api/cities/CityKeys';
import { AdministrativeArea } from '#/models/location/AdministrativeArea';

const ServiceContainer = styled.div(({ theme }) => css`
  width: 11rem;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`);

const getOptions = (areas: AdministrativeArea[]): OptionType[] => {
  const result: OptionType[] = [];
  for (const data of areas) {
    data.code && data.name && result.push({
      name: data.name,
      value: data.code
    });
  }
  return result;
};

export const SelectPlan = () => {
  const { t, languageVO } = useI18n();
  const { setStep, onSelectPlan, selectedPlan, onSelectArea } = useModalContext();
  const formMethods = useForm({ defaultValues: { service: selectedPlan } });

  const { data: areas = [], isLoading } = useGet<AdministrativeArea[]>(CityKeys.administrativeAreas('ES'), API.cities.administrativeAreas('ES'));
  const areaOptions = useMemo(() => getOptions(areas), [areas]);

  const service = formMethods.watch('service');
  const area = formMethods.watch('area');
  const acceptEnable = service && (service !== 'real_estate_opportunities_area' || area);

  const onConfirm = () => {
    onSelectPlan(service!);
    service === 'real_estate_opportunities_area' && area ? onSelectArea(areas.find(a => a.code === area)) : onSelectArea(undefined);
    setStep('accept-conditions');
  };

  return (
    <ZazumeModal title={t('zazumeServices.contractPlanInvestorModal.selectPlan.title')}>
      <ZazumeFormProvider form={formMethods} inlineErrors={false}>
        <Flex direction="column" gap="sm">
          <Bold variant="XL">{t('zazumeServices.contractPlanInvestorModal.selectPlan.selectService')}</Bold>
          <InputGroup name="service" type="radio" inline={true} gap="s10" wrap={false}>
            {Object.values(OPPORTUNITIES_PLANS).map(plan => (
              <InputGroup.CardInput key={plan.name} value={plan.name} showRadio>
                <ServiceContainer>
                  <ZazumeServiceDetails
                    icon={plan.icon as IconType}
                    amount={
                      <Trans
                        i18nKey={`zazumeServices.${plan.name}.price`}
                        components={{ 1: <Bold variant="XXXXL" as="span" color="Primary"/>, 2: <Regular color="Primary" as="span" variant="S"/> }}
                        values={{ price: plan.price.format(languageVO) }}
                      />}
                    monthlyRent={plan.price.toPrimitive()}
                    serviceName={plan.name}
                    quota={100}
                    withFootnote={false}
                    includeCurrency={false}
                  />
                  <Flex justify="center" align="center" gap="s2" grow={0}>
                    <Regular variant="XXS">{t('zazumeServices.moreInformation')}</Regular>
                    <Info size={16} color="PrimaryDark3"/>
                  </Flex>
                </ServiceContainer>
              </InputGroup.CardInput>
            ))}
          </InputGroup>
          {service === 'real_estate_opportunities_area' && <Select
            name="area"
            withSearch={true}
            label={t('zazumeServices.contractPlanInvestorModal.areas')}
            options={areaOptions}
            placeholder={t('zazumeServices.contractPlanInvestorModal.areasPlaceholder')}
          />}
          {service && Object.values(OPPORTUNITIES_PLANS).filter(plan => plan.name === service).map(plan => (
            <BenefitsList
              key={plan.name}
              title={t('zazumeServices.contractPlanInvestorModal.selectPlan.benefitsTitle')}
              benefits={t(`zazumeServices.${plan.name}.benefits` as I18NValues, { returnObjects: true }) as any as string[]}/>
          ))}
        </Flex>
      </ZazumeFormProvider>
      <ZazumeModal.Footer
        cancelButtonText={t('buttons.cancel')}
        acceptButtonText={t('buttons.next')}
        acceptButtonType="button"
        isAcceptDisabled={!acceptEnable || isLoading}
        onConfirm={onConfirm}
      />
    </ZazumeModal>
  );
};
