import { TFunction } from '#/hooks/useI18n';
import { FormValidation } from '@zazume/zzm-base';
import { DocumentCategories } from '../../models';
import { getCreateDocumentSchema } from './CreateDocument.schema';
import { toFormValidation } from '#/utils/formValidation/toFormValidation';

export const schema = (t) => {
  return getCreateDocumentSchema(t).omit(['signers', 'signatureConfiguration']);
};

export type UpdateDocumentFormInputs = {
  category: DocumentCategories;
  type: string;
  isExpirable: boolean;
  isSignable: boolean;
  expireAt: Date | undefined;
  document: any;
  isHiddenFromOwner: boolean;
}

export const getUpdateDocumentSchema = (t: TFunction): FormValidation =>
  toFormValidation(t, schema(t));
