import { FieldError as FieldErrorOriginal } from 'react-hook-form';

/**
   * @deprecated use FieldError from react-hook-form package instead
   */
export interface FieldError {
  type: 'required' | 'minLength' | 'invalid' ;
  message?: string;
}

export interface WithErrors {
  errors?: FieldError | FieldErrorOriginal;
  clearErrors?: (name?) => void;
  requiredErrorMessage?: string;
}

const generateErrorMessage = (options: WithErrors): string | undefined => {
  if (!options.errors) {
    return undefined;
  }

  if (options.errors.type === 'required' && options.requiredErrorMessage) {
    // TODO i18n here!
    return options.requiredErrorMessage;
  }

  // TODO i18n here!
  return options.errors.message;
};

interface ParseErrorsResult {
  hasErrors: boolean;
  errorMessage?: string;
}

export const parseErrors = <T extends WithErrors>(withErrors: T): ParseErrorsResult => ({
  hasErrors: Boolean(Object.keys(withErrors.errors || {}).length),
  errorMessage: generateErrorMessage(withErrors)
});
