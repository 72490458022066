import React from 'react';
import { ResultGoToItem, ResultItemBase } from './ResultItem';
import styled from '@emotion/styled';
import { Owner } from '../../icons/Owner';
import { EntitySearch } from '#/models/algolia/EntitySearch';

const SmallDetails = styled.div`
  font-size: 12px;
`;

export const EntityCell: ResultItemBase<{}> = ({ hit, components, t }) => {
  return (
    <a href={ResultGoToItem(hit)} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <Owner/>
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="name"/>
          <SmallDetails>
            {(hit as EntitySearch).entityType ?
              t(`ownerAccount.entity.types.${(hit as EntitySearch).entityType}`) :
              <components.Highlight hit={hit} attribute="entityType"/>}
          </SmallDetails>
          <SmallDetails>

          </SmallDetails>
        </div>
      </div>
    </a>
  );
};
