import styled from '@emotion/styled';
import { FC } from 'react';
import { useI18n } from '../../hooks/useI18n';
import { Signer } from '#/models/contract/Signer';
import { css } from '@emotion/react';
import { Flex, IdentifierDTO, Regular, TertiaryButton, useModal } from '@zazume/zzm-base';
import { EditSignerModal } from './atoms/editSigner/EditSignerModal';

const Container = styled(Flex)(({ theme }) => css`
  width: 100%;
  flex-direction: column;
  padding: ${theme.spacing.s2} 0 ${theme.spacing.s2} ${theme.spacing.xs};
  border-left: ${theme.borderWeights.bold} solid ${theme.colors.Red};
  word-break: break-all;
`);

interface SignerEmailErrorInfoProps {
  documentId: IdentifierDTO;
  signer: Signer;
  onAfterUpdate?: () => Promise<void>;
}

export const SignerEmailErrorInfo: FC<SignerEmailErrorInfoProps> = ({ signer, documentId, onAfterUpdate }) => {
  const { t, Trans } = useI18n();
  const { RenderModal, open } = useModal({ modalSize: 's' });

  return (
    <Container gap="s3">
      <Regular variant="S" color="Gray500">
        <Trans
          i18nKey="signatures.signerEmailError"
          values={{ email: signer.email }}
          components={{ 1: <Regular as="span" variant="S" color="Gray600"/> }}
        />
      </Regular>
      <TertiaryButton size="Medium" onClick={open}>{t('signatures.editSigner')}</TertiaryButton>
      <RenderModal>
        <EditSignerModal documentId={documentId} signer={signer} onAfterUpdate={onAfterUpdate}/>
      </RenderModal>
    </Container>
  );
};
