import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ResultProps } from './ResultList';
import { Body14 } from '../typography';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0;
  user-select: none;
  padding: 12px 0;
`;

export const DefaultResult: FC<ResultProps> = ({ result, onSelect }) => {
  const handleSelect = () => onSelect(result);

  return (
    <Container onClick={handleSelect}>
      {result.name && <Body14>{result.name}</Body14>}
    </Container>
  );
};
