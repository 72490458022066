import { Schema, SchemaOf } from '@zazume/zzm-utils';
import { mixed } from 'nyup';
import { TFunction } from '#hooks/useI18n';
import { toFormValidation } from '#/utils/formValidation/toFormValidation';
import { FormValidation } from '@zazume/zzm-base';
import { DocumentTypes, DocumentType } from '#/models';

export type CreateDocumentUploadLinkForm = {
  type: DocumentType;
  description?: string;
}

const schema = (t: TFunction): SchemaOf<CreateDocumentUploadLinkForm> => {
  const requiredMessage = t('general.forms.mandatory');
  return Schema.isObject({
    type: mixed<DocumentType>().oneOf(DocumentTypes, requiredMessage).required(requiredMessage),
    description: Schema.isString().optional()
  });
};

export const getCreateDocumentUploadLinkSchema = (t: TFunction): FormValidation =>
  toFormValidation(t, schema(t));
