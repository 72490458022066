import { IdentifierDTO } from '@zazume/zzm-base';
import { doDelete, doGet, doPut } from '../../helpers';
import { Document, RelatedModelType } from '#/models';
import { DocumentToDownload } from '#/models/documents/DocumentToDownload';
import { DocumentUrl } from '#/models/documents/DocumentUrl';
import { EditSignerModalFormData } from '#/components/documentUploader/atoms/editSigner/EditSignerModal.schema';

export const documents = {
  delete: (id: IdentifierDTO) =>
    doDelete(`/documents/${id}`),
  downloadAuditTrail: (documentId: IdentifierDTO) =>
    doGet<DocumentToDownload>(`/documents/download-audit/${documentId}`),
  get: (documentId: IdentifierDTO) => () =>
    doGet<Document>(`/documents/${documentId}`),
  getDocuments: (type: RelatedModelType, relatedId: IdentifierDTO) => () =>
    doGet<Document[]>(`/documents/type/${type}/id/${relatedId}`),
  getDownloadURL: (documentId: IdentifierDTO) =>
    doGet<DocumentToDownload>(`/documents/open-url/${documentId}`),
  getURL: (documentId: IdentifierDTO) => () =>
    doGet<DocumentUrl>(`/documents/url/${documentId}`),
  rejectSigningDocument: (documentId: IdentifierDTO) =>
    (reason: object) =>
      doPut(`/documents/${documentId}/reject`, reason),
  sendSignatureReminder: (documentId: IdentifierDTO) => () =>
    doPut<void>(`/documents/${documentId}/signatureReminder`),
  updateSignerEmail: (documentId: IdentifierDTO, signerExternalId: string) => (formData: EditSignerModalFormData) =>
    doPut<void>(`/documents/${documentId}/${signerExternalId}/update-signer-email`, formData)
};
