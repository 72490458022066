import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { getStrokeWidth, IconProps, IconSize } from './index';

export const Info: FC<IconProps> = ({ size = 24, color = Colors.FONT_3 }) => {
  const strokeWidth = getStrokeWidth(size as IconSize);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M12 10.4963V16.4963M21 11.9963C21 16.9669 16.9706 20.9963 12 20.9963C7.02944 20.9963 3 16.9669 3 11.9963C3 7.02577 7.02944 2.99634 12 2.99634C16.9706 2.99634 21 7.02577 21 11.9963Z"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
    />
    <path
        d="M12.75 7.75C12.75 8.16421 12.4142 8.5 12 8.5C11.5858 8.5 11.25 8.16421 11.25 7.75C11.25 7.33579 11.5858 7 12 7C12.4142 7 12.75 7.33579 12.75 7.75Z"
        fill={color}
        stroke={color}
    />
  </svg>;
};
