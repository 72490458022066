import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from '.';

export const EmailSent: FC<IconProps> = ({ color = '#FFFFFF', size = 24 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        d="M13.2195 17.5185H18.2928C19.2397 17.5185 20.0073 16.6693 20.0073 15.6218V6.89675C20.0073 6.62596 19.9561 6.36843 19.8636 6.1354C19.7703 5.89997 19.6349 5.68954 19.4681 5.5157M6.14435 5.5157C6.49459 5.15081 6.86475 5 7.31962 5H18.2928C18.7477 5 19.1611 5.19595 19.4681 5.5157M6.14435 5.5157C5.92922 5.73984 5.72784 6.08036 5.72784 6.19054L5.76812 9.33333M6.14435 5.5157L11.7106 11.7145C12.3457 12.2982 13.2667 12.2982 13.9018 11.7145L19.4681 5.5157M4 12.7222H8.36364M5.72049 15.6111H9.33333M7.31227 18.5H10.5072"
    />
  </svg>;
};
