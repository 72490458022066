import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useI18n } from '#hooks/useI18n';
import { Link, Regular } from '@zazume/zzm-base';

const Container = styled.div(({ theme }) => css`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${theme.spacing.s11} 0;
`);

interface EmptyStateProps {
  openCreate: () => void;
}

export const EmptyState: FC<EmptyStateProps> = ({ openCreate }) => {
  const { Trans } = useI18n();

  return (
    <Container>
      <Regular variant="XS" color="Gray500">
        <Trans
          i18nKey="documentUploadLink.emptyState"
          components={{ 1: <Link textVariant="XS" inline onClick={openCreate}/> }}
        />
      </Regular>
    </Container>
  );
};
