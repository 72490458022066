import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { Colors, FCC, Fonts } from '@zazume/zzm-base';

interface ContainerProps {
  center?: boolean;
  soft?: boolean;
}
const Container = styled.div<ContainerProps>(({ center, soft }) => css`
  width: 100%;
  font-family: ${Fonts.BASE};
  font-size: 20px;
  font-style: normal;
  font-weight: ${soft ? 600 : 700};
  line-height: 32px;
  letter-spacing: 0;
  color: ${Colors.FONT_3};

  ${center && css`
    display: flex;
    align-items: center;
  `}
`);

type Headline3Props = ContainerProps
export const Headline3: FCC<Headline3Props> = (props) => {
  return <Container {...props}>{props.children}</Container>;
};
