import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps } from './index';

export const Staff: FC<IconProps> = ({ size = 20, color = Colors.FONT_3 }) =>
  <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M14,13.8H6c-1,0-1.8-0.8-1.8-1.8v-1c0-2,1.2-5.8,5.8-5.8c4.5,0,5.8,3.8,5.8,5.8v1C15.8,13,15,13.8,14,13.8z
    M10,6.8c-4.2,0-4.2,4.1-4.2,4.2v1c0,0.1,0.1,0.2,0.2,0.2h8c0.1,0,0.2-0.1,0.2-0.2v-1C14.2,10.6,14.1,6.8,10,6.8z" strokeWidth="1.5"/>
    <rect x="3" y="15.25" width="14" height="1.5" strokeLinejoin="bevel"/>
    <rect x="9.25" y="4" width="1.5" height="2"/>
  </svg>;
