import * as yup from 'yup';
import { TFunction } from '../../hooks/useI18n';

export const getRejectConfirmationSchema = (t: TFunction) => {
  const requiredMessage = t('general.forms.mandatory');
  const shortMessage = t('general.forms.short');

  return yup.object().shape({
    description: yup.string().min(10, shortMessage).required(requiredMessage)
  });
};

export interface RejectConfirmationFormInputs {
  description: string;
}
