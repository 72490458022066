import React, { FC } from 'react';
import { SectionProps } from '#/containers/asideNavigation/Sections';
import { useI18n } from '#hooks/useI18n';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';

interface AdminProps extends SectionProps {
}

export const Admin: FC<AdminProps> = ({ onClickItem, isMobile, depth }) => {
  const { t } = useI18n();
  return <Can hasRoles={['admin']}>
    <NavigationLink to={Private.SETTINGS.route} parentPath="/settings" onClick={onClickItem} depth={depth} icon="settings" title={t('asideNavigation.settings')} isMobile={isMobile}/>
  </Can>;
};
