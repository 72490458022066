import { TFunction } from '#hooks/useI18n';
import { Schema, SchemaOf } from '@zazume/zzm-utils';
import { FormValidation } from '@zazume/zzm-base';
import { createFormValidation } from '#/utils/formValidation/toFormValidation';
import { AlertAdvertiserType, AlertAdvertiserTypeValues, AlertChannelType, AlertChannelTypeValues, AlertElevatorType, AlertElevatorTypeValues, AlertPropertyStateType, AlertPropertyStateTypeValues } from '#/models/investment/alert/InvestmentAlert';

export type InvestmentAlertFormInputs = {
  location: string;
  maxBudget: number;
  profitability: number;
  minRentPrice: number;
  rooms: number;
  status: AlertPropertyStateType;
  advertiser: AlertAdvertiserType;
  elevator: AlertElevatorType;
  channel: AlertChannelType;
  isActive?: boolean;
}

const schema = (t: TFunction): SchemaOf<InvestmentAlertFormInputs> => {
  const requiredMessage = t('general.forms.mandatory');
  const maxBudgetMessage = t('investment.alertModal.form.errors.maxBudget');
  const profitabilityMessage = t('investment.alertModal.form.errors.profitability');
  const minRentPriceMessage = t('investment.alertModal.form.errors.minRentPrice');

  return Schema.isObject({
    location: Schema.isString().required(requiredMessage),
    maxBudget: Schema.isNumber().typeError(maxBudgetMessage).positive().required(requiredMessage),
    profitability: Schema.isNumber().typeError(profitabilityMessage).positive().required(requiredMessage),
    minRentPrice: Schema.isNumber().typeError(minRentPriceMessage).positive().required(requiredMessage),
    rooms: Schema.isNumber().positive().required(requiredMessage),
    status: Schema.isStringOf<AlertPropertyStateType>(AlertPropertyStateTypeValues).required(requiredMessage),
    advertiser: Schema.isStringOf<AlertAdvertiserType>(AlertAdvertiserTypeValues).required(requiredMessage),
    elevator: Schema.isStringOf<AlertElevatorType>(AlertElevatorTypeValues).required(requiredMessage),
    channel: Schema.isStringOf<AlertChannelType>(AlertChannelTypeValues).required(requiredMessage),
    isActive: Schema.isBoolean().optional()
  });
};


export const getInvestmentAlertSchema = (t: TFunction): FormValidation =>
  createFormValidation(t, schema(t));
