export class BadRequestError extends Error {
  public readonly error: string;
  public readonly errorCode: number;
  public readonly structuredData: any;

  constructor(error = 'User error', errorCode?: any, structuredData?: any) {
    super('[BadRequest] ' + error);
    this.error = error;
    this.errorCode = parseInt(errorCode);
    this.structuredData = structuredData;
  }
}
