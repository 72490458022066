import React, { FC } from 'react';
import styled from '@emotion/styled';
import { AvatarWithName } from '@zazume/zzm-base';
import { ResultProps } from './ResultList';
import { OwnerAccountHelper } from '#/models/helpers/ownerAccount/OwnerAccountHelper';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0;
  user-select: none;
  padding: 12px 0;
`;

export const OwnerAccountResult: FC<ResultProps> = ({ result, onSelect }) => {
  const handleSelect = () => onSelect(result);
  const user = OwnerAccountHelper.build(result).toAvatarWithNameUser('');
  return (
    <Container onClick={handleSelect}>
      <AvatarWithName user={user}/>
    </Container>
  );
};
