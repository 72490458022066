import styled from '@emotion/styled';
import { FC } from 'react';
import { useI18n } from '../../hooks/useI18n';
import { DocumentRejected } from '../../models';
import { css } from '@emotion/react';
import { Flex, Regular } from '@zazume/zzm-base';

const Container = styled(Flex)(({ theme }) => css`
  width: 100%;
  flex-direction: column;
  padding-left: ${theme.spacing.xs};
  border-left: ${theme.borderWeights.bold} solid ${theme.colors.Red};
  word-break: break-all;
`);

interface RejectedSignatureInfoProps {
  rejected: DocumentRejected;
}

export const RejectedSignatureInfo: FC<RejectedSignatureInfoProps> = ({ rejected }) => {
  const { t } = useI18n();

  return (
    <Container gap="s2">
      <Regular variant="S" color="Gray600">
        {rejected.name} - {rejected.email}&nbsp;
        <Regular as="span" variant="S" color="Gray500">{t('onboarding.contractSigning.hasRejected')}</Regular>
      </Regular>
      <Regular variant="S" color="Gray400">{rejected.description}</Regular>
    </Container>
  );
};
