import { EndpointBuilder, doDelete, doGet, toPaginatedURL } from '../../helpers';
import { Booking } from '#/models/Booking';
import { IdentifierDTO } from '@zazume/zzm-base';
import { PaginationOptions } from '#/hooks';
import { ActiveP2PUnitBooking } from './models/ActiveP2PUnitBooking';

export const bookings = {
  findActiveP2PBookingByUnitId: (unitId: IdentifierDTO) => () =>
    doGet<ActiveP2PUnitBooking>(`/bookings/active-p2p-service/${unitId}`),
  findActiveProductType: (productType: string) => () =>
    doGet<ActiveP2PUnitBooking>(`/bookings/active-product/${productType}`),
  getPaginated: (params: EndpointBuilder) =>
    (pagination: PaginationOptions) =>
      () => doGet<Booking[]>(toPaginatedURL(`/bookings${params.url}${params.queryParams}`, pagination)),
  get: (bookingId: IdentifierDTO) => () =>
    doGet<Booking>(`/bookings/${bookingId}`),
  cancelBooking: () => (bookingId: IdentifierDTO) =>
    doDelete<Booking>(`/bookings/${bookingId}`)
};
