import React, { FC } from 'react';
import { PaginationButtonModel } from './utils';
import styled from '@emotion/styled';
import { LeftArrow, RightArrow, Shadows } from '@zazume/zzm-base';
import { css } from '@emotion/react';

const Container = styled.button<any>(({ theme, disabled, selected }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  box-shadow: ${Shadows.SHADOW_3};
  border-radius: 8px;
  margin: 0 4px;
  color: ${theme.colors.Gray600};
  font-family: ${theme.fonts.base};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0;
  user-select: none;
  cursor: ${disabled ? 'default' : 'pointer'};
  border: ${selected ? `solid 2px ${theme.colors.Primary}` : 'none'};
  background-color: ${selected ? theme.colors.Gray200 : theme.colors.White};

  &:hover {
    background-color: ${disabled ? theme.colors.White : theme.colors.Gray200};
    color: ${theme.colors.Primary};
  }

  &:disabled {
    background-color: ${theme.colors.Gray200};
  }

  :focus {
    outline: none;
  }
`);

const PageButton = styled.span<any>(({ theme, disabled }) => css`
  ${disabled && `
    color: ${theme.colors.Gray300};
  `};
`);

const Ellipsis = styled(Container)(({ theme }) => css`
  display: block;
  cursor: default;
  text-align: center;
  user-select: none;

  &:hover {
    background-color: ${theme.colors.White};
    color: ${theme.colors.Gray600};
  }
`);

interface PaginationButtonProps extends PaginationButtonModel {
  onClick?: (newPage: number) => void;
}

export const PaginationButton: FC<PaginationButtonProps> = ({ value, selected, type, onClick, disabled }) => {
  if (['end-ellipsis', 'start-ellipsis'].includes(type)) {
    return <Ellipsis>...</Ellipsis>;
  }

  const handleOnClick = () =>
    value && !disabled && onClick!(value);

  if (type === 'first') {
    return <Container type="button" disabled={disabled} onClick={handleOnClick}><LeftArrow color={disabled ? 'Gray400' : 'Gray600'}/></Container>;
  }

  if (type === 'last') {
    return <Container type="button" disabled={disabled} onClick={handleOnClick}><RightArrow color={disabled ? 'Gray400' : 'Gray600'}/></Container>;
  }

  return <Container type="button" onClick={handleOnClick} disabled={disabled} selected={selected}>
    <PageButton disabled={disabled}>{value}</PageButton>
  </Container>;
};
