import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IconProps } from './index';

const SVG = styled.svg<any>`
  cursor: ${props => props.cursor};
`;

export const TinyArrow: FC<IconProps> = ({ color = '#FFFFFF', size = 20, ...rest }) => {
  return <SVG width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M10.866 13.5C10.4811 14.1667 9.51887 14.1667 9.13397 13.5L6.5359 9C6.151 8.33333 6.63212 7.5 7.40192 7.5L12.5981 7.5C13.3679 7.5 13.849 8.33333 13.4641 9L10.866 13.5Z" fill={color} stroke={color} strokeWidth="1.5"/>
  </SVG>;
};
