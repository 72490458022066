import { IdentifierDTO } from '@zazume/zzm-base';
import { doGet } from '../../helpers';

export const cities = {
  get: (cityId: IdentifierDTO) => () =>
    doGet<any>(`/cities/id/${cityId}`),
  administrativeAreas: (country: string) => () =>
    doGet<any>(`/cities/administrative-areas/${country}`)
};

