import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { TFunction } from '#hooks/useI18n';
import { toFormValidation } from '#/utils/formValidation/toFormValidation';
import { FormValidation } from '@zazume/zzm-base';

export interface EditSignerModalFormData {
  email: string;
}

const schema = (t: TFunction): SchemaOf<EditSignerModalFormData> => {
  return yup.object().shape({
    email: yup.string().email(t('general.forms.invalid')).required(t('general.forms.mandatory'))
  });
};

export const getEditSignerSchema = (t: TFunction): FormValidation =>
  toFormValidation(t, schema(t));
