import { Actor } from '#/models/actor/Actor';
import { IdentifierDTO } from '@zazume/zzm-base';
import { doGet } from '../../helpers';

const toActor = (rawActor: any): Actor =>
  new Actor({
    ...rawActor
  });

export const actor = {
  get: (actorId: IdentifierDTO) => () =>
    doGet<Actor>(`/dashboard/actor/${actorId}`, toActor)
};
