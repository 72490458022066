import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps, getStrokeWidth } from './index';

export const Lock: FC<IconProps> = ({ size = 20, color = Colors.FONT_3 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} d="M15.2366 9.9492V5.5979C15.2366 4.49333 14.3411 3.5979 13.2366 3.5979H10.7638C9.65919 3.5979 8.76376 4.49333 8.76376 5.5979V9.9492M6.8584 20.4021H17.1419C17.6942 20.4021 18.1419 19.9544 18.1419 19.4021V11.9492C18.1419 10.8446 17.2465 9.9492 16.1419 9.9492H7.8584C6.75383 9.9492 5.8584 10.8446 5.8584 11.9492V19.4021C5.8584 19.9544 6.30611 20.4021 6.8584 20.4021ZM13.0002 14.6492C13.0002 15.2015 12.5524 15.6492 12.0002 15.6492C11.4479 15.6492 11.0002 15.2015 11.0002 14.6492C11.0002 14.0969 11.4479 13.6492 12.0002 13.6492C12.5524 13.6492 13.0002 14.0969 13.0002 14.6492Z"/>
  </svg>;
};
