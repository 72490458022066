import { Dropdown } from '#/components/dropdown/Dropdown';
import { useSession } from '#/hooks/session/useSession';
import { useI18n } from '#/hooks/useI18n';
import { isProductionEnvironment } from '#/lib/Config';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ConciergeBell, Electricity, EmailSent, Help, Semibold, Sparkles, Video, Visible, Whatsapp } from '@zazume/zzm-base';
import React, { forwardRef } from 'react';
import { BeamerHelmet } from 'react-beamer';
import { CanInDefaultOrganization } from '#/components/roles/CanInDefaultOrganization';
import { Can } from '#/components/roles/Can';
import { useCopilotContext } from '#/pages/copilot/CopilotContext';

const Base = styled.div(({ theme }) => css`
  ${theme.mq.tablet} {
    position: relative;
  }

  ${theme.mq.desktop} {
    position: relative;
  }
`);

const Container = styled.div<{ isOpened: boolean }>(({ theme, isOpened }) => css`
  display: ${isOpened ? 'block' : 'none'};
  position: absolute;
  width: 100%;
  left: 0;
  top: 4.375rem;

  ${theme.mq.tablet} {
    width: 19.3125rem;
    max-width: 19.3125rem;
    left: -17.25rem;
    top: ${theme.spacing.s2};
  }
`);

const Body = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.White};
  border-radius: ${theme.radius.lg};
  box-shadow: 0 2px 3px rgb(0 0 0 / 5%), 0 0 2px rgb(0 0 0 / 20%);
`);

const MenuItem = styled(Dropdown.Item)`
  padding: 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const MenuItemBeamer = styled(MenuItem)`
  .beamer_icon {
    right: 15px !important;
    top: 12.5px !important;;
  }
`;

const Title = styled.div(({ theme }) => css`
  padding: ${theme.spacing.sm};
  box-shadow: ${theme.shadows.s1};
`);

interface Props {
  isOpened: boolean;
  onCloseOverlay: () => void;
  children?: React.ReactNode;
}

export const HelpOverlay = forwardRef<any, Props>(({ children, isOpened, onCloseOverlay }, ref) => {
  const { t, language } = useI18n();
  const { user, isAgent, isOwner } = useSession();
  const { handleIsOpen: openCopilotChat } = useCopilotContext();

  const beamerOpts = {
    selector: '#beamer-trigger, #help-hub',
    language: language?.toUpperCase(),
    user_firstname: user.name,
    user_lastname: user.surnames,
    user_email: user.email,
    user_id: user._id,
    right: -5,
    top: -5
  };

  const goToHelpCenter = () => {
    if (isAgent) {
      return window.open('https://faq.zazume.com/hc/es/categories/12667934708508-Ayuda-para-agentes');
    }
    if (isOwner) {
      return window.open('https://faq.zazume.com/hc/es/categories/7632135856017-Ayuda-para-propietarios');
    }
    return window.open('https://faq.zazume.com/hc/es');
  };

  const goToWhatsApp = () => {
    window.open('https://wa.me/+34623979456');
  };

  const goToNewFeatureRequest = () => {
    window.open('https://insigh.to/b/zazume-propietarios');
  };

  const reportProblem = () => {
    window.open('mailto:help@zazume.com');
  };

  const goToYoutube = () =>
    window.open('https://www.youtube.com/playlist?list=PLA5NYIFXQtX9BwuZlXfn1h6RkEN_UDZWS');

  const openCopilot = () => {
    openCopilotChat();
    onCloseOverlay();
  };

  return <Base>
    <Container ref={ref} isOpened={isOpened}>
      <Body>
        <Title>
          <Semibold variant="M">{t('helpHub.overlay.title')}</Semibold>
        </Title>
        {isProductionEnvironment && <MenuItemBeamer data-id="news" id="beamer-trigger">
          <Electricity size={20} color="Gray500"/>
          {t('helpHub.overlay.news')}
          <BeamerHelmet id="lqsZImmf52994" args={beamerOpts}/>
        </MenuItemBeamer>}
        <MenuItem onClick={goToHelpCenter}>
          <Help size={20} color="Gray500"/>
          {t('helpHub.overlay.helpCenter')}
        </MenuItem>
        <CanInDefaultOrganization>
          <MenuItem onClick={goToWhatsApp}>
            <Whatsapp size={20} color="Gray500"/>
            {t('helpHub.overlay.whatsapp')}
          </MenuItem>
          <Can hasRoles={['ownerManager', 'ownerMiddle', 'ownerBasic']}>
            <MenuItem onClick={goToNewFeatureRequest}>
              <ConciergeBell size={20} color="Gray500"/>
              {t('helpHub.overlay.newFeature')}
            </MenuItem>
          </Can>
          <MenuItem onClick={reportProblem}>
            <EmailSent size={20} color="Gray500"/>
            {t('helpHub.overlay.email')}
          </MenuItem>
        </CanInDefaultOrganization>
        <MenuItem onClick={goToYoutube}>
          <Video size={20} color="Gray500"/>
          {t('helpHub.overlay.tutorials')}
        </MenuItem>
        <Visible mobile>
          <Can hasRoles={['admin', 'ownerBasic', 'ownerMiddle', 'ownerManager']}>
            <MenuItem onClick={openCopilot}>
              <Sparkles size={20} color="Gray500"/>
              Zazume Copilot
            </MenuItem>
          </Can>
        </Visible>
      </Body>
    </Container>
  </Base>;
});
