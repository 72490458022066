import React, { FC } from 'react';
import { IconProps, getStrokeWidth } from './index';
import { Colors } from '@zazume/zzm-base';

/**
 * @deprecated use zzm-base
 * @param color
 * @param size
 * @constructor
 */
export const Marketplace: FC<IconProps> = ({ color = Colors.FONT_3, size = 20 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} strokeLinejoin="round" d="M3.81104 15.0449C3.81104 14.4926 4.25875 14.0449 4.81104 14.0449H8.99348C9.54576 14.0449 9.99348 14.4926 9.99348 15.0449V19.2273C9.99348 19.7796 9.54576 20.2273 8.99348 20.2273H4.81103C4.25875 20.2273 3.81104 19.7796 3.81104 19.2273V15.0449Z"/>
    <path stroke={color} strokeWidth={strokeWidth} strokeLinejoin="round" d="M3.81104 5.35906C3.81104 4.80677 4.25875 4.35906 4.81104 4.35906H8.99348C9.54576 4.35906 9.99348 4.80677 9.99348 5.35906V9.5415C9.99348 10.0938 9.54576 10.5415 8.99348 10.5415H4.81103C4.25875 10.5415 3.81104 10.0938 3.81104 9.5415V5.35906Z"/>
    <path stroke={color} strokeWidth={strokeWidth} strokeLinejoin="round" d="M13.2766 7.84835C12.8861 7.45782 12.8861 6.82466 13.2766 6.43413L15.881 3.82976C16.2715 3.43923 16.9047 3.43923 17.2952 3.82976L19.8996 6.43413C20.2901 6.82466 20.2901 7.45782 19.8996 7.84835L17.2952 10.4527C16.9047 10.8432 16.2715 10.8432 15.881 10.4527L13.2766 7.84835Z"/>
    <path stroke={color} strokeWidth={strokeWidth} strokeLinejoin="round" d="M13.4969 15.0449C13.4969 14.4926 13.9446 14.0449 14.4969 14.0449H18.6793C19.2316 14.0449 19.6793 14.4926 19.6793 15.0449V19.2273C19.6793 19.7796 19.2316 20.2273 18.6793 20.2273H14.4969C13.9446 20.2273 13.4969 19.7796 13.4969 19.2273V15.0449Z"/>
  </svg>;
};
