import React from 'react';
import { ResultGoToItem, ResultItemBase } from './ResultItem';
import styled from '@emotion/styled';
import { Owner } from '../../icons/Owner';

const SmallDetails = styled.div`
  font-size: 12px;
`;

export const OwnerCell: ResultItemBase<{}> = ({ hit, components }) => {
  return (
    <a href={ResultGoToItem(hit)} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <Owner/>
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="name"/>
          <SmallDetails>
            <components.Highlight hit={hit} attribute="email"/>
          </SmallDetails>
          <SmallDetails>
            <components.Highlight hit={hit} attribute="phoneNumber"/>
          </SmallDetails>
        </div>
      </div>
    </a>
  );
};
