import { InfiniteGetData } from '#/hooks';
import { useMutation as useMutationLib, useQueryClient } from 'react-query';

type Mutator<TData = unknown, TVariables = unknown> = (variables: TVariables) => Promise<TData>;

interface UseUpdateOptions<REQUEST, MUTATOR_RESPONSE> {
  isOptimistic?: boolean;
  customIdKey?: string;
  createOptimisticResult?: (content: REQUEST) => MUTATOR_RESPONSE;
}

interface Response<RESPONSE, REQUEST> {
  mutate: (variables: REQUEST) => Promise<RESPONSE>;
  isLoading: boolean;
}

const defaultCreateOptimisticResult = (customIdKey = '_id') => (newItem: any) =>
  ({ [customIdKey]: Math.random().toString(), ...newItem });

export function useUpdate<RESPONSE = unknown, REQUEST = unknown, MUTATOR_RESPONSE = any>(uniqueKey: string | string[], mutator: Mutator<RESPONSE, REQUEST>, options: UseUpdateOptions<REQUEST, MUTATOR_RESPONSE> = {}): Response<RESPONSE, REQUEST> {
  const { isOptimistic = false, createOptimisticResult = defaultCreateOptimisticResult(options.customIdKey) } = options;
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutationLib(mutator, {
    onMutate: async (newItem: REQUEST) => {
      await queryClient.cancelQueries(uniqueKey);

      const previousData = queryClient.getQueryData<unknown[]>(uniqueKey) || [];
      if (isOptimistic) {
        queryClient.setQueryData(uniqueKey, (oldData: MUTATOR_RESPONSE[] | InfiniteGetData | undefined) => {
          if (!oldData) {
            return [createOptimisticResult(newItem)];
          }
          if (Array.isArray(oldData)) {
            return [...oldData, createOptimisticResult(newItem)];
          }
          if (oldData.pages) {
            return {
              ...oldData,
              pages: oldData.pages.map((page, index) => {
                if (index === 0) {
                  return {
                    ...page,
                    data: [createOptimisticResult(newItem), ...page.data]
                  };
                }
                return page;
              })
            };
          }
          return oldData;
        });
        return previousData;
      }
      return previousData;
    },
    onSettled: async () => {
      queryClient.invalidateQueries(uniqueKey);
    },
    onError: (_, __, context) => {
      queryClient.setQueryData<unknown[]>(uniqueKey, context as unknown[]);
    },
    onSuccess: async () => queryClient.invalidateQueries(uniqueKey)
  });

  return {
    mutate: mutateAsync,
    isLoading
  };
}
