import { EndpointBuilder, doGet } from '../../helpers';
import { CalendarEvent } from '#/models/event/CalendarEvent';
import { IdentifierDTO } from '@zazume/zzm-base';

export const calendar = {
  getEvents: (params: EndpointBuilder) =>
    doGet<CalendarEvent[]>(`/dashboard/calendar/events${params.queryParams}`),
  getAvailabilityEvents: (agentId: IdentifierDTO, params: EndpointBuilder) =>
    doGet<CalendarEvent[]>(`/dashboard/calendar/availability-events/${agentId}/${params.queryParams}`)
};
