import { MutationStatus, QueryKey, RefetchOptions, useQuery, UseQueryOptions } from 'react-query';
import { useAuth } from '../../contexts/AuthProvider';
import { isAClientError, isUnauthorizedError } from '../../lib/network/helpers';

const MAX_RETRY = 3;

export type Fetcher<T> = () => Promise<T>;

export type UseGetOptions = UseQueryOptions;

export interface UseAPIResult<T> {
  data?: T;
  isLoading: boolean;
  error: any;
  isError: boolean;
  refetch: (options?: RefetchOptions) => Promise<any>;
  status: MutationStatus;
}

export const useGet = <T extends any>(uniqueKey: QueryKey, fetcher: Fetcher<T>, options?: UseGetOptions): UseAPIResult<T> => {
  const { isLoading, error, isError, data, refetch, status } = useQuery<T>(uniqueKey, fetcher, {
    notifyOnChangeProps: ['data', 'error'],
    retry: (failureCount: number, error) => {
      if (error && isAClientError(error)) {
        return false;
      }

      if (error && isUnauthorizedError(error)) {
        return false;
      }

      return failureCount < MAX_RETRY;
    },
    ...options as any
  });

  const { logout } = useAuth();

  if (error) {
    if (isUnauthorizedError(error)) {
      console.log('Error force logout!!');
      logout().catch(console.error);
    }
  }

  return {
    data,
    isLoading,
    error,
    refetch,
    isError,
    status
  };
};
