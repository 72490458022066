import { Public } from '#/containers/router/routes/Public';
import { BaseRoute, ZazumeRoute } from '#/containers/router/routes/types';
import { QueryKey } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

/**
 * ?m=edit
 */
const MODAL_SEARCH_PARAM_NAME = 'm';

export const isRoute = (pathname: string) => (routerPath: BaseRoute): boolean => {
  if (routerPath.route === pathname) {
    return true;
  }
  // Check complex routes
  if (routerPath.route.includes(':')) {
    const start = routerPath.route.split(':');
    if (pathname.startsWith(start[0])) {
      return true;
    }
  }

  return false;
};

export interface LocationState {
  canGoBack?: Boolean;
  navigationQuery?: QueryKey;
}

export interface NavigationOptions {
  /**
   * Specifying replace: true will cause the navigation to replace the current entry in the history stack instead of adding a new one.
   */
  replace?: boolean;
  canGoBack?: boolean;
  navigationQuery?: QueryKey;
  noScrollToTop?: boolean;
}

export type Navigator = (to: ZazumeRoute, options?: NavigationOptions) => void;

interface UseRouterResult {
  navigate: Navigator;
  goBack: (defaultGoBackRoute?: ZazumeRoute) => void;
  goBackWithFallback: (fallbackBackRoute: ZazumeRoute) => void;
  pathname: string;
  search: string;
  isPublicRoute: () => boolean;
  modalIdentifier: string | null;
  afterCloseModal: (modalIdentifierClosed: string) => void;
  afterOpenModal: (modalIdentifierClosed: string) => void;
  getParam: (param: string) => string | undefined;
}

export const useRouter = (): UseRouterResult => {
  const baseNavigate = useNavigate();
  const { state, pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = (to: ZazumeRoute, options: NavigationOptions = {}) => {
    const { replace, canGoBack, navigationQuery, noScrollToTop } = options;
    baseNavigate(to, {
      ...(replace && { replace: true }),
      state: {
        canGoBack: (canGoBack || (state as LocationState)?.canGoBack),
        navigationQuery,
        ...(noScrollToTop && { noScrollToTop })
      }
    });
  };

  const isPublicRoute = (): boolean => {
    return Object
      .values(Public)
      .some(isRoute(pathname));
  };

  const goBack = (defaultGoBackRoute?: ZazumeRoute) => {
    baseNavigate(defaultGoBackRoute as any || -1);
  };

  const goBackWithFallback = (route: ZazumeRoute) => {
    (state as LocationState)?.canGoBack
      ? goBack()
      : goBack(route);
  };

  const modalIdentifier = searchParams.get(MODAL_SEARCH_PARAM_NAME);

  const afterCloseModal = (modalIdentifierClosed: string) => {
    const current = searchParams.get(MODAL_SEARCH_PARAM_NAME);
    if (current && current === modalIdentifierClosed) {
      searchParams.delete(MODAL_SEARCH_PARAM_NAME);
      setSearchParams(searchParams);
    }
  };

  const afterOpenModal = (modalIdentifierOpened: string) => {
    searchParams.set(MODAL_SEARCH_PARAM_NAME, modalIdentifierOpened);
    setSearchParams(searchParams);
  };

  const getParam = (param: string): string | undefined =>
    searchParams.get(param) || undefined;

  return {
    navigate,
    goBack,
    goBackWithFallback,
    pathname,
    search,
    isPublicRoute,
    modalIdentifier,
    afterCloseModal,
    afterOpenModal,
    getParam
  };
};
