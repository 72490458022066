import React, { FC } from 'react';
import { Flex, getIcon, IconType, Regular, Semibold, ZZMColor } from '@zazume/zzm-base';
import { useDetailFieldsContext } from './atoms/FieldsSectionContext';
import { Email } from '@zazume/zzm-utils';
import { ButtonsEmailContact } from '#/components/buttonsContact/ButtonsEmailContact';

export interface FieldValueProps {
  title: string;
  value?: string | null;
  emptyValue?: string;
  isDefaultHidden?: boolean;
  icon?: IconType;
  color?: ZZMColor;
}

export const EmailValue: FC<FieldValueProps> = ({ title, value, emptyValue = '-', isDefaultHidden, icon, color = 'Gray600' }) => {
  const { areSectionsHidden } = useDetailFieldsContext();

  if (isDefaultHidden && areSectionsHidden) {
    return null;
  }

  const isValid = Email.isValid(value);
  const Icon = icon && getIcon(icon);

  return (
    <Flex direction="column" gap="s2">
      <Regular color="Gray400" variant="S">{title}</Regular>
      <Flex gap="xs">
        {Icon && value && <Icon size={20} color="Gray400"/>}
        <Semibold variant="S" color={color}>{value || emptyValue}</Semibold>
        {isValid && <ButtonsEmailContact email={value}/>}
      </Flex>
    </Flex>
  );
};
