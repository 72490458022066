import { DiscountType } from '#/models/Payment';
import { LanguageVO, PriceVO } from '@zazume/zzm-utils';

interface Params {
  discountType: DiscountType;
  percentageDiscount?: number;
  absoluteDiscount?: PriceVO;
  concept?: string;
  netAmountWith100Percentage?: PriceVO;
}

export class Discount {
  discountType: DiscountType;
  percentageDiscount?: number;
  absoluteDiscount?: PriceVO;
  concept?: string;
  netAmountWith100Percentage?: PriceVO;

  constructor(params: Params) {
    this.discountType = params.discountType;
    this.percentageDiscount = params.percentageDiscount;
    this.absoluteDiscount = params.absoluteDiscount;
    this.concept = params.concept;
    this.netAmountWith100Percentage = params.netAmountWith100Percentage;
  }

  format(language: LanguageVO): string {
    if (this.discountType === 'percentage') {
      return `${this.percentageDiscount}%`;
    }
    return this.absoluteDiscount!.format(language);
  }
}
