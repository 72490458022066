import { FC } from 'react';
import { Document, RelatedModelType } from '../../models';
import { findDocumentCategory } from './utils/utils';
import { useNotifications } from '../../hooks';
import { getUpdateDocumentSchema, UpdateDocumentFormInputs } from './UpdateDocument.schema';
import { ZZMForm, ZazumeModal } from '@zazume/zzm-base';
import { useI18n } from '../../hooks/useI18n';
import { UpdateDocumentRequest } from '../../lib/network/api/documents/requests/UpdateDocumentRequest';
import { UpdateDocumentModalForm } from './UpdateDocumentModalForm';
import { useAsyncMethod } from '#/hooks/useAsyncMethod';

interface UpdateDocumentModalProps {
  close: () => void;
  document: Document;
  relatedModel: RelatedModelType;
  isSelectCategoryLocked?: boolean;
  onUpdate: (formData: UpdateDocumentRequest) => Promise<void>;
  onAfterUpdate?: () => Promise<void>;
  withHiddenFromOwner?: boolean;
}

export const UpdateDocumentModal: FC<UpdateDocumentModalProps> = ({ close, document, onUpdate, onAfterUpdate, isSelectCategoryLocked = false, relatedModel, withHiddenFromOwner = true }) => {
  const { t } = useI18n();
  const { call } = useAsyncMethod();
  const { showNotification } = useNotifications();
  const documentCategory = findDocumentCategory(document.type, document.relatedModel.name);

  const defaultValues: Partial<UpdateDocumentFormInputs> = {
    document: document,
    type: document.type,
    expireAt: document.expireAt ?? undefined,
    isExpirable: Boolean(document.expireAt),
    isSignable: Boolean(document.signatureExternalId),
    category: documentCategory,
    isHiddenFromOwner: Boolean(document.isHiddenFromOwner)
  };
  const validation = getUpdateDocumentSchema(t);

  const onUpdateDocument = (formData: UpdateDocumentFormInputs) => call(async () => {
    const { type, isHiddenFromOwner, document } = formData;

    const updateDocumentRequest = new UpdateDocumentRequest(document._id, type);

    if (formData.isExpirable) {
      updateDocumentRequest.addExpiredAt(formData.expireAt);
    }

    if (document.fileToAdd?.length) {
      updateDocumentRequest.addFile(document.fileToAdd[0]);
    }

    if (withHiddenFromOwner) {
      updateDocumentRequest.addHiddenFromOwner(isHiddenFromOwner);
    }

    await onUpdate(updateDocumentRequest);
    await onAfterUpdate?.();
    showNotification(t('documents.updated'));
    close();
  });

  return (
    <ZazumeModal title={t('documentsModals.edit.title')}>
      <ZZMForm.Form validation={validation} onSubmit={onUpdateDocument} defaultValues={defaultValues}>
        <UpdateDocumentModalForm
          document={document}
          relatedModel={relatedModel}
          isSelectCategoryLocked={isSelectCategoryLocked}
          withHiddenFromOwner={withHiddenFromOwner}
        />
      </ZZMForm.Form>
    </ZazumeModal>
  );
};
