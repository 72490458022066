import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Help, useClickOutside } from '@zazume/zzm-base';
import { FC, useRef, useState } from 'react';
import { HelpOverlay } from './HelpOverlay';

const HeaderContainer = styled.span(({ theme }) => css`
  width: 2rem;
  height: 2rem;
  display: flex;
  cursor: pointer;

  &:hover {
    svg  > path {
      stroke: ${theme.colors.Primary};
    }
  }
`);

const Header = (props) => {
  return <HeaderContainer id="help-hub" data-beamer-click="false" data-id="help" {...props}>
    <Help size={32} color="Gray500"/>
  </HeaderContainer>;
};

export const HelpHub: FC = () => {
  const [isOverlayOpened, setIsOverlayOpened] = useState<boolean>(false);

  const overlayRef = useRef<any>();
  useClickOutside(overlayRef, () => setIsOverlayOpened(false));

  const openOverlay = (event) => {
    event.preventDefault();
    setIsOverlayOpened((prevState) => !prevState);
  };

  const closeOverlay = () =>
    setIsOverlayOpened(false);


  return (
    <div>
      <Header onClick={openOverlay}/>
      <HelpOverlay
        isOpened={isOverlayOpened}
        onCloseOverlay={closeOverlay}
        ref={overlayRef}
      />
    </div>
  );
};
