import { NavigationOptions, useRouter } from '../../hooks';
import { URLBuilder } from '@zazume/zzm-utils';
import { DeepLinkingRoute } from '../../models/deepLinking/deepLinking';

const toValidRoute = (type: string) => {
  // TODO: temporal fix onBoarding route must be renamed to application
  if (type === 'application') {
    return 'onboarding';
  }
  return type;
};

export const getDestinationURL = (route: DeepLinkingRoute): string => {
  const url = new URLBuilder('/' + toValidRoute(route.type));
  if (route.pathParams) {
    url.addPathnameList(route.pathParams);
  }

  if (route.queryParams) {
    url.addQueryParamList(route.queryParams);
  }

  return url.toString();
};

interface DeepLinkingNavigationOptions extends NavigationOptions {
  forceReload?: boolean;
}

interface Response {
  navigateTo: (route: DeepLinkingRoute, options?: DeepLinkingNavigationOptions) => void;
}

export const useDeepLinking = (): Response => {
  const { navigate } = useRouter();

  const navigateTo = (route: DeepLinkingRoute, options?: DeepLinkingNavigationOptions) => {
    const url = getDestinationURL(route);
    navigate(url, options);
    if (options?.forceReload) {
      // TODO: Temporal fix to open modals on the same path.
      navigate(0 as any);
    }
  };

  return {
    navigateTo
  };
};
