import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useImgix } from '../../hooks/useImgix';
import { Subtitle } from '../typography';
import empty from '../../assets/images/service/defaultService.png';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 0;
  user-select: none;
  padding: 12px 0;
  gap: 12px;
`;

const Photo = styled.div<any>`
  border-radius: 4px;
  width: 32px;
  height: 32px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Details = styled.div`
`;


interface BuildingResultProps {
  result: any;
  onSelect: (result: any) => void;
}


export const BuildingResult: FC<BuildingResultProps> = ({ result, onSelect }) => {
  const { applyImgix } = useImgix();

  const { image = empty, name } = result;

  const handleSelect = () => onSelect(result);

  return (
    <Container onClick={handleSelect}>
      <Photo src={applyImgix({ src: image, width: 32, height: 32 })}/>
      <Details>
        <Subtitle soft>{name}</Subtitle>
      </Details>
    </Container>
  );
};
