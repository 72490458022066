import React, { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { useI18n } from '#hooks/useI18n';
import { SectionProps } from '#/containers/asideNavigation/Sections';
import { Private } from '#/containers/router/routes/Private';

interface Props extends SectionProps {
}

export const TaskManager: FC<Props> = ({ onClickItem, isMobile }) => {
  const { t } = useI18n();
  return <Can hasRoles={['staff', 'buildingManager', 'admin', 'commercial']}>
    <NavigationLink to={Private.TASKS.route} icon="check" title={t('asideNavigation.tasks')} onClick={onClickItem} isMobile={isMobile}/>
  </Can>;
};
