import { AutocompletionRequest } from './GooglePlacesAutocomplete.types';

const autocompletionRequestBuilder = (
  autocompletionRequest: AutocompletionRequest,
  input: string,
  // @ts-ignore
  sessionToken?: google.maps.places.AutocompleteSessionToken
// @ts-ignore
): google.maps.places.AutocompletionRequest => {
  const { bounds, location, ...rest } = autocompletionRequest;

  // @ts-ignore
  const res: google.maps.places.AutocompletionRequest = {
    input,
    ...rest
  };

  if (sessionToken) {
    res.sessionToken = sessionToken;
  }

  if (bounds) {
    // @ts-ignore
    res.bounds = new google.maps.LatLngBounds(...bounds);
  }

  if (location) {
    // @ts-ignore
    res.location = new google.maps.LatLng(location);
  }

  return res;
};

export default autocompletionRequestBuilder;
