import { Can } from '#/components/roles/Can';
import { useCopilotContext } from '#/pages/copilot/CopilotContext';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Hidden, Sparkles } from '@zazume/zzm-base';

const HeaderContainer = styled.span(({ theme }) => css`
  width: 2rem;
  height: 2rem;
  display: flex;
  cursor: pointer;

  &:hover {
    svg  > path {
      stroke: ${theme.colors.Primary};
    }
  }
`);

export const CopilotIconButton = () => {
  const { handleIsOpen: openCopilotChat } = useCopilotContext();

  return <Can hasRoles={['admin', 'ownerBasic', 'ownerMiddle', 'ownerManager']}>
    <Hidden mobile>
      <HeaderContainer id="zazume-copilot" onClick={openCopilotChat}>
        <Sparkles size={32} color="Gray500"/>
      </HeaderContainer>
    </Hidden>
  </Can>;
};
