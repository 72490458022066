import { PriceVO } from '@zazume/zzm-utils';

interface ConstructorParams {
  totalPaid: PriceVO;
  totalFeePaid: PriceVO;
}

export class RentAdvanceProgress {
  totalPaid: PriceVO;
  totalFeePaid: PriceVO;

  constructor(params: ConstructorParams) {
    this.totalPaid = params.totalPaid;
    this.totalFeePaid = params.totalFeePaid;
  }
}
