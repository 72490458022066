import React, { FC } from 'react';
import { IconProps } from './index';

export const ProfileThin: FC<IconProps> = ({ size = 20, color = '#FFFFFF' }) =>
  <svg width={size} height={size} viewBox="0 0 48 48" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
        d="M17.5,16.8c0-3.6,2.9-6.5,6.5-6.5s6.5,2.9,6.5,6.5s-2.9,6.4-6.5,6.4S17.5,20.4,17.5,16.8z M24,8.8
      c-4.4,0-8,3.6-8,7.9s3.6,7.9,8,7.9s8-3.6,8-7.9S28.4,8.8,24,8.8z M12.8,32.4c0-1.2,1-2.5,3.1-3.6c2-1,4.9-1.7,8.1-1.7
      s6.1,0.7,8.1,1.7c2.1,1,3.1,2.4,3.1,3.6c0,1.2-1,2.5-3.1,3.6c-2,1-4.9,1.7-8.1,1.7S17.9,37,15.9,36C13.8,34.9,12.8,33.6,12.8,32.4z
      M24,25.6c-3.4,0-6.5,0.7-8.8,1.8c-2.3,1.1-3.9,2.8-3.9,4.9c0,2.1,1.7,3.8,3.9,4.9c2.3,1.1,5.4,1.8,8.8,1.8s6.5-0.7,8.8-1.8
      c2.3-1.1,3.9-2.8,3.9-4.9c0-2.1-1.7-3.8-3.9-4.9C30.5,26.3,27.4,25.6,24,25.6z"/>
  </svg>;
