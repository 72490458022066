import { useAuth } from '../../contexts/AuthProvider';
import { isOwnerUser, UserRolesTypes } from '../../models';
import { useCallback } from 'react';

interface UsePermissionsResult {
  includesRoles: (rolesArray: UserRolesTypes[]) => boolean;
  canRender: (hasRoles: UserRolesTypes[], hideFromZazumeUser?: boolean) => boolean;
}

const userHasZazumeRol = (user) =>
  user?.roles.some(role => role === 'zazume') ?? false;

export const usePermissions = (): UsePermissionsResult => {
  const { user, role } = useAuth();

  const includesRoles = useCallback(rolesArray => {
    if (userHasZazumeRol(user)) {
      return true;
    }

    if (isOwnerUser(user)) {
      return rolesArray.includes(role);
    }

    return user?.roles.some(role => rolesArray.includes(role)) ?? false;
  }, [user, role]);

  const canRender = useCallback((hasRoles: UserRolesTypes[], hideFromZazumeUser?: boolean, hideForNonDefaulOrganization?: boolean): boolean => {

    if (!includesRoles(hasRoles)) {
      return false;
    }

    if (hideFromZazumeUser && includesRoles(['zazume'])) {
      return false;
    }

    return true;
  }, [includesRoles]);

  return {
    includesRoles,
    canRender
  };
};
