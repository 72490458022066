import React, { FC } from 'react';
import { getStrokeWidth, IconProps } from './index';

export const Help: FC<IconProps> = ({ color = '#FFFFFF', size = 24 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.33518 9.29808C9.33518 8.28674 10.1642 6.69201 11.8066 6.69201C13.449 6.69201 14.6645 7.64623 14.6645 9.29808C14.6645 10.2765 13.9851 11.0747 13.2138 11.6767C12.458 12.2666 11.6813 13.1309 11.8066 14.1339M21.2649 12C21.2649 17.1169 17.1168 21.265 11.9999 21.265C6.88295 21.265 2.73486 17.1169 2.73486 12C2.73486 6.88307 6.88295 2.73499 11.9999 2.73499C17.1168 2.73499 21.2649 6.88307 21.2649 12Z"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeLinejoin="round"/>
    <path
        d="M12.6143 17.1776C12.6143 17.5918 12.2785 17.9276 11.8643 17.9276C11.45 17.9276 11.1143 17.5918 11.1143 17.1776C11.1143 16.7634 11.45 16.4276 11.8643 16.4276C12.2785 16.4276 12.6143 16.7634 12.6143 17.1776Z"
        fill={color}/>
  </svg>;
};
