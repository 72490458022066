import { Error } from '#/pages/Error.page';
import { Component } from 'react';
import MetricsHelper from '../../utils/metrics/metricsHelper';

export interface ErrorBoundaryState {
  hasError: boolean;
  logout?: boolean;
}

interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = {
      // error: undefined,
      // errorInfo: undefined,
      // eventId: undefined,
      hasError: false
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    MetricsHelper.reportError(error, errorInfo);
  }

  render() {
    // if (this.state.logout) {
    //   return <LogoutRedirection/>;
    // }

    if (this.state.hasError) {
      return <Error/>;
    }

    return this.props.children;
  }
}
