import React, { FC } from 'react';
import { IconProps } from './index';

/**
 * @deprecated use zzm-base
 * @param size
 * @param color
 * @constructor
 */
export const Link: FC<IconProps> = ({ size = 20, color }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.7803 4.21969C14.521 2.9603 12.4791 2.9603 11.2197 4.21969L9.46969 5.96969C8.76669 6.67269
      8.45611 7.61951 8.53795 8.53795C7.61951 8.45611 6.67269 8.76669 5.96969 9.46969L4.21969 11.2197C2.9603 12.4791 2.9603 14.521 4.21969 15.7803C5.47908
      17.0397 7.52096 17.0397 8.78035 15.7803L10.5303 14.0303C11.2334 13.3273 11.5439 12.3805 11.4621 11.4621C12.3805 11.5439 13.3273 11.2333 14.0303 10.5303L15.7803
      8.78035C17.0397 7.52096 17.0397 5.47908 15.7803 4.21969ZM12.2804 5.28035C12.954 4.60675 14.0461 4.60675 14.7197 5.28035C15.3933 5.95395 15.3933 7.04608 14.7197
      7.71969L12.9697 9.46969C12.2961 10.1433 11.204 10.1433 10.5304 9.46969C9.85675 8.79608 9.85675 7.70395 10.5304 7.03035L12.2804 5.28035ZM7.03035 10.5303C7.70396
      9.85674 8.79608 9.85674 9.46969 10.5303C10.1433 11.204 10.1433 12.2961 9.46969 12.9697L7.71969 14.7197C7.04608 15.3933 5.95396 15.3933 5.28035 14.7197C4.60675
      14.0461 4.60675 12.954 5.28035 12.2803L7.03035 10.5303Z"
    />
  </svg>;
};
