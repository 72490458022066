import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { Colors, FCC, Fonts } from '@zazume/zzm-base';

interface ContainerProps {
  color?: string;
}

const Container = styled.div<ContainerProps>(({ color }) => css`
  font-family: ${Fonts.BASE};
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: 0;
  color: ${color ?? Colors.FONT_2};
  white-space: pre-wrap;
`);

/**
 * @deprecated use Base paragraph
 * <Regular variant="S" color="Gray400">
 * @param props
 * @constructor
 */
export const Body14: FCC<ContainerProps> = (props) => {
  return <Container {...props}>{props.children}</Container>;
};
