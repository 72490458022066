import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Flex, Regular } from '@zazume/zzm-base';
import { useI18n } from '../../../../../hooks/useI18n';
import { DetailFields, FieldsSection, FieldValue, PhoneValue } from '../../../../../components/pages/detail';
import { Provider } from '../../../../../models';
import { Checkbox } from '../../../../../components/forms';
import { I18NValues } from '../../../../../lib/i18n/generatedKeys';
import { css } from '@emotion/react';
import { EmailValue } from '#/components/pages/detail/FieldsSection/EmailValue';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const DetailBlockSeparator = styled.div(({ theme }) => css`
  margin: 2.125rem 0;
  border-bottom: 1px solid ${theme.colors.Gray200};
`);

interface AboutTabProps {
  provider: Provider;
}

export const AboutTab: FC<AboutTabProps> = ({ provider }) => {
  const { t } = useI18n();
  const { name, phone, billingData, email, services } = provider;
  const activeServices: string[] = Object.entries(services).reduce<string[]>((acc, [key, value]) => {
    return value ? [...acc, key] : acc;
  }, []);

  return <Container>
    <DetailFields title={t('providers.common.details')} icon="document">
      <FieldsSection>
        <FieldValue title={t('providers.common.taxName')} value={name}/>
        <FieldValue title={t('general.idNumber')} value={billingData?.idNumber}/>
        <PhoneValue title={t('providers.common.contact')} value={phone} email={email} name={name || ''} lang="es"/>
        <EmailValue title={t('general.email')} value={email}/>
        <FieldValue title={t('general.address')} value={billingData?.fullAddress}/>
        <FieldValue title={t('general.iban')} value={billingData?.iban}/>
      </FieldsSection>
    </DetailFields>

    <DetailBlockSeparator/>

    <DetailFields title={t('providers.common.services')} icon="marketplace">
      <FieldsSection>
        <Flex direction="column" gap="lg">
          {activeServices.map(service => (
            <Flex gap="sm" key={service}>
              <Checkbox name={service} disabled checked/>
              <Regular color="Gray500">{t(`providers.services.${service}` as I18NValues)}</Regular>
            </Flex>
          ))}
        </Flex>
      </FieldsSection>
    </DetailFields>
  </Container>;
};
