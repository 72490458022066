import { useI18n } from '#/hooks/useI18n';
import { User } from '#/models';
import { Spacer, Tabs, ZazumeModal } from '@zazume/zzm-base';
import { FC } from 'react';
import { EditAvatar } from './EditAvatar';
import { EditProfile } from './EditProfile';
import { EditNotifications } from './EditNotifications';

interface ProfileModalProps {
  user: User;
  close: () => void;
  onUpdate: () => void;
}

export const ProfileModal: FC<ProfileModalProps> = ({ user, close, onUpdate }) => {
  const { t } = useI18n();

  return (
    <ZazumeModal title={t('profile.modal.title')}>
      <EditAvatar user={user} onUpdate={onUpdate}/>
      <Spacer vertical="s10"/>

      <Tabs label="Profile tabs">
        <Tabs.Tab key="account" title={t('profile.modal.account.title')}>
          <EditProfile user={user} close={close} onUpdate={onUpdate}/>
        </Tabs.Tab>
        <Tabs.Tab key="notifications" title={t('profile.modal.notifications.title')}>
          <EditNotifications close={close}/>
        </Tabs.Tab>
      </Tabs>
    </ZazumeModal>
  );
};
