import { HEADER_HEIGHT } from '#/utils/containers/containersConstants';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FCC } from '@zazume/zzm-base';

const Container = styled.div(({ theme }) => css`
  flex: 1;
  margin: 0;
  align-items: center;
  overflow-y: scroll;
  height: calc(100vh - ${HEADER_HEIGHT}px);

  ${theme.mq.desktop} {
    margin-right: ${theme.spacing.s10};

    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`);

export const PageDetailMainWithDrawer: FCC<any> = ({ children, ...rest }) => {
  return <Container {...rest}>
    {children}
  </Container>;
};
