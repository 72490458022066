import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps } from './index';

export const Tenants: FC<IconProps> = ({ color = Colors.FONT_3, size = 20 }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
        d="M7.75 7C7.75 5.75736 8.75736 4.75 10 4.75C11.2426 4.75 12.25 5.75736 12.25 7C12.25 8.24264 11.2426 9.25 10 9.25C8.75736 9.25 7.75 8.24264 7.75 7ZM10 3.25C7.92893 3.25 6.25 4.92893 6.25 7C6.25 8.48539 7.11363 9.76907 8.36615 10.3763C7.52825 10.5094 6.76395 10.744 6.12906 11.0614C5.14451 11.5537 4.25 12.3725 4.25 13.5C4.25 14.6275 5.14451 15.4463 6.12906 15.9386C7.15847 16.4533 8.5281 16.75 10 16.75C11.4719 16.75 12.8415 16.4533 13.8709 15.9386C14.8555 15.4463 15.75 14.6275 15.75 13.5C15.75 12.3725 14.8555 11.5537 13.8709 11.0614C13.2361 10.744 12.4717 10.5094 11.6338 10.3763C12.8864 9.76907 13.75 8.48539 13.75 7C13.75 4.92893 12.0711 3.25 10 3.25ZM5.75 13.5C5.75 13.2468 5.97478 12.8156 6.79988 12.4031C7.58011 12.0129 8.71047 11.75 10 11.75C11.2895 11.75 12.4199 12.0129 13.2001 12.4031C14.0252 12.8156 14.25 13.2468 14.25 13.5C14.25 13.7532 14.0252 14.1844 13.2001 14.5969C12.4199 14.9871 11.2895 15.25 10 15.25C8.71047 15.25 7.58011 14.9871 6.79988 14.5969C5.97478 14.1844 5.75 13.7532 5.75 13.5Z"
    />
  </svg>;
};
