import React, { FC } from 'react';
import { useGet } from '../../../../../hooks';
import { NotificationKeys } from '../../../../../hooks/api/notifications/NotificationKeys';
import { API } from '../../../../../lib/network/API';
import styled from '@emotion/styled';
import { Header } from './Header';
import { Filters } from './Filters';
import { NotificationList } from './NotificationList';
import { css } from '@emotion/react';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.White};
  border-radius: ${theme.radius.lg};
  box-shadow: 0 2px 3px rgb(0 0 0 / 5%), 0 0 2px rgb(0 0 0 / 20%);
`);

interface BodyProps {
  onCloseOverlay: () => void;
}

export const Body: FC<BodyProps> = ({ onCloseOverlay }) => {
  const { data: notifications, isLoading } = useGet<any[]>(NotificationKeys.unread, API.notifications.getAll);

  return <Container>
    <Header/>
    <Filters/>
    {!isLoading && <NotificationList notifications={notifications} onCloseOverlay={onCloseOverlay}/>}
  </Container>;
};
