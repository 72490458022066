import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Regular, Semibold, Signature } from '@zazume/zzm-base';
import { useI18n } from '../../../hooks/useI18n';
import { SelectField } from '../select/SelectField';
import { useFormContext } from 'react-hook-form';
import { Title } from '../../title/Title';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  gap: ${theme.spacing.s6};
  width: fit-content;
`);

interface DocumentSignatureConfigurationProps {
  name: string;
}

export const DocumentSignatureConfiguration: FC<DocumentSignatureConfigurationProps> = ({ name }) => {
  const { t } = useI18n();
  const { register, watch, setValue, errors } = useFormContext();

  const options = useMemo(() => [
    { value: 'pageEnd', name: t('documents.signature.options.pageEnd') },
    { value: 'free', name: t('documents.signature.options.free') }
  ], [t]);

  const configuration = watch(name);

  return <Container>
    <Title><Signature color="Primary" size={24}/><Semibold>{t('documents.signature.title')}</Semibold></Title>
    <div><Regular variant="S" color="Gray500">{t('documents.signature.description')}</Regular></div>
    <SelectField name={name} options={options} ref={register} setValue={setValue} errors={errors?.[name]} defaultValue={configuration}/>
  </Container>;
};
