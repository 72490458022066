import { FCC } from '@zazume/zzm-base';
import styled from '@emotion/styled';
import { HEADER_HEIGHT } from '../utils/containers/containersConstants';
import { css } from '@emotion/react';

const Container = styled.div(({ theme }) => css`
  padding: ${theme.spacing.s8} ${theme.spacing.s7};

  ${theme.mq.tablet} {
    padding: ${theme.spacing.s12} ${theme.spacing.s15};
  }

  ${theme.mq.desktop} {
    height: calc(100vh - ${HEADER_HEIGHT}px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${theme.spacing.s14} ${theme.spacing.s17};

    -ms-overflow-style: none;
    scrollbar-width: ${theme.spacing.s3};

    &::-webkit-scrollbar {
      width: ${theme.spacing.s3};
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background: ${theme.colors.Primary};
    }
  }
`);

const BodyWithoutPadding = styled(Container)(({ theme }) => css`
  padding: 0;

  ${theme.mq.tablet} {
    padding: 0;
  }

  ${theme.mq.desktop} {
    padding: 0;
  }
`);

interface Props {
  padding?: boolean;
}

export const ResponsiveBody: FCC<Props> = ({ padding = true, children, ...rest }) => {
  if (!padding) {
    return <BodyWithoutPadding {...rest}>
      {children}
    </BodyWithoutPadding>;
  }

  return <Container {...rest}>
    {children}
  </Container>;
};
