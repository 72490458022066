/**
 * @deprecated use base
 */
export const SignTypes = <const>['certificate', 'biometrics'];
export type SignType = typeof SignTypes[number];


/**
 * @deprecated use base
 */
export const SignatureConfigurations = <const>['pageEnd', 'free'];
export type SignatureConfiguration = typeof SignatureConfigurations[number];
