import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { FCC, Flex } from '@zazume/zzm-base';

const Input = styled.input(({ theme, disabled }) => css`
  position: relative;
  height: 21px;
  width: 21px;
  margin: 0;
  padding: 0;
  background: ${theme.colors.White};
  border: 1px solid ${theme.colors.Gray300};
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 100%;
  appearance: none;
  cursor: ${disabled ? 'default' : 'pointer'};

  &:after {
    display: block;
    position: relative;
    width: 7px;
    height: 7px;
    left: 6px;
    top: 6px;
    background-color: ${theme.colors.White};
    border-radius: 100%;
    content: '';
  }

  &:checked {
    background: linear-gradient(0deg, ${theme.colors.Primary}, ${theme.colors.Primary}), #2C3CCE;
  }
`);

const Label = styled.label<any>(({ theme, disabled }) => css`
  color: ${disabled ? theme.colors.Gray400 : theme.colors.Gray600};
  font-size: ${theme.fontsSizes.base};
  line-height: 140%;
  font-weight: ${theme.fontWeights.normal};
  -webkit-font-smoothing: antialiased;
  cursor: ${disabled ? 'default' : 'pointer'};
`);

export interface RadioProps {
  name: string;
  value: string;
  disabled?: boolean;
  defaultChecked?: boolean;
}

export type RadioInputTemplate = FC<RadioProps>;

/**
 * @deprecated use RadioBase from zzm-base
 */
export const RadioInput: FCC<RadioProps> = ({ name, children, defaultChecked = false, disabled, value }) => {
  const { register } = useFormContext();

  const id = name + '_' + value;
  return <Flex gap="md">
    <Input ref={register} name={name} id={id} type="radio" value={value} disabled={disabled} defaultChecked={defaultChecked}/>
    <Label htmlFor={id} disabled={disabled}>{children}</Label>
  </Flex>;
};
