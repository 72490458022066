import React, { FC } from 'react';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '../components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';
import { useAuth } from '#/contexts/AuthProvider';
import { useI18n } from '#hooks/useI18n';
import { SectionProps } from '#/containers/asideNavigation/Sections';

interface Props extends SectionProps {
}

export const Applications: FC<Props> = ({ onClickItem, depth, isMobile }) => {
  const { organization } = useAuth();
  const { t } = useI18n();

  if (!organization || !organization.onBoardingSettings) {
    return null;
  }

  return <Can hasRoles={['buildingManager', 'admin', 'commercial', 'ownerManager', 'ownerMiddle', 'viewer', 'agent']}>
    <NavigationLink to={Private.ONBOARDING.route} icon="welcome" title={t('asideNavigation.onboarding')} onClick={onClickItem} depth={depth} isMobile={isMobile}/>
  </Can>;
};
