import { FC } from 'react';
import { getStrokeWidth, IconProps } from './index';

export const Sign: FC<IconProps> = ({ color = '#FFFFFF', size = 24 }) =>{
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" d="M13.6268 3.60791H6.00684C5.45455 3.60791 5.00684 4.05563 5.00684 4.60791V18.9999C5.00684 19.5522 5.45455 19.9999 6.00684 19.9999H16.6268C17.1791 19.9999 17.6268 19.5522 17.6268 18.9999V18.2618M13.6268 3.60791C13.6268 5.26476 15.9699 7.60791 17.6268 7.60791M13.6268 3.60791C14.5153 3.60791 15.9758 3.62668 16.8629 4.60791C17.6736 5.50473 17.6268 6.83956 17.6268 7.60791M17.6268 7.60791V8.96538M7.55337 6.10452H11.7452M7.55337 9.10452H12.5836M7.55338 12.1046H11.7452M7.55338 16.7282H12.5836M19.2414 9.7323L21.3057 11.7967M16.6633 16.4146C16.4485 16.6294 16.1477 16.735 15.8458 16.7014L14.4871 16.5504L14.3361 15.1917C14.3025 14.8898 14.4081 14.589 14.6229 14.3742L17.6569 11.3402C18.0474 10.9496 18.6805 10.9496 19.0711 11.3402L19.6973 11.9664C20.0879 12.357 20.0879 12.9901 19.6973 13.3806L16.6633 16.4146Z"/>
  </svg>;
};
