import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Regular, Spinner } from '@zazume/zzm-base';
import { useI18n } from '#hooks/useI18n';

const Container = styled.div(({ theme }) => css`
  padding: ${theme.spacing.s24} ${theme.spacing.s7};
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.s5};

  ${theme.mq.md} {
    padding: ${theme.spacing.s24} ${theme.spacing.s12};
  }
`);

const Card = styled.div(({ theme }) => css`
  text-align: center;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${theme.spacing.s6};
  align-items: center;
  padding: 28px 30px;
`);

export const LoadingBody = () => {
  const { t } = useI18n();
  return (
    <Container>
      <Card>
        <Spinner size="medium" color="Primary"/>
        <Regular color="Gray500">{t('general.loading')}</Regular>
      </Card>
    </Container>
  );
};
