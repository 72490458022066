import React, { FC } from 'react';
import { IconProps } from '.';
import { Colors } from '@zazume/zzm-base';
import { getStrokeWidth } from './index';

/**
 * @deprecated use zzm-base
 * @param size
 * @param color
 * @constructor
 */
export const Check: FC<IconProps> = ({ size = 20, color = Colors.FONT_3 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" d="M22 2.85736L10.1788 21.1426L2 12.7065"/>
  </svg>;
};
