import { doGet, doPost } from '../../helpers';
import { TaxesStepFormSchema } from '../../../../pages/taxes/components/ownerTaxesEmptyState/taxesStepForm/schema/TaxesStepForm.schema';
import { StatementOfIncomes } from '../../../../models/taxes/StatementOfIncomes';
import { ExportTaxesInputs } from '../../../../pages/taxes/components/ownerTaxes/ExportTaxes.schema';
import { IdentifierDTO } from '@zazume/zzm-base';
import { OwnerTaxes } from '../../../../models/taxes/OwnerTaxes';
import { Unit } from '../../../../models';

export const ownerTaxes = {
  create: (request: TaxesStepFormSchema) =>
    doPost<OwnerTaxes>('/dashboard/owner-taxes', request),
  currentForMe: () => () =>
    doGet<any>('/dashboard/owner-taxes/me-current'),
  export: (body: ExportTaxesInputs, organizationId: IdentifierDTO) =>
    doPost(`/dashboard/owner-taxes/export/${organizationId}`, body),
  findStatementByUnit: (unitId: IdentifierDTO, year: string) => () =>
    doGet<StatementOfIncomes>(`/dashboard/owner-taxes/statement/unit/${unitId}/year/${year}`),
  getUnitsWithTaxesInLastFiscalYear: () => () =>
    doGet<Unit[]>('/dashboard/owner-taxes/units-with-taxes-in-last-fiscal-year')
};
