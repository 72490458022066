import React, { FC, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Delete, ImageIcon } from '@zazume/zzm-base';
import { FileUploadValue, toAddFile } from '../fileUpload/utils';
import { Body14 } from '../../typography';
import { DocumentThin } from '../../icons';
import { checkFiles, getAllFileTypes, isImage } from '../../../utils/fileHelper';
import { parseErrors, WithErrors } from '../utils';
import { Document } from '../../../models';
import { DocumentCardFootnote } from '../../documentUploader/DocumentCardFootnote';
import { DownloadLink } from './atoms/DownloadLink';
import { css, useTheme } from '@emotion/react';
import { useI18n } from '../../../hooks/useI18n';
import { I18NValues } from '../../../lib/i18n/generatedKeys';

const Container = styled.div`
  width: 100%;
  fieldset {
    border: none;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
  }
`;

const DragAndDropArea = styled.label(({ theme }) => css`
  width: 100%;
  min-height: 96px;
  display: flex;
  padding: 13.5px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${theme.fonts.base};
  overflow: hidden;
  border-radius: 8px;
  background: ${theme.colors.Gray100};
  color: ${theme.colors.Gray500};
  border: 1px dashed ${theme.colors.Gray200};
  box-sizing: border-box;
  border-radius: 8px;
`);

const DragAndDropLabel = styled.div<any>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  margin: 0;
  min-height: 48px;

  svg {
    margin-bottom: 8px;
  }
`;

const DragAndDropLabelText = styled(Body14)<any>(({ theme }) => css`
  display: flex;
  align-items: center;
  span {
    color: ${theme.colors.Primary};
  }
`);

const Error = styled(Body14)(({ theme }) => css`
  margin-top: 8px;
  color: ${theme.colors.Red};
`);

const Helper = styled.div(({ theme }) => css`
  color: ${theme.colors.Gray400};
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  margin-top: 2px;
`);

const Label = styled.label(({ theme }) => css`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: ${theme.colors.Gray600};
  height: 28px;
  white-space: nowrap;
  margin-bottom: 8px;
`);

const DocumentPreview = styled.div(({ theme }) => css`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;
  padding: 8.5px 12px 8.5px 8px;
  border: 1px solid ${theme.colors.Gray200};
  gap: 5px;
  border-radius: 8px;
`);

const DeleteContainer = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

export interface DocumentUploadProps extends WithErrors {
  name: string;
  fileTypes?: string[];
  type?: string;
  help?: string;
  label?: string;
  size?: string;
  register: any;
  setValue: any;
  defaultValue?: Document;
  maxFileSizeMB?: number;
  maxBunchFilesSizeMB?: number;
  maxNumberOfFiles?: number;
}

export const DocumentUpload: FC<DocumentUploadProps> = (props) => {
  const {
    name,
    help,
    register,
    setValue,
    size,
    label,
    defaultValue,
    fileTypes = getAllFileTypes(),
    maxFileSizeMB = 10,
    maxBunchFilesSizeMB = 50,
    maxNumberOfFiles
  } = props;

  const { t } = useI18n();
  const { colors: themeColors } = useTheme();

  const isEdit = Boolean(defaultValue);

  const [isWrongFile, setIsWrongFile] = useState<string|null>(null);
  const [errorMessageMaxFilesReached, setErrorMessageMaxFilesReached] = useState<string|null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const { errorMessage } = parseErrors(props);

  const fileInput = useRef<HTMLInputElement>();

  const handleOnDragOver = (event) =>
    event.preventDefault();

  const registerChange = (newValue: FileUploadValue|null) => {
    setValue(name, newValue, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const registerErase = () => {
    const newValue = defaultValue || null;
    setValue(name, newValue, {
      shouldValidate: true,
      shouldDirty: false
    });
  };

  const addFiles = (files: File[]) => {
    if (isEdit) {
      setSelectedFiles(files);
      registerChange(toAddFile(files));
    } else {
      setSelectedFiles([...files, ...selectedFiles]);
      registerChange(toAddFile([...files, ...selectedFiles]));
    }

  };

  const getErrorMessageMaxFiles = (event) => {
    if ((typeof maxNumberOfFiles !== 'undefined') && (selectedFiles.length + event.dataTransfer.files.length) > maxNumberOfFiles) {
      return (t('documentsModals.filesToUpload') + maxNumberOfFiles);
    }
    return null;
  };

  const handleOnDrop = (event) => {
    event.preventDefault();

    const errorMessage = getErrorMessageMaxFiles(event);
    setErrorMessageMaxFilesReached(errorMessage);
    if (errorMessage) {
      return;
    }

    const files: File[] = Array.from(event.dataTransfer.files);
    if (!files.length) {
      // No changes
      return;
    }

    const filesSize = files.map(({ size }) => size).reduce((a, b) => a + b, 0);
    const selectedFilesSize = selectedFiles.map(({ size }) => size).reduce((a, b) => a + b, 0);
    const error = checkFiles(files, maxFileSizeMB, fileTypes, filesSize, maxBunchFilesSizeMB, selectedFilesSize);
    if (error) {
      setIsWrongFile(error);
      return;
    }

    setIsWrongFile(null);
    addFiles(files);
  };

  const onInputChange = ({ target: { files } }) => {
    const filesArray: File[] = Array.from(files);
    const filesSize = filesArray.map(({ size }) => size).reduce((a, b) => a + b, 0);
    const selectedFilesSize = selectedFiles.map(({ size }) => size).reduce((a, b) => a + b, 0);

    const error = checkFiles(files, maxFileSizeMB, fileTypes, filesSize, maxBunchFilesSizeMB, selectedFilesSize);

    if (error) {
      setIsWrongFile(error);
      return;
    }

    setIsWrongFile(null);
    addFiles(filesArray);
  };

  const removeFile = (key: number) => () => {
    const updatedFiles: File[] = selectedFiles.filter((_, index) => index !== key);
    setSelectedFiles(updatedFiles);

    if (updatedFiles.length) {
      registerChange(toAddFile(updatedFiles));
    } else {
      registerErase();
    }

    if (fileInput.current) {
      fileInput.current.value = '';
    }
  };

  const showUploadArea = (maxNumberOfFiles === 1 && !selectedFiles.length) || maxNumberOfFiles !== 1;

  return <Container>
    <fieldset>
      {label && <Label htmlFor={name}>{label}</Label>}
      {showUploadArea &&
        <DragAndDropArea
          htmlFor={name}
          onDragOver={handleOnDragOver}
          onDrop={handleOnDrop}
        >
          <DragAndDropLabel size={size}>
            <DocumentThin color={themeColors.Primary} size={40}/>
            <DragAndDropLabelText size={size}>
              {t('general.forms.dragnDrogOr')} <span>{t('general.forms.browse')}</span>
            </DragAndDropLabelText>
            {help && <Helper>{help}</Helper>}
          </DragAndDropLabel>
        </DragAndDropArea>
      }
      <input
          hidden
          id={name}
          name={name}
          onChange={onInputChange}
          type="file"
          accept={fileTypes}
          ref={fileInput}
          {...register(name)}
          multiple={maxNumberOfFiles !== 1}
      />
    </fieldset>
    {selectedFiles &&
      selectedFiles.map((file, key) =>
        <DocumentPreview key={key}>
          <DocumentThin color={themeColors.Gray600} size={40}/>
          <Body14>{file.name}</Body14>
          <DeleteContainer onClick={removeFile(key)}>
            <Delete color="Gray400"/>
          </DeleteContainer>
        </DocumentPreview>
      )
    }
    {!selectedFiles.length && defaultValue &&
      <DocumentPreview>
        {isImage(defaultValue.filename)
          ? (<ImageIcon color="Gray600" size={40}/>)
          : (<DocumentThin color={themeColors.Gray600} size={40}/>)
        }
        <div>
          <DownloadLink documentId={defaultValue._id} filename={defaultValue.filename}/>
          <DocumentCardFootnote document={defaultValue}/>
        </div>
      </DocumentPreview>
    }
    {errorMessageMaxFilesReached && <Error>{errorMessageMaxFilesReached}</Error>}
    {isWrongFile && <Error>{t(`documentsModals.${isWrongFile}` as I18NValues, { maxFileSizeMB, maxBunchFilesSizeMB })}</Error>}
    {errorMessage && <Error>{errorMessage}</Error>}
  </Container>;
};
