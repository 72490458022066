import { useGet } from '..';
import { API } from '#lib/network/API';
import { useCurrentOrganization } from '#/contexts/AuthProvider';
import { OrganizationIntegrationsSettingsKeys } from '../api/organizations/OrganizationIntegrationsSettings.keys';
import { useAsyncMethod } from '../useAsyncMethod';
import { useToggle } from '@zazume/zzm-base';
import { useEffect, useState } from 'react';

interface Response {
  isLoading: boolean;
  openRespondio?: () => Promise<void>;
  isEnabled?: boolean;
}

export const useRespondio = (email: string): Response => {
  const { call } = useAsyncMethod();
  const organizationId = useCurrentOrganization();
  const { value: isLoading, toggle: toggleLoading } = useToggle(false);

  const [fetchedUrl, setFetchedUrl] = useState<string | undefined>();

  useEffect(() => setFetchedUrl(undefined), [email]);

  const { data: settings } = useGet(
    OrganizationIntegrationsSettingsKeys.byOrganizationId(organizationId),
    API.organizationIntegrationsSettings.findIntegrationsSettings);

  const openRespondio = async () => call(async () => {
    if (isLoading) {
      return;
    }
    if (fetchedUrl) {
      window.open(fetchedUrl, '_blank', 'noreferrer');
      return;
    }
    toggleLoading();
    try {
      const { url } = await API.messaging.getRespondioRedirectURL(email);
      window.open(url, '_blank', 'noreferrer');
      setFetchedUrl(url);
    } finally {
      toggleLoading();
    }
  });

  if (!settings) {
    return {
      isEnabled: false,
      isLoading
    };
  }

  return {
    isEnabled: settings.isRespondioEnabled(),
    openRespondio,
    isLoading
  };
};
