import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { AnonymousRoute } from '../../components/routes/AnonymousRoute';
import { BaseRoutes } from './BaseRoutes';
import { Anonymous } from './routes/Anonymous';
import { useRouter } from '../../hooks';
import { LazyLoader } from '../../utils/LazyLoader';

const Login = LazyLoader(() => import('../../pages/login/Login.page'));
const SingleSignOn = LazyLoader(() => import('../../pages/login/SingleSignOn.page'));
const Forgot = LazyLoader(() => import('../../pages/forgot/Forgot.page'));
const NewPassword = LazyLoader(() => import('../../pages/NewPassword.page'));

/**
 * Routes with NO session user
 * @param props
 * @constructor
 */
export const AnonymousRoutes: FC = (props) => {
  const { pathname, search } = useRouter();
  const redirect = `?redirect=${pathname}${search}`;

  return <BaseRoutes>
    <AnonymousRoute path={Anonymous.HOME.route} element={<Navigate to={'/login'}/>} {...props}/>
    <AnonymousRoute path={Anonymous.LOGIN.route} element={<Login/>} {...props}/>
    <AnonymousRoute path={Anonymous.SINGLE_SIGN_ON_LOGIN.route} element={<SingleSignOn/>} {...props}/>
    <AnonymousRoute path={Anonymous.FORGOT_PASSWORD.route} element={<Forgot/>} {...props}/>
    <AnonymousRoute path={Anonymous.NEW_PASSWORD.route} element={<NewPassword/>} {...props}/>
    <AnonymousRoute path="*" element={<Navigate to={`/login${redirect}`}/>} {...props}/>
  </BaseRoutes>;
};
