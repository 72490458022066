import React, { FC } from 'react';
import { IconProps } from '.';

export const Care: FC<IconProps> = ({ color = '#FFFFFF', size = 20 }) => {
  return <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M10,15.8c-1.4,0-6.8-4.3-6.8-8.2C3.3,4,8,2.6,10,5.1c2-2.5,6.8-1.1,6.8,2.5C16.8,11.5,11.4,15.8,10,15.8z
      M7.2,5.3C6,5.3,4.8,6.2,4.8,7.5c0,3.1,4.5,6.6,5.3,6.7c0.7-0.1,5.2-3.6,5.2-6.7c0-1.4-1.3-2.2-2.5-2.2c-0.5,0-2,0.2-2,2.1
      c0,0.4-0.3,0.8-0.8,0.8S9.2,7.9,9.2,7.5c0-0.7-0.2-1.3-0.6-1.7C8.3,5.5,7.8,5.3,7.2,5.3z"
    />
  </svg>;
};
