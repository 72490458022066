import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { EventType } from './events/events';
import { User } from '../../models';
import { Config, isSegmentEnabled } from '../../lib/Config';

export default class Segment {
  private static instance: Segment;

  private analyticsModule?: Analytics;

  private isEnabled: boolean;

  private constructor() {
    this.isEnabled = true;
  }

  private static getInstance(): Segment {
    if (!this.instance) {
      this.instance = new Segment();
    }
    return this.instance;
  }

  static async initialize(user?: User) {
    const instance = this.getInstance();
    instance.isEnabled = isSegmentEnabled();
    if (!isSegmentEnabled()) {
      return;
    }

    try {
      const [response] = await AnalyticsBrowser.load({
        cdnURL: 'https://analytics.zazume.com',
        writeKey: Config.SEGMENT_KEY
      });
      instance.analyticsModule = response;

      user && Segment.updateProfile(user);
    } catch (err) {
      console.log(err);
    }
  }

  static trackPageView(pageName: string) {
    this.getInstance().trackPageView(pageName);
  }

  static trackEvent(eventName: EventType, event?: Object) {
    return this.getInstance().trackEvent(eventName, event);
  }

  static updateProfile(user: User) {
    this.getInstance().updateProfile(user);
  }

  static logout() {
    this.getInstance().logout();
  }

  static isEnabled() {
    return this.getInstance().isEnabled;
  }

  trackPageView(pageName: string) {
    if (this.isEnabled) {
      this.analyticsModule?.page({ page: pageName });
    }
  }

  trackEvent(eventName: string, event?: Object) {
    if (this.isEnabled) {
      this.analyticsModule?.track(eventName, event);
    }
  }

  updateProfile(user: User) {
    if (this.isEnabled) {
      this.analyticsModule?.identify(user._id, user);
    }
  }

  logout() {
    this.analyticsModule?.reset();
  }
}
