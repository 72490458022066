import { useI18n } from '#/hooks/useI18n';
import { API } from '#/lib/network/API';
import { ErrorCode, useNetworkExceptions } from '#/lib/network/helpers';
import { User, isZazumeUser } from '#/models';
import { FC } from 'react';
import { UpdateProfileFormInputs, getUpdateProfileSchema } from './UpdateProfile.schema';
import { FormProvider, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { Input, Textarea } from '#/components/forms';
import { PhoneField } from '../../../../components/forms/inputV2/phoneField/PhoneField';
import { SelectField } from '../../../../components/forms/select/SelectField';
import { useLangOptions } from '#/utils/language';
import { css } from '@emotion/react';
import { ZazumeModal } from '@zazume/zzm-base';
import { useYupValidationResolver } from '../../../../hooks/formResolvers/useYupValidationResolver';
import { useNotifications } from '#/hooks';

const Body = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s4};
`);

const Footer = styled.div(({ theme }) => css`
  margin-top: ${theme.spacing.lg};
`);

interface EditProfileProps {
  user: User;
  close: () => void;
  onUpdate: () => void;
}

export const EditProfile: FC<EditProfileProps> = ({ user, onUpdate, close }) => {
  const { t, changeLanguage } = useI18n();
  const langOptions = useLangOptions(false);

  const { showNotification } = useNotifications();
  const { handleNetworkExceptions } = useNetworkExceptions();

  const defaultValues = {
    name: user.name,
    surnames: user.surnames,
    phoneNumber: user.phoneNumber,
    email: user.email,
    avatar: user.avatar,
    bio: user.bio,
    videoUrl: user.videoUrl,
    lang: user.lang
  };

  const formSchema = getUpdateProfileSchema(t);
  const { resolver } = useYupValidationResolver(formSchema);
  const formMethods = useForm<UpdateProfileFormInputs>({ resolver, defaultValues });
  const {
    handleSubmit,
    register,
    setError,
    formState: { isSubmitting, errors }
  } = formMethods;

  const updateProfile = async (formData) => {
    const { avatar, ...updatedProfile } = formData;

    if (formData.lang !== user.lang) {
      changeLanguage(formData.lang);
    }

    try {
      await API.users.update(updatedProfile);
      showNotification(t('profile.modal.updated'));
      onUpdate();
      close();
    } catch (error: any) {
      if (error.errorCode === ErrorCode.USER_ALREADY_EXISTS) {
        setError('email', { type: 'custom', message: t('general.forms.emailExisting') });
      } else {
        handleNetworkExceptions(error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(updateProfile)}>
      <FormProvider {...formMethods}>
        <Body>
          <Input
            name="name"
            ref={register}
            label={t('general.name')}
            type="text"
            errors={errors.name}
          />
          <Input
            name="surnames"
            ref={register}
            label={t('profile.surnames')}
            type="text"
            errors={errors.surnames}
          />
          <PhoneField
            name="phoneNumber"
            label={t('profile.phoneNumber')}
            type="text"
          />
          <Input
            name="email"
            ref={register}
            label={t('general.email')}
            type="text"
            errors={errors.email}
          />
          <SelectField
            name="lang"
            label={t('general.language')}
            options={langOptions}
            ref={register}
            defaultValue={user.lang}
            errors={errors.lang}
          />
          {isZazumeUser(user) && <>
            <Textarea
              name="bio"
              ref={register}
              label={t('profile.bio')}
              scale="small"
            />
            <Input
              name="videoUrl"
              ref={register}
              label={t('profile.videoUrl')}
              type="text"
            />
          </>}
        </Body>

        <Footer>
          <ZazumeModal.Footer
            variant="base"
            acceptButtonType="submit"
            onCancel={close}
            acceptButtonText={t('profile.save')}
            cancelButtonText={t('buttons.cancel')}
            isSubmitting={isSubmitting}
          />
        </Footer>
      </FormProvider>
    </form>
  );
};
