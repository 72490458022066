import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { getStrokeWidth, IconProps } from './index';

export const Percentage: FC<IconProps> = ({ size = 20, color = Colors.FONT_3 }) => {
  const strokeWidth = getStrokeWidth(size);

  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        d="M19.14 4.86011L4.86022 19.1399M10.1583 10.1583C8.91138 11.4053 6.88968 11.4053 5.64273 10.1583C4.39578 8.91138 4.39578 6.88968 5.64273 5.64273C6.88968 4.39578 8.91138 4.39578 10.1583 5.64273C11.4053 6.88968 11.4053 8.91138 10.1583 10.1583ZM18.3574 18.3574C17.1105 19.6043 15.0888 19.6043 13.8418 18.3574C12.5949 17.1105 12.5949 15.0887 13.8418 13.8418C15.0888 12.5949 17.1105 12.5949 18.3574 13.8418C19.6043 15.0887 19.6043 17.1105 18.3574 18.3574Z"
    />
  </svg>;
};
