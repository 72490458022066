import { useI18n } from '#hooks/useI18n';
import { Colors } from '@zazume/zzm-base';
import { FC } from 'react';

import { DocumentState } from '../../../models';
import { Tag } from '../../tag/Tag';

export interface SigningDocumentStateTagProps {
  state: DocumentState;
  hasSignatureError?: boolean;
}

/**
 * @deprecated use zzm-base AttachedDocument component instead
 * @param state
 * @constructor
 */
export const SigningDocumentStateTag: FC<SigningDocumentStateTagProps> = ({ state, hasSignatureError }) => {
  const { t } = useI18n();

  const signingDocumentStateColors = {
    draft: {
      backgroundColor: Colors.YELLOW_LIGHT,
      textColor: Colors.YELLOW_DARK
    },
    signed: {
      backgroundColor: Colors.GREEN_LIGHT,
      textColor: Colors.GREEN_DARK
    },
    rejected: {
      backgroundColor: Colors.RED_LIGHT,
      textColor: Colors.RED
    },
    expired_signature: {
      backgroundColor: Colors.RED_LIGHT,
      textColor: Colors.RED
    }
  };

  if (hasSignatureError) {
    return <Tag {...signingDocumentStateColors.rejected} small>{t('general.error')}</Tag>;
  }

  return <Tag {...signingDocumentStateColors[state]} small>{t(`documents.signingDocumentStates.${state}`)}</Tag>;
};
