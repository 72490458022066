import React from 'react';
import styled from '@emotion/styled';
import { Colors, FCC, Fonts } from '@zazume/zzm-base';

const Container = styled.div`
  width: 100%;
  font-family: ${Fonts.BASE};
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0;
  color: ${Colors.FONT_3};
`;

/**
 * @deprecated use base
 * @param props
 * @constructor
 */
export const Headline: FCC<any> = (props) => {
  return <Container {...props}>{props.children}</Container>;
};
