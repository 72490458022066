import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getIcon, IconType, Regular, TinyArrow, ZZMColor } from '@zazume/zzm-base';
import { FC } from 'react';
import { Label } from '../inputV2/components/Label';

export const Container = styled.div<any>(({ theme, trim, isLocked, hasError }) => css`
  position: relative;
  width: 100%;
  display: flex;
  gap: ${theme.spacing.s2};
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  border-radius: ${theme.radius.lg};
  margin: ${theme.spacing.s0};
  appearance: none;
  user-select: none;
  border: ${theme.borderWeights.light} solid ${theme.colors.Gray300};
  ${hasError && `border-color: ${theme.colors.Red};`}
  ${!isLocked && 'cursor: pointer;'}
  ${isLocked && 'pointer-events: none;'}

  background: ${theme.colors.White};

  ${isLocked && `background: ${theme.colors.PrimaryLight4};`}
  ${trim &&
  `display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;`}
  & option {
    display: none;
  }

  &:hover {
    ${!isLocked && `border: ${theme.borderWeights.light} solid ${theme.colors.Primary};`}
  }

  &:focus {
    outline: none;
    border: ${theme.borderWeights.light} solid ${theme.colors.Primary};
    border-radius: ${theme.radius.lg};
  }
`);


export interface SelectHeaderProps {
  isLocked?: boolean;
  trimSelected?: boolean;
  readOnly?: boolean;
  selectedOptionName?: string;
  selectedOptionIcon?: React.ReactNode;
  label?: string;
  headerText?: string;
  headerIcon?: IconType;
  headerIconColor?: ZZMColor;
  hasError?: boolean;
  isOptional?: boolean;
  onClick?: () => void;
}

/**
 * @deprecated use zzm-base
 */
export const SelectHeader: FC<SelectHeaderProps> = ((props) => {
  const {
    isLocked = false,
    trimSelected = false,
    readOnly = false,
    hasError = false,
    selectedOptionName,
    selectedOptionIcon,
    headerIcon,
    headerIconColor,
    headerText,
    onClick,
    label,
    isOptional
  } = props;

  const baseColor: ZZMColor = Boolean(selectedOptionName) ? 'Gray500' : 'Gray400';
  const Icon = headerIcon && getIcon(headerIcon);
  const RenderedIcon = Icon ? <Icon color={headerIconColor || baseColor} size={24}/> : null;
  const headerColor = (isLocked || readOnly || !selectedOptionName) ? 'Gray400' : 'Gray600';

  const handleOnClick = () => {
    if (!isLocked) {
      onClick?.();
    }
  };

  return (<>
    {label && <Label isOptional={isOptional}>{label}</Label>}
    <Container
      isLocked={isLocked || readOnly}
      hasError={hasError}
      isUsingPlaceholder={!Boolean(selectedOptionName)}
      trim={trimSelected}
      onClick={handleOnClick}
    >
      {selectedOptionIcon ?? RenderedIcon ?? null}
      <Regular color={headerColor}>{selectedOptionName || headerText}</Regular>
      <TinyArrow color="Gray400" size={16}/>
    </Container>
  </>);

});
