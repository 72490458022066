import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Regular } from '@zazume/zzm-base';

const Container = styled.div`
  margin-right: 16px;
  user-select: none;
`;

interface OrganizationLabelProps {
  organizationName: string;
}

export const OrganizationLabel: FC<OrganizationLabelProps> = ({ organizationName }) => {
  return <Container>
    <Regular color="Gray500">{organizationName}</Regular>
  </Container>;
};
