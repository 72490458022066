import { FCC } from '@zazume/zzm-base';
import React from 'react';
import { useAuth } from '../../contexts/AuthProvider';
import { BooleanHelper } from '@zazume/zzm-utils';

interface CanInDefaultOrganizationProps {
  if?: boolean;
}

export const CanInDefaultOrganization: FCC<CanInDefaultOrganizationProps> = (props) => {
  const { organization } = useAuth();

  if (BooleanHelper.isFalse(props.if)) {
    return null;
  }

  if (!organization?.isDefaultOrganization) {
    return null;
  }

  return <>{props.children}</>;
};
