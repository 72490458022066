import { useI18n } from '#hooks/useI18n';
import React, { forwardRef, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AutocompletionRequest, GooglePlacesAutocompleteProps } from './GooglePlacesAutocomplete.types';
import autocompletionRequestBuilder from './autocompletionRequestBuilder';
import { Loader } from '@googlemaps/js-api-loader';
import { Input } from '../forms';
import { SelectOption } from '../forms/select/SelectOption';
import styled from '@emotion/styled';
import { Colors, Shadows } from '@zazume/zzm-base';

import { containsNumbers, isNotEmpty } from '@zazume/zzm-utils';

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Suggestions = styled.div`
  position: absolute;
  max-width: 320px;
  max-height: 248px;
  background-color: ${Colors.BACKGROUND_0};
  background-color: ${Colors.BACKGROUND_0};
  min-width: 160px;
  box-shadow: ${Shadows.SHADOW_7};
  z-index: 1;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  border-radius: 8px;
`;

/**
 * Inspired by https://github.com/Tintef/react-google-places-autocomplete
 */
/** @deprecated Use ZZMGooglePlacesAutocomplete instead */
const GooglePlacesAutocomplete: React.ForwardRefRenderFunction<any, GooglePlacesAutocompleteProps> = ({
  apiKey = '',
  autocompletionRequest = {},
  debounce = 300,
  minLengthAutocomplete = 0,
  selectProps = {},
  withSessionToken = false,
  setValue,
  onChangeFullAddress,
  setErrors,
  clearErrors,
  addressWithoutNumber = false,
  isOptional = false,
  placeIdName,
  ...rest
}: GooglePlacesAutocompleteProps, ref): React.ReactElement => {
  const { t } = useI18n();
  const [placesService, setPlacesService] = useState<any | undefined>(undefined);
  const [sessionToken, setSessionToken] = useState<any | undefined>(undefined);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [selection, setSelection] = useState<null | any>({});

  const { name } = rest;

  const fetchSuggestions = useDebouncedCallback((value: string, cb: (options: any) => void): void => {
    if (!placesService) {
      return cb([]);
    }
    if (value.length < minLengthAutocomplete) {
      return cb([]);
    }

    const autocompletionReq: AutocompletionRequest = { ...autocompletionRequest };

    placesService.getPlacePredictions(
      autocompletionRequestBuilder(
        autocompletionReq,
        value,
        withSessionToken && sessionToken
      ), (suggestions) => {
        cb((suggestions || []).map(suggestion => ({ label: suggestion.description, value: suggestion })));
      }
    );
  }, debounce);

  const initializeService = () => {
    // @ts-ignore
    if (!window.google) {
      console.error('Google script not loaded');
      return;
    }
    // @ts-ignore
    if (!window.google.maps) {
      console.error('Google maps script not loaded');
      return;
    }
    // @ts-ignore
    if (!window.google.maps.places) {
      console.error('Google maps places script not loaded');
      return;
    }

    // @ts-ignore
    setPlacesService(new window.google.maps.places.AutocompleteService());
    // @ts-ignore
    setSessionToken(new google.maps.places.AutocompleteSessionToken());
  };

  useEffect(() => {
    const init = async () => {
      try {
        await new Loader({ apiKey, ...{ libraries: ['places'] } }).load();
        initializeService();
      } catch (error) {
        console.error(error);
      }
    };

    if (apiKey) {
      init();
    } else {
      initializeService();
    }
  }, [apiKey]);

  const validateInput = (value: string) => {
    if (value !== selection?.value?.description) {
      setErrors(name,
        { type: 'required', message: t('general.forms.mandatoryGooglePlacesSelection') }
      );
    }

    if (!addressWithoutNumber && !containsNumbers(value)) {
      setErrors(
        name,
        { type: 'required', message: t('general.forms.addressNumberMandatory') }
      );
    }

    if (isOptional && !isNotEmpty(value)) {
      clearErrors(name);
    }
  };

  const inputHandler = (value: string) => {
    validateInput(value);

    fetchSuggestions(value, (options) => {
      setSuggestions(options);
      if (!options.length) {
        setErrors(name, {
          type: 'required', message: t('general.forms.invalidGooglePlacesSelection')
        });
      }
    });
  };

  const handleOnClickOption = option => () => {
    setSuggestions([]);
    setSelection(option);
    if (!addressWithoutNumber && !containsNumbers(option.value.description)) {
      setErrors(name, { type: 'required', message: t('general.forms.addressNumberMandatory') });
    } else {
      clearErrors && clearErrors(name);
    }
    onChangeFullAddress && onChangeFullAddress(option.label);
    setValue(name, option.label, { shouldDirty: true });
    placeIdName && setValue(placeIdName, option.value?.place_id, { shouldDirty: true });
  };

  return (
    <Container>
      {placeIdName && <input type="hidden" name={placeIdName} ref={ref}/>}
      <Input ref={ref} type="text" placeholder={t('general.forms.typeAndSelect')} onChange={inputHandler} autoComplete="off" defaultValue={selection?.label} onBlur={validateInput} isOptional={isOptional} {...rest}/>
      <Suggestions>
        {suggestions.map(suggestion =>
          <SelectOption key={suggestion.value.place_id}
              value={suggestion.value}
              name={suggestion.label}
              onClick={handleOnClickOption(suggestion)}>
            {suggestion.label}
          </SelectOption>
        )}
      </Suggestions>
    </Container>
  );
};

export default forwardRef(GooglePlacesAutocomplete);
