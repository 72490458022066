import { RadioGroup } from '#/components/forms/inputsBase';
import { useAuth } from '#/contexts/AuthProvider';
import { useI18n } from '#/hooks/useI18n';
import { I18NValues } from '#/lib/i18n/generatedKeys';
import { ManagementService } from '#/models';
import { ZazumeServicesHelper } from '#/models/helpers/zazumeServices/ZazumeServicesHelper';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex, Info, Regular } from '@zazume/zzm-base';
import { PriceVO } from '@zazume/zzm-utils';
import { FC } from 'react';

const ServiceContainer = styled(Flex)(({ theme }) => css`
  position: relative;
  width: fit-content;
`);

const InfoContainer = styled.div(({ theme }) => css`
  position: absolute;
  right: -1.5rem;
  top: 0.20rem;
`);

interface FormContentProps {
  paidServices: ManagementService[];
  onClickInfo: (service) => any;
  hasUnitSelected: boolean;
  monthlyRent: PriceVO;
}

export const FormContent: FC<FormContentProps> = ({ paidServices, onClickInfo, hasUnitSelected, monthlyRent }) => {
  const { t } = useI18n();
  const { organization } = useAuth();

  if (!organization) {
    return null;
  }

  return (
    <Flex direction="column" gap="md">
      <Flex direction="column" gap="xs">
        <Regular>{t('modals.requestServiceUpgrade.selectService')}</Regular>
      </Flex>
      <RadioGroup name="service" direction="column">
        {paidServices?.map(service =>
          <RadioGroup.Radio value={service.type} disabled={!hasUnitSelected}>
            <Flex direction="column" gap="s2">
              <ServiceContainer gap="s3">
                {t(`zazumeServices.${service.type}.name` as I18NValues)}
                <InfoContainer onClick={onClickInfo(service.type)}>
                  <Info color="Primary" size={16}/>
                </InfoContainer>
              </ServiceContainer>
              <Regular variant="XS" color="Gray400">
                {t('modals.requestServiceUpgrade.pricing', {
                  percentage: service.managementFee.value,
                  amount: new ZazumeServicesHelper(organization, service.type).getManagementPrice(monthlyRent).toString()
                })}
              </Regular>
            </Flex>
          </RadioGroup.Radio>
        )}
      </RadioGroup>
    </Flex>
  );
};
