import { useI18n } from '#hooks/useI18n';
import { BasePhoneInput, BasePhoneInputProps, getFieldError } from '@zazume/zzm-base';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

export interface PhoneInputProps extends Omit<BasePhoneInputProps, 'defaultValue' | 'onChangeValue'> {
  name: string;
  isOptional?: boolean;
}

export const PhoneInput: FC<PhoneInputProps> = ({ name, isOptional, responsive = false, ...props }) => {
  const { t } = useI18n();
  const { register, formState: { errors }, getValues, setValue } = useFormContext();

  const error = getFieldError(name, errors);

  const defaultValue = getValues(name);
  const onChangeValue = (number: string) => setValue(name, number, { shouldValidate: true });

  const optionalText = isOptional ? t('general.optional') : undefined;

  return (<>
    <BasePhoneInput
      {...props as any}
      ref={register}
      optionalText={optionalText}
      responsive={responsive}
      onChangeValue={onChangeValue}
      defaultValue={defaultValue}
      error={error}
    />
    <input ref={register} name={name} hidden readOnly/>
  </>);
};
