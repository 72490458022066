import { FC } from 'react';
import { IdentifierDTO, Pencil, Regular, Table, Tag } from '@zazume/zzm-base';
import { useI18n } from '#hooks/useI18n';
import { InvestmentAlert } from '#/models/investment/alert/InvestmentAlert';

interface AlertShortTableItemProps {
  alert: InvestmentAlert;
  onEditAlert: (task: InvestmentAlert) => void;
  onClick?: (id: IdentifierDTO) => void;
}

export const AlertShortTableItem: FC<AlertShortTableItemProps> = ({ alert, onClick, onEditAlert }) => {
  const { t, languageVO } = useI18n();

  const handleOnClick = (id: IdentifierDTO) => () => onClick?.(id);
  const handleEditTask = (alert: InvestmentAlert) => () => onEditAlert(alert);

  return (
    <Table.Short.Item key={alert._id} item={alert} onClick={handleOnClick(alert._id)}>
      <Table.Short.Header label={alert.location.city.name}>
        <Tag variant={alert.isActive ? 'green' : 'white'}>{alert.isActive ? t('investment.myAlerts.table.status.active') : t('investment.myAlerts.table.status.disabled')}</Tag>
        <Table.Short.Options>
          <Table.Short.Options.Item onClick={handleEditTask(alert)}>
            <Pencil color="Gray500" size={20}/>
            <Regular color="Gray600" variant="S">{t('investment.myAlerts.table.editAlert')}</Regular>
          </Table.Short.Options.Item>
        </Table.Short.Options>
      </Table.Short.Header>
      <Table.Short.Body>
        <Table.Short.PriceField title={t('investment.myAlerts.table.header.maxBudget')} field="maxBudget" language={languageVO}/>
        <Table.Short.CustomField title={t('investment.myAlerts.table.header.profitability')}>
          <Regular color="Gray500" variant="S">{alert.profitability}%</Regular>
        </Table.Short.CustomField>
        <Table.Short.CustomField title={t('investment.myAlerts.table.header.channel')}>
          <Regular color="Gray500" variant="S">{t(`investment.myAlerts.table.channel.${alert.channel}`)}</Regular>
        </Table.Short.CustomField>
      </Table.Short.Body>
    </Table.Short.Item>
  );
};
