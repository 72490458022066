import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { useI18n } from '../../../hooks/useI18n';
import { Signer } from './Signer';
import { Divider, Flex, Plus, Semibold } from '@zazume/zzm-base';
import { DocumentSignatureConfiguration } from '../documentSignatureConfiguration/DocumentSignatureConfiguration';
import { SignerType } from '#/models/Contract';

const show = keyframes`
  0% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0);
  }
`;

const SignerContainer = styled(Flex)`
  animation: ${show} .5s forwards;
`;

const AddSignerContainer = styled.div(({ theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.s2};
  cursor: pointer;
  width: fit-content;
`);

interface SignersBulkProps {
  name?: string
  withConfiguration?: boolean;
  type?: SignerType;
}

export const SignersBulk: FC<SignersBulkProps> = ({ name = 'signers', withConfiguration = true, type }) => {
  const { t } = useI18n();

  const [signersCount, setSignersCount] = useState<number>(1);

  const onAddSigner = () =>
    setSignersCount(signersCount + 1);

  return (
    <Flex direction="column" gap="sm">
      {[...Array(signersCount)].map((_, index) =>
        <SignerContainer key={index} direction="column" gap="xs">
          <Semibold>{t('signatures.signerCount', { number: index + 1 })}</Semibold>
          <Signer fieldPrefix={`${name}[${index}]`} isBulk={true} type={type}/>
        </SignerContainer>
      )}
      <AddSignerContainer onClick={onAddSigner}>
        <Plus color="Primary"/>
        <Semibold variant="S" color="Primary">{t('signatures.addAnotherSigner')}</Semibold>
      </AddSignerContainer>
      <Divider/>
      {withConfiguration && <DocumentSignatureConfiguration name="signatureConfiguration"/>}
    </Flex>
  );
};
