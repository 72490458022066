import { isGoogleAnalyticsEnabled } from '../../lib/Config';
import ReactGA from 'react-ga4';
import { isOwnerUser, isZazumeUser, User } from '../../models';

export default class GoogleAnalytics {
  static startGoogleAnalytics(user?: User) {
    if (isGoogleAnalyticsEnabled()) {
      ReactGA.initialize('G-MFKDTKF48B');
      user && GoogleAnalytics.setUser(user);
    }
  }

  static stopGoogleAnalytics() {
    if (isGoogleAnalyticsEnabled()) {
      ReactGA.reset();
    }
  }

  static setUser(user: User) {
    if (isGoogleAnalyticsEnabled()) {
      ReactGA.set({ 'userId': user._id });

      if (isOwnerUser(user)) {
        ReactGA.set({ 'role': 'owner' });
      } else if (isZazumeUser(user)) {
        ReactGA.set({ 'role': 'zazume' });
      } else {
        ReactGA.set({ 'role': 'propertyManager' });
      }
    }
  }

  static sendPageView(path: string) {
    if (isGoogleAnalyticsEnabled()) {
      ReactGA.send({ hitType: 'pageview', page: path });
    }
  }
}
