import { FC } from 'react';
import { AvatarWithName, ClipboardHelper, Copy, Delete, Regular, SecondaryButton, Semibold, StateTag, Table, TertiaryButton } from '@zazume/zzm-base';
import { useI18n } from '#/hooks/useI18n';
import { DocumentUploadLink } from '#/models/documentUploadLink/DocumentUploadLink';
import { useDelete, useNotifications } from '#/hooks';
import { I18NValues } from '#/lib/i18n/generatedKeys';
import { API } from '#/lib/network/API';
import { useAsyncMethod } from '#/hooks/useAsyncMethod';

interface DocumentUploadLinkTableItemProps {
  uploadLink: DocumentUploadLink;
  type: 'short' | 'base';
  queryKey: string[];
}

export const DocumentUploadLinkTableItem: FC<DocumentUploadLinkTableItemProps> = ({ uploadLink, type, queryKey }) => {
  const { t, language } = useI18n();
  const { call } = useAsyncMethod();
  const { showNotification } = useNotifications();

  const { mutate: deleteLink, isLoading } = useDelete(queryKey, API.documentUploadLinks.delete);

  const copyToClipboard = (): void => {
    ClipboardHelper.writeText(uploadLink.url);
    showNotification(t('tenants.detail.preScoringLinkButtonSuccess'));
  };

  const handleDelete = () => call(async () => {
    await deleteLink(uploadLink._id);
    showNotification(t('general.notifications.deleteSuccess'));
  });

  const documentType = t(`documents.types.${uploadLink.type}` as I18NValues);
  const expiresAt = uploadLink.expiresAt.format('datetimeMinutes', language);
  const isExpired = uploadLink.isExpired();
  const tagVariant = uploadLink.getStatusTag();
  const tagName = uploadLink.getStatusName(t);
  const createdByName = uploadLink.createdBy.getFullName();

  if (type === 'short') {
    return (
      <Table.Short.Item item={uploadLink} >
        <Table.Short.Header label="" tagName={tagName} tagVariant={tagVariant}/>
        <Table.Short.Field title={t('documentUploadLink.fields.link')} field="slug"/>
        <Table.Short.CustomField title={t('documentUploadLink.fields.subcategory')}>{documentType}</Table.Short.CustomField>
        <Table.Short.CustomField title={t('documentUploadLink.fields.createdBy')}>
          <AvatarWithName user={{ fullName: createdByName, avatar: uploadLink.createdBy.avatar, extraContent: '' }}/>
        </Table.Short.CustomField>
        <Table.Short.CustomField title={t('documentUploadLink.fields.expiresOn')}>{expiresAt}</Table.Short.CustomField>
        <Table.Short.CustomField title="">
          {isExpired
            ? <TertiaryButton size="Medium" onClick={handleDelete} isSubmitting={isLoading} noWrapText>
              <Delete size={16} color="Gray500"/>{t('general.delete')}
            </TertiaryButton>
            : <SecondaryButton size="Medium" onClick={copyToClipboard} noWrapText>
              <Copy size={16} color="Primary"/>{t('buttons.copyLink')}
            </SecondaryButton>
          }
        </Table.Short.CustomField>
      </Table.Short.Item>
    );
  }

  return (
    <Table.Base.Item item={uploadLink}>
      <Table.Base.CustomField>
        <Semibold variant="S">{uploadLink.slug}</Semibold>
      </Table.Base.CustomField>
      <Table.Base.CustomField>
        <Regular variant="S" color="Gray500">{documentType}</Regular>
      </Table.Base.CustomField>
      <Table.Base.AvatarField avatar={uploadLink.createdBy.avatar} name={createdByName}/>
      <Table.Base.CustomField>
        <Regular variant="S" color="Gray500">{expiresAt}</Regular>
      </Table.Base.CustomField>
      <Table.Base.CustomField>
        <StateTag variant={tagVariant} text={tagName}/>
      </Table.Base.CustomField>
      <Table.Base.CustomField>
        {isExpired
          ? <TertiaryButton size="Medium" onClick={handleDelete} noWrapText>
            <Delete size={16} color="Gray500"/>{t('general.delete')}
          </TertiaryButton>
          : <SecondaryButton size="Medium" onClick={copyToClipboard} noWrapText>
            <Copy size={16} color="Primary"/>{t('buttons.copyLink')}
          </SecondaryButton>
        }
      </Table.Base.CustomField>
    </Table.Base.Item>
  );
};
