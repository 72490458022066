import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import { IconProps, getStrokeWidth } from './index';

/**
 * @deprecated use zzm-base
 * @param color
 * @param size
 */
export const Home: FC<IconProps> = ({ color = Colors.WHITE, size = 20 }) => {
  const strokeWidth = getStrokeWidth(size);
  return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" d="M18.828 10.2902V19.9339C18.828 20.4862 18.3803 20.9339 17.828 20.9339H6.17241C5.62012 20.9339 5.17241 20.4862 5.17241 19.9339V10.2902M12.0002 11.9025V16.9025M21.3452 13.0803L12.7427 3.54511C12.3456 3.10492 11.6549 3.10492 11.2577 3.54511L2.65527 13.0803"/>
  </svg>;
};
