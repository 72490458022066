import React, { FC } from 'react';
import { Flex, Regular, ZazumeModal } from '@zazume/zzm-base';
import { useI18n } from '../../../hooks/useI18n';
import { Textarea } from '../../forms';
import { useYupValidationResolver } from '../../../hooks';
import { useForm } from 'react-hook-form';
import { getRejectConfirmationSchema, RejectConfirmationFormInputs } from '../RejectConfirmationModal.schema';
import { useNetworkExceptions } from '../../../lib/network/helpers';

export interface RejectConfirmationModalProps {
  close: () => void;
  onReject: (reason: object) => Promise<any>;
  onAfterReject?: () => Promise<void>;
}

export const RejectConfirmationModal: FC<RejectConfirmationModalProps> = ({ close, onReject, onAfterReject }) => {
  const { t } = useI18n();
  const { handleNetworkExceptions } = useNetworkExceptions();

  const formSchema = getRejectConfirmationSchema(t);
  const { resolver } = useYupValidationResolver(formSchema);
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors }
  } = useForm<RejectConfirmationFormInputs>({ resolver });

  const onRejectContract = async (formData: RejectConfirmationFormInputs) => {
    try {
      await onReject(formData);
      await onAfterReject?.();
    } catch (error) {
      handleNetworkExceptions(error);
    }
    close();
  };

  return (
    <ZazumeModal title={t('documents.confirmRejectModal.rejectTitle')} onClose={close}>
      <Flex direction="column" gap="sm">
        <Regular variant="S" color="Gray500">{t('documents.confirmRejectModal.description')}</Regular>
        <Textarea name="description" label={t('documents.confirmRejectModal.rejectLabel')} ref={register} errors={errors.description}/>
      </Flex>
      <ZazumeModal.Footer
        onConfirm={handleSubmit(onRejectContract)}
        acceptButtonText={t('documents.confirmRejectModal.confirmButton')}
        onCancel={close}
        cancelButtonText={t('buttons.cancel')}
        variant="decline"
        isSubmitting={isSubmitting}
      />
    </ZazumeModal>
  );
};
