import React, { FC } from 'react';

import { useAuth } from '../../contexts/AuthProvider';
import { useUsetiful } from '#hooks/useUsetiful';
/*import { Colors } from '@zazume/zzm-base';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { useWindowWidth } from '../../hooks/useWindowSize';
*/

export const OtherScriptsContainer: FC = () => {

  const { user } = useAuth();

  useUsetiful(user);

  /*
  const { colors: themeColors } = useTheme();

  const width = useWindowWidth();
  const launcherWidth = 50;
  const marginLeft = 40;

  const chatBotSettings = {
    color: {
      theme: themeColors.Primary
    },
    offset: {
      mobile: {
        horizontal: `${width - launcherWidth - marginLeft}px`
      }
    },
    launcher: {
      mobile: {
        labelVisible: false
      }
    },
    position: {
      horizontal: 'right',
      vertical: 'bottom'
    }
  };

  const zendeskLoaded = () => {
    ZendeskAPI('webWidget', 'identify',
      {
        'name': user?.name,
        'email': user?.email,
        'external_id': user?._id
      }
    );
  };

  /*if (isLogged() && isOwner) {
    return <>
      <Zendesk zendeskKey="6441f85b-7850-448c-832e-9e597104db21" { ...chatBotSettings } onLoaded={() => zendeskLoaded()}/>
    </>;
  }*/

  return <></>;
};
