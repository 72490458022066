import React, { FC } from 'react';
import { BaseRoutes } from './BaseRoutes';
import { PublicRoute } from '../../components/routes/PublicRoute';
import { Public } from './routes/Public';
import { LazyLoader } from '../../utils/LazyLoader';

const DirectDebitDataConfirmation = LazyLoader(() => import('../../pages/DirectDebitDataConfirmation.page'));
const DownloadApp = LazyLoader(() => import('../../pages/DownloadApp.page'));
const Checkout = LazyLoader(() => import('../../pages/accounting/payments/Checkout.page'));
const BookingReview = LazyLoader(() => import('../../pages/public/bookingReview/BookingReview.page'));
const ViewingReview = LazyLoader(() => import('../../pages/ViewingReview.page'));
const AgentViewingFeedback = LazyLoader(() => import('../../pages/public/agentViewingFeedback/AgentViewingFeedback.page'));
const Signup = LazyLoader(() => import('../../pages/signup/Signup.page'));
const AutoSignup = LazyLoader(() => import('../../pages/autosignup/AutoSignup.page'));
const Logout = LazyLoader(() => import('../../pages/Logout.page'));

/**
 * Impersonal routes, we don't use the session user
 * Normally, we use a URL token to know the user
 * @constructor
 */
export const PublicRoutes: FC = (props) => {
  return <BaseRoutes>
    <PublicRoute path={Public.SIGNUP.route} element={<Signup/>} {...props}/>
    <PublicRoute path={Public.SIGNUP_WITH_SLUG.route} element={<AutoSignup/>} {...props}/>
    <PublicRoute path={Public.DIRECT_DEBIT_DATA_CONFIRMATION.route} element={<DirectDebitDataConfirmation/>} {...props}/>
    <PublicRoute path={Public.DOWNLOAD_APP.route} element={<DownloadApp/>} {...props}/>
    <PublicRoute path={Public.LOGOUT.route} element={<Logout/>} {...props}/>
    <PublicRoute path={Public.PAYMENTS_CHECKOUT.route} element={<Checkout/>}{...props}/>
    <PublicRoute path={Public.REVIEW.route} element={<BookingReview/>}{...props}/>
    <PublicRoute path={Public.REVIEW_VIEWING.route} element={<ViewingReview/>}{...props}/>
    <PublicRoute path={Public.AGENT_VIEWING_FEEDBACK.route} element={<AgentViewingFeedback/>}{...props}/>
  </BaseRoutes>;
};
