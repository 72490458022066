import { FCC } from '@zazume/zzm-base';
import { FC } from 'react';
import { RadioInput as BaseRadioInput, RadioProps } from '../../atoms/radio/RadioInput';
import { useRadioGroupContext } from '../RadioGroupContext';

type RadioInputProps = Omit<RadioProps, 'name'>;
export type RadioInputTemplate = FCC<RadioInputProps>;

/**
 * @deprecated use RadioBase from zzm-base
 */
export const RadioInput: FC<RadioInputProps> = (props) => {
  const { name } = useRadioGroupContext();
  return <BaseRadioInput name={name} {...props}/>;
};
