import { Flex, Regular, Table } from '@zazume/zzm-base';
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useI18n } from '#hooks/useI18n';

const Container = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.s7};
`);

type EmptyTableProps = {
  headerTitles: string[];
};

export const EmptyTable: FC<EmptyTableProps> = ({ headerTitles }) => {
  const { t } = useI18n();

  return <Table>
    <Container>
      <Table.Short.Item.Skeleton>
        <Table.Short.Body>
          <Flex justify="center" align="center" padding="xl">
            <Regular variant="S">{t('investment.myAlerts.table.emptyState.description')}</Regular>
          </Flex>
        </Table.Short.Body>
      </Table.Short.Item.Skeleton>
    </Container>
    <Table.Base>
      <Table.Base.Header>
        {headerTitles ? headerTitles.map(title => <Table.Base.Title key={title}>{title}</Table.Base.Title>) : null}
      </Table.Base.Header>
      <Table.Base.Body>
        <Table.Base.CustomItem>
          <Flex justify="center" align="center" padding="xl">
            <Regular variant="S">{t('investment.myAlerts.table.emptyState.description')}</Regular>
          </Flex>
        </Table.Base.CustomItem>
      </Table.Base.Body>
    </Table.Base>
  </Table>;
};
