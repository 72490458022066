import React, { FC } from 'react';
import { Colors } from '@zazume/zzm-base';
import styled from '@emotion/styled';

const SVG = styled.svg`
  cursor: pointer;
`;

interface CrossProps {
  color?: string;
  onClick?: () => void;
  size?: number;
}

/**
 * @deprecated use base
 * @param color
 * @param onClick
 * @param size
 * @constructor
 */
export const Cross: FC<CrossProps> = ({ color = Colors.FONT_3, onClick, size = 24 }) => {
  return <SVG onClick={onClick} width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
    <title>Cross icon</title>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9393 12L4.26965 5.33034L5.33031 4.26968L12 10.9394L18.6697 4.26969L19.7303 5.33035L13.0606 12L19.7303 18.6697L18.6696 19.7303L12 13.0607L5.33033 19.7303L4.26967 18.6697L10.9393 12Z"/>
  </SVG>;
};
