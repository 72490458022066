import { Storage } from '#/utils/Storage';

export const CURRENT_VERSION = 1;

export type State = {
  /**
   * Version of the navigation state.
   * If the version changes, the state is reset.
   */
  version: number,
  sectionGroups: {
    [key: string]: {
      open: boolean;
    }
  },
  /**
   * The aside navigation is collapsed.
   */
  isCollapsed: boolean;
};

type Action =
  | { type: 'TOGGLE_SECTION_GROUP', sectionId: string }
  | { type: 'TOGGLE_COLLAPSE_NAVIGATION' }
  | { type: 'SET_COLLAPSE_NAVIGATION', newValue: boolean }

const saveStateToStorage = (state: State) => {
  Storage.navigation.saveRawState(JSON.stringify(state));
};

const toggleSectionGroupReduce = (state: State, action) => {
  const newState = {
    ...state,
    sectionGroups: {
      ...state.sectionGroups,
      [action.sectionId]: {
        ...(state.sectionGroups[action.sectionId] || {}),
        open: !Boolean(state.sectionGroups?.[action.sectionId]?.open)
      }
    }
  };
  saveStateToStorage(newState);
  return newState;
};

const toggleCollapseNavigatorReduce = (state: State) => {
  const newState = {
    ...state,
    isCollapsed: !state.isCollapsed
  };
  saveStateToStorage(newState);
  return newState;
};

const setCollapseNavigationReduce = (state: any, action: { type: 'SET_COLLAPSE_NAVIGATION'; newValue: boolean }) => {
  const newState = {
    ...state,
    isCollapsed: action.newValue
  };
  saveStateToStorage(newState);
  return newState;
};

export const reducer = (state: any, action: Action): State => {
  switch (action.type) {
    case 'TOGGLE_SECTION_GROUP':
      return toggleSectionGroupReduce(state, action);
    case 'TOGGLE_COLLAPSE_NAVIGATION':
      return toggleCollapseNavigatorReduce(state);
    case 'SET_COLLAPSE_NAVIGATION':
      return setCollapseNavigationReduce(state, action);
    default:
      return state;
  }
};
