import { TFunction } from '#/hooks/useI18n';
import { IdentifierDTO, Variants } from '@zazume/zzm-base';
import { DateVO, assertUnreachable } from '@zazume/zzm-utils';

export const InvitationStatuses = <const>['sent', 'pending', 'signed'];
export type InvitationStatus = typeof InvitationStatuses[number];

export const InvitationOrigins = <const>['link', 'email'];
export type InvitationOrigin = typeof InvitationOrigins[number];

interface InvitationReceiver {
  email: string;
  userId?: IdentifierDTO;
}

interface ConstructorParams {
  from: IdentifierDTO;
  receiver: InvitationReceiver;
  status: InvitationStatus;
  origin: InvitationOrigin;
  createdAt: DateVO;
}

export class Invitation {
  from: IdentifierDTO;
  receiver: InvitationReceiver;
  status: InvitationStatus;
  origin: InvitationOrigin;
  createdAt: DateVO;

  constructor(params: ConstructorParams){
    this.from = params.from;
    this.receiver = params.receiver;
    this.status = params.status;
    this.origin = params.origin;
    this.createdAt = params.createdAt;
  }

  static fromRaw(invitation: any): Invitation {
    return new Invitation({
      from: invitation.from,
      receiver: invitation.receiver,
      status: invitation.status,
      origin: invitation.origin,
      createdAt: DateVO.fromPrimitive(invitation.createdAt)
    });
  }

  getExpirationDate(): DateVO {
    return this.createdAt.add(3, 'month');
  }

  isExpired(): boolean {
    return this.getExpirationDate().isBefore(new DateVO());
  }

  getStatusTagVariant(): Variants {
    if (this.isExpired()) {
      return 'error';
    }
    switch (this.status) {
      case 'pending':
        return 'primary';
      case 'sent':
        return 'warning';
      case 'signed':
        return 'success';
      default:
        assertUnreachable(this.status);
    }
  }

  getStatusName(t: TFunction): string {
    if (this.isExpired()) {
      return t('general.expired');
    }
    switch (this.status) {
      case 'pending':
        return t('general.pending');
      case 'sent':
        return t('general.sent');
      case 'signed':
        return t('general.signed');
      default:
        assertUnreachable(this.status);
    }
  }
}
