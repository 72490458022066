import React, { FC } from 'react';
import { SectionProps } from '#/containers/asideNavigation/Sections';
import { useI18n } from '#hooks/useI18n';
import { Can } from '#/components/roles/Can';
import { NavigationLink } from '#/containers/asideNavigation/components/NavigationLink';
import { Private } from '#/containers/router/routes/Private';

interface InvoicesProps extends SectionProps {
}

export const Invoices: FC<InvoicesProps> = ({ onClickItem, depth, isMobile }) => {
  const { t } = useI18n();
  return <Can hasRoles={['zazume', 'admin', 'buildingManager', 'ownerBasic', 'ownerMiddle', 'ownerManager']}>
    <NavigationLink to={Private.INVOICES.route} icon="lease" title={t('invoices.title')} onClick={onClickItem} depth={depth} isMobile={isMobile}/>
  </Can>;
};
