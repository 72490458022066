import React, { FC } from 'react';
import { Flex, Regular, Semibold } from '@zazume/zzm-base';
import { I18NValues } from '#lib/i18n/generatedKeys';
import { DocumentType } from '#/models';
import { useI18n } from '#hooks/useI18n';

interface FileTypeProps {
  type: DocumentType;
}

export const FileType: FC<FileTypeProps> = ({ type }) => {
  const { t } = useI18n();
  return <Flex direction="column" gap="s2">
    <Regular variant="XS" color="Gray500">{t('documents.type')}</Regular>
    <Semibold variant="S">{t(`documents.types.${type}` as I18NValues)}</Semibold>
  </Flex>;
};
