import { FCC } from '@zazume/zzm-base';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Background = styled.div(({ theme }) => css`
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  z-index: ${theme.zIndices.i10};
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-x: hidden;
  overflow-y: auto;
`);

const Container = styled.div<any>(({ theme, transparent }) => css`
  position: relative;
  display: block;
  min-width: 400px;
  height: fit-content;
  z-index: 100;
  background: ${transparent ? 'transparent' : theme.colors.White};
  border-radius: 8px;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.05));
`);

const ScrollableContent = styled.div`
  display: flex;
  align-items: center;
  margin: 1.75rem auto;
  width: fit-content;
  min-height: calc(100% - 3.5rem);
`;

interface ModalProps {
  close: () => void;
  transparent?: boolean;
  children?: React.ReactNode;
}

/**
 * @deprecated use zzm-base modals
 */
export const Modal: FCC<ModalProps> = React.memo<ModalProps>(({ children, transparent, close }) => {
  const domEL = document.getElementById('modal');

  if (!domEL) {
    return null;
  }

  return ReactDOM.createPortal(<Background>
    <ScrollableContent>
      <Container transparent={transparent}>
        {children}
      </Container>
    </ScrollableContent>
  </Background>, domEL);
});
