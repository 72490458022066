import { QueryKey, RefetchOptions } from 'react-query';
import { Fetcher, useGet, UseGetOptions } from './useGet';
import { useURLParams } from '../useURLParams';
import { useState } from 'react';
import { SortDir } from '../useSort';

export const DEFAULT_TABLE_LENGTH = 15;
export const DEFAULT_STARTING_PAGE = 1;

export type PaginationOptions = {
  page?: number;
  size: number;
  last?: string;
  urlLinked?: boolean;
  urlParam?: string;
  sort?: string;
  dir?: SortDir;
}

export type PaginationFetcher<T> = (options: PaginationOptions) => Fetcher<T>;

export type UsePaginationOptions = Partial<PaginationOptions> & UseGetOptions;

interface UseAPIResult<T> {
  data?: T;
  isLoading: boolean;
  error: any;
  refetch: (options?: RefetchOptions) => Promise<any>;
  nextPage: () => void;
  previousPage: () => void;
  toPage: (newPage: number) => void;
  currentPage: number;
  usedQueryKey: QueryKey;
}

/**
 * @deprecated use src/lib/reactQuery/hooks/usePagination.ts instead
 * @param uniqueKey
 * @param paginationFetcher
 * @param options
 */
// TODO change UniqueKey to unknown[] (migration to the new version of react-query)
export const usePagination = <T extends any>(uniqueKey: QueryKey, paginationFetcher: PaginationFetcher<T>, options: UsePaginationOptions = {}): UseAPIResult<T> => {
  const { getParam, setParam } = useURLParams();
  const { page, size = DEFAULT_TABLE_LENGTH, urlLinked = true, urlParam = 'page', sort, dir, ...rest } = options;

  const currentURLPage = urlLinked ? Number(getParam(urlParam)) : undefined;

  const [currentPage, setCurrentPage] = useState<number>(page || currentURLPage || DEFAULT_STARTING_PAGE);

  if (currentURLPage !== currentPage) {
    currentURLPage && setCurrentPage(currentURLPage);
  }

  // TODO force to use uniqueKey as unknown[] (migration to the new version of react-query)
  const key = Array.isArray(uniqueKey) ? [...uniqueKey, { currentPage }] : [uniqueKey, { currentPage }];
  const { data, isLoading, error, refetch } = useGet(key, paginationFetcher({ page: currentPage, size, dir, sort }), {
    ...rest
  });

  const nextPage = () => {
    if (urlLinked) {
      setParam(urlParam, (currentPage + 1).toString());
    }
    setCurrentPage(currentPage + 1);
  };
  const previousPage = () => {
    if (urlLinked) {
      setParam(urlParam, (Math.max(currentPage - 1, 1)).toString());
    }
    setCurrentPage(currentPage - 1);
  };

  const toPage = (newPage: number) => {
    if (urlLinked) {
      setParam(urlParam, newPage.toString());
    }
    setCurrentPage(newPage);
  };

  return {
    data,
    isLoading,
    error,
    refetch,
    nextPage,
    previousPage,
    toPage,
    currentPage,
    usedQueryKey: key
  };
};
